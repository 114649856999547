import React, { useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { DialogProps, Stack } from '@mui/material';

import { NoDataAvailable } from '../NoDataAvailable/NoDataAvailable';
import { SampleDetailsDialogBody } from './SampleDetailsDialogBody/SampleDetailsDialogBody';
import { SampleDetailsDialogHeader } from './SampleDetailsDialogHeader/SampleDetailsDialogHeader';
import { SampleDetailsDialogComparison } from './SampleDetailsDialogComparison/SampleDetailsDialogComparison';

import { sxStyles } from './SampleDetailsDialog.styles';
import { StyledDialogBase, StyledLoader } from '@/components/lib';

import { getAppStorage } from '@/helpers/utils/localStorage';
import { usePropertiesInfo } from '@/helpers/hooks/usePropertiesInfo';
import { UpdatePropertySchema } from '@/helpers/utils/deepCloneUpdates';
import { ReadPropertyConfigSchema, InteractionSchema } from '@/helpers/services/api';

import { SelectedColumnType } from '@/components/Samples/Samples.types';

interface SampleDetailsDialogProps extends DialogProps {
  appId?: number;
  isPentest?: boolean;
  isFetching?: boolean;
  samplesCount?: number;
  samples?: InteractionSchema[];
  isAnnotationInteraction?: boolean;
  sample: InteractionSchema | undefined;
  selectedColumns?: SelectedColumnType[];
  listOfProperties?: ReadPropertyConfigSchema[];
  interactionToCompare?: { application_version_id: number; user_interaction_id: string };
  closeDialog: () => void;
  getMoreInteractions?: () => void;
  updateProperty?: (updateData: UpdatePropertySchema) => void;
  updateAnnotation?: (interactionId: string, annotation: string, reason: string, isEstimated?: boolean) => void;
}

export const SampleDetailsDialog = (props: SampleDetailsDialogProps) => {
  const {
    appId,
    sample,
    samples,
    isPentest,
    samplesCount,
    selectedColumns,
    listOfProperties,
    isFetching = false,
    interactionToCompare,
    isAnnotationInteraction,
    closeDialog,
    updateProperty,
    updateAnnotation,
    getMoreInteractions,
    ...otherProps
  } = props;
  const { versionId } = getAppStorage();

  const [currentPage, setCurrentPage] = useState(() =>
    samples ? samples.findIndex(s => s.user_interaction_id === sample?.user_interaction_id) : 0
  );

  const [isComparisonView, setIsComparisonView] = useState(false);
  const [similarInteractionToCompare, setSimilarInteractionToCompare] = useState<
    { application_version_id: number; user_interaction_id: string } | undefined
  >();

  const { query, pathname, replace } = useRouter();

  const { getPropertyInfo } = usePropertiesInfo();

  const isTheLastPageOfTheList = (currentPage + 1) % 20 === 0;
  const sampleToUse = (samples && samples[currentPage]) || sample;
  const pageCount = interactionToCompare || similarInteractionToCompare ? 0 : Math.ceil(samplesCount || 0 / 1); // Pagination is hidden for similarity view
  const interactionId = samples ? samples[currentPage]?.user_interaction_id : sampleToUse?.user_interaction_id;

  const handleBack = () => setIsComparisonView(false);

  useEffect(() => {
    if (isTheLastPageOfTheList && getMoreInteractions) {
      getMoreInteractions();
    }

    if (interactionId && !isAnnotationInteraction) {
      replace(
        {
          pathname,
          query: {
            ...query,
            versionId: versionId,
            search_text: interactionId
          }
        },
        undefined,
        {}
      );
    }

    if (interactionToCompare) {
      setIsComparisonView(true);
    }
  }, [currentPage]);

  const curCloseDialog = () => {
    if (interactionId && !isAnnotationInteraction) {
      const queryCopy = { ...query };

      delete queryCopy.versionId;
      delete queryCopy.search_text;

      replace(
        {
          pathname,
          query: queryCopy
        },
        undefined,
        {}
      );
    }

    closeDialog();
    setSimilarInteractionToCompare(undefined);
  };

  return (
    <StyledDialogBase fullWidth maxWidth="lg" onClose={curCloseDialog} {...otherProps}>
      {isFetching ? (
        <StyledLoader />
      ) : sampleToUse ? (
        <Stack sx={sxStyles}>
          {isComparisonView ? (
            <SampleDetailsDialogComparison
              pageCount={pageCount}
              interaction={sampleToUse}
              currentPage={currentPage}
              selectedColumns={selectedColumns}
              interactionToCompare={interactionToCompare || similarInteractionToCompare}
              handleBack={handleBack}
              closeDialog={curCloseDialog}
              setCurrentPage={setCurrentPage}
              getPropertyInfo={getPropertyInfo}
              updateAnnotation={updateAnnotation}
            />
          ) : (
            <>
              <SampleDetailsDialogHeader
                versionId={versionId}
                pageCount={pageCount}
                currentPage={currentPage}
                iscComparisonView={isComparisonView}
                isAnnotationInteraction={isAnnotationInteraction}
                interaction={samples ? samples[currentPage] : sampleToUse}
                closeDialog={curCloseDialog}
                setCurrentPage={setCurrentPage}
                setIsComparisonView={setIsComparisonView}
              />
              <SampleDetailsDialogBody
                appId={appId}
                isPentest={isPentest}
                selectedColumns={selectedColumns}
                listOfProperties={listOfProperties}
                isAnnotationInteraction={isAnnotationInteraction}
                sample={samples ? samples[currentPage] : sampleToUse}
                updateProperty={updateProperty}
                getPropertyInfo={getPropertyInfo}
                updateAnnotation={updateAnnotation}
                setIsComparisonView={setIsComparisonView}
              />
            </>
          )}
        </Stack>
      ) : (
        <NoDataAvailable />
      )}
    </StyledDialogBase>
  );
};
