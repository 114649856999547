import React, { useEffect, useState } from 'react';

import { Stack } from '@mui/material';

import {
  SampleDetailsBodyProperties,
  SamplesDetailsBodyAllPropertiesType
} from './SampleDetailsBodyProperties/SampleDetailsBodyProperties';
import { AnnotationCell } from '@/components/shared/SamplesAnnotationCell/SamplesAnnotationCell';
import { SamplesDetailsBodyItemTag } from './SamplesDetailsBodyItemTag/SamplesDetailsBodyItemTag';
import { SamplesDetailsBodyInfoRetrievalItem } from './SamplesDetailsBodyInfoRetrievalItem/SamplesDetailsBodyInfoRetrievalItem';

import { StyledText, StyledToolTip } from '@/components/lib';
import { StyledSamplesDetailsBodyItemTag } from './SamplesDetailsBodyItemTag/SamplesDetailsBodyItemTag.styles';
import {
  StyledInteractionIdText,
  StyledAnnotationCellText,
  StyledAnnotationCellContainer,
  StyledAnnotationPropsContainer,
  StyledAnnotationAndPropsWrapper
} from '../../SampleDetailsDialogHeader/SampleDetailsDialogHeader.styles';
import {
  StyledContent,
  StyledSamplesDetailsBodyItemContainer,
  StyledSamplesDetailsBodySectionsContainer,
  StyledSamplesDetailsBodyItemColumnContainer,
  StyledSamplesDetailsBodyItemColumnContainerContent
} from './SamplesDetailsBodyItem.styles';

import { getAppStorage } from '@/helpers/utils/localStorage';
import { getMarkedText } from '@/helpers/utils/getMarkedText';
import { UpdatePropertySchema } from '@/helpers/utils/deepCloneUpdates';
import {
  StepType,
  StepSchema,
  StepSchemaType,
  TextEntrySchema,
  PerPropertySchema,
  InteractionSchema,
  ReadPropertyConfigSchema,
  PropertyDefinitionSchema,
  InteractionSchemaLlmPropertiesReasons,
  useGetInteractionPropertiesMetadataApiV1ApplicationVersionsApplicationVersionIdInteractionsUserInteractionIdPropertiesMetadataGet,
  listPropertiesConfigs
} from '@/helpers/services/api';

import { SelectedColumnType } from '@/components/Samples/Samples.types';
import { constants as tableConstants } from '../../../../Samples/SamplesTable/samplesTable.constants';

interface SamplesDetailsBodyItemProps {
  appId?: number;
  section: StepSchema;
  isPentest?: boolean;
  appVersionId: number;
  isFullPrompt: boolean;
  isTranslation?: boolean;
  isMultiSection: boolean;
  isInfoRetrieval?: boolean;
  userInteractionId?: string;
  isTranslationExist?: boolean;
  interaction: InteractionSchema;
  isAnnotationInteraction?: boolean;
  selectedColumns?: SelectedColumnType[];
  listOfProperties?: ReadPropertyConfigSchema[];
  properties: SamplesDetailsBodyAllPropertiesType;
  llmReasons?: InteractionSchemaLlmPropertiesReasons;
  multiSectionContent: TextEntrySchema[] | undefined;
  setSection: (section: string) => void;
  setIsTranslation: (isTranslation: boolean) => void;
  getPropertyInfo?: (name: string) => PropertyDefinitionSchema;
  updateProperty?: (updateData: UpdatePropertySchema) => void;
  updateAnnotation?: (interactionId: string, annotation: string, reason: string, isEstimated?: boolean) => void;
}

const constants = {
  stepLabel: 'Step:',
  paramsWidth: '250px',
  annotationLabel: 'Annotation:',
  parametersLabel: 'Parameters:',
  userInteractionIdLabel: 'User Interaction ID:',
  errorLabel: (err: string) => `Error = ${err}`,
  typeFormatted: (type?: StepSchemaType) => (type ? `Type = ${type}` : ''),
  inputLabel: (withInteractionPrefix?: boolean) => (withInteractionPrefix ? 'Interaction Input:' : 'Input:'),
  outputLabel: (withInteractionPrefix?: boolean) => (withInteractionPrefix ? 'Interaction Output:' : 'Output:')
};

const {
  stepLabel,
  paramsWidth,
  parametersLabel,
  annotationLabel,
  userInteractionIdLabel,
  inputLabel,
  outputLabel,
  errorLabel,
  typeFormatted
} = constants;

export const SamplesDetailsBodyItem = (props: SamplesDetailsBodyItemProps) => {
  const {
    appId,
    section,
    isPentest,
    properties,
    llmReasons,
    interaction,
    isFullPrompt,
    appVersionId,
    isTranslation,
    isMultiSection,
    isInfoRetrieval,
    selectedColumns,
    listOfProperties,
    userInteractionId,
    isTranslationExist,
    multiSectionContent,
    isAnnotationInteraction,
    setSection,
    updateProperty,
    getPropertyInfo,
    updateAnnotation,
    setIsTranslation
  } = props;

  const { interactionType } = getAppStorage();

  const [propertyToMark, setPropertyToMark] = useState('');
  const [listOfPropsToUse, setListOfPropsToUse] = useState(listOfProperties);

  const isStep = !!section?.name;
  const isMainInteraction = (!!section?.input || !!section?.output) && !isStep;
  const isSingleView = !isStep && !!((!section?.input && section?.output) || (section?.input && !section?.output));
  const isOnlyOutputStep = (isStep && !section?.input && !!section?.output) || (!!section?.input && !section?.output);

  const pageInteractionType = interactionType?.value;
  const curInteractionType = interaction?.interaction_type?.name;

  const { data: allMarkingDetails } =
    useGetInteractionPropertiesMetadataApiV1ApplicationVersionsApplicationVersionIdInteractionsUserInteractionIdPropertiesMetadataGet(
      appVersionId,
      `${userInteractionId}`
    );

  const chosenPropertyToMarkDetails = Array?.isArray(allMarkingDetails)
    ? allMarkingDetails?.find(el => el?.name === propertyToMark)
    : ({} as PerPropertySchema);

  useEffect(() => {
    if (isTranslationExist && !isTranslation) {
      setPropertyToMark('');
    }
  }, [isTranslationExist, isTranslation]);

  useEffect(() => {
    // If the current interaction type is not the same like the one on the page, need to fetch the properties of the new one
    if (curInteractionType && curInteractionType !== pageInteractionType) {
      listPropertiesConfigs({ app_id: Number(appId), interaction_type: curInteractionType }).then(res => {
        Array.isArray(res) && setListOfPropsToUse(res);
      });
    } else {
      // Set the list of properties to use to the one that is already fetched
      setListOfPropsToUse(listOfProperties);
    }
  }, [curInteractionType]);

  return (
    <StyledSamplesDetailsBodyItemContainer>
      <StyledSamplesDetailsBodySectionsContainer
        step={isStep || undefined}
        single={isSingleView ? 'true' : 'false'}
        is_info_retrieval={isMultiSection.toString()}
        is_only_output_step={isOnlyOutputStep ? 'true' : 'false'}
      >
        {section?.input && (
          <StyledSamplesDetailsBodyItemColumnContainer>
            <StyledText text={inputLabel(isMainInteraction)} type="small" />
            <StyledContent
              includeCopy
              translation={isTranslation}
              text={getMarkedText(
                section?.input,
                chosenPropertyToMarkDetails?.element_to_mark === 'input' && !isStep
                  ? chosenPropertyToMarkDetails
                  : undefined
              )}
            />
          </StyledSamplesDetailsBodyItemColumnContainer>
        )}
        {isMultiSection &&
          multiSectionContent?.map((el, index) => (
            <SamplesDetailsBodyInfoRetrievalItem
              key={index}
              index={index}
              infoRetrievalItem={el}
              isTranslation={isTranslation}
              markingDetails={
                chosenPropertyToMarkDetails?.metadata?.find(el => el?.document_index === index) && isInfoRetrieval
                  ? chosenPropertyToMarkDetails
                  : undefined
              }
            />
          ))}
        {!isMultiSection && section?.output && (
          <StyledSamplesDetailsBodyItemColumnContainer>
            <StyledText text={outputLabel(isMainInteraction)} type="small" />
            <StyledContent
              includeCopy
              translation={isTranslation}
              text={getMarkedText(
                section?.output,
                chosenPropertyToMarkDetails?.element_to_mark === 'output' && !isFullPrompt && !isStep
                  ? chosenPropertyToMarkDetails
                  : undefined
              )}
              important={section?.type === StepType['INFORMATION_RETRIEVAL']}
            />
          </StyledSamplesDetailsBodyItemColumnContainer>
        )}
        {isStep && (
          <StyledSamplesDetailsBodyItemColumnContainer>
            <StyledSamplesDetailsBodyItemColumnContainerContent>
              <StyledText text={stepLabel} type="small" width={paramsWidth} />
              <StyledSamplesDetailsBodyItemTag text={section?.name} />
              <StyledSamplesDetailsBodyItemTag text={tableConstants?.stepId(section?.id)} />
              <StyledSamplesDetailsBodyItemTag text={typeFormatted(section?.type)} />
              {section?.error && <StyledSamplesDetailsBodyItemTag text={errorLabel(section?.error)} />}
              <StyledText text={parametersLabel} type="small" width={paramsWidth} />
              {section?.attributes &&
                Object.keys(section?.attributes).map(i => (
                  <SamplesDetailsBodyItemTag key={i} index={i} section={section} />
                ))}
            </StyledSamplesDetailsBodyItemColumnContainerContent>
          </StyledSamplesDetailsBodyItemColumnContainer>
        )}
      </StyledSamplesDetailsBodySectionsContainer>
      <StyledAnnotationAndPropsWrapper>
        <Stack>
          <StyledText text={userInteractionIdLabel} type="small" />
          <StyledInteractionIdText text={userInteractionId} contrast="low" includeCopy />
        </Stack>
        <Stack>
          <StyledText text={annotationLabel} type="small" />
          <StyledAnnotationPropsContainer>
            {(!!updateAnnotation || isAnnotationInteraction) && (
              <StyledAnnotationCellContainer>
                <AnnotationCell
                  disableTooltip={true}
                  annotation={interaction?.annotation}
                  key={interaction?.user_interaction_id}
                  interactionId={interaction?.user_interaction_id}
                  estAnnotation={interaction?.estimated_annotation}
                  updateAnnotation={updateAnnotation}
                />
              </StyledAnnotationCellContainer>
            )}
            {interaction?.annotation?.reason && (
              <StyledToolTip text={interaction?.annotation?.reason}>
                <StyledAnnotationCellContainer>
                  <StyledAnnotationCellText text={interaction?.annotation?.reason} />
                </StyledAnnotationCellContainer>
              </StyledToolTip>
            )}
          </StyledAnnotationPropsContainer>
        </Stack>
        <SampleDetailsBodyProperties
          appId={appId}
          isPentest={isPentest}
          llmReasons={llmReasons}
          properties={properties}
          propertyToMark={propertyToMark}
          selectedColumns={selectedColumns}
          listOfProperties={listOfPropsToUse}
          allMarkingDetails={allMarkingDetails}
          userInteractionId={userInteractionId}
          excludeStorageProps={pageInteractionType !== curInteractionType}
          key={JSON.stringify(properties?.output) + `${listOfProperties?.length}`}
          switchToTranslated={isTranslationExist && !isTranslation}
          setSection={setSection}
          updateProperty={updateProperty}
          getPropertyInfo={getPropertyInfo}
          setIsTranslation={setIsTranslation}
          setPropertyToMark={setPropertyToMark}
          ignoreReasoningTooltip
        />
      </StyledAnnotationAndPropsWrapper>
    </StyledSamplesDetailsBodyItemContainer>
  );
};
