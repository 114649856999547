import { styled, Stack, Divider } from '@mui/material';

import { StyledContainer, StyledText } from '@/components/lib';

import { fadeInAnimation } from '@/helpers/animations';

export const StyledSamplesDetailsBodyItemContainer = styled(StyledContainer)({
  gap: '24px',
  padding: '8px',
  display: 'grid',
  height: '580px',
  overflowX: 'auto',
  transition: '0.2s',
  overflowY: 'hidden',
  gridTemplateColumns: 'auto 240px',
  animation: `${fadeInAnimation} 0.5s ease`,

  '.MuiTypography-h5': { padding: '0 4px' },

  '@media (max-height: 790px)': { height: '70vh' },
  '@media (max-height: 610px)': { height: '67vh' }
});

interface StyledSamplesDetailsBodySectionsContainerProps {
  step?: boolean;
  single?: string;
  is_info_retrieval?: string;
  is_only_output_step?: string;
}

export const StyledSamplesDetailsBodySectionsContainer = styled(
  StyledContainer
)<StyledSamplesDetailsBodySectionsContainerProps>(({ step, single, is_info_retrieval, is_only_output_step }) => ({
  gap: '24px',
  padding: '0',
  flexDirection: 'column',
  display: is_info_retrieval === 'true' ? 'flex' : 'grid',
  overflow: is_info_retrieval === 'true' ? 'auto' : 'none',
  paddingBottom: is_info_retrieval === 'true' ? '15px' : 0,
  gridTemplateColumns:
    is_info_retrieval === 'true'
      ? 'none'
      : step
        ? is_only_output_step === 'true'
          ? '49% 49%'
          : '32% 32% 29%'
        : single === 'true'
          ? '98%'
          : '49% 49%'
}));

export const StyledSamplesDetailsBodyItemColumnContainer = styled(StyledContainer)({
  gap: '12px',
  width: '100%',
  height: '580px',
  minWidth: '250px',
  overflow: 'hidden',

  '@media (max-height: 790px)': { height: '70vh' },
  '@media (max-height: 610px)': { height: '67vh' }
});

export const StyledBodyItemContainer = styled(Stack)({
  overflow: 'unset'
});

export const StyledSamplesDetailsBodyItemColumnContainerContent = styled(StyledContainer)({
  gap: '24px',
  display: 'grid',
  overflow: 'auto',
  paddingRight: 0,

  '& > p': { overflow: 'visible' }
});

export const StyledPropertiesText = styled(StyledText)(({ theme }) => ({
  flexShrink: 0,
  fontWeight: 700,
  margin: '24px 0 0 0',
  color: theme.palette.grey[700]
}));

interface StyledContentProps {
  property?: string;
  important?: boolean;
  translation?: boolean;
  marking_indexes?: { start: number; end: number; property?: string };
}

export const StyledContent = styled(StyledText)<StyledContentProps>(({ important, translation, theme }) => ({
  padding: '16px',
  fontWeight: 700,
  overflow: 'auto',
  transition: '0.4s',
  borderRadius: '16px',
  wordBreak: 'break-word',
  maxHeight: 'calc(100% - 46px)',
  color: theme.palette.grey[700],
  background: theme.palette.grey[100],
  border: `2px solid ${translation ? theme.palette.primary.main : important ? theme.palette.grey[600] : 'transparent'}`
}));

export const StyledPropertiesContainer = styled(Stack)({
  height: 'auto'
});

export const StyledPropertiesScrollContainer = styled(Stack, {
  shouldForwardProp: prop => prop !== 'row'
})<{ row?: boolean }>(({ row }) => ({
  gap: '12px',
  overflow: 'auto',
  marginTop: row ? '8px' : '0px',
  flexDirection: row ? 'row' : 'column'
}));

export const StyledRowContainer = styled(Stack)({
  gap: '12px',
  flexDirection: 'row',
  alignItems: 'center'
});

export const StyledTitleDivider = styled(Divider)(({ theme }) => ({
  height: '2px',
  width: 'calc(100% - 155px)',
  background: theme.palette.grey[200]
}));

export const PropertyAddCardContainer = styled(StyledContainer)(({ theme }) => ({
  cursor: 'pointer',
  maxWidth: '163px',
  minWidth: '163px',
  background: 'transparent',
  border: `2px solid ${theme.palette.grey[300]}`
}));

export const PropertiesAddCardText = styled(StyledText)(({ theme }) => ({
  margin: 'auto',
  color: theme.palette.grey[400]
}));
