/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * Deepchecks LLM Evaluation
 * Deepchecks LLM Evaluation and Monitoring API Spec
 * OpenAPI spec version: 0.23.4.post84.dev0+5114d77cb
 */
import { useMutation, useQuery } from 'react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from 'react-query';
import { customInstance } from './customAxios';
import type { ErrorType } from './customAxios';

export type AnnotationOrigin = (typeof AnnotationOrigin)[keyof typeof AnnotationOrigin];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AnnotationOrigin = {
  user: 'user',
  llm: 'llm',
  similarity: 'similarity',
  property: 'property',
  garak: 'garak',
  Deepchecks_Evaluator: 'Deepchecks Evaluator',
  No_Annotation_Assigned: 'No Annotation Assigned'
} as const;

export type CreateInteractions201Item = string | string;

export type DeleteInteractionSessionsDeprecatedParams = {
  ids?: string[];
};

export type ListInteractionSessionsDeprecatedParams = {
  /**
   * Index from where to start data retrieval
   */
  offset?: number;
  /**
   * Maximum number of items to return
   */
  limit?: number;
};

export type DeleteInteractionSessionsParams = {
  ids?: string[];
};

export type ListInteractionSessionsParams = {
  /**
   * Index from where to start data retrieval
   */
  offset?: number;
  /**
   * Maximum number of items to return
   */
  limit?: number;
};

export type GetPropertyMonitorApiV1ApplicationVersionsApplicationVersionIdGetPropertyMonitorPostParams = {
  /**
   * Property display name
   */
  property_name: string;
  /**
   * Property type
   */
  property_type: PropertyType;
};

export type GetDefaultTimeRangeApiV1ApplicationVersionsApplicationVersionIdDefaultTimeRangeGetParams = {
  env_type: EnvType;
};

export type GetConfigApiV1DatadogConfigGetParams = {
  /**
   * Application ID
   */
  application_id?: number;
};

export type SetDatadogUserConfigApiV1DatadogUserConfigPutParams = {
  /**
   * Application ID
   */
  application_id?: number;
};

export type GetUsedTokensParams = {
  year?: number;
  month?: number;
};

export type GetUsageBillParams = {
  year?: number;
  month?: number;
};

export type GetUsagePlanParams = {
  year?: number;
  month?: number;
};

export type GetActiveUsagePlan200 = UserUsagePlanSchema | null;

export type UpdateUiPreferencesApiV1UiPreferencesPut200 = { [key: string]: unknown };

export type UpdateUiPreferencesApiV1UiPreferencesPutBody = { [key: string]: unknown };

export type GetUiPreferencesApiV1UiPreferencesGet200 = { [key: string]: unknown };

export type GetSimilarApiV1ApplicationVersionsComparisonPutParams = {
  /**
   * User provided interaction id
   */
  user_interaction_id?: string;
  /**
   * Application id
   */
  application_id: number;
  /**
   * Environment type
   */
  env_type: EnvType;
};

export type ListAppLogsParams = {
  start_time_epoch?: number | null;
  end_time_epoch?: number | null;
  log_level?: AppLogLevelType | null;
  message_type?: AppLogMessageType | null;
  limit?: number;
  offset?: number;
};

export type ListLlmBankPropsParams = {
  app_id: number;
};

export type GetTestRunInteractionIdApiV1ApplicationsAppIdInteractionForLlmTestGetParams = {
  pipeline_elements_to_use: string[];
  interaction_type?: string | null;
  limit?: number;
};

export type DeleteLlmPropsDefinitionsParams = {
  prop_names_to_delete: string[];
  interaction_type?: string | null;
};

export type ListLlmPropsDefinitionsParams = {
  interaction_type?: string | null;
};

export type DeleteCustomPropDefinitionParams = {
  prop_names_to_delete: string[];
  interaction_type?: string | null;
};

export type ListCustomPropDefinitionParams = {
  interaction_type?: string | null;
};

export type GenerateTopicsApiV1GenerateTopicsPostParams = {
  env_type: EnvType;
  app_name?: string | null;
  app_version_name?: string | null;
  app_version_id?: number | null;
  /**
   * For internal use only
   */
  interaction_id?: string[] | null;
};

export type GenerateSamplesApiV1GoldensetGeneratorTaskTaskIdGeneratePostParams = {
  /**
   * The application id to generate the samples for
   */
  application_id: number;
  /**
   * The environment type to generate the samples for
   */
  env_type: EnvType;
};

export type DeleteSourceApiV1GoldensetGeneratorTaskTaskIdDataSourceSourceIdDelete200 = { [key: string]: unknown };

export type AddSourceApiV1GoldensetGeneratorTaskTaskIdDataSourcePost200 = { [key: string]: unknown };

export type GetGeneratedInputsApiV1GenerateUserInputsPostParams = {
  /**
   * The application id to generate the samples for
   */
  application_id: number;
  /**
   * The environment type to generate the samples for
   */
  env_type: EnvType;
};

export type RecalculateInsightsApiV1InsightsRecalculatePostParams = {
  application_version_id: number;
  environment: EnvType;
};

export type GetTopInsightsPerAppApiV1TopInsightsPerAppGetParams = {
  application_id: number;
  env_type?: EnvType;
  insight_type?: InsightType | null;
  interaction_type_name?: string | null;
};

export type GetInsightApiV1InsightsGetParams = {
  application_version_id: number;
  env_type?: EnvType;
  insight_type?: InsightType | null;
  interaction_type_name?: string | null;
};

export type GetAppLastUpdateTimeParams = {
  application_version_id: number;
  env_type: EnvType;
};

export type GetInteractionCompletionStatusApiV1ApplicationVersionsApplicationVersionIdInteractionsCompleteStatusPost200 =
  { [key: string]: CompleteStatusSchema };

export type GetSimilarityScoreDeprecated200 = SimilarityScore | null;

export type GetSimilarityScoreDeprecatedParams = {
  interaction_user_id_1: string;
  interaction_user_id_2: string;
  interaction_version_id_1: number;
  interaction_version_id_2: number;
};

export type GetSimilarityScoreApiV1ApplicationVersionsGetSimilarityScoreGet200 = SimilarityScore | null;

export type GetSimilarityScoreApiV1ApplicationVersionsGetSimilarityScoreGetParams = {
  interaction_user_id_1: string;
  interaction_user_id_2: string;
  interaction_version_id_1: number;
  interaction_version_id_2: number;
};

/**
 * Filter for interactions to delete or list of user interaction ids.
 */
export type DeleteInteractionsApiV1ApplicationVersionsApplicationVersionIdInteractionsDeleteBody =
  | FilterSchema
  | string[]
  | null;

export type ListInteractionsDownloadByFilterBody = FilterSchema | null;

export type ListInteractionsDownloadByFilterParams = {
  return_topics?: boolean;
  return_output_props?: boolean;
  return_input_props?: boolean;
  return_custom_props?: boolean;
  return_llm_props?: boolean;
  return_garak_props?: boolean;
  return_annotation_data?: boolean;
  return_similarities_data?: boolean;
  return_steps?: boolean;
  include_incomplete?: boolean;
};

export type ListInteractionsByFilterBody = InteractionsFilterWithPaginationSchema | null;

export type ListInteractionsByFilterParams = {
  return_topics?: boolean;
  return_input_props?: boolean;
  return_output_props?: boolean;
  return_annotation_data?: boolean;
  return_similarities_data?: boolean;
  return_translated_data?: boolean;
  include_incomplete?: boolean;
};

export type DownloadInteractionsParams = {
  return_topics?: boolean;
  return_output_props?: boolean;
  return_input_props?: boolean;
  return_custom_props?: boolean;
  return_llm_props?: boolean;
  return_garak_props?: boolean;
  return_annotation_data?: boolean;
  return_similarities_data?: boolean;
  return_steps?: boolean;
};

export type RetrieveAnnotationStatsByFilter200 = { [key: string]: number };

export type GetStepsForInteractionDeprecatedParams = {
  step_id?: string;
};

export type GetStepsForInteractionApiV1ApplicationVersionsAppVersionIdInteractionsUserInteractionIdStepsGetParams = {
  step_id?: string;
};

export type AnnotateStepApiV1InteractionsStepsStepIdAnnotatePutParams = {
  annotation: AnnotationType;
};

export type GetPentestPromptsPerCategoryApiV1PentestInputsPerCategoryGetParams = {
  app_id: number;
  categories: string[];
};

export type GetPentestPromptsApiV1PentestPromptsGetParams = {
  app_id: number;
  probes?: string[] | null;
};

export type GetPropertyHistogramParams = {
  /**
   * Property display name
   */
  property_name: string;
  /**
   * Property type
   */
  property_type: PropertyType;
  /**
   * Number of bins for the histogram
   */
  number_of_bins?: number;
  interaction_type?: string | null;
  /**
   * Number of samples to take for the histogram
   */
  samples_count?: number;
};

export type GetInteractionsPerPropertyApiV1InteractionsPerPropertyGet200 = { [key: string]: TextScore[] };

export type GetInteractionsPerPropertyApiV1InteractionsPerPropertyGetParams = {
  app_version_id: number;
  property_config_id: number;
  order_by_desc?: boolean;
  string_category_filter?: string | null;
  only_none_category_filter?: boolean;
  limit?: number;
  offset?: number;
};

export type ListPropertiesDataDeprecated200 = { [key: string]: { [key: string]: number | CategoryPropertyValue[] } };

export type ListPropertiesDataDeprecatedParams = {
  property_config_id?: number | null;
  start_time_epoch?: number | null;
  end_time_epoch?: number | null;
  interaction_type?: string | null;
};

export type ListPropertiesDataApiV1ApplicationVersionsAppVersionIdPropertiesConfigDataGet200 = {
  [key: string]: { [key: string]: number | CategoryPropertyValue[] };
};

export type ListPropertiesDataApiV1ApplicationVersionsAppVersionIdPropertiesConfigDataGetParams = {
  property_config_id?: number | null;
  start_time_epoch?: number | null;
  end_time_epoch?: number | null;
  interaction_type?: string | null;
};

export type ListPropertiesConfigsParams = {
  app_id: number;
  only_in_dashboard?: boolean;
  interaction_type?: string | null;
};

export type ListPropertiesDefinitionsParams = {
  enviroment?: EnvType | null;
  only_non_hidden?: boolean;
  only_in_dashboard?: boolean;
  interaction_type?: string | null;
};

export type GetAppVersionProdSummaryParams = {
  environment?: EnvType;
  interaction_type?: string;
  start_time_epoch?: number | null;
  end_time_epoch?: number | null;
};

export type ListAppVersionsExtendedParams = {
  app_id: number;
  only_user_annotations_score?: boolean;
  order_by_column?: string;
  order_by_env_type?: EnvType;
  order_by_model?: OrderByVersionExtendedModel;
  order_by_direction?: OrderByDirection;
  order_by_n_of_samples?: boolean;
  order_by_score?: boolean;
  order_by_sessions_score?: boolean;
  search_version_name?: string;
  overlapping_version_ids?: number[];
  order_by_similarity_to_version_id?: number | null;
  order_by_similarity_score?: SimilarityMetricEnum;
  include_similarities_to_versions?: number[];
  interaction_type?: string | null;
};

export type DeleteAppVersionParams = {
  app_id?: number | null;
  id?: number | null;
  name?: string | null;
};

export type ListAppVersionsParams = {
  app_id?: number[] | null;
  id?: number[] | null;
  name?: string[] | null;
  app_name?: string[] | null;
};

export type RetrainDcEvaluatorParams = {
  /**
   * List of version ids to train on
   */
  application_version_ids: number[];
};

export type HasRunningTasksParams = {
  application_id?: number;
  environment?: EnvType;
  actor_key?: ActorKey | string;
  application_version_id?: number;
};

export type DeleteAppParams = {
  id?: number | null;
  name?: string | null;
};

export type ListAppsParams = {
  id?: number[] | null;
  name?: string[] | null;
};

export type AdminListAppsParams = {
  id?: number[] | null;
  name?: string[] | null;
};

export type UpdateConnectionApiV1AdminConnectionsConnectionIdPutBody =
  | OpenaiConnectionsSchema
  | AzureOpenaiConnectionsSchema
  | BedrockConnectionsSchema;

export type CreateConnectionApiV1AdminConnectionsPostBody =
  | OpenaiConnectionsSchema
  | AzureOpenaiConnectionsSchema
  | BedrockConnectionsSchema;

export type AdminListInteractionsByFilterParams = {
  return_topics?: boolean;
  return_input_props?: boolean;
  return_output_props?: boolean;
  return_annotation_data?: boolean;
  return_similarities_data?: boolean;
  return_translated_data?: boolean;
};

export type GetConfigApiV1OrganizationSchemaNameDatadogConfigGetParams = {
  /**
   * Application ID
   */
  application_id: number;
};

export type SetDatadogSystemConfigApiV1OrganizationSchemaNameDatadogSystemConfigPutParams = {
  /**
   * Application ID
   */
  application_id?: number;
};

export type GetRawConfigApiV1ConfigsOrganizationSchemaNameDetailedGetParams = {
  /**
   * Category
   */
  category: CategoryName;
  /**
   * Application ID
   */
  application_id?: number;
  /**
   * Environment
   */
  environment?: EnvType;
};

export type GetConfigApiV1ConfigsOrganizationSchemaNameGetParams = {
  /**
   * Application ID
   */
  application_id?: number;
  /**
   * Environment
   */
  environment?: EnvType;
  /**
   * Category
   */
  category: CategoryName;
};

/**
 * Data
 */
export type CreateConfigApiV1ConfigsOrganizationSchemaNamePutBody = { [key: string]: unknown };

export type CreateConfigApiV1ConfigsOrganizationSchemaNamePutParams = {
  /**
   * Application ID
   */
  application_id?: number;
  /**
   * Environment
   */
  environment?: EnvType;
  /**
   * Category
   */
  category: CategoryName;
};

export type GetUsageBillApiV1UsageOrganizationSchemaNameGetParams = {
  year?: number;
  month?: number;
};

export type SetUsagePriceApiV1UtilsOrganizationSchemaNameGetParams = {
  /**
   * Organization schema name search pattern (%...%)
   */
  organization_pattern: string;
};

export type RecalcNonOssPropertiesApiV1OrganizationOrganizationSchemaNameRecalcNonOssPropertiesPutParams = {
  application_id?: number;
  application_name?: string;
  application_version_ids?: number[];
  application_version_names?: string[];
  /**
   * List of non oss property names to recalculate, None means all non oss will be recalculated
   */
  properties_to_calc?: string[];
  reset_estimated_annotations?: boolean;
};

export type RecalcEvalProbasApiV1OrganizationOrganizationSchemaNameRecalcProbasPutParams = {
  application_id?: number;
  application_name?: string;
  application_version_ids?: number[];
  application_version_names?: string[];
  only_calc_none?: boolean;
};

export type DeleteUserFromOrgApiV1OrganizationsOrganizationSchemaNameUsersEmailDeleteEntityType =
  (typeof DeleteUserFromOrgApiV1OrganizationsOrganizationSchemaNameUsersEmailDeleteEntityType)[keyof typeof DeleteUserFromOrgApiV1OrganizationsOrganizationSchemaNameUsersEmailDeleteEntityType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeleteUserFromOrgApiV1OrganizationsOrganizationSchemaNameUsersEmailDeleteEntityType = {
  user: 'user',
  invite: 'invite'
} as const;

export type DeleteUserFromOrgApiV1OrganizationsOrganizationSchemaNameUsersEmailDeleteParams = {
  entity_type: DeleteUserFromOrgApiV1OrganizationsOrganizationSchemaNameUsersEmailDeleteEntityType;
};

export type GetCompleteDetailsApiV1UsersCompleteDetailsGetParams = {
  organization_name: string;
};

export type DeepchecksLlmEvalApiV1SchemasRecordIdentifierSchemaId = number | string;

export interface DeepchecksLlmEvalApiV1SchemasRecordIdentifierSchema {
  id: DeepchecksLlmEvalApiV1SchemasRecordIdentifierSchemaId;
}

/**
 * Schema for the organization.
 */
export interface DeepchecksLlmEvalApiV1PublicOrganizationOrganizationSchema {
  name: string;
}

export type DeepchecksLlmEvalApiV1MeteringUsageUsageReportExtraAnyOf = { [key: string]: unknown };

export type DeepchecksLlmEvalApiV1MeteringUsageUsageReportExtra =
  DeepchecksLlmEvalApiV1MeteringUsageUsageReportExtraAnyOf | null;

export interface DeepchecksLlmEvalApiV1MeteringUsageUsageReport {
  /** Total bill (in $) for the given period */
  bill: number;
  extra?: DeepchecksLlmEvalApiV1MeteringUsageUsageReportExtra;
  llm_tokens: number;
  tokens_sent: number;
  translation_units: number;
}

export interface DeepchecksLlmEvalApiV1GeneralRecordIdentifierSchema {
  app_latest_update_time: number;
  insights: StatusSchema;
  topics: StatusSchema;
}

/**
 * Usage breakdown (tokens) for the given period
 */
export type DeepchecksLlmEvalApiV1DeepchecksOnlyUsageUsageReportUsageBreakdown = { [key: string]: number };

export interface DeepchecksLlmEvalApiV1DeepchecksOnlyUsageUsageReport {
  /** Total bill (in $) for the given period */
  bill: number;
  /** Usage breakdown (tokens) for the given period */
  usage_breakdown: DeepchecksLlmEvalApiV1DeepchecksOnlyUsageUsageReportUsageBreakdown;
}

export interface DeepchecksLlmEvalApiV1DeepchecksOnlyOrganizationOrganizationSchema {
  name: string;
  schema_name: string;
}

export type VersionMetadataSchemaTimestamp = string | null;

export interface VersionMetadataSchema {
  env_type: EnvType;
  id: number;
  name: string;
  timestamp?: VersionMetadataSchemaTimestamp;
}

export type VersionsMetadataSchemaProduction = VersionMetadataSchema | null;

export type VersionsMetadataSchemaMostPerforming = VersionMetadataSchema | null;

export type VersionsMetadataSchemaMostAnnotated = VersionMetadataSchema | null;

export type VersionsMetadataSchemaEvaluation = VersionMetadataSchema | null;

export interface VersionsMetadataSchema {
  evaluation: VersionsMetadataSchemaEvaluation;
  most_annotated: VersionsMetadataSchemaMostAnnotated;
  most_performing: VersionsMetadataSchemaMostPerforming;
  production: VersionsMetadataSchemaProduction;
}

export type ValidationErrorLocItem = string | number;

export interface ValidationError {
  loc: ValidationErrorLocItem[];
  msg: string;
  type: string;
}

export type UserUsagePlanSchemaUsageLimit = number | null;

export type UserUsagePlanSchemaUsage = PlanUsageSchema | null;

export type UserUsagePlanSchemaDescription = string | null;

export interface UserUsagePlanSchema {
  base_price: number;
  description: UserUsagePlanSchemaDescription;
  is_translation_on: boolean;
  llm_price: number;
  plan_type: PlanType;
  status: UsagePlanStatus;
  timestamp: string;
  usage?: UserUsagePlanSchemaUsage;
  usage_limit: UserUsagePlanSchemaUsageLimit;
  usage_percentage: number;
}

export interface UserUpdateSchema {
  name: string;
}

export type UserSchemaOrganizationName = string | null;

export type UserSchemaName = string | null;

export type UserSchemaLastLogin = string | null;

export type UserSchemaCreatedAt = string | null;

/**
 * Schema for user with roles.
 */
export interface UserSchema {
  created_at?: UserSchemaCreatedAt;
  deployment_type?: string;
  email: string;
  email_verified: boolean;
  eula: boolean;
  invitation_accepted?: boolean;
  last_login?: UserSchemaLastLogin;
  name: UserSchemaName;
  organization_name: UserSchemaOrganizationName;
}

export type UserInvitationSchemaName = string | null;

export type UserInvitationSchemaLastLogin = string | null;

export type UserInvitationSchemaInvitationExpirationDate = string | null;

export type UserInvitationSchemaCreatedAt = string | null;

export interface UserInvitationSchema {
  created_at?: UserInvitationSchemaCreatedAt;
  email: string;
  invitation_accepted?: boolean;
  invitation_expiration_date?: UserInvitationSchemaInvitationExpirationDate;
  last_login?: UserInvitationSchemaLastLogin;
  name: UserInvitationSchemaName;
}

export type UsagePlanStatus = (typeof UsagePlanStatus)[keyof typeof UsagePlanStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UsagePlanStatus = {
  blocked: 'blocked',
  warning: 'warning',
  ok: 'ok',
  'not-active': 'not-active'
} as const;

/**
 * Monthly budget limit for the plan
 */
export type UsagePlanSchemaUsageLimit = number | null;

/**
 * Timestamp of the plan
 */
export type UsagePlanSchemaTimestamp = string | null;

export interface UsagePlanSchema {
  /** Price per ingested 1m token with dc eval */
  base_price: number;
  /** Description of the plan */
  description?: string;
  /** Price per ingested 1m token wo dc eval */
  lite_price: number;
  /** Maximum number of base tokens */
  max_base_tokens: number;
  /** Maximum number of llm properties tokens */
  max_llm_properties: number;
  /** Maximum number of applications */
  max_number_of_applications: number;
  /** Maximum number of users */
  max_number_of_users: number;
  /** Maximum number of translations */
  max_translations: number;
  /** Plan type */
  plan_type: PlanType;
  /** llm property pricing per 1m token */
  property_price: number;
  /** Timestamp of the plan */
  timestamp?: UsagePlanSchemaTimestamp;
  /** Price per translated 1m characters */
  translation_price: number;
  /** Monthly budget limit for the plan */
  usage_limit?: UsagePlanSchemaUsageLimit;
}

export interface UsageModel {
  show_cost?: boolean;
  show_limit?: boolean;
}

export interface UpdateLLMPropDefinitionSchema {
  force_update?: boolean;
  new_name: string;
  old_name: string;
}

export type UpdateInteractionTypeSchemaName = string | null;

export type UpdateInteractionTypeSchemaIconId = number | null;

export type UpdateInteractionTypeSchemaColorCode = string | null;

export interface UpdateInteractionTypeSchema {
  color_code?: UpdateInteractionTypeSchemaColorCode;
  icon_id?: UpdateInteractionTypeSchemaIconId;
  name?: UpdateInteractionTypeSchemaName;
}

export type UpdateInteractionSessionSchemaUserAnnotationReason = string | null;

export type UpdateInteractionSessionSchemaUserAnnotation = AnnotationType | null;

export interface UpdateInteractionSessionSchema {
  user_annotation?: UpdateInteractionSessionSchemaUserAnnotation;
  user_annotation_reason?: UpdateInteractionSessionSchemaUserAnnotationReason;
}

export type UpdateInteractionSchemaSteps = NewStepSchema[] | null;

export type UpdateInteractionSchemaOutput = string | null;

export type UpdateInteractionSchemaIsCompleted = boolean | null;

export type UpdateInteractionSchemaInput = string | null;

export type UpdateInteractionSchemaInformationRetrieval = string[] | string | null;

export type UpdateInteractionSchemaFinishedAt = string | number | null;

export type UpdateInteractionSchemaCustomPropertiesAnyOf = { [key: string]: unknown };

export type UpdateInteractionSchemaCustomProperties = UpdateInteractionSchemaCustomPropertiesAnyOf | null;

export type UpdateInteractionSchemaAnnotationReason = string | null;

export type UpdateInteractionSchemaAnnotation = AnnotationType | null;

export interface UpdateInteractionSchema {
  annotation?: UpdateInteractionSchemaAnnotation;
  annotation_reason?: UpdateInteractionSchemaAnnotationReason;
  custom_properties?: UpdateInteractionSchemaCustomProperties;
  finished_at?: UpdateInteractionSchemaFinishedAt;
  information_retrieval?: UpdateInteractionSchemaInformationRetrieval;
  input?: UpdateInteractionSchemaInput;
  is_completed?: UpdateInteractionSchemaIsCompleted;
  output?: UpdateInteractionSchemaOutput;
  steps?: UpdateInteractionSchemaSteps;
}

export type UpdateInteractionCustomPropertySchemaValue = number | number | string[];

export interface UpdateInteractionCustomPropertySchema {
  property_name: string;
  value: UpdateInteractionCustomPropertySchemaValue;
}

export interface UpdateInsightSchema {
  is_deleted: boolean;
}

export type UpdateCustomPropDefinitionSchemaIsEditable = boolean | null;

export type UpdateCustomPropDefinitionSchemaDescription = string | null;

export interface UpdateCustomPropDefinitionSchema {
  description?: UpdateCustomPropDefinitionSchemaDescription;
  force_update?: boolean;
  is_editable?: UpdateCustomPropDefinitionSchemaIsEditable;
  new_name: string;
  old_name: string;
}

export type UpdateApplicationVersionSchemaDescription = string | null;

export type UpdateApplicationVersionSchemaCustomAnyOfItem = { [key: string]: unknown };

export type UpdateApplicationVersionSchemaCustom = UpdateApplicationVersionSchemaCustomAnyOfItem[] | null;

export interface UpdateApplicationVersionSchema {
  ai_model?: AIModel;
  app_version_id: number;
  custom?: UpdateApplicationVersionSchemaCustom;
  description?: UpdateApplicationVersionSchemaDescription;
  name: string;
}

export type TokensSchemaExtraAnyOf = { [key: string]: unknown };

export type TokensSchemaExtra = TokensSchemaExtraAnyOf | null;

export interface TokensSchema {
  extra?: TokensSchemaExtra;
  llm_tokens: number;
  tokens_sent: number;
  translation_units: number;
}

export type TextScoreScore = number | string[] | null;

export type TextScoreEstimatedAnnotation = AnnotationSchema | null;

export type TextScoreAnnotation = AnnotationSchema | null;

export interface TextScore {
  annotation: TextScoreAnnotation;
  estimated_annotation: TextScoreEstimatedAnnotation;
  input: string;
  output: string;
  score: TextScoreScore;
  user_interaction_id: string;
}

export interface TextEntrySchema {
  created_at: string;
  data: string;
  id: string;
  updated_at: string;
}

export type StepType = (typeof StepType)[keyof typeof StepType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StepType = {
  LLM: 'LLM',
  INFORMATION_RETRIEVAL: 'INFORMATION_RETRIEVAL',
  TRANSFORMATION: 'TRANSFORMATION',
  FILTER: 'FILTER',
  FINE_TUNING: 'FINE_TUNING',
  PII_REMOVAL: 'PII_REMOVAL',
  UDF: 'UDF'
} as const;

export type StepSchemaType = StepType | null;

export type StepSchemaStartedAt = string | number | number | null;

export type StepSchemaOutput = string | null;

export type StepSchemaInput = string | null;

export type StepSchemaFinishedAt = string | number | number | null;

export type StepSchemaError = string | null;

export interface StepSchema {
  annotation?: StepSchemaAnnotation;
  application_version_id: number;
  attributes: StepSchemaAttributes;
  error?: StepSchemaError;
  finished_at?: StepSchemaFinishedAt;
  id: string;
  input?: StepSchemaInput;
  interaction_id: string;
  name: string;
  output?: StepSchemaOutput;
  started_at?: StepSchemaStartedAt;
  type?: StepSchemaType;
  user_interaction_id: string;
}

export type StepSchemaAttributesAnyOf = { [key: string]: unknown };

export type StepSchemaAttributes = StepSchemaAttributesAnyOf | null;

export type StepSchemaAnnotation = AnnotationType | null;

export interface StatusSchema {
  can_execute_calculation: boolean;
  in_progress: boolean;
}

/**
 * Status of a task processed by service worker
 */
export type Status = (typeof Status)[keyof typeof Status];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Status = {
  enqueued: 'enqueued',
  executing: 'executing',
  scheduled_for_retry: 'scheduled_for_retry',
  failed: 'failed',
  success: 'success'
} as const;

export interface SimilarityScore {
  blue_1_score: number;
  rouge_1_score: number;
  score: number;
}

export type SimilarityReasonSchemaVersionId = number | null;

export type SimilarityReasonSchemaUserInteractionId = string | null;

export interface SimilarityReasonSchema {
  user_interaction_id: SimilarityReasonSchemaUserInteractionId;
  version_id: SimilarityReasonSchemaVersionId;
}

export type SimilarityMetricEnum = (typeof SimilarityMetricEnum)[keyof typeof SimilarityMetricEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SimilarityMetricEnum = {
  similarity: 'similarity',
  blue_1_similarity: 'blue_1_similarity',
  rouge_1_similarity: 'rouge_1_similarity'
} as const;

/**
 * Billable services.
 */
export type ServiceName = (typeof ServiceName)[keyof typeof ServiceName];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ServiceName = {
  LITE_TOKENS: 'LITE_TOKENS',
  BASE_TOKENS: 'BASE_TOKENS',
  TRANSLATION: 'TRANSLATION',
  LLM_PROPERTIES: 'LLM_PROPERTIES',
  LLM_PROPERTIES_TEST: 'LLM_PROPERTIES_TEST',
  SAMPLE_GENERATION: 'SAMPLE_GENERATION',
  DC_EVALUATOR: 'DC_EVALUATOR'
} as const;

export type SearchSchemaFieldNamesItem = (typeof SearchSchemaFieldNamesItem)[keyof typeof SearchSchemaFieldNamesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SearchSchemaFieldNamesItem = {
  output: 'output',
  input: 'input',
  user_interaction_id: 'user_interaction_id',
  session_id: 'session_id'
} as const;

export interface SearchSchema {
  accent_sensitive?: boolean;
  case_sensitive?: boolean;
  field_names: SearchSchemaFieldNamesItem[];
  is_exact?: boolean;
  text: string;
}

export type ResetAnnotationPayloadInteractionType = string | null;

export interface ResetAnnotationPayload {
  envs: EnvType[];
  interaction_type?: ResetAnnotationPayloadInteractionType;
  retrain_dc_evaluator?: boolean;
  versions: number[];
}

export type RerunCalculationsSchemaInteractionIds = string[] | null;

export interface RerunCalculationsSchema {
  application_version_id: number;
  interaction_ids?: RerunCalculationsSchemaInteractionIds;
}

export interface RecordIdentifiersPairSchema {
  id: number;
  name: string;
}

export type ReadPropertyConfigSchemaThreshold = number | null;

export type ReadPropertyConfigSchemaInteractionType = string | null;

export type ReadPropertyConfigSchemaCondition = Operator | null;

export interface ReadPropertyConfigSchema {
  application_id: number;
  condition: ReadPropertyConfigSchemaCondition;
  id: number;
  interaction_type?: ReadPropertyConfigSchemaInteractionType;
  kind: PropertyType;
  text_property: string;
  threshold: ReadPropertyConfigSchemaThreshold;
}

export type ReadInteractionTypeSchemaId = number | null;

export type ReadInteractionTypeSchemaIconId = number | null;

export type ReadInteractionTypeSchemaColorCode = string | null;

export interface ReadInteractionTypeSchema {
  color_code?: ReadInteractionTypeSchemaColorCode;
  icon_id?: ReadInteractionTypeSchemaIconId;
  id?: ReadInteractionTypeSchemaId;
  is_active: boolean;
  is_builtin_type: boolean;
  name: string;
}

export type ReadInteractionSessionSchemaUserAnnotationReason = string | null;

export type ReadInteractionSessionSchemaUserAnnotation = AnnotationType | null;

export type ReadInteractionSessionSchemaEstimatedAnnotation = AnnotationType | null;

export interface ReadInteractionSessionSchema {
  application_version_id: number;
  created_at: string;
  env_type: EnvType;
  estimated_annotation?: ReadInteractionSessionSchemaEstimatedAnnotation;
  session_id: string;
  updated_at: string;
  user_annotation?: ReadInteractionSessionSchemaUserAnnotation;
  user_annotation_reason?: ReadInteractionSessionSchemaUserAnnotationReason;
}

export type RawSettingRecordSettings = { [key: string]: unknown };

export interface RawSettingRecord {
  label: string;
  priority: number;
  settings: RawSettingRecordSettings;
}

export type PropertyType = (typeof PropertyType)[keyof typeof PropertyType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyType = {
  input: 'input',
  output: 'output',
  custom: 'custom',
  llm: 'llm',
  garak: 'garak'
} as const;

export type PropertyDefinitionSchemaThreshold = number | null;

export type PropertyDefinitionSchemaMinValue = number | null;

export type PropertyDefinitionSchemaMaxValue = number | null;

export type PropertyDefinitionSchemaInteractionType = string | null;

export interface PropertyDefinitionSchema {
  column_type: PropertyColumnType;
  description: string;
  in_dashboard: boolean;
  interaction_type: PropertyDefinitionSchemaInteractionType;
  is_hidden: boolean;
  max_value?: PropertyDefinitionSchemaMaxValue;
  min_value?: PropertyDefinitionSchemaMinValue;
  property_column_name: string;
  property_name: string;
  property_type: PropertyType;
  threshold?: PropertyDefinitionSchemaThreshold;
  url: string;
}

export type PropertyConfigCreationSchemaThreshold = number | null;

export type PropertyConfigCreationSchemaCondition = Operator | null;

export interface PropertyConfigCreationSchema {
  application_id: number;
  condition: PropertyConfigCreationSchemaCondition;
  kind: PropertyType;
  text_property: string;
  threshold: PropertyConfigCreationSchemaThreshold;
}

export type PropertyColumnType = (typeof PropertyColumnType)[keyof typeof PropertyColumnType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyColumnType = {
  categorical: 'categorical',
  numeric: 'numeric'
} as const;

export interface PropertiesPointSchema {
  avg_value: number;
  end_time: string;
  start_time: string;
}

export type PropertiesMonitorSchemaValuesItem = PropertiesPointSchema | null;

export type PropertiesMonitorSchemaTotalAvg = number | null;

export interface PropertiesMonitorSchema {
  frequency: Frequency;
  total_avg: PropertiesMonitorSchemaTotalAvg;
  values: PropertiesMonitorSchemaValuesItem[];
  window_list: string[];
}

export type ProductionDataSummarySchemaUnannotated = number | null;

export type ProductionDataSummarySchemaStartDate = string | null;

export type ProductionDataSummarySchemaScore = number | null;

export type ProductionDataSummarySchemaPendingAnnotations = number | null;

export type ProductionDataSummarySchemaNOfSessions = number | null;

export type ProductionDataSummarySchemaNOfSamples = number | null;

export type ProductionDataSummarySchemaLatencySeconds = number | null;

export type ProductionDataSummarySchemaEstimatedScore = number | null;

export type ProductionDataSummarySchemaEndDate = string | null;

export type ProductionDataSummarySchemaAnnotationRatio = number | null;

export type ProductionDataSummarySchemaAnnotatedGood = number | null;

export type ProductionDataSummarySchemaAnnotatedBad = number | null;

export interface ProductionDataSummarySchema {
  annotated_bad: ProductionDataSummarySchemaAnnotatedBad;
  annotated_good: ProductionDataSummarySchemaAnnotatedGood;
  annotation_ratio: ProductionDataSummarySchemaAnnotationRatio;
  annotations_distribution?: AnnotationsDistributionSchema;
  end_date: ProductionDataSummarySchemaEndDate;
  estimated: AnnotationInfoSchema;
  estimated_score: ProductionDataSummarySchemaEstimatedScore;
  latency_seconds: ProductionDataSummarySchemaLatencySeconds;
  n_of_samples: ProductionDataSummarySchemaNOfSamples;
  n_of_sessions: ProductionDataSummarySchemaNOfSessions;
  pending_annotations: ProductionDataSummarySchemaPendingAnnotations;
  score: ProductionDataSummarySchemaScore;
  start_date: ProductionDataSummarySchemaStartDate;
  unannotated: ProductionDataSummarySchemaUnannotated;
  user_annotated: AnnotationInfoSchema;
}

export interface PlanUsageSchema {
  applications: LimitUsageSchema;
  base_tokens: LimitUsageSchema;
  llm_tokens: LimitUsageSchema;
  translation_tokens: LimitUsageSchema;
  users: LimitUsageSchema;
}

export type PlanType = (typeof PlanType)[keyof typeof PlanType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlanType = {
  free: 'free',
  pay_as_you_go: 'pay_as_you_go',
  fixed_plan_with_limit: 'fixed_plan_with_limit',
  fixed_plan_with_overage: 'fixed_plan_with_overage'
} as const;

export type PipelineElementType = (typeof PipelineElementType)[keyof typeof PipelineElementType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PipelineElementType = {
  input: 'input',
  information_retrieval: 'information_retrieval',
  output: 'output'
} as const;

export type PerPropertySchemaElementToMark = PipelineElementType | null;

export type PerPropertySchemaDescription = string | null;

export interface PerPropertySchema {
  color: string;
  description: PerPropertySchemaDescription;
  element_to_mark: PerPropertySchemaElementToMark;
  metadata: GeneralMetadataChunk[];
  name: string;
}

export type PerEnvApplicationVersionExtendedSchemaSimilarities = { [key: string]: number | null };

export type PerEnvApplicationVersionExtendedSchemaOutputProperties = { [key: string]: unknown };

export type PerEnvApplicationVersionExtendedSchemaLlmProperties = { [key: string]: unknown };

export type PerEnvApplicationVersionExtendedSchemaInputProperties = { [key: string]: unknown };

export type PerEnvApplicationVersionExtendedSchemaGarakProperties = { [key: string]: unknown };

export type PerEnvApplicationVersionExtendedSchemaCustomProperties = { [key: string]: unknown };

export interface PerEnvApplicationVersionExtendedSchema {
  custom_properties: PerEnvApplicationVersionExtendedSchemaCustomProperties;
  garak_properties: PerEnvApplicationVersionExtendedSchemaGarakProperties;
  input_properties: PerEnvApplicationVersionExtendedSchemaInputProperties;
  llm_properties: PerEnvApplicationVersionExtendedSchemaLlmProperties;
  n_of_annotations: number;
  n_of_estimated_annotations: number;
  n_of_samples: number;
  n_of_sessions: number;
  output_properties: PerEnvApplicationVersionExtendedSchemaOutputProperties;
  score: number;
  similarities: PerEnvApplicationVersionExtendedSchemaSimilarities;
}

export interface PentestModel {
  pentest_enabled?: boolean;
}

export type PentestDataVulnTriggerStr = string | null;

export interface PentestData {
  category: string;
  input: string;
  vuln_trigger_str: PentestDataVulnTriggerStr;
  vuln_type: string;
}

export interface PentestPerCategorySchema {
  count: number;
  pentest_data: PentestData[];
}

/**
 * Schema for the payment method update endpoint.
 */
export interface PaymentMethodSchema {
  payment_method_id: string;
}

export interface PaginationSchema {
  /** @maximum 20 */
  limit?: number;
  offset?: number;
}

export type OutputPropertiesSchemaURLsCount = number | null;

export type OutputPropertiesSchemaUniqueNounCount = number | null;

export type OutputPropertiesSchemaToxicity = number | null;

export type OutputPropertiesSchemaTextLength = number | null;

export type OutputPropertiesSchemaSubjectivity = number | null;

export type OutputPropertiesSchemaSentiment = number | null;

export type OutputPropertiesSchemaSentencesCount = number | null;

export type OutputPropertiesSchemaRetrievalRelevance = number | null;

export type OutputPropertiesSchemaRelevance = number | null;

export type OutputPropertiesSchemaReadingTime = number | null;

export type OutputPropertiesSchemaReadingEase = number | null;

export type OutputPropertiesSchemaPIIRisk = number | null;

export type OutputPropertiesSchemaMaxWordLength = number | null;

export type OutputPropertiesSchemaLexicalDensity = number | null;

export type OutputPropertiesSchemaLanguage = string[] | null;

export type OutputPropertiesSchemaInvalidLinkSyntax = number | null;

export type OutputPropertiesSchemaInformationDensity = number | null;

export type OutputPropertiesSchemaGroundedInContext = number | null;

export type OutputPropertiesSchemaFormality = number | null;

export type OutputPropertiesSchemaFluency = number | null;

export type OutputPropertiesSchemaExpectedOutputSimilarity = number | null;

export type OutputPropertiesSchemaEmailAddressesCount = number | null;

export type OutputPropertiesSchemaCoverage = number | null;

export type OutputPropertiesSchemaContentType = string[] | null;

export type OutputPropertiesSchemaCompressionRatio = number | null;

export type OutputPropertiesSchemaAvoidedAnswer = number | null;

export type OutputPropertiesSchemaAverageWordsPerSentence = number | null;

export type OutputPropertiesSchemaAverageWordLength = number | null;

export type OutputPropertiesSchemaSpecialCharacters = number | null;

export interface OutputPropertiesSchema {
  '% Special Characters': OutputPropertiesSchemaSpecialCharacters;
  'Average Word Length': OutputPropertiesSchemaAverageWordLength;
  'Average Words Per Sentence': OutputPropertiesSchemaAverageWordsPerSentence;
  'Avoided Answer': OutputPropertiesSchemaAvoidedAnswer;
  'Compression Ratio': OutputPropertiesSchemaCompressionRatio;
  'Content Type': OutputPropertiesSchemaContentType;
  Coverage: OutputPropertiesSchemaCoverage;
  'Email Addresses Count': OutputPropertiesSchemaEmailAddressesCount;
  'Expected Output Similarity': OutputPropertiesSchemaExpectedOutputSimilarity;
  Fluency: OutputPropertiesSchemaFluency;
  Formality: OutputPropertiesSchemaFormality;
  'Grounded in Context': OutputPropertiesSchemaGroundedInContext;
  'Information Density': OutputPropertiesSchemaInformationDensity;
  'Invalid Link Syntax': OutputPropertiesSchemaInvalidLinkSyntax;
  Language: OutputPropertiesSchemaLanguage;
  'Lexical Density': OutputPropertiesSchemaLexicalDensity;
  'Max Word Length': OutputPropertiesSchemaMaxWordLength;
  'PII Risk': OutputPropertiesSchemaPIIRisk;
  'Reading Ease': OutputPropertiesSchemaReadingEase;
  'Reading Time': OutputPropertiesSchemaReadingTime;
  Relevance: OutputPropertiesSchemaRelevance;
  'Retrieval Relevance': OutputPropertiesSchemaRetrievalRelevance;
  'Sentences Count': OutputPropertiesSchemaSentencesCount;
  Sentiment: OutputPropertiesSchemaSentiment;
  Subjectivity: OutputPropertiesSchemaSubjectivity;
  'Text Length': OutputPropertiesSchemaTextLength;
  Toxicity: OutputPropertiesSchemaToxicity;
  'Unique Noun Count': OutputPropertiesSchemaUniqueNounCount;
  'URLs Count': OutputPropertiesSchemaURLsCount;
}

export interface OrganizationSettingsModel {
  /** Llm Runner settings */
  llm_runner?: LlmRunnerModel;
  /** Pentest settings */
  pentest?: PentestModel;
  /** Usage settings */
  usage?: UsageModel;
}

export interface OrganizationSchemaName {
  name: string;
  schema_name: string;
}

export type OrganizationInvitationCreationSchemaTtl = number | null;

export type OrganizationInvitationCreationSchemaName = string | null;

/**
 * Schema for the organization invitation creation.
 */
export interface OrganizationInvitationCreationSchema {
  email: string;
  name?: OrganizationInvitationCreationSchemaName;
  ttl?: OrganizationInvitationCreationSchemaTtl;
  usagePlan?: UsagePlanSchema;
}

export type OrderedLlmPropertySchemaInteractionType = string | null;

export type OrderedLlmPropertySchemaDescription = string | null;

export interface OrderedLlmPropertySchema {
  description?: OrderedLlmPropertySchemaDescription;
  has_few_shot: boolean;
  id: number;
  in_dashboard?: boolean;
  interaction_type?: OrderedLlmPropertySchemaInteractionType;
  is_hidden?: boolean;
  /**
   * @minLength 1
   * @pattern ^[^'";%`\\]*$
   */
  name: string;
  /** @minItems 1 */
  pipeline_elements_to_use: string[];
  /** @minLength 1 */
  score_steps: string;
}

export type OrderByVersionExtendedModel =
  (typeof OrderByVersionExtendedModel)[keyof typeof OrderByVersionExtendedModel];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderByVersionExtendedModel = {
  input_properties: 'input_properties',
  output_properties: 'output_properties',
  application_versions: 'application_versions',
  custom_properties: 'custom_properties',
  llm_properties: 'llm_properties',
  garak_properties: 'garak_properties'
} as const;

export type OrderBySchemaSimilarityVersion = number | null;

export type OrderBySchemaColumn = string | SimilarityMetricEnum;

export type OrderByModel = (typeof OrderByModel)[keyof typeof OrderByModel];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderByModel = {
  annotations: 'annotations',
  input_properties: 'input_properties',
  output_properties: 'output_properties',
  interactions: 'interactions',
  custom_properties: 'custom_properties',
  llm_properties: 'llm_properties',
  garak_properties: 'garak_properties'
} as const;

export type OrderByDirection = (typeof OrderByDirection)[keyof typeof OrderByDirection];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderByDirection = {
  asc: 'asc',
  desc: 'desc'
} as const;

export interface OrderBySchema {
  column: OrderBySchemaColumn;
  direction?: OrderByDirection;
  model: OrderByModel;
  similarity_version?: OrderBySchemaSimilarityVersion;
}

/**
 * Operators for numeric and categorical filters.
 */
export type Operator = (typeof Operator)[keyof typeof Operator];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Operator = {
  GE: 'GE',
  GT: 'GT',
  LE: 'LE',
  LT: 'LT',
  EQ: 'EQ',
  NEQ: 'NEQ',
  IN: 'IN',
  NIN: 'NIN',
  OVERLAP: 'OVERLAP'
} as const;

export type OpenaiConnectionsSchemaApiType =
  (typeof OpenaiConnectionsSchemaApiType)[keyof typeof OpenaiConnectionsSchemaApiType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OpenaiConnectionsSchemaApiType = {
  openai: 'openai'
} as const;

export interface OpenaiConnectionsSchema {
  api_base?: string;
  api_key: string;
  api_type: OpenaiConnectionsSchemaApiType;
  /** The maximum number of tokens per request */
  max_tokens_per_request: number;
  /** The model to use for the connection */
  model: string;
  /** The price per token */
  property_price?: number;
}

/**
 * The deployment id for the connection
 */
export type OpenaiConnectionsPoolSchemaOrmDeploymentId = string | null;

/**
 * The region to use for the AWS Bedrock API
 */
export type OpenaiConnectionsPoolSchemaOrmAwsRegionName = string | null;

/**
 * The version of the api to use
 */
export type OpenaiConnectionsPoolSchemaOrmApiVersion = string | null;

/**
 * The api key for the connection
 */
export type OpenaiConnectionsPoolSchemaOrmApiKey = string | null;

/**
 * The base url for the connection
 */
export type OpenaiConnectionsPoolSchemaOrmApiBase = string | null;

export interface OpenaiConnectionsPoolSchemaOrm {
  /** The base url for the connection */
  api_base?: OpenaiConnectionsPoolSchemaOrmApiBase;
  /** The api key for the connection */
  api_key?: OpenaiConnectionsPoolSchemaOrmApiKey;
  /** The type of the connection */
  api_type: ApiType;
  /** The version of the api to use */
  api_version?: OpenaiConnectionsPoolSchemaOrmApiVersion;
  /** The region to use for the AWS Bedrock API */
  aws_region_name?: OpenaiConnectionsPoolSchemaOrmAwsRegionName;
  /** The deployment id for the connection */
  deployment_id?: OpenaiConnectionsPoolSchemaOrmDeploymentId;
  id: number;
  /** The maximum number of tokens per request */
  max_tokens_per_request: number;
  /** The model to use for the connection */
  model: string;
  /** The price per token */
  property_price?: number;
}

export type NewStepSchemaType = StepType | null;

export type NewStepSchemaStartedAt = string | number | number | null;

export type NewStepSchemaOutput = string | null;

export type NewStepSchemaInput = string | null;

export type NewStepSchemaFinishedAt = string | number | number | null;

export type NewStepSchemaError = string | null;

export interface NewStepSchema {
  annotation?: NewStepSchemaAnnotation;
  attributes: NewStepSchemaAttributes;
  error?: NewStepSchemaError;
  finished_at?: NewStepSchemaFinishedAt;
  input?: NewStepSchemaInput;
  name: string;
  output?: NewStepSchemaOutput;
  started_at?: NewStepSchemaStartedAt;
  type?: NewStepSchemaType;
}

export type NewStepSchemaAttributesAnyOf = { [key: string]: unknown };

export type NewStepSchemaAttributes = NewStepSchemaAttributesAnyOf | null;

export type NewStepSchemaAnnotation = AnnotationType | null;

export type MonitorFilterSchemaWindowSize = Frequency | null;

/**
 * List of user interaction IDs to filter by. Maximum 1000 items allowed.
 */
export type MonitorFilterSchemaUserInteractionIds = string[] | null;

export type MonitorFilterSchemaTopics = string[] | null;

/**
 * List of session IDs to filter by. Maximum 1000 items allowed.
 */
export type MonitorFilterSchemaSessionIds = string[] | null;

export type MonitorFilterSchemaSearch = SearchSchema | null;

export type MonitorFilterSchemaPropFilters = DataFilter[] | null;

export type MonitorFilterSchemaOrderBy = OrderBySchema[] | null;

export type MonitorFilterSchemaInteractionType = string | null;

export type MonitorFilterSchemaEnvironment = EnvType | null;

export type MonitorFilterSchemaAnnotations = AnnotationFilterSchema[] | null;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MonitorFilterSchemaAnnotationOriginsAnyOfItem = { ...AnnotationOrigin, 'no-origin': 'no-origin' } as const;
export type MonitorFilterSchemaAnnotationOrigins =
  | (typeof MonitorFilterSchemaAnnotationOriginsAnyOfItem)[keyof typeof MonitorFilterSchemaAnnotationOriginsAnyOfItem][]
  | null;

export interface MonitorFilterSchema {
  annotation_origins?: MonitorFilterSchemaAnnotationOrigins;
  annotations?: MonitorFilterSchemaAnnotations;
  end_time_epoch: number;
  environment?: MonitorFilterSchemaEnvironment;
  interaction_type?: MonitorFilterSchemaInteractionType;
  order_by?: MonitorFilterSchemaOrderBy;
  prop_filters?: MonitorFilterSchemaPropFilters;
  search?: MonitorFilterSchemaSearch;
  /** List of session IDs to filter by. Maximum 1000 items allowed. */
  session_ids?: MonitorFilterSchemaSessionIds;
  start_time_epoch: number;
  topics?: MonitorFilterSchemaTopics;
  /** Timezone */
  tz?: string;
  /** List of user interaction IDs to filter by. Maximum 1000 items allowed. */
  user_interaction_ids?: MonitorFilterSchemaUserInteractionIds;
  window_size?: MonitorFilterSchemaWindowSize;
}

export interface LlmRunnerModel {
  llm_runner_enabled?: boolean;
}

export type LlmRunResultScore = number | null;

export interface LlmRunResult {
  reason: string;
  score: LlmRunResultScore;
}

export interface LlmRunInteraction {
  application_version_id: number;
  user_interaction_id: string;
}

export type LlmPropertySchemaInteractionType = string | null;

export type LlmPropertySchemaDescription = string | null;

export interface LlmPropertySchema {
  description?: LlmPropertySchemaDescription;
  interaction_type?: LlmPropertySchemaInteractionType;
  /**
   * @minLength 1
   * @pattern ^[^'";%`\\]*$
   */
  name: string;
  /** @minItems 1 */
  pipeline_elements_to_use: string[];
  /** @minLength 1 */
  score_steps: string;
}

export interface LimitUsageSchema {
  limit: number;
  used: number;
}

export type InvitationCreationSchemaTtl = number | null;

export type InvitationCreationSchemaEmail = string | string[];

/**
 * Schema for the invitation creation.
 */
export interface InvitationCreationSchema {
  email: InvitationCreationSchemaEmail;
  ttl?: InvitationCreationSchemaTtl;
}

export interface InteractionsFilterWithPaginationSchema {
  filter_schema?: FilterSchema;
  pagination_schema?: PaginationSchema;
}

export type InteractionsComparisonResponseVersionsItem = InteractionSchema | null;

export type InteractionsComparisonResponseVersions = { [key: string]: InteractionsComparisonResponseVersionsItem[] };

export type InteractionsComparisonResponseMessage = string | null;

export interface InteractionsComparisonResponse {
  message: InteractionsComparisonResponseMessage;
  total: number;
  versions: InteractionsComparisonResponseVersions;
}

export interface InteractionTypeSchema {
  id: number;
  name: string;
}

export type InteractionTranslatedTextEntriesSchemaPrompt = TextEntrySchema | null;

export type InteractionTranslatedTextEntriesSchemaOutput = TextEntrySchema | null;

export type InteractionTranslatedTextEntriesSchemaInput = TextEntrySchema | null;

export type InteractionTranslatedTextEntriesSchemaInformationRetrieval = TextEntrySchema[] | null;

export type InteractionTranslatedTextEntriesSchemaHistory = TextEntrySchema[] | null;

export type InteractionTranslatedTextEntriesSchemaExpectedOutput = TextEntrySchema | null;

export interface InteractionTranslatedTextEntriesSchema {
  expected_output?: InteractionTranslatedTextEntriesSchemaExpectedOutput;
  history?: InteractionTranslatedTextEntriesSchemaHistory;
  information_retrieval?: InteractionTranslatedTextEntriesSchemaInformationRetrieval;
  input?: InteractionTranslatedTextEntriesSchemaInput;
  output?: InteractionTranslatedTextEntriesSchemaOutput;
  prompt?: InteractionTranslatedTextEntriesSchemaPrompt;
}

export interface InteractionSessionSchema {
  application_version_id: number;
  created_at: string;
  env_type: EnvType;
  session_id: string;
  updated_at: string;
}

export interface InteractionSessionCreateSchema {
  /** Environment type for the session */
  env_type: EnvType;
  /** Unique identifier for the session. If not provided, a UUID will be generated. */
  session_id?: string;
}

export type InteractionSchemaTopic = string | null;

export type InteractionSchemaSteps = BaseStepSchema[] | null;

export type InteractionSchemaStartedAt = string | null;

export type InteractionSchemaSimilaritiesAnyOf = { [key: string]: unknown };

export type InteractionSchemaSimilarities = InteractionSchemaSimilaritiesAnyOf | null;

export type InteractionSchemaSessionId = string | null;

export type InteractionSchemaPrompt = TextEntrySchema | null;

export type InteractionSchemaOutputProperties = OutputPropertiesSchema | null;

export type InteractionSchemaOutput = TextEntrySchema | null;

export type InteractionSchemaLlmPropertiesReasonsAnyOf = { [key: string]: unknown };

export type InteractionSchemaLlmPropertiesReasons = InteractionSchemaLlmPropertiesReasonsAnyOf | null;

export type InteractionSchemaLlmPropertiesAnyOf = { [key: string]: unknown };

export type InteractionSchemaLlmProperties = InteractionSchemaLlmPropertiesAnyOf | null;

export type InteractionSchemaInteractionType = InteractionTypeSchema | null;

export type InteractionSchemaInputProperties = InputPropertiesSchema | null;

export type InteractionSchemaInput = TextEntrySchema | null;

export type InteractionSchemaInformationRetrieval = TextEntrySchema[] | null;

export type InteractionSchemaHistory = TextEntrySchema[] | null;

export type InteractionSchemaGarakPropertiesAnyOf = { [key: string]: unknown };

export type InteractionSchemaGarakProperties = InteractionSchemaGarakPropertiesAnyOf | null;

export type InteractionSchemaFinishedAt = string | null;

export type InteractionSchemaExpectedOutput = TextEntrySchema | null;

export type InteractionSchemaEstimatedAnnotation = AnnotationSchema | null;

export type InteractionSchemaCustomPropertiesAnyOf = { [key: string]: unknown };

export type InteractionSchemaCustomProperties = InteractionSchemaCustomPropertiesAnyOf | null;

export type InteractionSchemaAnnotation = AnnotationSchema | null;

export interface InteractionSchema {
  annotation: InteractionSchemaAnnotation;
  application_version_id: number;
  created_at: string;
  custom_properties: InteractionSchemaCustomProperties;
  env_type: EnvType;
  estimated_annotation: InteractionSchemaEstimatedAnnotation;
  expected_output: InteractionSchemaExpectedOutput;
  finished_at?: InteractionSchemaFinishedAt;
  garak_properties: InteractionSchemaGarakProperties;
  history: InteractionSchemaHistory;
  information_retrieval: InteractionSchemaInformationRetrieval;
  input: InteractionSchemaInput;
  input_properties: InteractionSchemaInputProperties;
  interaction_datetime: string;
  interaction_type?: InteractionSchemaInteractionType;
  is_completed: boolean;
  llm_properties: InteractionSchemaLlmProperties;
  llm_properties_reasons: InteractionSchemaLlmPropertiesReasons;
  output: InteractionSchemaOutput;
  output_properties: InteractionSchemaOutputProperties;
  prompt: InteractionSchemaPrompt;
  session_id?: InteractionSchemaSessionId;
  similarities: InteractionSchemaSimilarities;
  started_at?: InteractionSchemaStartedAt;
  steps?: InteractionSchemaSteps;
  topic: InteractionSchemaTopic;
  translated: InteractionTranslatedTextEntriesSchema;
  updated_at: string;
  user_interaction_id: string;
}

export type InteractionCreationSchemaVulnType = string | null;

export type InteractionCreationSchemaVulnTriggerStr = string | null;

export type InteractionCreationSchemaUserInteractionId = string | null;

/**
 * Topic associated with the interaction. Topic longer than 40 characters will be truncated
 */
export type InteractionCreationSchemaTopic = string | null;

export type InteractionCreationSchemaSteps = NewStepSchema[] | null;

export type InteractionCreationSchemaStartedAt = string | number | null;

export type InteractionCreationSchemaSessionId = string | null;

export type InteractionCreationSchemaRawJsonDataAnyOf = { [key: string]: unknown };

export type InteractionCreationSchemaRawJsonData = InteractionCreationSchemaRawJsonDataAnyOf | null;

export type InteractionCreationSchemaOutput = string | null;

export type InteractionCreationSchemaInteractionType = string | null;

export type InteractionCreationSchemaInput = string | null;

export type InteractionCreationSchemaInformationRetrieval = string[] | string | null;

export type InteractionCreationSchemaHistory = string[] | string | null;

export type InteractionCreationSchemaFullPrompt = string | null;

export type InteractionCreationSchemaFinishedAt = string | number | null;

export type InteractionCreationSchemaExpectedOutput = string | null;

export interface InteractionCreationSchema {
  annotation?: InteractionCreationSchemaAnnotation;
  annotation_reason?: InteractionCreationSchemaAnnotationReason;
  custom_props?: InteractionCreationSchemaCustomProps;
  expected_output?: InteractionCreationSchemaExpectedOutput;
  finished_at?: InteractionCreationSchemaFinishedAt;
  full_prompt?: InteractionCreationSchemaFullPrompt;
  history?: InteractionCreationSchemaHistory;
  information_retrieval?: InteractionCreationSchemaInformationRetrieval;
  input?: InteractionCreationSchemaInput;
  interaction_type?: InteractionCreationSchemaInteractionType;
  is_completed?: boolean;
  output?: InteractionCreationSchemaOutput;
  raw_json_data?: InteractionCreationSchemaRawJsonData;
  session_id?: InteractionCreationSchemaSessionId;
  started_at?: InteractionCreationSchemaStartedAt;
  steps?: InteractionCreationSchemaSteps;
  /** Topic associated with the interaction. Topic longer than 40 characters will be truncated */
  topic?: InteractionCreationSchemaTopic;
  user_interaction_id?: InteractionCreationSchemaUserInteractionId;
  vuln_trigger_str?: InteractionCreationSchemaVulnTriggerStr;
  vuln_type?: InteractionCreationSchemaVulnType;
}

export type InteractionCreationSchemaCustomPropsAnyOf = { [key: string]: unknown };

export type InteractionCreationSchemaCustomProps = InteractionCreationSchemaCustomPropsAnyOf | null;

export type InteractionCreationSchemaAnnotationReason = string | null;

export type InteractionCreationSchemaAnnotation = AnnotationType | null;

export type InsightType = (typeof InsightType)[keyof typeof InsightType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InsightType = {
  Property_Segment: 'Property Segment',
  Weak_Segment: 'Weak Segment',
  Under_Annotated_Segment: 'Under Annotated Segment'
} as const;

export interface InsightTextInfo {
  analysis: string;
  suggestions: string;
}

export type InsightSchemaName = string | null;

export type InsightSchemaAdditionalTextInfo = InsightTextInfo | null;

export interface InsightSchema {
  additional_text_info: InsightSchemaAdditionalTextInfo;
  application_version_id: number;
  calculated_at: string;
  categorical_segment_filters: DataFilter[];
  env_type: string;
  id: number;
  insight_type: InsightType;
  interaction_type_id: number;
  interaction_type_name: string;
  n_of_samples: number;
  name: InsightSchemaName;
  percent_of_data: number;
  priority: number;
  properties: string;
  segment_filters: DataFilter[];
  segment_score: number;
}

export type InputPropertiesSchemaURLsCount = number | null;

export type InputPropertiesSchemaUniqueNounCount = number | null;

export type InputPropertiesSchemaToxicity = number | null;

export type InputPropertiesSchemaTextLength = number | null;

export type InputPropertiesSchemaSubjectivity = number | null;

export type InputPropertiesSchemaSentiment = number | null;

export type InputPropertiesSchemaSentencesCount = number | null;

export type InputPropertiesSchemaReadingTime = number | null;

export type InputPropertiesSchemaReadingEase = number | null;

export type InputPropertiesSchemaMaxWordLength = number | null;

export type InputPropertiesSchemaLexicalDensity = number | null;

export type InputPropertiesSchemaLanguage = string[] | null;

export type InputPropertiesSchemaInputSafety = number | null;

export type InputPropertiesSchemaFormality = number | null;

export type InputPropertiesSchemaFluency = number | null;

export type InputPropertiesSchemaEmailAddressesCount = number | null;

export type InputPropertiesSchemaAverageWordsPerSentence = number | null;

export type InputPropertiesSchemaAverageWordLength = number | null;

export type InputPropertiesSchemaSpecialCharacters = number | null;

export interface InputPropertiesSchema {
  '% Special Characters': InputPropertiesSchemaSpecialCharacters;
  'Average Word Length': InputPropertiesSchemaAverageWordLength;
  'Average Words Per Sentence': InputPropertiesSchemaAverageWordsPerSentence;
  'Email Addresses Count': InputPropertiesSchemaEmailAddressesCount;
  Fluency: InputPropertiesSchemaFluency;
  Formality: InputPropertiesSchemaFormality;
  'Input Safety': InputPropertiesSchemaInputSafety;
  Language: InputPropertiesSchemaLanguage;
  'Lexical Density': InputPropertiesSchemaLexicalDensity;
  'Max Word Length': InputPropertiesSchemaMaxWordLength;
  'Reading Ease': InputPropertiesSchemaReadingEase;
  'Reading Time': InputPropertiesSchemaReadingTime;
  'Sentences Count': InputPropertiesSchemaSentencesCount;
  Sentiment: InputPropertiesSchemaSentiment;
  Subjectivity: InputPropertiesSchemaSubjectivity;
  'Text Length': InputPropertiesSchemaTextLength;
  Toxicity: InputPropertiesSchemaToxicity;
  'Unique Noun Count': InputPropertiesSchemaUniqueNounCount;
  'URLs Count': InputPropertiesSchemaURLsCount;
}

export interface HTTPValidationError {
  detail?: ValidationError[];
}

export type GenerateConfigNumberOfSubUrls = number | null;

export type GenerateConfigNumberOfSamplesToGenerate = number | null;

export type GenerateConfigExampleQuestions = string[] | null;

export type GenerateConfigEmailAddresses = string[] | null;

export type GenerateConfigApplicationDesc = string | null;

export interface GenerateConfig {
  application_desc: GenerateConfigApplicationDesc;
  as_csv?: boolean;
  email_addresses: GenerateConfigEmailAddresses;
  example_questions: GenerateConfigExampleQuestions;
  number_of_samples_to_generate?: GenerateConfigNumberOfSamplesToGenerate;
  number_of_sub_urls?: GenerateConfigNumberOfSubUrls;
  url_to_crawl: string;
}

export type GeneralMetadataChunkText = string | null;

export type GeneralMetadataChunkDocumentIndex = number | null;

export interface GeneralMetadataChunk {
  document_index?: GeneralMetadataChunkDocumentIndex;
  end: number;
  score: number;
  start: number;
  text?: GeneralMetadataChunkText;
}

/**
 * Monitor execution frequency.
 */
export type Frequency = (typeof Frequency)[keyof typeof Frequency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Frequency = {
  HOUR: 'HOUR',
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH'
} as const;

/**
 * List of user interaction IDs to filter by. Maximum 1000 items allowed.
 */
export type FilterSchemaUserInteractionIds = string[] | null;

export type FilterSchemaTopics = string[] | null;

export type FilterSchemaStartTimeEpoch = number | null;

/**
 * List of session IDs to filter by. Maximum 1000 items allowed.
 */
export type FilterSchemaSessionIds = string[] | null;

export type FilterSchemaSearch = SearchSchema | null;

export type FilterSchemaPropFilters = DataFilter[] | null;

export type FilterSchemaOrderBy = OrderBySchema[] | null;

export type FilterSchemaInteractionType = string | null;

export type FilterSchemaEndTimeEpoch = number | null;

export type FilterSchemaAnnotations = AnnotationFilterSchema[] | null;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FilterSchemaAnnotationOriginsAnyOfItem = { ...AnnotationOrigin, 'no-origin': 'no-origin' } as const;
export type FilterSchemaAnnotationOrigins =
  | (typeof FilterSchemaAnnotationOriginsAnyOfItem)[keyof typeof FilterSchemaAnnotationOriginsAnyOfItem][]
  | null;

export type EventToCheck = (typeof EventToCheck)[keyof typeof EventToCheck];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EventToCheck = {
  topics_completed: 'topics_completed',
  properties_completed: 'properties_completed',
  similarity_completed: 'similarity_completed',
  llm_properties_completed: 'llm_properties_completed',
  annotation_completed: 'annotation_completed',
  dc_evaluation_completed: 'dc_evaluation_completed'
} as const;

export type EnvType = (typeof EnvType)[keyof typeof EnvType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EnvType = {
  PROD: 'PROD',
  EVAL: 'EVAL',
  PENTEST: 'PENTEST'
} as const;

export type FilterSchemaEnvironment = EnvType | null;

export interface FilterSchema {
  annotation_origins?: FilterSchemaAnnotationOrigins;
  annotations?: FilterSchemaAnnotations;
  end_time_epoch?: FilterSchemaEndTimeEpoch;
  environment?: FilterSchemaEnvironment;
  interaction_type?: FilterSchemaInteractionType;
  order_by?: FilterSchemaOrderBy;
  prop_filters?: FilterSchemaPropFilters;
  search?: FilterSchemaSearch;
  /** List of session IDs to filter by. Maximum 1000 items allowed. */
  session_ids?: FilterSchemaSessionIds;
  start_time_epoch?: FilterSchemaStartTimeEpoch;
  topics?: FilterSchemaTopics;
  /** List of user interaction IDs to filter by. Maximum 1000 items allowed. */
  user_interaction_ids?: FilterSchemaUserInteractionIds;
}

export type DefaultTimeRangeSchemaStartTime = number | null;

export type DefaultTimeRangeSchemaEndTime = number | null;

export interface DefaultTimeRangeSchema {
  end_time: DefaultTimeRangeSchemaEndTime;
  start_time: DefaultTimeRangeSchemaStartTime;
}

/**
 * Whether the datadog integration is enabled on not
 */
export type DatadogUserConfigDataEnabled = boolean | null;

/**
 * The API Key to communicate with the user's datadog account
 */
export type DatadogUserConfigDataApiKey = string | null;

/**
 * Datadog's user configuration data - the user can set the api key, and enable/disable using this config
 */
export interface DatadogUserConfigData {
  /** The API Key to communicate with the user's datadog account */
  api_key?: DatadogUserConfigDataApiKey;
  /** Whether the datadog integration is enabled on not */
  enabled?: DatadogUserConfigDataEnabled;
}

/**
 * Whether the datadog integration is enabled on not, set by deepchecks only
 */
export type DatadogSystemConfigDataEnabled = boolean | null;

/**
 * Datadog's system configuration data - someone from deepchecks must enable the integration before setting the api key
using the DatadogUserConfigData
 */
export interface DatadogSystemConfigData {
  /** Whether the datadog integration is enabled on not, set by deepchecks only */
  enabled?: DatadogSystemConfigDataEnabled;
}

export interface DatadogConfigData {
  system_config: DatadogSystemConfigData;
  user_config: DatadogUserConfigData;
}

export type DataFilterValueAnyOfItem = string | null;

export type DataFilterValue = number | string | DataFilterValueAnyOfItem[] | null;

export type DataFilterPropertyType = PropertyType | null;

export interface DataFilter {
  column: string;
  operator: Operator;
  property_type?: DataFilterPropertyType;
  value: DataFilterValue;
}

export type CustomPropDefinitionSchemaInteractionType = string | null;

export type CustomPropDefinitionSchemaDescription = string | null;

export interface CustomPropDefinitionSchema {
  description?: CustomPropDefinitionSchemaDescription;
  /** @pattern ^[^'";%`\\]*$ */
  display_name: string;
  in_dashboard?: boolean;
  interaction_type?: CustomPropDefinitionSchemaInteractionType;
  is_editable?: boolean;
  is_hidden?: boolean;
  type: PropertyColumnType;
}

export interface CreateUserSchema {
  email: string;
  name: string;
}

export interface CreateOrganizationSchema {
  email: string;
  name: string;
  usagePlan?: UsagePlanSchema;
}

export type CreateOrUpdatePropertySchemaThreshold = number | null;

export type CreateOrUpdatePropertySchemaInteractionType = string | null;

export type CreateOrUpdatePropertySchemaCondition = Operator | null;

export interface CreateOrUpdatePropertySchema {
  application_id: number;
  condition?: CreateOrUpdatePropertySchemaCondition;
  in_dashboard: boolean;
  interaction_type?: CreateOrUpdatePropertySchemaInteractionType;
  is_hidden: boolean;
  kind: PropertyType;
  text_property: string;
  threshold?: CreateOrUpdatePropertySchemaThreshold;
}

export interface CreateNotificationEmailSchema {
  email: string;
}

export type CreateInteractionTypeSchemaPropertiesToCopy = PropertyDefinitionSchema[] | null;

export type CreateInteractionTypeSchemaIconId = number | null;

export type CreateInteractionTypeSchemaCopyFromInteractionTypeId = number | null;

export type CreateInteractionTypeSchemaColorCode = string | null;

export interface CreateInteractionTypeSchema {
  color_code?: CreateInteractionTypeSchemaColorCode;
  copy_from_interaction_type_id?: CreateInteractionTypeSchemaCopyFromInteractionTypeId;
  icon_id?: CreateInteractionTypeSchemaIconId;
  name: string;
  properties_to_copy?: CreateInteractionTypeSchemaPropertiesToCopy;
}

export type CreateApplicationVersionSchemaDescription = string | null;

export interface CreateApplicationVersionSchema {
  ai_model?: AIModel;
  application_id: number;
  custom?: CreateApplicationVersionSchemaCustom;
  description?: CreateApplicationVersionSchemaDescription;
  name: string;
}

export type CreateApplicationVersionSchemaCustomAnyOfItem = { [key: string]: unknown };

export type CreateApplicationVersionSchemaCustom = CreateApplicationVersionSchemaCustomAnyOfItem[] | null;

export interface CompleteStatusSchema {
  all_completed: boolean;
  number_of_completed: number;
}

export type CompareOnSchemaCompareOnPropertyName = string | null;

export type CompareOnPropertyTypeEnum = (typeof CompareOnPropertyTypeEnum)[keyof typeof CompareOnPropertyTypeEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompareOnPropertyTypeEnum = {
  input: 'input',
  output: 'output',
  custom: 'custom',
  llm: 'llm'
} as const;

export type CompareOnSchemaCompareOnPropertyType = CompareOnPropertyTypeEnum | null;

export type CompareOnFieldEnum = (typeof CompareOnFieldEnum)[keyof typeof CompareOnFieldEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompareOnFieldEnum = {
  annotation: 'annotation',
  similarity: 'similarity',
  blue_1_similarity: 'blue_1_similarity',
  rouge_1_similarity: 'rouge_1_similarity',
  property: 'property'
} as const;

export interface CompareOnSchema {
  compare_on: CompareOnFieldEnum;
  compare_on_property_name?: CompareOnSchemaCompareOnPropertyName;
  compare_on_property_type?: CompareOnSchemaCompareOnPropertyType;
}

export type ChargeSchemaPlanAnyOf = { [key: string]: string };

export type ChargeSchemaPlan = ChargeSchemaPlanAnyOf | null;

/**
 * Schema for the charge object.
 */
export interface ChargeSchema {
  amount: number;
  created: number;
  id: string;
  paid: boolean;
  plan: ChargeSchemaPlan;
  receipt_url: string;
}

export type CategoryPropertyValueName = string | null;

export interface CategoryPropertyValue {
  count: number;
  name: CategoryPropertyValueName;
}

export type CategoryName = (typeof CategoryName)[keyof typeof CategoryName];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CategoryName = {
  TRANSLATION: 'TRANSLATION',
  DD_INTEGRATION: 'DD_INTEGRATION',
  DC_EVALUATOR: 'DC_EVALUATOR',
  DATADOG_USER_CONFIG: 'DATADOG_USER_CONFIG',
  DATADOG_SYSTEM_CONFIG: 'DATADOG_SYSTEM_CONFIG',
  LLM_PROPERTIES_MODEL: 'LLM_PROPERTIES_MODEL',
  LLM_COVERAGE_MODEL: 'LLM_COVERAGE_MODEL',
  LLM_EXPECTED_OUTPUT_SIMILARITY_MODEL: 'LLM_EXPECTED_OUTPUT_SIMILARITY_MODEL',
  LLM_TOPIC_MODEL: 'LLM_TOPIC_MODEL',
  LLM_SAMPLE_GENERATION_MODEL: 'LLM_SAMPLE_GENERATION_MODEL'
} as const;

export interface BodyUploadApiV1UploadCsvPost {
  application_name: string;
  application_version_name: string;
  env_type: EnvType;
  file: Blob;
}

export interface BodyUpdateInteractionTypeConfigRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeConfigPut {
  file: Blob;
}

export interface BodyUpdateApplicationConfigRouteApiV1ApplicationsAppIdConfigPut {
  file: Blob;
}

export type BodyTestRunLlmPropApiV1ApplicationsApplicationVersionIdLlmPropTestRunUserInteractionIdPostFewShotFile =
  Blob | null;

export type BodyTestRunLlmPropApiV1ApplicationsApplicationVersionIdLlmPropTestRunUserInteractionIdPostDescription =
  | string
  | null;

export interface BodyTestRunLlmPropApiV1ApplicationsApplicationVersionIdLlmPropTestRunUserInteractionIdPost {
  description?: BodyTestRunLlmPropApiV1ApplicationsApplicationVersionIdLlmPropTestRunUserInteractionIdPostDescription;
  few_shot_file?: BodyTestRunLlmPropApiV1ApplicationsApplicationVersionIdLlmPropTestRunUserInteractionIdPostFewShotFile;
  name: string;
  pipeline_elements_to_use: string[];
  score_steps: string;
}

export type BodyRecalcLlmPropertiesApiV1ApplicationsRecalcLlmPropertiesPutPropIdsToCalc = number[] | null;

export interface BodyRecalcLlmPropertiesApiV1ApplicationsRecalcLlmPropertiesPut {
  app_id: number;
  application_version_ids?: number[];
  environments: EnvType[];
  interaction_type: string;
  prop_ids_to_calc?: BodyRecalcLlmPropertiesApiV1ApplicationsRecalcLlmPropertiesPutPropIdsToCalc;
}

export type BodyInteractionsComparisonApiV1ApplicationVersionsInteractionComparisonPostInteractionType = string | null;

export interface BodyInteractionsComparisonApiV1ApplicationVersionsInteractionComparisonPost {
  compare_on: CompareOnSchema;
  first_app_version_for_comparison: AppVersionForComparisonDataSchema;
  interaction_type?: BodyInteractionsComparisonApiV1ApplicationVersionsInteractionComparisonPostInteractionType;
  limit?: number;
  offset?: number;
  second_app_version_for_comparison: AppVersionForComparisonDataSchema;
}

export interface BodyGetInteractionCompletionStatusApiV1ApplicationVersionsApplicationVersionIdInteractionsCompleteStatusPost {
  events_to_check: EventToCheck[];
  user_interaction_ids: string[];
}

export interface BodyGenerateSamplesApiV1GoldensetGeneratorTaskTaskIdGeneratePost {
  description?: string;
  guidelines: string;
}

export interface BodyDownloadInteractions {
  application_version_id: number;
  env_type?: EnvType;
  user_interaction_ids: string[];
}

export type BodyCreateLlmPropsDefinitionsInteractionType = string | null;

export type BodyCreateLlmPropsDefinitionsFewShotFile = Blob | null;

export type BodyCreateLlmPropsDefinitionsDescription = string | null;

export interface BodyCreateLlmPropsDefinitions {
  description?: BodyCreateLlmPropsDefinitionsDescription;
  few_shot_file?: BodyCreateLlmPropsDefinitionsFewShotFile;
  interaction_type?: BodyCreateLlmPropsDefinitionsInteractionType;
  name: string;
  pipeline_elements_to_use: string[];
  score_steps: string;
}

export interface BodyCreateInteractions {
  app_name: string;
  env_type: EnvType;
  interactions: InteractionCreationSchema[];
  version_name: string;
}

export interface BodyCopyInteractionToEvalSetApiV1CopyInteractionToEvalSetPost {
  application_version_id: number;
  user_interaction_ids: string[];
}

export type BodyAddSourceApiV1GoldensetGeneratorTaskTaskIdDataSourcePostUrlToCrawl = string | null;

export type BodyAddSourceApiV1GoldensetGeneratorTaskTaskIdDataSourcePostFile = Blob | null;

export interface BodyAddSourceApiV1GoldensetGeneratorTaskTaskIdDataSourcePost {
  file?: BodyAddSourceApiV1GoldensetGeneratorTaskTaskIdDataSourcePostFile;
  url_to_crawl?: BodyAddSourceApiV1GoldensetGeneratorTaskTaskIdDataSourcePostUrlToCrawl;
}

export type BedrockConnectionsSchemaApiType =
  (typeof BedrockConnectionsSchemaApiType)[keyof typeof BedrockConnectionsSchemaApiType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BedrockConnectionsSchemaApiType = {
  bedrock: 'bedrock'
} as const;

export interface BedrockConnectionsSchema {
  api_type: BedrockConnectionsSchemaApiType;
  aws_region_name: string;
  /** The maximum number of tokens per request */
  max_tokens_per_request: number;
  /** The model to use for the connection */
  model: string;
  /** The price per token */
  property_price?: number;
}

export type BaseStepSchemaType = StepType | null;

export type BaseStepSchemaStartedAt = string | number | number | null;

export type BaseStepSchemaOutput = string | null;

export type BaseStepSchemaInput = string | null;

export type BaseStepSchemaFinishedAt = string | number | number | null;

export type BaseStepSchemaError = string | null;

export type BaseStepSchemaAttributesAnyOf = { [key: string]: unknown };

export type BaseStepSchemaAttributes = BaseStepSchemaAttributesAnyOf | null;

export type BaseStepSchemaAnnotation = AnnotationType | null;

export interface BaseStepSchema {
  annotation?: BaseStepSchemaAnnotation;
  attributes: BaseStepSchemaAttributes;
  error?: BaseStepSchemaError;
  finished_at?: BaseStepSchemaFinishedAt;
  input?: BaseStepSchemaInput;
  name: string;
  output?: BaseStepSchemaOutput;
  started_at?: BaseStepSchemaStartedAt;
  type?: BaseStepSchemaType;
}

export type BaseApplicationVersionSchemaDescription = string | null;

export type BaseApplicationVersionSchemaCustomAnyOfItem = { [key: string]: unknown };

export type BaseApplicationVersionSchemaCustom = BaseApplicationVersionSchemaCustomAnyOfItem[] | null;

/**
 * The id of the connection, Do not provide this field
 */
export type AzureTranslationConnectionsSchemaId = number | null;

export interface AzureTranslationConnectionsSchema {
  /** The id of the connection, Do not provide this field */
  id?: AzureTranslationConnectionsSchemaId;
  /** The key for the connection */
  key: string;
  /** The region to use for the connection */
  region: string;
}

export type AzureOpenaiConnectionsSchemaApiType =
  (typeof AzureOpenaiConnectionsSchemaApiType)[keyof typeof AzureOpenaiConnectionsSchemaApiType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AzureOpenaiConnectionsSchemaApiType = {
  azure: 'azure'
} as const;

export interface AzureOpenaiConnectionsSchema {
  api_base: string;
  api_key: string;
  api_type: AzureOpenaiConnectionsSchemaApiType;
  api_version?: string;
  deployment_id: string;
  /** The maximum number of tokens per request */
  max_tokens_per_request: number;
  /** The model to use for the connection */
  model: string;
  /** The price per token */
  property_price?: number;
}

export type ApplicationVersionSchemaLastUpload = string | null;

export type ApplicationVersionSchemaDescription = string | null;

export type ApplicationVersionSchemaCustomItem = { [key: string]: unknown };

export interface ApplicationVersionSchema {
  ai_model: AIModel;
  application_id: number;
  created_at: string;
  custom: ApplicationVersionSchemaCustomItem[];
  description: ApplicationVersionSchemaDescription;
  id: number;
  last_upload?: ApplicationVersionSchemaLastUpload;
  name: string;
  updated_at: string;
}

export type ApplicationVersionExtendedSchemaProd = PerEnvApplicationVersionExtendedSchema | null;

export type ApplicationVersionExtendedSchemaPentest = PerEnvApplicationVersionExtendedSchema | null;

export type ApplicationVersionExtendedSchemaLastUpload = string | null;

export type ApplicationVersionExtendedSchemaEval = PerEnvApplicationVersionExtendedSchema | null;

export type ApplicationVersionExtendedSchemaDescription = string | null;

export type ApplicationVersionExtendedSchemaCustomItem = { [key: string]: unknown };

export interface ApplicationVersionExtendedSchema {
  ai_model: AIModel;
  application_id: number;
  created_at: string;
  custom: ApplicationVersionExtendedSchemaCustomItem[];
  description: ApplicationVersionExtendedSchemaDescription;
  eval?: ApplicationVersionExtendedSchemaEval;
  id: number;
  last_upload?: ApplicationVersionExtendedSchemaLastUpload;
  name: string;
  pentest?: ApplicationVersionExtendedSchemaPentest;
  prod?: ApplicationVersionExtendedSchemaProd;
  updated_at: string;
}

export type ApplicationUpdateSchemaNotificationsEnabled = boolean | null;

export type ApplicationUpdateSchemaName = string | null;

export type ApplicationUpdateSchemaDescription = string | null;

export type ApplicationType = (typeof ApplicationType)[keyof typeof ApplicationType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApplicationType = {
  'Q&A': 'Q&A',
  OTHER: 'OTHER',
  SUMMARIZATION: 'SUMMARIZATION',
  GENERATION: 'GENERATION',
  CLASSIFICATION: 'CLASSIFICATION',
  FEATURE_EXTRACTION: 'FEATURE EXTRACTION'
} as const;

export type ApplicationUpdateSchemaKind = ApplicationType | null;

export interface ApplicationUpdateSchema {
  description?: ApplicationUpdateSchemaDescription;
  id: number;
  kind?: ApplicationUpdateSchemaKind;
  name?: ApplicationUpdateSchemaName;
  notifications_enabled?: ApplicationUpdateSchemaNotificationsEnabled;
}

export type ApplicationSchemaNotificationsEnabled = boolean | null;

export type ApplicationSchemaNOfLlmProperties = number | null;

export type ApplicationSchemaNOfInteractions = number | null;

export type ApplicationSchemaLogLatestInsertTimeEpoch = number | null;

export type ApplicationSchemaLastUpload = string | null;

export type ApplicationSchemaDescription = string | null;

export interface ApplicationSchema {
  created_at: string;
  description: ApplicationSchemaDescription;
  id: number;
  in_progress: boolean;
  interaction_types: ReadInteractionTypeSchema[];
  kind: ApplicationType;
  last_upload?: ApplicationSchemaLastUpload;
  log_latest_insert_time_epoch: ApplicationSchemaLogLatestInsertTimeEpoch;
  n_of_interactions?: ApplicationSchemaNOfInteractions;
  n_of_llm_properties?: ApplicationSchemaNOfLlmProperties;
  name: string;
  notifications_enabled: ApplicationSchemaNotificationsEnabled;
  updated_at: string;
  versions: ApplicationVersionSchema[];
}

export interface ApplicationNotificationEmailSchema {
  application_id: number;
  email: string;
  id: number;
}

export type ApplicationLogSchemaEnvType = EnvType | null;

export type ApplicationLogSchemaApplicationVersionId = number | null;

export interface ApplicationLogSchema {
  application_version_id: ApplicationLogSchemaApplicationVersionId;
  created_at: string;
  env_type: ApplicationLogSchemaEnvType;
  id: number;
  log_level: AppLogLevelType;
  message: string;
  message_type: AppLogMessageType;
}

export type ApplicationCreationSchemaPropertyConfigs = PropertyConfigCreationSchema[] | null;

export type ApplicationCreationSchemaDescription = string | null;

export type ApplicationCreationSchemaCustomProperties = CustomPropDefinitionSchema[] | null;

export interface ApplicationCreationSchema {
  custom_properties?: ApplicationCreationSchemaCustomProperties;
  description?: ApplicationCreationSchemaDescription;
  kind: ApplicationType;
  name: string;
  property_configs?: ApplicationCreationSchemaPropertyConfigs;
  versions: BaseApplicationVersionSchema[];
}

export type AppVersionTopicsResponseSchemaStatus = Status | null;

export interface AppVersionTopicSchema {
  count: number;
  topic_name: string;
}

export interface AppVersionTopicsResponseSchema {
  status: AppVersionTopicsResponseSchemaStatus;
  topics: AppVersionTopicSchema[];
}

export interface AppVersionForComparisonDataSchema {
  env: EnvType;
  id: number;
}

export type AppTokensUsageReportEnvTokens = { [key: string]: TokensSchema };

export interface AppTokensUsageReport {
  application_name: string;
  env_tokens: AppTokensUsageReportEnvTokens;
}

export type AppLogMessageType = (typeof AppLogMessageType)[keyof typeof AppLogMessageType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppLogMessageType = {
  data_load: 'data_load',
  delete_entity: 'delete_entity',
  configuration_updated: 'configuration_updated',
  missing_data: 'missing_data',
  quota_exceeded: 'quota_exceeded',
  calibration: 'calibration'
} as const;

export type AppLogLevelType = (typeof AppLogLevelType)[keyof typeof AppLogLevelType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppLogLevelType = {
  info: 'info',
  warning: 'warning',
  error: 'error'
} as const;

export type ApiType = (typeof ApiType)[keyof typeof ApiType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApiType = {
  azure: 'azure',
  openai: 'openai',
  bedrock: 'bedrock'
} as const;

export interface AnnotationsOriginCount {
  annotation_origin: AnnotationOrigin;
  count: number;
}

export type AnnotationsMonitorSchemaValuesItem = AnnotationPointSchema | null;

export interface AnnotationsMonitorSchema {
  frequency: Frequency;
  values: AnnotationsMonitorSchemaValuesItem[];
  window_list: string[];
}

export interface AnnotationsDistributionSchema {
  bad?: AnnotationDistributionInfoSchema;
  good?: AnnotationDistributionInfoSchema;
  unknown?: AnnotationDistributionInfoSchema;
}

export type AnnotationUpdateSchemaReason = string | null;

export interface AnnotationTypeResultSchema {
  estimated?: AnnotationOriginResultSchema;
  user?: AnnotationOriginResultSchema;
}

export type AnnotationType = (typeof AnnotationType)[keyof typeof AnnotationType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AnnotationType = {
  good: 'good',
  bad: 'bad',
  unknown: 'unknown'
} as const;

export type AnnotationUpdateSchemaValue = AnnotationType | null;

export interface AnnotationUpdateSchema {
  application_version_id: number;
  reason?: AnnotationUpdateSchemaReason;
  revert_to_estimated?: boolean;
  user_interaction_id: string;
  value?: AnnotationUpdateSchemaValue;
}

export type AnnotationSource = (typeof AnnotationSource)[keyof typeof AnnotationSource];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AnnotationSource = {
  user_annotated: 'user_annotated',
  estimated: 'estimated'
} as const;

export type AnnotationSchemaValue = AnnotationType | null;

export type AnnotationSchemaSimilarityReason = SimilarityReasonSchema | null;

export type AnnotationSchemaReason = string | null;

export interface AnnotationSchema {
  created_at: string;
  is_estimated: boolean;
  origin: AnnotationSchemaOrigin;
  reason: AnnotationSchemaReason;
  similarity_reason: AnnotationSchemaSimilarityReason;
  updated_at: string;
  value: AnnotationSchemaValue;
}

export type AnnotationPointSchemaPerTypeResults = { [key: string]: AnnotationTypeResultSchema };

export interface AnnotationPointSchema {
  amount: number;
  end_time: string;
  per_type_results: AnnotationPointSchemaPerTypeResults;
  score: number;
  start_time: string;
}

export interface AnnotationOriginResultSchema {
  amount?: number;
  percentage?: number;
  ratio?: number;
}

export type AnnotationSchemaOrigin = AnnotationOrigin | null;

export interface AnnotationPerPropertySchema {
  count: number;
  name: string;
  origin: AnnotationOrigin;
}

export interface AnnotationInfoSchema {
  bad?: number;
  good?: number;
  pending?: number;
  score?: number;
  unknown?: number;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AnnotationFilterSchemaValue = { ...AnnotationType, pending: 'pending' } as const;
export type AnnotationFilterSchemaSource = AnnotationSource | null;

export interface AnnotationFilterSchema {
  source?: AnnotationFilterSchemaSource;
  value: (typeof AnnotationFilterSchemaValue)[keyof typeof AnnotationFilterSchemaValue];
}

export type AnnotationDistributionInfoSchemaPerProperty = AnnotationPerPropertySchema[] | null;

export interface AnnotationDistributionInfoSchema {
  per_property?: AnnotationDistributionInfoSchemaPerProperty;
  total_estimated?: number;
  total_user?: number;
}

export interface AdminOpsData {
  read_permission: boolean;
  write_permission: boolean;
}

export type ActorKey = (typeof ActorKey)[keyof typeof ActorKey];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ActorKey = {
  AnnotationEstimator: 'AnnotationEstimator',
  InsightCalculator: 'InsightCalculator',
  LlmPropertyCalculator: 'LlmPropertyCalculator',
  PropCalcBatcher: 'PropCalcBatcher',
  PropertyCalculator: 'PropertyCalculator',
  TopicsTraining: 'TopicsTraining',
  TopicsInference: 'TopicsInference',
  Translation: 'Translation'
} as const;

export type AIModel = (typeof AIModel)[keyof typeof AIModel];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AIModel = {
  'gpt35-turbo': 'gpt3.5-turbo'
} as const;

export interface BaseApplicationVersionSchema {
  ai_model?: AIModel;
  custom?: BaseApplicationVersionSchemaCustom;
  description?: BaseApplicationVersionSchemaDescription;
  name: string;
}

/**
 * @summary Logout
 */
export const logoutApiV1AuthLogoutGet = (signal?: AbortSignal) => {
  return customInstance<unknown>({ url: `/api/v1/auth/logout`, method: 'GET', signal });
};

export const getLogoutApiV1AuthLogoutGetQueryKey = () => {
  return [`/api/v1/auth/logout`] as const;
};

export const getLogoutApiV1AuthLogoutGetQueryOptions = <
  TData = Awaited<ReturnType<typeof logoutApiV1AuthLogoutGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof logoutApiV1AuthLogoutGet>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getLogoutApiV1AuthLogoutGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof logoutApiV1AuthLogoutGet>>> = ({ signal }) =>
    logoutApiV1AuthLogoutGet(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof logoutApiV1AuthLogoutGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LogoutApiV1AuthLogoutGetQueryResult = NonNullable<Awaited<ReturnType<typeof logoutApiV1AuthLogoutGet>>>;
export type LogoutApiV1AuthLogoutGetQueryError = ErrorType<unknown>;

/**
 * @summary Logout
 */

export function useLogoutApiV1AuthLogoutGet<
  TData = Awaited<ReturnType<typeof logoutApiV1AuthLogoutGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof logoutApiV1AuthLogoutGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getLogoutApiV1AuthLogoutGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Health Check
 */
export const healthCheckApiV1HealthCheckGet = (signal?: AbortSignal) => {
  return customInstance<unknown>({ url: `/api/v1/health-check`, method: 'GET', signal });
};

export const getHealthCheckApiV1HealthCheckGetQueryKey = () => {
  return [`/api/v1/health-check`] as const;
};

export const getHealthCheckApiV1HealthCheckGetQueryOptions = <
  TData = Awaited<ReturnType<typeof healthCheckApiV1HealthCheckGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof healthCheckApiV1HealthCheckGet>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getHealthCheckApiV1HealthCheckGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof healthCheckApiV1HealthCheckGet>>> = ({ signal }) =>
    healthCheckApiV1HealthCheckGet(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof healthCheckApiV1HealthCheckGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type HealthCheckApiV1HealthCheckGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof healthCheckApiV1HealthCheckGet>>
>;
export type HealthCheckApiV1HealthCheckGetQueryError = ErrorType<unknown>;

/**
 * @summary Health Check
 */

export function useHealthCheckApiV1HealthCheckGet<
  TData = Awaited<ReturnType<typeof healthCheckApiV1HealthCheckGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof healthCheckApiV1HealthCheckGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getHealthCheckApiV1HealthCheckGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Redirect to the Auth0 signup page.
 * @summary Auth0 Signup
 */
export const auth0SignupApiV1AuthSignupAuth0Get = (signal?: AbortSignal) => {
  return customInstance<unknown>({ url: `/api/v1/auth/signup/auth0`, method: 'GET', signal });
};

export const getAuth0SignupApiV1AuthSignupAuth0GetQueryKey = () => {
  return [`/api/v1/auth/signup/auth0`] as const;
};

export const getAuth0SignupApiV1AuthSignupAuth0GetQueryOptions = <
  TData = Awaited<ReturnType<typeof auth0SignupApiV1AuthSignupAuth0Get>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof auth0SignupApiV1AuthSignupAuth0Get>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAuth0SignupApiV1AuthSignupAuth0GetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof auth0SignupApiV1AuthSignupAuth0Get>>> = ({ signal }) =>
    auth0SignupApiV1AuthSignupAuth0Get(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof auth0SignupApiV1AuthSignupAuth0Get>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type Auth0SignupApiV1AuthSignupAuth0GetQueryResult = NonNullable<
  Awaited<ReturnType<typeof auth0SignupApiV1AuthSignupAuth0Get>>
>;
export type Auth0SignupApiV1AuthSignupAuth0GetQueryError = ErrorType<unknown>;

/**
 * @summary Auth0 Signup
 */

export function useAuth0SignupApiV1AuthSignupAuth0Get<
  TData = Awaited<ReturnType<typeof auth0SignupApiV1AuthSignupAuth0Get>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof auth0SignupApiV1AuthSignupAuth0Get>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getAuth0SignupApiV1AuthSignupAuth0GetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Redirect to the Auth0 login page.
 * @summary Auth0 Login
 */
export const auth0LoginApiV1AuthLoginAuth0Get = (signal?: AbortSignal) => {
  return customInstance<unknown>({ url: `/api/v1/auth/login/auth0`, method: 'GET', signal });
};

export const getAuth0LoginApiV1AuthLoginAuth0GetQueryKey = () => {
  return [`/api/v1/auth/login/auth0`] as const;
};

export const getAuth0LoginApiV1AuthLoginAuth0GetQueryOptions = <
  TData = Awaited<ReturnType<typeof auth0LoginApiV1AuthLoginAuth0Get>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof auth0LoginApiV1AuthLoginAuth0Get>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAuth0LoginApiV1AuthLoginAuth0GetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof auth0LoginApiV1AuthLoginAuth0Get>>> = ({ signal }) =>
    auth0LoginApiV1AuthLoginAuth0Get(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof auth0LoginApiV1AuthLoginAuth0Get>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type Auth0LoginApiV1AuthLoginAuth0GetQueryResult = NonNullable<
  Awaited<ReturnType<typeof auth0LoginApiV1AuthLoginAuth0Get>>
>;
export type Auth0LoginApiV1AuthLoginAuth0GetQueryError = ErrorType<unknown>;

/**
 * @summary Auth0 Login
 */

export function useAuth0LoginApiV1AuthLoginAuth0Get<
  TData = Awaited<ReturnType<typeof auth0LoginApiV1AuthLoginAuth0Get>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof auth0LoginApiV1AuthLoginAuth0Get>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getAuth0LoginApiV1AuthLoginAuth0GetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Auth0 Callback
 */
export const auth0CallbackApiV1AuthLoginAuth0CallbackGet = (signal?: AbortSignal) => {
  return customInstance<unknown>({ url: `/api/v1/auth/login/auth0/callback`, method: 'GET', signal });
};

export const getAuth0CallbackApiV1AuthLoginAuth0CallbackGetQueryKey = () => {
  return [`/api/v1/auth/login/auth0/callback`] as const;
};

export const getAuth0CallbackApiV1AuthLoginAuth0CallbackGetQueryOptions = <
  TData = Awaited<ReturnType<typeof auth0CallbackApiV1AuthLoginAuth0CallbackGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof auth0CallbackApiV1AuthLoginAuth0CallbackGet>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAuth0CallbackApiV1AuthLoginAuth0CallbackGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof auth0CallbackApiV1AuthLoginAuth0CallbackGet>>> = ({
    signal
  }) => auth0CallbackApiV1AuthLoginAuth0CallbackGet(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof auth0CallbackApiV1AuthLoginAuth0CallbackGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type Auth0CallbackApiV1AuthLoginAuth0CallbackGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof auth0CallbackApiV1AuthLoginAuth0CallbackGet>>
>;
export type Auth0CallbackApiV1AuthLoginAuth0CallbackGetQueryError = ErrorType<unknown>;

/**
 * @summary Auth0 Callback
 */

export function useAuth0CallbackApiV1AuthLoginAuth0CallbackGet<
  TData = Awaited<ReturnType<typeof auth0CallbackApiV1AuthLoginAuth0CallbackGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof auth0CallbackApiV1AuthLoginAuth0CallbackGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getAuth0CallbackApiV1AuthLoginAuth0CallbackGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Retrieve user details
 * @summary Retrieve User Info
 */
export const retrieveUserInfoApiV1UsersMeGet = (signal?: AbortSignal) => {
  return customInstance<UserSchema>({ url: `/api/v1/users/me`, method: 'GET', signal });
};

export const getRetrieveUserInfoApiV1UsersMeGetQueryKey = () => {
  return [`/api/v1/users/me`] as const;
};

export const getRetrieveUserInfoApiV1UsersMeGetQueryOptions = <
  TData = Awaited<ReturnType<typeof retrieveUserInfoApiV1UsersMeGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof retrieveUserInfoApiV1UsersMeGet>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRetrieveUserInfoApiV1UsersMeGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof retrieveUserInfoApiV1UsersMeGet>>> = ({ signal }) =>
    retrieveUserInfoApiV1UsersMeGet(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof retrieveUserInfoApiV1UsersMeGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RetrieveUserInfoApiV1UsersMeGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof retrieveUserInfoApiV1UsersMeGet>>
>;
export type RetrieveUserInfoApiV1UsersMeGetQueryError = ErrorType<unknown>;

/**
 * @summary Retrieve User Info
 */

export function useRetrieveUserInfoApiV1UsersMeGet<
  TData = Awaited<ReturnType<typeof retrieveUserInfoApiV1UsersMeGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof retrieveUserInfoApiV1UsersMeGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getRetrieveUserInfoApiV1UsersMeGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Resend user verification email.
 * @summary Send Verification Email
 */
export const sendVerificationEmailApiV1UsersSendVerificationEmailGet = (signal?: AbortSignal) => {
  return customInstance<unknown>({ url: `/api/v1/users/send-verification-email`, method: 'GET', signal });
};

export const getSendVerificationEmailApiV1UsersSendVerificationEmailGetQueryKey = () => {
  return [`/api/v1/users/send-verification-email`] as const;
};

export const getSendVerificationEmailApiV1UsersSendVerificationEmailGetQueryOptions = <
  TData = Awaited<ReturnType<typeof sendVerificationEmailApiV1UsersSendVerificationEmailGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof sendVerificationEmailApiV1UsersSendVerificationEmailGet>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSendVerificationEmailApiV1UsersSendVerificationEmailGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sendVerificationEmailApiV1UsersSendVerificationEmailGet>>> = ({
    signal
  }) => sendVerificationEmailApiV1UsersSendVerificationEmailGet(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof sendVerificationEmailApiV1UsersSendVerificationEmailGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SendVerificationEmailApiV1UsersSendVerificationEmailGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof sendVerificationEmailApiV1UsersSendVerificationEmailGet>>
>;
export type SendVerificationEmailApiV1UsersSendVerificationEmailGetQueryError = ErrorType<unknown>;

/**
 * @summary Send Verification Email
 */

export function useSendVerificationEmailApiV1UsersSendVerificationEmailGet<
  TData = Awaited<ReturnType<typeof sendVerificationEmailApiV1UsersSendVerificationEmailGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof sendVerificationEmailApiV1UsersSendVerificationEmailGet>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSendVerificationEmailApiV1UsersSendVerificationEmailGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Retrive an organization.
 * @summary Retrive Organization
 */
export const retriveOrganizationApiV1OrganizationGet = (signal?: AbortSignal) => {
  return customInstance<DeepchecksLlmEvalApiV1PublicOrganizationOrganizationSchema>({
    url: `/api/v1/organization`,
    method: 'GET',
    signal
  });
};

export const getRetriveOrganizationApiV1OrganizationGetQueryKey = () => {
  return [`/api/v1/organization`] as const;
};

export const getRetriveOrganizationApiV1OrganizationGetQueryOptions = <
  TData = Awaited<ReturnType<typeof retriveOrganizationApiV1OrganizationGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof retriveOrganizationApiV1OrganizationGet>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRetriveOrganizationApiV1OrganizationGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof retriveOrganizationApiV1OrganizationGet>>> = ({ signal }) =>
    retriveOrganizationApiV1OrganizationGet(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof retriveOrganizationApiV1OrganizationGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RetriveOrganizationApiV1OrganizationGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof retriveOrganizationApiV1OrganizationGet>>
>;
export type RetriveOrganizationApiV1OrganizationGetQueryError = ErrorType<unknown>;

/**
 * @summary Retrive Organization
 */

export function useRetriveOrganizationApiV1OrganizationGet<
  TData = Awaited<ReturnType<typeof retriveOrganizationApiV1OrganizationGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof retriveOrganizationApiV1OrganizationGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getRetriveOrganizationApiV1OrganizationGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Remove an organization.
 * @summary Remove Organization
 */
export const removeOrganizationApiV1OrganizationDelete = () => {
  return customInstance<unknown>({ url: `/api/v1/organization`, method: 'DELETE' });
};

export const getRemoveOrganizationApiV1OrganizationDeleteMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeOrganizationApiV1OrganizationDelete>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof removeOrganizationApiV1OrganizationDelete>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof removeOrganizationApiV1OrganizationDelete>>,
    void
  > = () => {
    return removeOrganizationApiV1OrganizationDelete();
  };

  return { mutationFn, ...mutationOptions };
};

export type RemoveOrganizationApiV1OrganizationDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof removeOrganizationApiV1OrganizationDelete>>
>;

export type RemoveOrganizationApiV1OrganizationDeleteMutationError = ErrorType<unknown>;

/**
 * @summary Remove Organization
 */
export const useRemoveOrganizationApiV1OrganizationDelete = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeOrganizationApiV1OrganizationDelete>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof removeOrganizationApiV1OrganizationDelete>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getRemoveOrganizationApiV1OrganizationDeleteMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Create organization for user.
 * @summary Get Complete Details
 */
export const getCompleteDetailsApiV1UsersCompleteDetailsGet = (
  params: GetCompleteDetailsApiV1UsersCompleteDetailsGetParams,
  signal?: AbortSignal
) => {
  return customInstance<unknown>({ url: `/api/v1/users/complete-details`, method: 'GET', params, signal });
};

export const getGetCompleteDetailsApiV1UsersCompleteDetailsGetQueryKey = (
  params: GetCompleteDetailsApiV1UsersCompleteDetailsGetParams
) => {
  return [`/api/v1/users/complete-details`, ...(params ? [params] : [])] as const;
};

export const getGetCompleteDetailsApiV1UsersCompleteDetailsGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getCompleteDetailsApiV1UsersCompleteDetailsGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  params: GetCompleteDetailsApiV1UsersCompleteDetailsGetParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCompleteDetailsApiV1UsersCompleteDetailsGet>>, TError, TData>;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCompleteDetailsApiV1UsersCompleteDetailsGetQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompleteDetailsApiV1UsersCompleteDetailsGet>>> = ({
    signal
  }) => getCompleteDetailsApiV1UsersCompleteDetailsGet(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCompleteDetailsApiV1UsersCompleteDetailsGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCompleteDetailsApiV1UsersCompleteDetailsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCompleteDetailsApiV1UsersCompleteDetailsGet>>
>;
export type GetCompleteDetailsApiV1UsersCompleteDetailsGetQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Complete Details
 */

export function useGetCompleteDetailsApiV1UsersCompleteDetailsGet<
  TData = Awaited<ReturnType<typeof getCompleteDetailsApiV1UsersCompleteDetailsGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  params: GetCompleteDetailsApiV1UsersCompleteDetailsGetParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCompleteDetailsApiV1UsersCompleteDetailsGet>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetCompleteDetailsApiV1UsersCompleteDetailsGetQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Invite a new user by email to the organization
 * @summary Create Invite
 */
export const inviteUserToOrg = (invitationCreationSchema: InvitationCreationSchema) => {
  return customInstance<unknown>({
    url: `/api/v1/organization-invite`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: invitationCreationSchema
  });
};

export const getInviteUserToOrgMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof inviteUserToOrg>>,
    TError,
    { data: InvitationCreationSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof inviteUserToOrg>>,
  TError,
  { data: InvitationCreationSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof inviteUserToOrg>>,
    { data: InvitationCreationSchema }
  > = props => {
    const { data } = props ?? {};

    return inviteUserToOrg(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type InviteUserToOrgMutationResult = NonNullable<Awaited<ReturnType<typeof inviteUserToOrg>>>;
export type InviteUserToOrgMutationBody = InvitationCreationSchema;
export type InviteUserToOrgMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Create Invite
 */
export const useInviteUserToOrg = <TError = ErrorType<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof inviteUserToOrg>>,
    TError,
    { data: InvitationCreationSchema },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof inviteUserToOrg>>,
  TError,
  { data: InvitationCreationSchema },
  TContext
> => {
  const mutationOptions = getInviteUserToOrgMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Disable user invite.
 * @summary Close Invite
 */
export const closeInviteApiV1OrganizationInviteEmailDelete = (email: string) => {
  return customInstance<unknown>({ url: `/api/v1/organization-invite/${email}`, method: 'DELETE' });
};

export const getCloseInviteApiV1OrganizationInviteEmailDeleteMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof closeInviteApiV1OrganizationInviteEmailDelete>>,
    TError,
    { email: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof closeInviteApiV1OrganizationInviteEmailDelete>>,
  TError,
  { email: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof closeInviteApiV1OrganizationInviteEmailDelete>>,
    { email: string }
  > = props => {
    const { email } = props ?? {};

    return closeInviteApiV1OrganizationInviteEmailDelete(email);
  };

  return { mutationFn, ...mutationOptions };
};

export type CloseInviteApiV1OrganizationInviteEmailDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof closeInviteApiV1OrganizationInviteEmailDelete>>
>;

export type CloseInviteApiV1OrganizationInviteEmailDeleteMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Close Invite
 */
export const useCloseInviteApiV1OrganizationInviteEmailDelete = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof closeInviteApiV1OrganizationInviteEmailDelete>>,
    TError,
    { email: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof closeInviteApiV1OrganizationInviteEmailDelete>>,
  TError,
  { email: string },
  TContext
> => {
  const mutationOptions = getCloseInviteApiV1OrganizationInviteEmailDeleteMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Accept End-User License Aggrement
 * @summary Eula-Acceptance
 */
export const eulaAcceptanceApiV1UsersAcceptEulaGet = (signal?: AbortSignal) => {
  return customInstance<unknown>({ url: `/api/v1/users/accept-eula`, method: 'GET', signal });
};

export const getEulaAcceptanceApiV1UsersAcceptEulaGetQueryKey = () => {
  return [`/api/v1/users/accept-eula`] as const;
};

export const getEulaAcceptanceApiV1UsersAcceptEulaGetQueryOptions = <
  TData = Awaited<ReturnType<typeof eulaAcceptanceApiV1UsersAcceptEulaGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof eulaAcceptanceApiV1UsersAcceptEulaGet>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getEulaAcceptanceApiV1UsersAcceptEulaGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof eulaAcceptanceApiV1UsersAcceptEulaGet>>> = ({ signal }) =>
    eulaAcceptanceApiV1UsersAcceptEulaGet(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof eulaAcceptanceApiV1UsersAcceptEulaGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type EulaAcceptanceApiV1UsersAcceptEulaGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof eulaAcceptanceApiV1UsersAcceptEulaGet>>
>;
export type EulaAcceptanceApiV1UsersAcceptEulaGetQueryError = ErrorType<unknown>;

/**
 * @summary Eula-Acceptance
 */

export function useEulaAcceptanceApiV1UsersAcceptEulaGet<
  TData = Awaited<ReturnType<typeof eulaAcceptanceApiV1UsersAcceptEulaGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof eulaAcceptanceApiV1UsersAcceptEulaGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getEulaAcceptanceApiV1UsersAcceptEulaGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get All Organizations
 */
export const getAllOrganizationsApiV1OrganizationsGet = (signal?: AbortSignal) => {
  return customInstance<DeepchecksLlmEvalApiV1DeepchecksOnlyOrganizationOrganizationSchema[]>({
    url: `/api/v1/organizations`,
    method: 'GET',
    signal
  });
};

export const getGetAllOrganizationsApiV1OrganizationsGetQueryKey = () => {
  return [`/api/v1/organizations`] as const;
};

export const getGetAllOrganizationsApiV1OrganizationsGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getAllOrganizationsApiV1OrganizationsGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getAllOrganizationsApiV1OrganizationsGet>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllOrganizationsApiV1OrganizationsGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllOrganizationsApiV1OrganizationsGet>>> = ({ signal }) =>
    getAllOrganizationsApiV1OrganizationsGet(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAllOrganizationsApiV1OrganizationsGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAllOrganizationsApiV1OrganizationsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAllOrganizationsApiV1OrganizationsGet>>
>;
export type GetAllOrganizationsApiV1OrganizationsGetQueryError = ErrorType<unknown>;

/**
 * @summary Get All Organizations
 */

export function useGetAllOrganizationsApiV1OrganizationsGet<
  TData = Awaited<ReturnType<typeof getAllOrganizationsApiV1OrganizationsGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getAllOrganizationsApiV1OrganizationsGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetAllOrganizationsApiV1OrganizationsGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Create Organization
 */
export const createOrganizationApiV1OrganizationsPost = (createOrganizationSchema: CreateOrganizationSchema) => {
  return customInstance<unknown>({
    url: `/api/v1/organizations`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createOrganizationSchema
  });
};

export const getCreateOrganizationApiV1OrganizationsPostMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createOrganizationApiV1OrganizationsPost>>,
    TError,
    { data: CreateOrganizationSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createOrganizationApiV1OrganizationsPost>>,
  TError,
  { data: CreateOrganizationSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createOrganizationApiV1OrganizationsPost>>,
    { data: CreateOrganizationSchema }
  > = props => {
    const { data } = props ?? {};

    return createOrganizationApiV1OrganizationsPost(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateOrganizationApiV1OrganizationsPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof createOrganizationApiV1OrganizationsPost>>
>;
export type CreateOrganizationApiV1OrganizationsPostMutationBody = CreateOrganizationSchema;
export type CreateOrganizationApiV1OrganizationsPostMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Create Organization
 */
export const useCreateOrganizationApiV1OrganizationsPost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createOrganizationApiV1OrganizationsPost>>,
    TError,
    { data: CreateOrganizationSchema },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createOrganizationApiV1OrganizationsPost>>,
  TError,
  { data: CreateOrganizationSchema },
  TContext
> => {
  const mutationOptions = getCreateOrganizationApiV1OrganizationsPostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Create User For Org
 */
export const createUserForOrgApiV1OrganizationsOrganizationSchemaNameUsersPost = (
  organizationSchemaName: string,
  createUserSchema: CreateUserSchema
) => {
  return customInstance<unknown>({
    url: `/api/v1/organizations/${organizationSchemaName}/users`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createUserSchema
  });
};

export const getCreateUserForOrgApiV1OrganizationsOrganizationSchemaNameUsersPostMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createUserForOrgApiV1OrganizationsOrganizationSchemaNameUsersPost>>,
    TError,
    { organizationSchemaName: string; data: CreateUserSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createUserForOrgApiV1OrganizationsOrganizationSchemaNameUsersPost>>,
  TError,
  { organizationSchemaName: string; data: CreateUserSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createUserForOrgApiV1OrganizationsOrganizationSchemaNameUsersPost>>,
    { organizationSchemaName: string; data: CreateUserSchema }
  > = props => {
    const { organizationSchemaName, data } = props ?? {};

    return createUserForOrgApiV1OrganizationsOrganizationSchemaNameUsersPost(organizationSchemaName, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateUserForOrgApiV1OrganizationsOrganizationSchemaNameUsersPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof createUserForOrgApiV1OrganizationsOrganizationSchemaNameUsersPost>>
>;
export type CreateUserForOrgApiV1OrganizationsOrganizationSchemaNameUsersPostMutationBody = CreateUserSchema;
export type CreateUserForOrgApiV1OrganizationsOrganizationSchemaNameUsersPostMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Create User For Org
 */
export const useCreateUserForOrgApiV1OrganizationsOrganizationSchemaNameUsersPost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createUserForOrgApiV1OrganizationsOrganizationSchemaNameUsersPost>>,
    TError,
    { organizationSchemaName: string; data: CreateUserSchema },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createUserForOrgApiV1OrganizationsOrganizationSchemaNameUsersPost>>,
  TError,
  { organizationSchemaName: string; data: CreateUserSchema },
  TContext
> => {
  const mutationOptions = getCreateUserForOrgApiV1OrganizationsOrganizationSchemaNameUsersPostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get All Users
 */
export const getAllUsersApiV1OrganizationsOrganizationSchemaNameUsersGet = (
  organizationSchemaName: string,
  signal?: AbortSignal
) => {
  return customInstance<UserInvitationSchema[]>({
    url: `/api/v1/organizations/${organizationSchemaName}/users`,
    method: 'GET',
    signal
  });
};

export const getGetAllUsersApiV1OrganizationsOrganizationSchemaNameUsersGetQueryKey = (
  organizationSchemaName: string
) => {
  return [`/api/v1/organizations/${organizationSchemaName}/users`] as const;
};

export const getGetAllUsersApiV1OrganizationsOrganizationSchemaNameUsersGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getAllUsersApiV1OrganizationsOrganizationSchemaNameUsersGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  organizationSchemaName: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAllUsersApiV1OrganizationsOrganizationSchemaNameUsersGet>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetAllUsersApiV1OrganizationsOrganizationSchemaNameUsersGetQueryKey(organizationSchemaName);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAllUsersApiV1OrganizationsOrganizationSchemaNameUsersGet>>
  > = ({ signal }) => getAllUsersApiV1OrganizationsOrganizationSchemaNameUsersGet(organizationSchemaName, signal);

  return { queryKey, queryFn, enabled: !!organizationSchemaName, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAllUsersApiV1OrganizationsOrganizationSchemaNameUsersGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAllUsersApiV1OrganizationsOrganizationSchemaNameUsersGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAllUsersApiV1OrganizationsOrganizationSchemaNameUsersGet>>
>;
export type GetAllUsersApiV1OrganizationsOrganizationSchemaNameUsersGetQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get All Users
 */

export function useGetAllUsersApiV1OrganizationsOrganizationSchemaNameUsersGet<
  TData = Awaited<ReturnType<typeof getAllUsersApiV1OrganizationsOrganizationSchemaNameUsersGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  organizationSchemaName: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAllUsersApiV1OrganizationsOrganizationSchemaNameUsersGet>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetAllUsersApiV1OrganizationsOrganizationSchemaNameUsersGetQueryOptions(
    organizationSchemaName,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Create invite between organization and a user.
 * @summary Create Invite
 */
export const createInviteApiV1OrganizationCreationInvitePost = (
  organizationInvitationCreationSchema: OrganizationInvitationCreationSchema
) => {
  return customInstance<unknown>({
    url: `/api/v1/organization-creation-invite`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: organizationInvitationCreationSchema
  });
};

export const getCreateInviteApiV1OrganizationCreationInvitePostMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createInviteApiV1OrganizationCreationInvitePost>>,
    TError,
    { data: OrganizationInvitationCreationSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createInviteApiV1OrganizationCreationInvitePost>>,
  TError,
  { data: OrganizationInvitationCreationSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createInviteApiV1OrganizationCreationInvitePost>>,
    { data: OrganizationInvitationCreationSchema }
  > = props => {
    const { data } = props ?? {};

    return createInviteApiV1OrganizationCreationInvitePost(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateInviteApiV1OrganizationCreationInvitePostMutationResult = NonNullable<
  Awaited<ReturnType<typeof createInviteApiV1OrganizationCreationInvitePost>>
>;
export type CreateInviteApiV1OrganizationCreationInvitePostMutationBody = OrganizationInvitationCreationSchema;
export type CreateInviteApiV1OrganizationCreationInvitePostMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Create Invite
 */
export const useCreateInviteApiV1OrganizationCreationInvitePost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createInviteApiV1OrganizationCreationInvitePost>>,
    TError,
    { data: OrganizationInvitationCreationSchema },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createInviteApiV1OrganizationCreationInvitePost>>,
  TError,
  { data: OrganizationInvitationCreationSchema },
  TContext
> => {
  const mutationOptions = getCreateInviteApiV1OrganizationCreationInvitePostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get Org Schema
 */
export const getOrgSchemaApiV1OrganizationOrganizationSchemaNameSettingsGet = (
  organizationSchemaName: string,
  signal?: AbortSignal
) => {
  return customInstance<OrganizationSettingsModel>({
    url: `/api/v1/organization/${organizationSchemaName}/settings`,
    method: 'GET',
    signal
  });
};

export const getGetOrgSchemaApiV1OrganizationOrganizationSchemaNameSettingsGetQueryKey = (
  organizationSchemaName: string
) => {
  return [`/api/v1/organization/${organizationSchemaName}/settings`] as const;
};

export const getGetOrgSchemaApiV1OrganizationOrganizationSchemaNameSettingsGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getOrgSchemaApiV1OrganizationOrganizationSchemaNameSettingsGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  organizationSchemaName: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getOrgSchemaApiV1OrganizationOrganizationSchemaNameSettingsGet>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetOrgSchemaApiV1OrganizationOrganizationSchemaNameSettingsGetQueryKey(organizationSchemaName);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getOrgSchemaApiV1OrganizationOrganizationSchemaNameSettingsGet>>
  > = ({ signal }) => getOrgSchemaApiV1OrganizationOrganizationSchemaNameSettingsGet(organizationSchemaName, signal);

  return { queryKey, queryFn, enabled: !!organizationSchemaName, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getOrgSchemaApiV1OrganizationOrganizationSchemaNameSettingsGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetOrgSchemaApiV1OrganizationOrganizationSchemaNameSettingsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getOrgSchemaApiV1OrganizationOrganizationSchemaNameSettingsGet>>
>;
export type GetOrgSchemaApiV1OrganizationOrganizationSchemaNameSettingsGetQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Org Schema
 */

export function useGetOrgSchemaApiV1OrganizationOrganizationSchemaNameSettingsGet<
  TData = Awaited<ReturnType<typeof getOrgSchemaApiV1OrganizationOrganizationSchemaNameSettingsGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  organizationSchemaName: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getOrgSchemaApiV1OrganizationOrganizationSchemaNameSettingsGet>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetOrgSchemaApiV1OrganizationOrganizationSchemaNameSettingsGetQueryOptions(
    organizationSchemaName,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Put Org Schema
 */
export const putOrgSchemaApiV1OrganizationOrganizationSchemaNameSettingsPut = (
  organizationSchemaName: string,
  organizationSettingsModel: OrganizationSettingsModel
) => {
  return customInstance<unknown>({
    url: `/api/v1/organization/${organizationSchemaName}/settings`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: organizationSettingsModel
  });
};

export const getPutOrgSchemaApiV1OrganizationOrganizationSchemaNameSettingsPutMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putOrgSchemaApiV1OrganizationOrganizationSchemaNameSettingsPut>>,
    TError,
    { organizationSchemaName: string; data: OrganizationSettingsModel },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putOrgSchemaApiV1OrganizationOrganizationSchemaNameSettingsPut>>,
  TError,
  { organizationSchemaName: string; data: OrganizationSettingsModel },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putOrgSchemaApiV1OrganizationOrganizationSchemaNameSettingsPut>>,
    { organizationSchemaName: string; data: OrganizationSettingsModel }
  > = props => {
    const { organizationSchemaName, data } = props ?? {};

    return putOrgSchemaApiV1OrganizationOrganizationSchemaNameSettingsPut(organizationSchemaName, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutOrgSchemaApiV1OrganizationOrganizationSchemaNameSettingsPutMutationResult = NonNullable<
  Awaited<ReturnType<typeof putOrgSchemaApiV1OrganizationOrganizationSchemaNameSettingsPut>>
>;
export type PutOrgSchemaApiV1OrganizationOrganizationSchemaNameSettingsPutMutationBody = OrganizationSettingsModel;
export type PutOrgSchemaApiV1OrganizationOrganizationSchemaNameSettingsPutMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Put Org Schema
 */
export const usePutOrgSchemaApiV1OrganizationOrganizationSchemaNameSettingsPut = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putOrgSchemaApiV1OrganizationOrganizationSchemaNameSettingsPut>>,
    TError,
    { organizationSchemaName: string; data: OrganizationSettingsModel },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof putOrgSchemaApiV1OrganizationOrganizationSchemaNameSettingsPut>>,
  TError,
  { organizationSchemaName: string; data: OrganizationSettingsModel },
  TContext
> => {
  const mutationOptions = getPutOrgSchemaApiV1OrganizationOrganizationSchemaNameSettingsPutMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Invite Users To Organization
 */
export const inviteUsersToOrganizationApiV1OrganizationsOrganizationSchemaNameInvitePut = (
  organizationSchemaName: string,
  invitationCreationSchema: InvitationCreationSchema
) => {
  return customInstance<unknown>({
    url: `/api/v1/organizations/${organizationSchemaName}/invite`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: invitationCreationSchema
  });
};

export const getInviteUsersToOrganizationApiV1OrganizationsOrganizationSchemaNameInvitePutMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof inviteUsersToOrganizationApiV1OrganizationsOrganizationSchemaNameInvitePut>>,
    TError,
    { organizationSchemaName: string; data: InvitationCreationSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof inviteUsersToOrganizationApiV1OrganizationsOrganizationSchemaNameInvitePut>>,
  TError,
  { organizationSchemaName: string; data: InvitationCreationSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof inviteUsersToOrganizationApiV1OrganizationsOrganizationSchemaNameInvitePut>>,
    { organizationSchemaName: string; data: InvitationCreationSchema }
  > = props => {
    const { organizationSchemaName, data } = props ?? {};

    return inviteUsersToOrganizationApiV1OrganizationsOrganizationSchemaNameInvitePut(organizationSchemaName, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type InviteUsersToOrganizationApiV1OrganizationsOrganizationSchemaNameInvitePutMutationResult = NonNullable<
  Awaited<ReturnType<typeof inviteUsersToOrganizationApiV1OrganizationsOrganizationSchemaNameInvitePut>>
>;
export type InviteUsersToOrganizationApiV1OrganizationsOrganizationSchemaNameInvitePutMutationBody =
  InvitationCreationSchema;
export type InviteUsersToOrganizationApiV1OrganizationsOrganizationSchemaNameInvitePutMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Invite Users To Organization
 */
export const useInviteUsersToOrganizationApiV1OrganizationsOrganizationSchemaNameInvitePut = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof inviteUsersToOrganizationApiV1OrganizationsOrganizationSchemaNameInvitePut>>,
    TError,
    { organizationSchemaName: string; data: InvitationCreationSchema },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof inviteUsersToOrganizationApiV1OrganizationsOrganizationSchemaNameInvitePut>>,
  TError,
  { organizationSchemaName: string; data: InvitationCreationSchema },
  TContext
> => {
  const mutationOptions =
    getInviteUsersToOrganizationApiV1OrganizationsOrganizationSchemaNameInvitePutMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete User From Org
 */
export const deleteUserFromOrgApiV1OrganizationsOrganizationSchemaNameUsersEmailDelete = (
  organizationSchemaName: string,
  email: string,
  params: DeleteUserFromOrgApiV1OrganizationsOrganizationSchemaNameUsersEmailDeleteParams
) => {
  return customInstance<unknown>({
    url: `/api/v1/organizations/${organizationSchemaName}/users/${email}`,
    method: 'DELETE',
    params
  });
};

export const getDeleteUserFromOrgApiV1OrganizationsOrganizationSchemaNameUsersEmailDeleteMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUserFromOrgApiV1OrganizationsOrganizationSchemaNameUsersEmailDelete>>,
    TError,
    {
      organizationSchemaName: string;
      email: string;
      params: DeleteUserFromOrgApiV1OrganizationsOrganizationSchemaNameUsersEmailDeleteParams;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteUserFromOrgApiV1OrganizationsOrganizationSchemaNameUsersEmailDelete>>,
  TError,
  {
    organizationSchemaName: string;
    email: string;
    params: DeleteUserFromOrgApiV1OrganizationsOrganizationSchemaNameUsersEmailDeleteParams;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteUserFromOrgApiV1OrganizationsOrganizationSchemaNameUsersEmailDelete>>,
    {
      organizationSchemaName: string;
      email: string;
      params: DeleteUserFromOrgApiV1OrganizationsOrganizationSchemaNameUsersEmailDeleteParams;
    }
  > = props => {
    const { organizationSchemaName, email, params } = props ?? {};

    return deleteUserFromOrgApiV1OrganizationsOrganizationSchemaNameUsersEmailDelete(
      organizationSchemaName,
      email,
      params
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteUserFromOrgApiV1OrganizationsOrganizationSchemaNameUsersEmailDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteUserFromOrgApiV1OrganizationsOrganizationSchemaNameUsersEmailDelete>>
>;

export type DeleteUserFromOrgApiV1OrganizationsOrganizationSchemaNameUsersEmailDeleteMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Delete User From Org
 */
export const useDeleteUserFromOrgApiV1OrganizationsOrganizationSchemaNameUsersEmailDelete = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUserFromOrgApiV1OrganizationsOrganizationSchemaNameUsersEmailDelete>>,
    TError,
    {
      organizationSchemaName: string;
      email: string;
      params: DeleteUserFromOrgApiV1OrganizationsOrganizationSchemaNameUsersEmailDeleteParams;
    },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteUserFromOrgApiV1OrganizationsOrganizationSchemaNameUsersEmailDelete>>,
  TError,
  {
    organizationSchemaName: string;
    email: string;
    params: DeleteUserFromOrgApiV1OrganizationsOrganizationSchemaNameUsersEmailDeleteParams;
  },
  TContext
> => {
  const mutationOptions =
    getDeleteUserFromOrgApiV1OrganizationsOrganizationSchemaNameUsersEmailDeleteMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete Org
 */
export const deleteOrgApiV1OrganizationsOrganizationSchemaNameDelete = (organizationSchemaName: string) => {
  return customInstance<unknown>({ url: `/api/v1/organizations/${organizationSchemaName}`, method: 'DELETE' });
};

export const getDeleteOrgApiV1OrganizationsOrganizationSchemaNameDeleteMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteOrgApiV1OrganizationsOrganizationSchemaNameDelete>>,
    TError,
    { organizationSchemaName: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteOrgApiV1OrganizationsOrganizationSchemaNameDelete>>,
  TError,
  { organizationSchemaName: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteOrgApiV1OrganizationsOrganizationSchemaNameDelete>>,
    { organizationSchemaName: string }
  > = props => {
    const { organizationSchemaName } = props ?? {};

    return deleteOrgApiV1OrganizationsOrganizationSchemaNameDelete(organizationSchemaName);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteOrgApiV1OrganizationsOrganizationSchemaNameDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteOrgApiV1OrganizationsOrganizationSchemaNameDelete>>
>;

export type DeleteOrgApiV1OrganizationsOrganizationSchemaNameDeleteMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Delete Org
 */
export const useDeleteOrgApiV1OrganizationsOrganizationSchemaNameDelete = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteOrgApiV1OrganizationsOrganizationSchemaNameDelete>>,
    TError,
    { organizationSchemaName: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteOrgApiV1OrganizationsOrganizationSchemaNameDelete>>,
  TError,
  { organizationSchemaName: string },
  TContext
> => {
  const mutationOptions = getDeleteOrgApiV1OrganizationsOrganizationSchemaNameDeleteMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Recalculate similarities
 * @summary Recalc Similarities
 */
export const recalcSimilaritiesApiV1OrganizationOrganizationSchemaNameRecalcSimilarityPut = (
  organizationSchemaName: string
) => {
  return customInstance<unknown>({
    url: `/api/v1/organization/${organizationSchemaName}/recalc-similarity`,
    method: 'PUT'
  });
};

export const getRecalcSimilaritiesApiV1OrganizationOrganizationSchemaNameRecalcSimilarityPutMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof recalcSimilaritiesApiV1OrganizationOrganizationSchemaNameRecalcSimilarityPut>>,
    TError,
    { organizationSchemaName: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof recalcSimilaritiesApiV1OrganizationOrganizationSchemaNameRecalcSimilarityPut>>,
  TError,
  { organizationSchemaName: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof recalcSimilaritiesApiV1OrganizationOrganizationSchemaNameRecalcSimilarityPut>>,
    { organizationSchemaName: string }
  > = props => {
    const { organizationSchemaName } = props ?? {};

    return recalcSimilaritiesApiV1OrganizationOrganizationSchemaNameRecalcSimilarityPut(organizationSchemaName);
  };

  return { mutationFn, ...mutationOptions };
};

export type RecalcSimilaritiesApiV1OrganizationOrganizationSchemaNameRecalcSimilarityPutMutationResult = NonNullable<
  Awaited<ReturnType<typeof recalcSimilaritiesApiV1OrganizationOrganizationSchemaNameRecalcSimilarityPut>>
>;

export type RecalcSimilaritiesApiV1OrganizationOrganizationSchemaNameRecalcSimilarityPutMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Recalc Similarities
 */
export const useRecalcSimilaritiesApiV1OrganizationOrganizationSchemaNameRecalcSimilarityPut = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof recalcSimilaritiesApiV1OrganizationOrganizationSchemaNameRecalcSimilarityPut>>,
    TError,
    { organizationSchemaName: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof recalcSimilaritiesApiV1OrganizationOrganizationSchemaNameRecalcSimilarityPut>>,
  TError,
  { organizationSchemaName: string },
  TContext
> => {
  const mutationOptions =
    getRecalcSimilaritiesApiV1OrganizationOrganizationSchemaNameRecalcSimilarityPutMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Recalculate eval probas
 * @summary Recalc Eval Probas
 */
export const recalcEvalProbasApiV1OrganizationOrganizationSchemaNameRecalcProbasPut = (
  organizationSchemaName: string,
  params?: RecalcEvalProbasApiV1OrganizationOrganizationSchemaNameRecalcProbasPutParams
) => {
  return customInstance<unknown>({
    url: `/api/v1/organization/${organizationSchemaName}/recalc-probas`,
    method: 'PUT',
    params
  });
};

export const getRecalcEvalProbasApiV1OrganizationOrganizationSchemaNameRecalcProbasPutMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof recalcEvalProbasApiV1OrganizationOrganizationSchemaNameRecalcProbasPut>>,
    TError,
    {
      organizationSchemaName: string;
      params?: RecalcEvalProbasApiV1OrganizationOrganizationSchemaNameRecalcProbasPutParams;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof recalcEvalProbasApiV1OrganizationOrganizationSchemaNameRecalcProbasPut>>,
  TError,
  {
    organizationSchemaName: string;
    params?: RecalcEvalProbasApiV1OrganizationOrganizationSchemaNameRecalcProbasPutParams;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof recalcEvalProbasApiV1OrganizationOrganizationSchemaNameRecalcProbasPut>>,
    {
      organizationSchemaName: string;
      params?: RecalcEvalProbasApiV1OrganizationOrganizationSchemaNameRecalcProbasPutParams;
    }
  > = props => {
    const { organizationSchemaName, params } = props ?? {};

    return recalcEvalProbasApiV1OrganizationOrganizationSchemaNameRecalcProbasPut(organizationSchemaName, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type RecalcEvalProbasApiV1OrganizationOrganizationSchemaNameRecalcProbasPutMutationResult = NonNullable<
  Awaited<ReturnType<typeof recalcEvalProbasApiV1OrganizationOrganizationSchemaNameRecalcProbasPut>>
>;

export type RecalcEvalProbasApiV1OrganizationOrganizationSchemaNameRecalcProbasPutMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Recalc Eval Probas
 */
export const useRecalcEvalProbasApiV1OrganizationOrganizationSchemaNameRecalcProbasPut = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof recalcEvalProbasApiV1OrganizationOrganizationSchemaNameRecalcProbasPut>>,
    TError,
    {
      organizationSchemaName: string;
      params?: RecalcEvalProbasApiV1OrganizationOrganizationSchemaNameRecalcProbasPutParams;
    },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof recalcEvalProbasApiV1OrganizationOrganizationSchemaNameRecalcProbasPut>>,
  TError,
  {
    organizationSchemaName: string;
    params?: RecalcEvalProbasApiV1OrganizationOrganizationSchemaNameRecalcProbasPutParams;
  },
  TContext
> => {
  const mutationOptions =
    getRecalcEvalProbasApiV1OrganizationOrganizationSchemaNameRecalcProbasPutMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Recalculate oss and non oss properties
 * @summary Recalc Non Oss Properties
 */
export const recalcNonOssPropertiesApiV1OrganizationOrganizationSchemaNameRecalcNonOssPropertiesPut = (
  organizationSchemaName: string,
  params?: RecalcNonOssPropertiesApiV1OrganizationOrganizationSchemaNameRecalcNonOssPropertiesPutParams
) => {
  return customInstance<unknown>({
    url: `/api/v1/organization/${organizationSchemaName}/recalc-non-oss-properties`,
    method: 'PUT',
    params
  });
};

export const getRecalcNonOssPropertiesApiV1OrganizationOrganizationSchemaNameRecalcNonOssPropertiesPutMutationOptions =
  <TError = ErrorType<HTTPValidationError>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof recalcNonOssPropertiesApiV1OrganizationOrganizationSchemaNameRecalcNonOssPropertiesPut>
      >,
      TError,
      {
        organizationSchemaName: string;
        params?: RecalcNonOssPropertiesApiV1OrganizationOrganizationSchemaNameRecalcNonOssPropertiesPutParams;
      },
      TContext
    >;
  }): UseMutationOptions<
    Awaited<ReturnType<typeof recalcNonOssPropertiesApiV1OrganizationOrganizationSchemaNameRecalcNonOssPropertiesPut>>,
    TError,
    {
      organizationSchemaName: string;
      params?: RecalcNonOssPropertiesApiV1OrganizationOrganizationSchemaNameRecalcNonOssPropertiesPutParams;
    },
    TContext
  > => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof recalcNonOssPropertiesApiV1OrganizationOrganizationSchemaNameRecalcNonOssPropertiesPut>
      >,
      {
        organizationSchemaName: string;
        params?: RecalcNonOssPropertiesApiV1OrganizationOrganizationSchemaNameRecalcNonOssPropertiesPutParams;
      }
    > = props => {
      const { organizationSchemaName, params } = props ?? {};

      return recalcNonOssPropertiesApiV1OrganizationOrganizationSchemaNameRecalcNonOssPropertiesPut(
        organizationSchemaName,
        params
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type RecalcNonOssPropertiesApiV1OrganizationOrganizationSchemaNameRecalcNonOssPropertiesPutMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof recalcNonOssPropertiesApiV1OrganizationOrganizationSchemaNameRecalcNonOssPropertiesPut>>
  >;

export type RecalcNonOssPropertiesApiV1OrganizationOrganizationSchemaNameRecalcNonOssPropertiesPutMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Recalc Non Oss Properties
 */
export const useRecalcNonOssPropertiesApiV1OrganizationOrganizationSchemaNameRecalcNonOssPropertiesPut = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof recalcNonOssPropertiesApiV1OrganizationOrganizationSchemaNameRecalcNonOssPropertiesPut>>,
    TError,
    {
      organizationSchemaName: string;
      params?: RecalcNonOssPropertiesApiV1OrganizationOrganizationSchemaNameRecalcNonOssPropertiesPutParams;
    },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof recalcNonOssPropertiesApiV1OrganizationOrganizationSchemaNameRecalcNonOssPropertiesPut>>,
  TError,
  {
    organizationSchemaName: string;
    params?: RecalcNonOssPropertiesApiV1OrganizationOrganizationSchemaNameRecalcNonOssPropertiesPutParams;
  },
  TContext
> => {
  const mutationOptions =
    getRecalcNonOssPropertiesApiV1OrganizationOrganizationSchemaNameRecalcNonOssPropertiesPutMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Get organizations by search pattern.
 * @summary Set Usage Price
 */
export const setUsagePriceApiV1UtilsOrganizationSchemaNameGet = (
  params: SetUsagePriceApiV1UtilsOrganizationSchemaNameGetParams,
  signal?: AbortSignal
) => {
  return customInstance<OrganizationSchemaName[]>({
    url: `/api/v1/utils/organization_schema_name`,
    method: 'GET',
    params,
    signal
  });
};

export const getSetUsagePriceApiV1UtilsOrganizationSchemaNameGetQueryKey = (
  params: SetUsagePriceApiV1UtilsOrganizationSchemaNameGetParams
) => {
  return [`/api/v1/utils/organization_schema_name`, ...(params ? [params] : [])] as const;
};

export const getSetUsagePriceApiV1UtilsOrganizationSchemaNameGetQueryOptions = <
  TData = Awaited<ReturnType<typeof setUsagePriceApiV1UtilsOrganizationSchemaNameGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  params: SetUsagePriceApiV1UtilsOrganizationSchemaNameGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof setUsagePriceApiV1UtilsOrganizationSchemaNameGet>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSetUsagePriceApiV1UtilsOrganizationSchemaNameGetQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof setUsagePriceApiV1UtilsOrganizationSchemaNameGet>>> = ({
    signal
  }) => setUsagePriceApiV1UtilsOrganizationSchemaNameGet(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof setUsagePriceApiV1UtilsOrganizationSchemaNameGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SetUsagePriceApiV1UtilsOrganizationSchemaNameGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof setUsagePriceApiV1UtilsOrganizationSchemaNameGet>>
>;
export type SetUsagePriceApiV1UtilsOrganizationSchemaNameGetQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Set Usage Price
 */

export function useSetUsagePriceApiV1UtilsOrganizationSchemaNameGet<
  TData = Awaited<ReturnType<typeof setUsagePriceApiV1UtilsOrganizationSchemaNameGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  params: SetUsagePriceApiV1UtilsOrganizationSchemaNameGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof setUsagePriceApiV1UtilsOrganizationSchemaNameGet>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSetUsagePriceApiV1UtilsOrganizationSchemaNameGetQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Get user allowed admin operations.
 * @summary Get Admin Operations
 */
export const getAdminOperationsApiV1UtilsAdminOperationsGet = (signal?: AbortSignal) => {
  return customInstance<AdminOpsData>({ url: `/api/v1/utils/admin-operations`, method: 'GET', signal });
};

export const getGetAdminOperationsApiV1UtilsAdminOperationsGetQueryKey = () => {
  return [`/api/v1/utils/admin-operations`] as const;
};

export const getGetAdminOperationsApiV1UtilsAdminOperationsGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getAdminOperationsApiV1UtilsAdminOperationsGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getAdminOperationsApiV1UtilsAdminOperationsGet>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAdminOperationsApiV1UtilsAdminOperationsGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAdminOperationsApiV1UtilsAdminOperationsGet>>> = ({
    signal
  }) => getAdminOperationsApiV1UtilsAdminOperationsGet(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAdminOperationsApiV1UtilsAdminOperationsGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAdminOperationsApiV1UtilsAdminOperationsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAdminOperationsApiV1UtilsAdminOperationsGet>>
>;
export type GetAdminOperationsApiV1UtilsAdminOperationsGetQueryError = ErrorType<unknown>;

/**
 * @summary Get Admin Operations
 */

export function useGetAdminOperationsApiV1UtilsAdminOperationsGet<
  TData = Awaited<ReturnType<typeof getAdminOperationsApiV1UtilsAdminOperationsGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getAdminOperationsApiV1UtilsAdminOperationsGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetAdminOperationsApiV1UtilsAdminOperationsGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Set Usage Plan
 */
export const adminSetUsagePlan = (organizationSchemaName: string, usagePlanSchema: UsagePlanSchema) => {
  return customInstance<number>({
    url: `/api/v1/metering/admin/${organizationSchemaName}/usage-plan`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: usagePlanSchema
  });
};

export const getAdminSetUsagePlanMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminSetUsagePlan>>,
    TError,
    { organizationSchemaName: string; data: UsagePlanSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminSetUsagePlan>>,
  TError,
  { organizationSchemaName: string; data: UsagePlanSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminSetUsagePlan>>,
    { organizationSchemaName: string; data: UsagePlanSchema }
  > = props => {
    const { organizationSchemaName, data } = props ?? {};

    return adminSetUsagePlan(organizationSchemaName, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminSetUsagePlanMutationResult = NonNullable<Awaited<ReturnType<typeof adminSetUsagePlan>>>;
export type AdminSetUsagePlanMutationBody = UsagePlanSchema;
export type AdminSetUsagePlanMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Set Usage Plan
 */
export const useAdminSetUsagePlan = <TError = ErrorType<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminSetUsagePlan>>,
    TError,
    { organizationSchemaName: string; data: UsagePlanSchema },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminSetUsagePlan>>,
  TError,
  { organizationSchemaName: string; data: UsagePlanSchema },
  TContext
> => {
  const mutationOptions = getAdminSetUsagePlanMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get Usage Plan
 */
export const adminGetUsagePlan = (organizationSchemaName: string, signal?: AbortSignal) => {
  return customInstance<UsagePlanSchema[]>({
    url: `/api/v1/metering/admin/${organizationSchemaName}/usage-plan`,
    method: 'GET',
    signal
  });
};

export const getAdminGetUsagePlanQueryKey = (organizationSchemaName: string) => {
  return [`/api/v1/metering/admin/${organizationSchemaName}/usage-plan`] as const;
};

export const getAdminGetUsagePlanQueryOptions = <
  TData = Awaited<ReturnType<typeof adminGetUsagePlan>>,
  TError = ErrorType<HTTPValidationError>
>(
  organizationSchemaName: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof adminGetUsagePlan>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAdminGetUsagePlanQueryKey(organizationSchemaName);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof adminGetUsagePlan>>> = ({ signal }) =>
    adminGetUsagePlan(organizationSchemaName, signal);

  return { queryKey, queryFn, enabled: !!organizationSchemaName, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof adminGetUsagePlan>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AdminGetUsagePlanQueryResult = NonNullable<Awaited<ReturnType<typeof adminGetUsagePlan>>>;
export type AdminGetUsagePlanQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Usage Plan
 */

export function useAdminGetUsagePlan<
  TData = Awaited<ReturnType<typeof adminGetUsagePlan>>,
  TError = ErrorType<HTTPValidationError>
>(
  organizationSchemaName: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof adminGetUsagePlan>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getAdminGetUsagePlanQueryOptions(organizationSchemaName, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Retrieve the bill for a given period for org
 * @summary Get Usage Bill
 */
export const getUsageBillApiV1UsageOrganizationSchemaNameGet = (
  organizationSchemaName: string,
  params?: GetUsageBillApiV1UsageOrganizationSchemaNameGetParams,
  signal?: AbortSignal
) => {
  return customInstance<DeepchecksLlmEvalApiV1DeepchecksOnlyUsageUsageReport>({
    url: `/api/v1/usage/${organizationSchemaName}`,
    method: 'GET',
    params,
    signal
  });
};

export const getGetUsageBillApiV1UsageOrganizationSchemaNameGetQueryKey = (
  organizationSchemaName: string,
  params?: GetUsageBillApiV1UsageOrganizationSchemaNameGetParams
) => {
  return [`/api/v1/usage/${organizationSchemaName}`, ...(params ? [params] : [])] as const;
};

export const getGetUsageBillApiV1UsageOrganizationSchemaNameGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getUsageBillApiV1UsageOrganizationSchemaNameGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  organizationSchemaName: string,
  params?: GetUsageBillApiV1UsageOrganizationSchemaNameGetParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUsageBillApiV1UsageOrganizationSchemaNameGet>>, TError, TData>;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetUsageBillApiV1UsageOrganizationSchemaNameGetQueryKey(organizationSchemaName, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsageBillApiV1UsageOrganizationSchemaNameGet>>> = ({
    signal
  }) => getUsageBillApiV1UsageOrganizationSchemaNameGet(organizationSchemaName, params, signal);

  return { queryKey, queryFn, enabled: !!organizationSchemaName, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUsageBillApiV1UsageOrganizationSchemaNameGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUsageBillApiV1UsageOrganizationSchemaNameGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUsageBillApiV1UsageOrganizationSchemaNameGet>>
>;
export type GetUsageBillApiV1UsageOrganizationSchemaNameGetQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Usage Bill
 */

export function useGetUsageBillApiV1UsageOrganizationSchemaNameGet<
  TData = Awaited<ReturnType<typeof getUsageBillApiV1UsageOrganizationSchemaNameGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  organizationSchemaName: string,
  params?: GetUsageBillApiV1UsageOrganizationSchemaNameGetParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUsageBillApiV1UsageOrganizationSchemaNameGet>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetUsageBillApiV1UsageOrganizationSchemaNameGetQueryOptions(
    organizationSchemaName,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Create or update a configuration
 * @summary Create Config
 */
export const createConfigApiV1ConfigsOrganizationSchemaNamePut = (
  organizationSchemaName: string,
  createConfigApiV1ConfigsOrganizationSchemaNamePutBody: CreateConfigApiV1ConfigsOrganizationSchemaNamePutBody,
  params: CreateConfigApiV1ConfigsOrganizationSchemaNamePutParams
) => {
  return customInstance<unknown>({
    url: `/api/v1/configs/${organizationSchemaName}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: createConfigApiV1ConfigsOrganizationSchemaNamePutBody,
    params
  });
};

export const getCreateConfigApiV1ConfigsOrganizationSchemaNamePutMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createConfigApiV1ConfigsOrganizationSchemaNamePut>>,
    TError,
    {
      organizationSchemaName: string;
      data: CreateConfigApiV1ConfigsOrganizationSchemaNamePutBody;
      params: CreateConfigApiV1ConfigsOrganizationSchemaNamePutParams;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createConfigApiV1ConfigsOrganizationSchemaNamePut>>,
  TError,
  {
    organizationSchemaName: string;
    data: CreateConfigApiV1ConfigsOrganizationSchemaNamePutBody;
    params: CreateConfigApiV1ConfigsOrganizationSchemaNamePutParams;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createConfigApiV1ConfigsOrganizationSchemaNamePut>>,
    {
      organizationSchemaName: string;
      data: CreateConfigApiV1ConfigsOrganizationSchemaNamePutBody;
      params: CreateConfigApiV1ConfigsOrganizationSchemaNamePutParams;
    }
  > = props => {
    const { organizationSchemaName, data, params } = props ?? {};

    return createConfigApiV1ConfigsOrganizationSchemaNamePut(organizationSchemaName, data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateConfigApiV1ConfigsOrganizationSchemaNamePutMutationResult = NonNullable<
  Awaited<ReturnType<typeof createConfigApiV1ConfigsOrganizationSchemaNamePut>>
>;
export type CreateConfigApiV1ConfigsOrganizationSchemaNamePutMutationBody =
  CreateConfigApiV1ConfigsOrganizationSchemaNamePutBody;
export type CreateConfigApiV1ConfigsOrganizationSchemaNamePutMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Create Config
 */
export const useCreateConfigApiV1ConfigsOrganizationSchemaNamePut = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createConfigApiV1ConfigsOrganizationSchemaNamePut>>,
    TError,
    {
      organizationSchemaName: string;
      data: CreateConfigApiV1ConfigsOrganizationSchemaNamePutBody;
      params: CreateConfigApiV1ConfigsOrganizationSchemaNamePutParams;
    },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createConfigApiV1ConfigsOrganizationSchemaNamePut>>,
  TError,
  {
    organizationSchemaName: string;
    data: CreateConfigApiV1ConfigsOrganizationSchemaNamePutBody;
    params: CreateConfigApiV1ConfigsOrganizationSchemaNamePutParams;
  },
  TContext
> => {
  const mutationOptions = getCreateConfigApiV1ConfigsOrganizationSchemaNamePutMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Get a configuration
 * @summary Get Config
 */
export const getConfigApiV1ConfigsOrganizationSchemaNameGet = (
  organizationSchemaName: string,
  params: GetConfigApiV1ConfigsOrganizationSchemaNameGetParams,
  signal?: AbortSignal
) => {
  return customInstance<unknown>({ url: `/api/v1/configs/${organizationSchemaName}`, method: 'GET', params, signal });
};

export const getGetConfigApiV1ConfigsOrganizationSchemaNameGetQueryKey = (
  organizationSchemaName: string,
  params: GetConfigApiV1ConfigsOrganizationSchemaNameGetParams
) => {
  return [`/api/v1/configs/${organizationSchemaName}`, ...(params ? [params] : [])] as const;
};

export const getGetConfigApiV1ConfigsOrganizationSchemaNameGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getConfigApiV1ConfigsOrganizationSchemaNameGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  organizationSchemaName: string,
  params: GetConfigApiV1ConfigsOrganizationSchemaNameGetParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getConfigApiV1ConfigsOrganizationSchemaNameGet>>, TError, TData>;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetConfigApiV1ConfigsOrganizationSchemaNameGetQueryKey(organizationSchemaName, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getConfigApiV1ConfigsOrganizationSchemaNameGet>>> = ({
    signal
  }) => getConfigApiV1ConfigsOrganizationSchemaNameGet(organizationSchemaName, params, signal);

  return { queryKey, queryFn, enabled: !!organizationSchemaName, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getConfigApiV1ConfigsOrganizationSchemaNameGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetConfigApiV1ConfigsOrganizationSchemaNameGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getConfigApiV1ConfigsOrganizationSchemaNameGet>>
>;
export type GetConfigApiV1ConfigsOrganizationSchemaNameGetQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Config
 */

export function useGetConfigApiV1ConfigsOrganizationSchemaNameGet<
  TData = Awaited<ReturnType<typeof getConfigApiV1ConfigsOrganizationSchemaNameGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  organizationSchemaName: string,
  params: GetConfigApiV1ConfigsOrganizationSchemaNameGetParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getConfigApiV1ConfigsOrganizationSchemaNameGet>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetConfigApiV1ConfigsOrganizationSchemaNameGetQueryOptions(
    organizationSchemaName,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Get all configurations list
 * @summary Get Raw Config
 */
export const getRawConfigApiV1ConfigsOrganizationSchemaNameDetailedGet = (
  organizationSchemaName: string,
  params: GetRawConfigApiV1ConfigsOrganizationSchemaNameDetailedGetParams,
  signal?: AbortSignal
) => {
  return customInstance<RawSettingRecord[]>({
    url: `/api/v1/configs/${organizationSchemaName}/detailed`,
    method: 'GET',
    params,
    signal
  });
};

export const getGetRawConfigApiV1ConfigsOrganizationSchemaNameDetailedGetQueryKey = (
  organizationSchemaName: string,
  params: GetRawConfigApiV1ConfigsOrganizationSchemaNameDetailedGetParams
) => {
  return [`/api/v1/configs/${organizationSchemaName}/detailed`, ...(params ? [params] : [])] as const;
};

export const getGetRawConfigApiV1ConfigsOrganizationSchemaNameDetailedGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getRawConfigApiV1ConfigsOrganizationSchemaNameDetailedGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  organizationSchemaName: string,
  params: GetRawConfigApiV1ConfigsOrganizationSchemaNameDetailedGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getRawConfigApiV1ConfigsOrganizationSchemaNameDetailedGet>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetRawConfigApiV1ConfigsOrganizationSchemaNameDetailedGetQueryKey(organizationSchemaName, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getRawConfigApiV1ConfigsOrganizationSchemaNameDetailedGet>>
  > = ({ signal }) => getRawConfigApiV1ConfigsOrganizationSchemaNameDetailedGet(organizationSchemaName, params, signal);

  return { queryKey, queryFn, enabled: !!organizationSchemaName, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getRawConfigApiV1ConfigsOrganizationSchemaNameDetailedGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetRawConfigApiV1ConfigsOrganizationSchemaNameDetailedGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getRawConfigApiV1ConfigsOrganizationSchemaNameDetailedGet>>
>;
export type GetRawConfigApiV1ConfigsOrganizationSchemaNameDetailedGetQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Raw Config
 */

export function useGetRawConfigApiV1ConfigsOrganizationSchemaNameDetailedGet<
  TData = Awaited<ReturnType<typeof getRawConfigApiV1ConfigsOrganizationSchemaNameDetailedGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  organizationSchemaName: string,
  params: GetRawConfigApiV1ConfigsOrganizationSchemaNameDetailedGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getRawConfigApiV1ConfigsOrganizationSchemaNameDetailedGet>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetRawConfigApiV1ConfigsOrganizationSchemaNameDetailedGetQueryOptions(
    organizationSchemaName,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Set datadog system integration configuration
 * @summary Set Datadog System Config
 */
export const setDatadogSystemConfigApiV1OrganizationSchemaNameDatadogSystemConfigPut = (
  organizationSchemaName: string,
  datadogSystemConfigData: DatadogSystemConfigData,
  params?: SetDatadogSystemConfigApiV1OrganizationSchemaNameDatadogSystemConfigPutParams
) => {
  return customInstance<unknown>({
    url: `/api/v1/${organizationSchemaName}/datadog-system-config`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: datadogSystemConfigData,
    params
  });
};

export const getSetDatadogSystemConfigApiV1OrganizationSchemaNameDatadogSystemConfigPutMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setDatadogSystemConfigApiV1OrganizationSchemaNameDatadogSystemConfigPut>>,
    TError,
    {
      organizationSchemaName: string;
      data: DatadogSystemConfigData;
      params?: SetDatadogSystemConfigApiV1OrganizationSchemaNameDatadogSystemConfigPutParams;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof setDatadogSystemConfigApiV1OrganizationSchemaNameDatadogSystemConfigPut>>,
  TError,
  {
    organizationSchemaName: string;
    data: DatadogSystemConfigData;
    params?: SetDatadogSystemConfigApiV1OrganizationSchemaNameDatadogSystemConfigPutParams;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setDatadogSystemConfigApiV1OrganizationSchemaNameDatadogSystemConfigPut>>,
    {
      organizationSchemaName: string;
      data: DatadogSystemConfigData;
      params?: SetDatadogSystemConfigApiV1OrganizationSchemaNameDatadogSystemConfigPutParams;
    }
  > = props => {
    const { organizationSchemaName, data, params } = props ?? {};

    return setDatadogSystemConfigApiV1OrganizationSchemaNameDatadogSystemConfigPut(
      organizationSchemaName,
      data,
      params
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type SetDatadogSystemConfigApiV1OrganizationSchemaNameDatadogSystemConfigPutMutationResult = NonNullable<
  Awaited<ReturnType<typeof setDatadogSystemConfigApiV1OrganizationSchemaNameDatadogSystemConfigPut>>
>;
export type SetDatadogSystemConfigApiV1OrganizationSchemaNameDatadogSystemConfigPutMutationBody =
  DatadogSystemConfigData;
export type SetDatadogSystemConfigApiV1OrganizationSchemaNameDatadogSystemConfigPutMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Set Datadog System Config
 */
export const useSetDatadogSystemConfigApiV1OrganizationSchemaNameDatadogSystemConfigPut = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setDatadogSystemConfigApiV1OrganizationSchemaNameDatadogSystemConfigPut>>,
    TError,
    {
      organizationSchemaName: string;
      data: DatadogSystemConfigData;
      params?: SetDatadogSystemConfigApiV1OrganizationSchemaNameDatadogSystemConfigPutParams;
    },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof setDatadogSystemConfigApiV1OrganizationSchemaNameDatadogSystemConfigPut>>,
  TError,
  {
    organizationSchemaName: string;
    data: DatadogSystemConfigData;
    params?: SetDatadogSystemConfigApiV1OrganizationSchemaNameDatadogSystemConfigPutParams;
  },
  TContext
> => {
  const mutationOptions =
    getSetDatadogSystemConfigApiV1OrganizationSchemaNameDatadogSystemConfigPutMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Get datadog integration configuration
 * @summary Get Config
 */
export const getConfigApiV1OrganizationSchemaNameDatadogConfigGet = (
  organizationSchemaName: string,
  params: GetConfigApiV1OrganizationSchemaNameDatadogConfigGetParams,
  signal?: AbortSignal
) => {
  return customInstance<DatadogConfigData>({
    url: `/api/v1/${organizationSchemaName}/datadog-config`,
    method: 'GET',
    params,
    signal
  });
};

export const getGetConfigApiV1OrganizationSchemaNameDatadogConfigGetQueryKey = (
  organizationSchemaName: string,
  params: GetConfigApiV1OrganizationSchemaNameDatadogConfigGetParams
) => {
  return [`/api/v1/${organizationSchemaName}/datadog-config`, ...(params ? [params] : [])] as const;
};

export const getGetConfigApiV1OrganizationSchemaNameDatadogConfigGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getConfigApiV1OrganizationSchemaNameDatadogConfigGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  organizationSchemaName: string,
  params: GetConfigApiV1OrganizationSchemaNameDatadogConfigGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getConfigApiV1OrganizationSchemaNameDatadogConfigGet>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetConfigApiV1OrganizationSchemaNameDatadogConfigGetQueryKey(organizationSchemaName, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getConfigApiV1OrganizationSchemaNameDatadogConfigGet>>> = ({
    signal
  }) => getConfigApiV1OrganizationSchemaNameDatadogConfigGet(organizationSchemaName, params, signal);

  return { queryKey, queryFn, enabled: !!organizationSchemaName, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getConfigApiV1OrganizationSchemaNameDatadogConfigGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetConfigApiV1OrganizationSchemaNameDatadogConfigGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getConfigApiV1OrganizationSchemaNameDatadogConfigGet>>
>;
export type GetConfigApiV1OrganizationSchemaNameDatadogConfigGetQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Config
 */

export function useGetConfigApiV1OrganizationSchemaNameDatadogConfigGet<
  TData = Awaited<ReturnType<typeof getConfigApiV1OrganizationSchemaNameDatadogConfigGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  organizationSchemaName: string,
  params: GetConfigApiV1OrganizationSchemaNameDatadogConfigGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getConfigApiV1OrganizationSchemaNameDatadogConfigGet>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetConfigApiV1OrganizationSchemaNameDatadogConfigGetQueryOptions(
    organizationSchemaName,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Get the list of available products from stripe.
 * @summary List All Charges
 */
export const listAllChargesApiV1BillingChargesGet = (signal?: AbortSignal) => {
  return customInstance<ChargeSchema[]>({ url: `/api/v1/billing/charges`, method: 'GET', signal });
};

export const getListAllChargesApiV1BillingChargesGetQueryKey = () => {
  return [`/api/v1/billing/charges`] as const;
};

export const getListAllChargesApiV1BillingChargesGetQueryOptions = <
  TData = Awaited<ReturnType<typeof listAllChargesApiV1BillingChargesGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof listAllChargesApiV1BillingChargesGet>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListAllChargesApiV1BillingChargesGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listAllChargesApiV1BillingChargesGet>>> = ({ signal }) =>
    listAllChargesApiV1BillingChargesGet(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listAllChargesApiV1BillingChargesGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListAllChargesApiV1BillingChargesGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof listAllChargesApiV1BillingChargesGet>>
>;
export type ListAllChargesApiV1BillingChargesGetQueryError = ErrorType<unknown>;

/**
 * @summary List All Charges
 */

export function useListAllChargesApiV1BillingChargesGet<
  TData = Awaited<ReturnType<typeof listAllChargesApiV1BillingChargesGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof listAllChargesApiV1BillingChargesGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getListAllChargesApiV1BillingChargesGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Return the payment method of the organization.
 * @summary Get Payment Method
 */
export const getPaymentMethodApiV1BillingPaymentMethodGet = (signal?: AbortSignal) => {
  return customInstance<unknown[]>({ url: `/api/v1/billing/payment-method`, method: 'GET', signal });
};

export const getGetPaymentMethodApiV1BillingPaymentMethodGetQueryKey = () => {
  return [`/api/v1/billing/payment-method`] as const;
};

export const getGetPaymentMethodApiV1BillingPaymentMethodGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getPaymentMethodApiV1BillingPaymentMethodGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getPaymentMethodApiV1BillingPaymentMethodGet>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPaymentMethodApiV1BillingPaymentMethodGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPaymentMethodApiV1BillingPaymentMethodGet>>> = ({
    signal
  }) => getPaymentMethodApiV1BillingPaymentMethodGet(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPaymentMethodApiV1BillingPaymentMethodGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPaymentMethodApiV1BillingPaymentMethodGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPaymentMethodApiV1BillingPaymentMethodGet>>
>;
export type GetPaymentMethodApiV1BillingPaymentMethodGetQueryError = ErrorType<unknown>;

/**
 * @summary Get Payment Method
 */

export function useGetPaymentMethodApiV1BillingPaymentMethodGet<
  TData = Awaited<ReturnType<typeof getPaymentMethodApiV1BillingPaymentMethodGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getPaymentMethodApiV1BillingPaymentMethodGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetPaymentMethodApiV1BillingPaymentMethodGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Update the payment method on stripe.
 * @summary Update Payment Method
 */
export const updatePaymentMethodApiV1BillingPaymentMethodPost = (paymentMethodSchema: PaymentMethodSchema) => {
  return customInstance<unknown>({
    url: `/api/v1/billing/payment-method`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: paymentMethodSchema
  });
};

export const getUpdatePaymentMethodApiV1BillingPaymentMethodPostMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePaymentMethodApiV1BillingPaymentMethodPost>>,
    TError,
    { data: PaymentMethodSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updatePaymentMethodApiV1BillingPaymentMethodPost>>,
  TError,
  { data: PaymentMethodSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updatePaymentMethodApiV1BillingPaymentMethodPost>>,
    { data: PaymentMethodSchema }
  > = props => {
    const { data } = props ?? {};

    return updatePaymentMethodApiV1BillingPaymentMethodPost(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdatePaymentMethodApiV1BillingPaymentMethodPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof updatePaymentMethodApiV1BillingPaymentMethodPost>>
>;
export type UpdatePaymentMethodApiV1BillingPaymentMethodPostMutationBody = PaymentMethodSchema;
export type UpdatePaymentMethodApiV1BillingPaymentMethodPostMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Update Payment Method
 */
export const useUpdatePaymentMethodApiV1BillingPaymentMethodPost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePaymentMethodApiV1BillingPaymentMethodPost>>,
    TError,
    { data: PaymentMethodSchema },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updatePaymentMethodApiV1BillingPaymentMethodPost>>,
  TError,
  { data: PaymentMethodSchema },
  TContext
> => {
  const mutationOptions = getUpdatePaymentMethodApiV1BillingPaymentMethodPostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * List interactions by filter (Admin Only)
 * @summary Get Interactions By Filter Admin
 */
export const adminListInteractionsByFilter = (
  schemaName: string,
  applicationVersionId: number,
  filterSchema: FilterSchema,
  params?: AdminListInteractionsByFilterParams
) => {
  return customInstance<InteractionSchema[]>({
    url: `/api/v1/admin/interactions/${schemaName}/application-versions/${applicationVersionId}/get-interactions-by-filter`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: filterSchema,
    params
  });
};

export const getAdminListInteractionsByFilterMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminListInteractionsByFilter>>,
    TError,
    {
      schemaName: string;
      applicationVersionId: number;
      data: FilterSchema;
      params?: AdminListInteractionsByFilterParams;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminListInteractionsByFilter>>,
  TError,
  {
    schemaName: string;
    applicationVersionId: number;
    data: FilterSchema;
    params?: AdminListInteractionsByFilterParams;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminListInteractionsByFilter>>,
    {
      schemaName: string;
      applicationVersionId: number;
      data: FilterSchema;
      params?: AdminListInteractionsByFilterParams;
    }
  > = props => {
    const { schemaName, applicationVersionId, data, params } = props ?? {};

    return adminListInteractionsByFilter(schemaName, applicationVersionId, data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminListInteractionsByFilterMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminListInteractionsByFilter>>
>;
export type AdminListInteractionsByFilterMutationBody = FilterSchema;
export type AdminListInteractionsByFilterMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Interactions By Filter Admin
 */
export const useAdminListInteractionsByFilter = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminListInteractionsByFilter>>,
    TError,
    {
      schemaName: string;
      applicationVersionId: number;
      data: FilterSchema;
      params?: AdminListInteractionsByFilterParams;
    },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminListInteractionsByFilter>>,
  TError,
  {
    schemaName: string;
    applicationVersionId: number;
    data: FilterSchema;
    params?: AdminListInteractionsByFilterParams;
  },
  TContext
> => {
  const mutationOptions = getAdminListInteractionsByFilterMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Rerun Calculations
 */
export const rerunCalculationsApiV1InteractionsSchemaNameRerunCalculationsPost = (
  schemaName: string,
  rerunCalculationsSchema: RerunCalculationsSchema
) => {
  return customInstance<unknown>({
    url: `/api/v1/interactions/${schemaName}/rerun-calculations`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: rerunCalculationsSchema
  });
};

export const getRerunCalculationsApiV1InteractionsSchemaNameRerunCalculationsPostMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof rerunCalculationsApiV1InteractionsSchemaNameRerunCalculationsPost>>,
    TError,
    { schemaName: string; data: RerunCalculationsSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof rerunCalculationsApiV1InteractionsSchemaNameRerunCalculationsPost>>,
  TError,
  { schemaName: string; data: RerunCalculationsSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof rerunCalculationsApiV1InteractionsSchemaNameRerunCalculationsPost>>,
    { schemaName: string; data: RerunCalculationsSchema }
  > = props => {
    const { schemaName, data } = props ?? {};

    return rerunCalculationsApiV1InteractionsSchemaNameRerunCalculationsPost(schemaName, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type RerunCalculationsApiV1InteractionsSchemaNameRerunCalculationsPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof rerunCalculationsApiV1InteractionsSchemaNameRerunCalculationsPost>>
>;
export type RerunCalculationsApiV1InteractionsSchemaNameRerunCalculationsPostMutationBody = RerunCalculationsSchema;
export type RerunCalculationsApiV1InteractionsSchemaNameRerunCalculationsPostMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Rerun Calculations
 */
export const useRerunCalculationsApiV1InteractionsSchemaNameRerunCalculationsPost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof rerunCalculationsApiV1InteractionsSchemaNameRerunCalculationsPost>>,
    TError,
    { schemaName: string; data: RerunCalculationsSchema },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof rerunCalculationsApiV1InteractionsSchemaNameRerunCalculationsPost>>,
  TError,
  { schemaName: string; data: RerunCalculationsSchema },
  TContext
> => {
  const mutationOptions = getRerunCalculationsApiV1InteractionsSchemaNameRerunCalculationsPostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Resend Topics
 */
export const resendTopicsApiV1ResendTopicsFromOldQueueGet = (signal?: AbortSignal) => {
  return customInstance<unknown>({ url: `/api/v1/resend-topics-from-old-queue`, method: 'GET', signal });
};

export const getResendTopicsApiV1ResendTopicsFromOldQueueGetQueryKey = () => {
  return [`/api/v1/resend-topics-from-old-queue`] as const;
};

export const getResendTopicsApiV1ResendTopicsFromOldQueueGetQueryOptions = <
  TData = Awaited<ReturnType<typeof resendTopicsApiV1ResendTopicsFromOldQueueGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof resendTopicsApiV1ResendTopicsFromOldQueueGet>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getResendTopicsApiV1ResendTopicsFromOldQueueGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof resendTopicsApiV1ResendTopicsFromOldQueueGet>>> = ({
    signal
  }) => resendTopicsApiV1ResendTopicsFromOldQueueGet(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof resendTopicsApiV1ResendTopicsFromOldQueueGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ResendTopicsApiV1ResendTopicsFromOldQueueGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof resendTopicsApiV1ResendTopicsFromOldQueueGet>>
>;
export type ResendTopicsApiV1ResendTopicsFromOldQueueGetQueryError = ErrorType<unknown>;

/**
 * @summary Resend Topics
 */

export function useResendTopicsApiV1ResendTopicsFromOldQueueGet<
  TData = Awaited<ReturnType<typeof resendTopicsApiV1ResendTopicsFromOldQueueGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof resendTopicsApiV1ResendTopicsFromOldQueueGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getResendTopicsApiV1ResendTopicsFromOldQueueGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Get the connections.
 * @summary List Connections
 */
export const listConnectionsApiV1AdminConnectionsGet = (signal?: AbortSignal) => {
  return customInstance<OpenaiConnectionsPoolSchemaOrm[]>({ url: `/api/v1/admin/connections`, method: 'GET', signal });
};

export const getListConnectionsApiV1AdminConnectionsGetQueryKey = () => {
  return [`/api/v1/admin/connections`] as const;
};

export const getListConnectionsApiV1AdminConnectionsGetQueryOptions = <
  TData = Awaited<ReturnType<typeof listConnectionsApiV1AdminConnectionsGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof listConnectionsApiV1AdminConnectionsGet>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListConnectionsApiV1AdminConnectionsGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listConnectionsApiV1AdminConnectionsGet>>> = ({ signal }) =>
    listConnectionsApiV1AdminConnectionsGet(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listConnectionsApiV1AdminConnectionsGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListConnectionsApiV1AdminConnectionsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof listConnectionsApiV1AdminConnectionsGet>>
>;
export type ListConnectionsApiV1AdminConnectionsGetQueryError = ErrorType<unknown>;

/**
 * @summary List Connections
 */

export function useListConnectionsApiV1AdminConnectionsGet<
  TData = Awaited<ReturnType<typeof listConnectionsApiV1AdminConnectionsGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof listConnectionsApiV1AdminConnectionsGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getListConnectionsApiV1AdminConnectionsGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Create a new connection.
 * @summary Create Connection
 */
export const createConnectionApiV1AdminConnectionsPost = (
  createConnectionApiV1AdminConnectionsPostBody: CreateConnectionApiV1AdminConnectionsPostBody
) => {
  return customInstance<unknown>({
    url: `/api/v1/admin/connections`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createConnectionApiV1AdminConnectionsPostBody
  });
};

export const getCreateConnectionApiV1AdminConnectionsPostMutationOptions = <
  TError = ErrorType<void | HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createConnectionApiV1AdminConnectionsPost>>,
    TError,
    { data: CreateConnectionApiV1AdminConnectionsPostBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createConnectionApiV1AdminConnectionsPost>>,
  TError,
  { data: CreateConnectionApiV1AdminConnectionsPostBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createConnectionApiV1AdminConnectionsPost>>,
    { data: CreateConnectionApiV1AdminConnectionsPostBody }
  > = props => {
    const { data } = props ?? {};

    return createConnectionApiV1AdminConnectionsPost(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateConnectionApiV1AdminConnectionsPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof createConnectionApiV1AdminConnectionsPost>>
>;
export type CreateConnectionApiV1AdminConnectionsPostMutationBody = CreateConnectionApiV1AdminConnectionsPostBody;
export type CreateConnectionApiV1AdminConnectionsPostMutationError = ErrorType<void | HTTPValidationError>;

/**
 * @summary Create Connection
 */
export const useCreateConnectionApiV1AdminConnectionsPost = <
  TError = ErrorType<void | HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createConnectionApiV1AdminConnectionsPost>>,
    TError,
    { data: CreateConnectionApiV1AdminConnectionsPostBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createConnectionApiV1AdminConnectionsPost>>,
  TError,
  { data: CreateConnectionApiV1AdminConnectionsPostBody },
  TContext
> => {
  const mutationOptions = getCreateConnectionApiV1AdminConnectionsPostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Update a connection.
 * @summary Update Connection
 */
export const updateConnectionApiV1AdminConnectionsConnectionIdPut = (
  connectionId: number,
  updateConnectionApiV1AdminConnectionsConnectionIdPutBody: UpdateConnectionApiV1AdminConnectionsConnectionIdPutBody
) => {
  return customInstance<unknown>({
    url: `/api/v1/admin/connections/${connectionId}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: updateConnectionApiV1AdminConnectionsConnectionIdPutBody
  });
};

export const getUpdateConnectionApiV1AdminConnectionsConnectionIdPutMutationOptions = <
  TError = ErrorType<void | HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateConnectionApiV1AdminConnectionsConnectionIdPut>>,
    TError,
    { connectionId: number; data: UpdateConnectionApiV1AdminConnectionsConnectionIdPutBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateConnectionApiV1AdminConnectionsConnectionIdPut>>,
  TError,
  { connectionId: number; data: UpdateConnectionApiV1AdminConnectionsConnectionIdPutBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateConnectionApiV1AdminConnectionsConnectionIdPut>>,
    { connectionId: number; data: UpdateConnectionApiV1AdminConnectionsConnectionIdPutBody }
  > = props => {
    const { connectionId, data } = props ?? {};

    return updateConnectionApiV1AdminConnectionsConnectionIdPut(connectionId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateConnectionApiV1AdminConnectionsConnectionIdPutMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateConnectionApiV1AdminConnectionsConnectionIdPut>>
>;
export type UpdateConnectionApiV1AdminConnectionsConnectionIdPutMutationBody =
  UpdateConnectionApiV1AdminConnectionsConnectionIdPutBody;
export type UpdateConnectionApiV1AdminConnectionsConnectionIdPutMutationError = ErrorType<void | HTTPValidationError>;

/**
 * @summary Update Connection
 */
export const useUpdateConnectionApiV1AdminConnectionsConnectionIdPut = <
  TError = ErrorType<void | HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateConnectionApiV1AdminConnectionsConnectionIdPut>>,
    TError,
    { connectionId: number; data: UpdateConnectionApiV1AdminConnectionsConnectionIdPutBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateConnectionApiV1AdminConnectionsConnectionIdPut>>,
  TError,
  { connectionId: number; data: UpdateConnectionApiV1AdminConnectionsConnectionIdPutBody },
  TContext
> => {
  const mutationOptions = getUpdateConnectionApiV1AdminConnectionsConnectionIdPutMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Delete a connection.
 * @summary Delete Connection
 */
export const deleteConnectionApiV1AdminConnectionsConnectionIdDelete = (connectionId: number) => {
  return customInstance<unknown>({ url: `/api/v1/admin/connections/${connectionId}`, method: 'DELETE' });
};

export const getDeleteConnectionApiV1AdminConnectionsConnectionIdDeleteMutationOptions = <
  TError = ErrorType<void | HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteConnectionApiV1AdminConnectionsConnectionIdDelete>>,
    TError,
    { connectionId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteConnectionApiV1AdminConnectionsConnectionIdDelete>>,
  TError,
  { connectionId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteConnectionApiV1AdminConnectionsConnectionIdDelete>>,
    { connectionId: number }
  > = props => {
    const { connectionId } = props ?? {};

    return deleteConnectionApiV1AdminConnectionsConnectionIdDelete(connectionId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteConnectionApiV1AdminConnectionsConnectionIdDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteConnectionApiV1AdminConnectionsConnectionIdDelete>>
>;

export type DeleteConnectionApiV1AdminConnectionsConnectionIdDeleteMutationError =
  ErrorType<void | HTTPValidationError>;

/**
 * @summary Delete Connection
 */
export const useDeleteConnectionApiV1AdminConnectionsConnectionIdDelete = <
  TError = ErrorType<void | HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteConnectionApiV1AdminConnectionsConnectionIdDelete>>,
    TError,
    { connectionId: number },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteConnectionApiV1AdminConnectionsConnectionIdDelete>>,
  TError,
  { connectionId: number },
  TContext
> => {
  const mutationOptions = getDeleteConnectionApiV1AdminConnectionsConnectionIdDeleteMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Get the connections.
 * @summary List Connections
 */
export const listConnectionsApiV1AdminTranslationGet = (signal?: AbortSignal) => {
  return customInstance<AzureTranslationConnectionsSchema[]>({
    url: `/api/v1/admin/translation`,
    method: 'GET',
    signal
  });
};

export const getListConnectionsApiV1AdminTranslationGetQueryKey = () => {
  return [`/api/v1/admin/translation`] as const;
};

export const getListConnectionsApiV1AdminTranslationGetQueryOptions = <
  TData = Awaited<ReturnType<typeof listConnectionsApiV1AdminTranslationGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof listConnectionsApiV1AdminTranslationGet>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListConnectionsApiV1AdminTranslationGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listConnectionsApiV1AdminTranslationGet>>> = ({ signal }) =>
    listConnectionsApiV1AdminTranslationGet(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listConnectionsApiV1AdminTranslationGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListConnectionsApiV1AdminTranslationGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof listConnectionsApiV1AdminTranslationGet>>
>;
export type ListConnectionsApiV1AdminTranslationGetQueryError = ErrorType<unknown>;

/**
 * @summary List Connections
 */

export function useListConnectionsApiV1AdminTranslationGet<
  TData = Awaited<ReturnType<typeof listConnectionsApiV1AdminTranslationGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof listConnectionsApiV1AdminTranslationGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getListConnectionsApiV1AdminTranslationGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Create a new translation connection.
 * @summary Create Connection
 */
export const createConnectionApiV1AdminTranslationPost = (
  azureTranslationConnectionsSchema: AzureTranslationConnectionsSchema
) => {
  return customInstance<unknown>({
    url: `/api/v1/admin/translation`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: azureTranslationConnectionsSchema
  });
};

export const getCreateConnectionApiV1AdminTranslationPostMutationOptions = <
  TError = ErrorType<void | HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createConnectionApiV1AdminTranslationPost>>,
    TError,
    { data: AzureTranslationConnectionsSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createConnectionApiV1AdminTranslationPost>>,
  TError,
  { data: AzureTranslationConnectionsSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createConnectionApiV1AdminTranslationPost>>,
    { data: AzureTranslationConnectionsSchema }
  > = props => {
    const { data } = props ?? {};

    return createConnectionApiV1AdminTranslationPost(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateConnectionApiV1AdminTranslationPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof createConnectionApiV1AdminTranslationPost>>
>;
export type CreateConnectionApiV1AdminTranslationPostMutationBody = AzureTranslationConnectionsSchema;
export type CreateConnectionApiV1AdminTranslationPostMutationError = ErrorType<void | HTTPValidationError>;

/**
 * @summary Create Connection
 */
export const useCreateConnectionApiV1AdminTranslationPost = <
  TError = ErrorType<void | HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createConnectionApiV1AdminTranslationPost>>,
    TError,
    { data: AzureTranslationConnectionsSchema },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createConnectionApiV1AdminTranslationPost>>,
  TError,
  { data: AzureTranslationConnectionsSchema },
  TContext
> => {
  const mutationOptions = getCreateConnectionApiV1AdminTranslationPostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Update a connection.
 * @summary Update Connection
 */
export const updateConnectionApiV1AdminTranslationTranslationIdPut = (
  translationId: number,
  azureTranslationConnectionsSchema: AzureTranslationConnectionsSchema
) => {
  return customInstance<unknown>({
    url: `/api/v1/admin/translation/${translationId}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: azureTranslationConnectionsSchema
  });
};

export const getUpdateConnectionApiV1AdminTranslationTranslationIdPutMutationOptions = <
  TError = ErrorType<void | HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateConnectionApiV1AdminTranslationTranslationIdPut>>,
    TError,
    { translationId: number; data: AzureTranslationConnectionsSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateConnectionApiV1AdminTranslationTranslationIdPut>>,
  TError,
  { translationId: number; data: AzureTranslationConnectionsSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateConnectionApiV1AdminTranslationTranslationIdPut>>,
    { translationId: number; data: AzureTranslationConnectionsSchema }
  > = props => {
    const { translationId, data } = props ?? {};

    return updateConnectionApiV1AdminTranslationTranslationIdPut(translationId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateConnectionApiV1AdminTranslationTranslationIdPutMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateConnectionApiV1AdminTranslationTranslationIdPut>>
>;
export type UpdateConnectionApiV1AdminTranslationTranslationIdPutMutationBody = AzureTranslationConnectionsSchema;
export type UpdateConnectionApiV1AdminTranslationTranslationIdPutMutationError = ErrorType<void | HTTPValidationError>;

/**
 * @summary Update Connection
 */
export const useUpdateConnectionApiV1AdminTranslationTranslationIdPut = <
  TError = ErrorType<void | HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateConnectionApiV1AdminTranslationTranslationIdPut>>,
    TError,
    { translationId: number; data: AzureTranslationConnectionsSchema },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateConnectionApiV1AdminTranslationTranslationIdPut>>,
  TError,
  { translationId: number; data: AzureTranslationConnectionsSchema },
  TContext
> => {
  const mutationOptions = getUpdateConnectionApiV1AdminTranslationTranslationIdPutMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Delete a single translation connection.
 * @summary Delete Translation Connection
 */
export const deleteTranslationConnectionApiV1AdminTranslationTranslationIdDelete = (translationId: number) => {
  return customInstance<unknown>({ url: `/api/v1/admin/translation/${translationId}`, method: 'DELETE' });
};

export const getDeleteTranslationConnectionApiV1AdminTranslationTranslationIdDeleteMutationOptions = <
  TError = ErrorType<void | HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteTranslationConnectionApiV1AdminTranslationTranslationIdDelete>>,
    TError,
    { translationId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteTranslationConnectionApiV1AdminTranslationTranslationIdDelete>>,
  TError,
  { translationId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteTranslationConnectionApiV1AdminTranslationTranslationIdDelete>>,
    { translationId: number }
  > = props => {
    const { translationId } = props ?? {};

    return deleteTranslationConnectionApiV1AdminTranslationTranslationIdDelete(translationId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteTranslationConnectionApiV1AdminTranslationTranslationIdDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteTranslationConnectionApiV1AdminTranslationTranslationIdDelete>>
>;

export type DeleteTranslationConnectionApiV1AdminTranslationTranslationIdDeleteMutationError =
  ErrorType<void | HTTPValidationError>;

/**
 * @summary Delete Translation Connection
 */
export const useDeleteTranslationConnectionApiV1AdminTranslationTranslationIdDelete = <
  TError = ErrorType<void | HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteTranslationConnectionApiV1AdminTranslationTranslationIdDelete>>,
    TError,
    { translationId: number },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteTranslationConnectionApiV1AdminTranslationTranslationIdDelete>>,
  TError,
  { translationId: number },
  TContext
> => {
  const mutationOptions =
    getDeleteTranslationConnectionApiV1AdminTranslationTranslationIdDeleteMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * List applications
 * @summary List Applications
 */
export const adminListApps = (organizationSchemaName: string, params?: AdminListAppsParams, signal?: AbortSignal) => {
  return customInstance<ApplicationSchema[]>({
    url: `/api/v1/configs/${organizationSchemaName}/applications`,
    method: 'GET',
    params,
    signal
  });
};

export const getAdminListAppsQueryKey = (organizationSchemaName: string, params?: AdminListAppsParams) => {
  return [`/api/v1/configs/${organizationSchemaName}/applications`, ...(params ? [params] : [])] as const;
};

export const getAdminListAppsQueryOptions = <
  TData = Awaited<ReturnType<typeof adminListApps>>,
  TError = ErrorType<HTTPValidationError>
>(
  organizationSchemaName: string,
  params?: AdminListAppsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof adminListApps>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAdminListAppsQueryKey(organizationSchemaName, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof adminListApps>>> = ({ signal }) =>
    adminListApps(organizationSchemaName, params, signal);

  return { queryKey, queryFn, enabled: !!organizationSchemaName, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof adminListApps>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AdminListAppsQueryResult = NonNullable<Awaited<ReturnType<typeof adminListApps>>>;
export type AdminListAppsQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary List Applications
 */

export function useAdminListApps<
  TData = Awaited<ReturnType<typeof adminListApps>>,
  TError = ErrorType<HTTPValidationError>
>(
  organizationSchemaName: string,
  params?: AdminListAppsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof adminListApps>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getAdminListAppsQueryOptions(organizationSchemaName, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Retrieve existing users and invited users
 * @summary Retrieve Users And Invitations
 */
export const retrieveUsersAndInvitationsApiV1UsersGet = (signal?: AbortSignal) => {
  return customInstance<UserInvitationSchema[]>({ url: `/api/v1/users`, method: 'GET', signal });
};

export const getRetrieveUsersAndInvitationsApiV1UsersGetQueryKey = () => {
  return [`/api/v1/users`] as const;
};

export const getRetrieveUsersAndInvitationsApiV1UsersGetQueryOptions = <
  TData = Awaited<ReturnType<typeof retrieveUsersAndInvitationsApiV1UsersGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof retrieveUsersAndInvitationsApiV1UsersGet>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRetrieveUsersAndInvitationsApiV1UsersGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof retrieveUsersAndInvitationsApiV1UsersGet>>> = ({ signal }) =>
    retrieveUsersAndInvitationsApiV1UsersGet(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof retrieveUsersAndInvitationsApiV1UsersGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RetrieveUsersAndInvitationsApiV1UsersGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof retrieveUsersAndInvitationsApiV1UsersGet>>
>;
export type RetrieveUsersAndInvitationsApiV1UsersGetQueryError = ErrorType<unknown>;

/**
 * @summary Retrieve Users And Invitations
 */

export function useRetrieveUsersAndInvitationsApiV1UsersGet<
  TData = Awaited<ReturnType<typeof retrieveUsersAndInvitationsApiV1UsersGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof retrieveUsersAndInvitationsApiV1UsersGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getRetrieveUsersAndInvitationsApiV1UsersGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Regenerate user token
 * @summary Regenerate Api Token
 */
export const regenerateApiTokenApiV1UsersRegenerateApiTokenGet = (signal?: AbortSignal) => {
  return customInstance<string>({ url: `/api/v1/users/regenerate-api-token`, method: 'GET', signal });
};

export const getRegenerateApiTokenApiV1UsersRegenerateApiTokenGetQueryKey = () => {
  return [`/api/v1/users/regenerate-api-token`] as const;
};

export const getRegenerateApiTokenApiV1UsersRegenerateApiTokenGetQueryOptions = <
  TData = Awaited<ReturnType<typeof regenerateApiTokenApiV1UsersRegenerateApiTokenGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof regenerateApiTokenApiV1UsersRegenerateApiTokenGet>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRegenerateApiTokenApiV1UsersRegenerateApiTokenGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof regenerateApiTokenApiV1UsersRegenerateApiTokenGet>>> = ({
    signal
  }) => regenerateApiTokenApiV1UsersRegenerateApiTokenGet(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof regenerateApiTokenApiV1UsersRegenerateApiTokenGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RegenerateApiTokenApiV1UsersRegenerateApiTokenGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof regenerateApiTokenApiV1UsersRegenerateApiTokenGet>>
>;
export type RegenerateApiTokenApiV1UsersRegenerateApiTokenGetQueryError = ErrorType<unknown>;

/**
 * @summary Regenerate Api Token
 */

export function useRegenerateApiTokenApiV1UsersRegenerateApiTokenGet<
  TData = Awaited<ReturnType<typeof regenerateApiTokenApiV1UsersRegenerateApiTokenGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof regenerateApiTokenApiV1UsersRegenerateApiTokenGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getRegenerateApiTokenApiV1UsersRegenerateApiTokenGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Get current user token last 5
 * @summary Get Current Token
 */
export const getCurrentTokenApiV1UsersTokenLastFiveGet = (signal?: AbortSignal) => {
  return customInstance<string>({ url: `/api/v1/users/token-last-five`, method: 'GET', signal });
};

export const getGetCurrentTokenApiV1UsersTokenLastFiveGetQueryKey = () => {
  return [`/api/v1/users/token-last-five`] as const;
};

export const getGetCurrentTokenApiV1UsersTokenLastFiveGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getCurrentTokenApiV1UsersTokenLastFiveGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getCurrentTokenApiV1UsersTokenLastFiveGet>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCurrentTokenApiV1UsersTokenLastFiveGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCurrentTokenApiV1UsersTokenLastFiveGet>>> = ({ signal }) =>
    getCurrentTokenApiV1UsersTokenLastFiveGet(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCurrentTokenApiV1UsersTokenLastFiveGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCurrentTokenApiV1UsersTokenLastFiveGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCurrentTokenApiV1UsersTokenLastFiveGet>>
>;
export type GetCurrentTokenApiV1UsersTokenLastFiveGetQueryError = ErrorType<unknown>;

/**
 * @summary Get Current Token
 */

export function useGetCurrentTokenApiV1UsersTokenLastFiveGet<
  TData = Awaited<ReturnType<typeof getCurrentTokenApiV1UsersTokenLastFiveGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getCurrentTokenApiV1UsersTokenLastFiveGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetCurrentTokenApiV1UsersTokenLastFiveGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Delete the user.
 * @summary Delete User
 */
export const deleteUserApiV1UsersEmailDelete = (email: string) => {
  return customInstance<unknown>({ url: `/api/v1/users/${email}`, method: 'DELETE' });
};

export const getDeleteUserApiV1UsersEmailDeleteMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUserApiV1UsersEmailDelete>>,
    TError,
    { email: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteUserApiV1UsersEmailDelete>>,
  TError,
  { email: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteUserApiV1UsersEmailDelete>>,
    { email: string }
  > = props => {
    const { email } = props ?? {};

    return deleteUserApiV1UsersEmailDelete(email);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteUserApiV1UsersEmailDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteUserApiV1UsersEmailDelete>>
>;

export type DeleteUserApiV1UsersEmailDeleteMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Delete User
 */
export const useDeleteUserApiV1UsersEmailDelete = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUserApiV1UsersEmailDelete>>,
    TError,
    { email: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteUserApiV1UsersEmailDelete>>,
  TError,
  { email: string },
  TContext
> => {
  const mutationOptions = getDeleteUserApiV1UsersEmailDeleteMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Update the user.
 * @summary Update User
 */
export const updateUserApiV1UsersEmailPut = (email: string, userUpdateSchema: UserUpdateSchema) => {
  return customInstance<unknown>({
    url: `/api/v1/users/${email}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: userUpdateSchema
  });
};

export const getUpdateUserApiV1UsersEmailPutMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUserApiV1UsersEmailPut>>,
    TError,
    { email: string; data: UserUpdateSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateUserApiV1UsersEmailPut>>,
  TError,
  { email: string; data: UserUpdateSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateUserApiV1UsersEmailPut>>,
    { email: string; data: UserUpdateSchema }
  > = props => {
    const { email, data } = props ?? {};

    return updateUserApiV1UsersEmailPut(email, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateUserApiV1UsersEmailPutMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateUserApiV1UsersEmailPut>>
>;
export type UpdateUserApiV1UsersEmailPutMutationBody = UserUpdateSchema;
export type UpdateUserApiV1UsersEmailPutMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Update User
 */
export const useUpdateUserApiV1UsersEmailPut = <TError = ErrorType<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUserApiV1UsersEmailPut>>,
    TError,
    { email: string; data: UserUpdateSchema },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateUserApiV1UsersEmailPut>>,
  TError,
  { email: string; data: UserUpdateSchema },
  TContext
> => {
  const mutationOptions = getUpdateUserApiV1UsersEmailPutMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Create Application
 * @summary Create Application
 */
export const createApp = (applicationCreationSchema: ApplicationCreationSchema) => {
  return customInstance<DeepchecksLlmEvalApiV1SchemasRecordIdentifierSchema>({
    url: `/api/v1/applications`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: applicationCreationSchema
  });
};

export const getCreateAppMutationOptions = <TError = ErrorType<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createApp>>,
    TError,
    { data: ApplicationCreationSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createApp>>,
  TError,
  { data: ApplicationCreationSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createApp>>,
    { data: ApplicationCreationSchema }
  > = props => {
    const { data } = props ?? {};

    return createApp(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateAppMutationResult = NonNullable<Awaited<ReturnType<typeof createApp>>>;
export type CreateAppMutationBody = ApplicationCreationSchema;
export type CreateAppMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Create Application
 */
export const useCreateApp = <TError = ErrorType<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createApp>>,
    TError,
    { data: ApplicationCreationSchema },
    TContext
  >;
}): UseMutationResult<Awaited<ReturnType<typeof createApp>>, TError, { data: ApplicationCreationSchema }, TContext> => {
  const mutationOptions = getCreateAppMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Update application
 * @summary Update Application
 */
export const updateApp = (applicationUpdateSchema: ApplicationUpdateSchema) => {
  return customInstance<unknown>({
    url: `/api/v1/applications`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: applicationUpdateSchema
  });
};

export const getUpdateAppMutationOptions = <TError = ErrorType<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateApp>>,
    TError,
    { data: ApplicationUpdateSchema },
    TContext
  >;
}): UseMutationOptions<Awaited<ReturnType<typeof updateApp>>, TError, { data: ApplicationUpdateSchema }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateApp>>,
    { data: ApplicationUpdateSchema }
  > = props => {
    const { data } = props ?? {};

    return updateApp(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateAppMutationResult = NonNullable<Awaited<ReturnType<typeof updateApp>>>;
export type UpdateAppMutationBody = ApplicationUpdateSchema;
export type UpdateAppMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Update Application
 */
export const useUpdateApp = <TError = ErrorType<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateApp>>,
    TError,
    { data: ApplicationUpdateSchema },
    TContext
  >;
}): UseMutationResult<Awaited<ReturnType<typeof updateApp>>, TError, { data: ApplicationUpdateSchema }, TContext> => {
  const mutationOptions = getUpdateAppMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * List applications
 * @summary List Applications
 */
export const listApps = (params?: ListAppsParams, signal?: AbortSignal) => {
  return customInstance<ApplicationSchema[]>({ url: `/api/v1/applications`, method: 'GET', params, signal });
};

export const getListAppsQueryKey = (params?: ListAppsParams) => {
  return [`/api/v1/applications`, ...(params ? [params] : [])] as const;
};

export const getListAppsQueryOptions = <
  TData = Awaited<ReturnType<typeof listApps>>,
  TError = ErrorType<HTTPValidationError>
>(
  params?: ListAppsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof listApps>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListAppsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listApps>>> = ({ signal }) => listApps(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listApps>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListAppsQueryResult = NonNullable<Awaited<ReturnType<typeof listApps>>>;
export type ListAppsQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary List Applications
 */

export function useListApps<TData = Awaited<ReturnType<typeof listApps>>, TError = ErrorType<HTTPValidationError>>(
  params?: ListAppsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof listApps>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getListAppsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Delete an application
 * @summary Delete Applications Route
 */
export const deleteApp = (params?: DeleteAppParams) => {
  return customInstance<RecordIdentifiersPairSchema>({ url: `/api/v1/applications`, method: 'DELETE', params });
};

export const getDeleteAppMutationOptions = <TError = ErrorType<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteApp>>, TError, { params?: DeleteAppParams }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteApp>>, TError, { params?: DeleteAppParams }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApp>>, { params?: DeleteAppParams }> = props => {
    const { params } = props ?? {};

    return deleteApp(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteAppMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApp>>>;

export type DeleteAppMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Delete Applications Route
 */
export const useDeleteApp = <TError = ErrorType<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteApp>>, TError, { params?: DeleteAppParams }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof deleteApp>>, TError, { params?: DeleteAppParams }, TContext> => {
  const mutationOptions = getDeleteAppMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get Application Config
 */
export const getApplicationConfigApiV1ApplicationsAppIdConfigGet = (appId: number, signal?: AbortSignal) => {
  return customInstance<unknown>({ url: `/api/v1/applications/${appId}/config`, method: 'GET', signal });
};

export const getGetApplicationConfigApiV1ApplicationsAppIdConfigGetQueryKey = (appId: number) => {
  return [`/api/v1/applications/${appId}/config`] as const;
};

export const getGetApplicationConfigApiV1ApplicationsAppIdConfigGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getApplicationConfigApiV1ApplicationsAppIdConfigGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  appId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getApplicationConfigApiV1ApplicationsAppIdConfigGet>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApplicationConfigApiV1ApplicationsAppIdConfigGetQueryKey(appId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApplicationConfigApiV1ApplicationsAppIdConfigGet>>> = ({
    signal
  }) => getApplicationConfigApiV1ApplicationsAppIdConfigGet(appId, signal);

  return { queryKey, queryFn, enabled: !!appId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApplicationConfigApiV1ApplicationsAppIdConfigGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApplicationConfigApiV1ApplicationsAppIdConfigGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApplicationConfigApiV1ApplicationsAppIdConfigGet>>
>;
export type GetApplicationConfigApiV1ApplicationsAppIdConfigGetQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Application Config
 */

export function useGetApplicationConfigApiV1ApplicationsAppIdConfigGet<
  TData = Awaited<ReturnType<typeof getApplicationConfigApiV1ApplicationsAppIdConfigGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  appId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getApplicationConfigApiV1ApplicationsAppIdConfigGet>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetApplicationConfigApiV1ApplicationsAppIdConfigGetQueryOptions(appId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update Application Config Route
 */
export const updateApplicationConfigRouteApiV1ApplicationsAppIdConfigPut = (
  appId: number,
  bodyUpdateApplicationConfigRouteApiV1ApplicationsAppIdConfigPut: BodyUpdateApplicationConfigRouteApiV1ApplicationsAppIdConfigPut
) => {
  const formData = new FormData();
  formData.append('file', bodyUpdateApplicationConfigRouteApiV1ApplicationsAppIdConfigPut.file);

  return customInstance<unknown | void>({
    url: `/api/v1/applications/${appId}/config`,
    method: 'PUT',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData
  });
};

export const getUpdateApplicationConfigRouteApiV1ApplicationsAppIdConfigPutMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateApplicationConfigRouteApiV1ApplicationsAppIdConfigPut>>,
    TError,
    { appId: number; data: BodyUpdateApplicationConfigRouteApiV1ApplicationsAppIdConfigPut },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateApplicationConfigRouteApiV1ApplicationsAppIdConfigPut>>,
  TError,
  { appId: number; data: BodyUpdateApplicationConfigRouteApiV1ApplicationsAppIdConfigPut },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateApplicationConfigRouteApiV1ApplicationsAppIdConfigPut>>,
    { appId: number; data: BodyUpdateApplicationConfigRouteApiV1ApplicationsAppIdConfigPut }
  > = props => {
    const { appId, data } = props ?? {};

    return updateApplicationConfigRouteApiV1ApplicationsAppIdConfigPut(appId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateApplicationConfigRouteApiV1ApplicationsAppIdConfigPutMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateApplicationConfigRouteApiV1ApplicationsAppIdConfigPut>>
>;
export type UpdateApplicationConfigRouteApiV1ApplicationsAppIdConfigPutMutationBody =
  BodyUpdateApplicationConfigRouteApiV1ApplicationsAppIdConfigPut;
export type UpdateApplicationConfigRouteApiV1ApplicationsAppIdConfigPutMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Update Application Config Route
 */
export const useUpdateApplicationConfigRouteApiV1ApplicationsAppIdConfigPut = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateApplicationConfigRouteApiV1ApplicationsAppIdConfigPut>>,
    TError,
    { appId: number; data: BodyUpdateApplicationConfigRouteApiV1ApplicationsAppIdConfigPut },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateApplicationConfigRouteApiV1ApplicationsAppIdConfigPut>>,
  TError,
  { appId: number; data: BodyUpdateApplicationConfigRouteApiV1ApplicationsAppIdConfigPut },
  TContext
> => {
  const mutationOptions = getUpdateApplicationConfigRouteApiV1ApplicationsAppIdConfigPutMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get Interaction Type Config
 */
export const getInteractionTypeConfigApiV1ApplicationsAppIdInteractionTypesInteractionTypeConfigGet = (
  appId: number,
  interactionType: string,
  signal?: AbortSignal
) => {
  return customInstance<unknown>({
    url: `/api/v1/applications/${appId}/interaction_types/${interactionType}/config`,
    method: 'GET',
    signal
  });
};

export const getGetInteractionTypeConfigApiV1ApplicationsAppIdInteractionTypesInteractionTypeConfigGetQueryKey = (
  appId: number,
  interactionType: string
) => {
  return [`/api/v1/applications/${appId}/interaction_types/${interactionType}/config`] as const;
};

export const getGetInteractionTypeConfigApiV1ApplicationsAppIdInteractionTypesInteractionTypeConfigGetQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getInteractionTypeConfigApiV1ApplicationsAppIdInteractionTypesInteractionTypeConfigGet>
  >,
  TError = ErrorType<HTTPValidationError>
>(
  appId: number,
  interactionType: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<typeof getInteractionTypeConfigApiV1ApplicationsAppIdInteractionTypesInteractionTypeConfigGet>
      >,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetInteractionTypeConfigApiV1ApplicationsAppIdInteractionTypesInteractionTypeConfigGetQueryKey(
      appId,
      interactionType
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getInteractionTypeConfigApiV1ApplicationsAppIdInteractionTypesInteractionTypeConfigGet>>
  > = ({ signal }) =>
    getInteractionTypeConfigApiV1ApplicationsAppIdInteractionTypesInteractionTypeConfigGet(
      appId,
      interactionType,
      signal
    );

  return { queryKey, queryFn, enabled: !!(appId && interactionType), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getInteractionTypeConfigApiV1ApplicationsAppIdInteractionTypesInteractionTypeConfigGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetInteractionTypeConfigApiV1ApplicationsAppIdInteractionTypesInteractionTypeConfigGetQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getInteractionTypeConfigApiV1ApplicationsAppIdInteractionTypesInteractionTypeConfigGet>>
  >;
export type GetInteractionTypeConfigApiV1ApplicationsAppIdInteractionTypesInteractionTypeConfigGetQueryError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Get Interaction Type Config
 */

export function useGetInteractionTypeConfigApiV1ApplicationsAppIdInteractionTypesInteractionTypeConfigGet<
  TData = Awaited<
    ReturnType<typeof getInteractionTypeConfigApiV1ApplicationsAppIdInteractionTypesInteractionTypeConfigGet>
  >,
  TError = ErrorType<HTTPValidationError>
>(
  appId: number,
  interactionType: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<typeof getInteractionTypeConfigApiV1ApplicationsAppIdInteractionTypesInteractionTypeConfigGet>
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetInteractionTypeConfigApiV1ApplicationsAppIdInteractionTypesInteractionTypeConfigGetQueryOptions(
      appId,
      interactionType,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update Interaction Type Config Route
 */
export const updateInteractionTypeConfigRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeConfigPut = (
  appId: number,
  interactionType: string,
  bodyUpdateInteractionTypeConfigRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeConfigPut: BodyUpdateInteractionTypeConfigRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeConfigPut
) => {
  const formData = new FormData();
  formData.append(
    'file',
    bodyUpdateInteractionTypeConfigRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeConfigPut.file
  );

  return customInstance<unknown | void>({
    url: `/api/v1/applications/${appId}/interaction_types/${interactionType}/config`,
    method: 'PUT',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData
  });
};

export const getUpdateInteractionTypeConfigRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeConfigPutMutationOptions =
  <TError = ErrorType<HTTPValidationError>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof updateInteractionTypeConfigRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeConfigPut
        >
      >,
      TError,
      {
        appId: number;
        interactionType: string;
        data: BodyUpdateInteractionTypeConfigRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeConfigPut;
      },
      TContext
    >;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof updateInteractionTypeConfigRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeConfigPut>
    >,
    TError,
    {
      appId: number;
      interactionType: string;
      data: BodyUpdateInteractionTypeConfigRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeConfigPut;
    },
    TContext
  > => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof updateInteractionTypeConfigRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeConfigPut
        >
      >,
      {
        appId: number;
        interactionType: string;
        data: BodyUpdateInteractionTypeConfigRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeConfigPut;
      }
    > = props => {
      const { appId, interactionType, data } = props ?? {};

      return updateInteractionTypeConfigRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeConfigPut(
        appId,
        interactionType,
        data
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type UpdateInteractionTypeConfigRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeConfigPutMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof updateInteractionTypeConfigRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeConfigPut>
    >
  >;
export type UpdateInteractionTypeConfigRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeConfigPutMutationBody =
  BodyUpdateInteractionTypeConfigRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeConfigPut;
export type UpdateInteractionTypeConfigRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeConfigPutMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Update Interaction Type Config Route
 */
export const useUpdateInteractionTypeConfigRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeConfigPut = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof updateInteractionTypeConfigRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeConfigPut>
    >,
    TError,
    {
      appId: number;
      interactionType: string;
      data: BodyUpdateInteractionTypeConfigRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeConfigPut;
    },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof updateInteractionTypeConfigRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeConfigPut>
  >,
  TError,
  {
    appId: number;
    interactionType: string;
    data: BodyUpdateInteractionTypeConfigRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeConfigPut;
  },
  TContext
> => {
  const mutationOptions =
    getUpdateInteractionTypeConfigRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeConfigPutMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};

/**
 * Check if application has running tasks
 * @summary Has Running Task
 */
export const hasRunningTasks = (params?: HasRunningTasksParams, signal?: AbortSignal) => {
  return customInstance<unknown>({ url: `/api/v1/application/has-executing-tasks`, method: 'GET', params, signal });
};

export const getHasRunningTasksQueryKey = (params?: HasRunningTasksParams) => {
  return [`/api/v1/application/has-executing-tasks`, ...(params ? [params] : [])] as const;
};

export const getHasRunningTasksQueryOptions = <
  TData = Awaited<ReturnType<typeof hasRunningTasks>>,
  TError = ErrorType<HTTPValidationError>
>(
  params?: HasRunningTasksParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof hasRunningTasks>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getHasRunningTasksQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof hasRunningTasks>>> = ({ signal }) =>
    hasRunningTasks(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof hasRunningTasks>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type HasRunningTasksQueryResult = NonNullable<Awaited<ReturnType<typeof hasRunningTasks>>>;
export type HasRunningTasksQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Has Running Task
 */

export function useHasRunningTasks<
  TData = Awaited<ReturnType<typeof hasRunningTasks>>,
  TError = ErrorType<HTTPValidationError>
>(
  params?: HasRunningTasksParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof hasRunningTasks>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getHasRunningTasksQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Emails for notification
 * @summary Get Notification Emails
 */
export const getNotificationEmail = (appId: number, signal?: AbortSignal) => {
  return customInstance<ApplicationNotificationEmailSchema[]>({
    url: `/api/v1/applications/${appId}/notification_emails`,
    method: 'GET',
    signal
  });
};

export const getGetNotificationEmailQueryKey = (appId: number) => {
  return [`/api/v1/applications/${appId}/notification_emails`] as const;
};

export const getGetNotificationEmailQueryOptions = <
  TData = Awaited<ReturnType<typeof getNotificationEmail>>,
  TError = ErrorType<HTTPValidationError>
>(
  appId: number,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getNotificationEmail>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetNotificationEmailQueryKey(appId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getNotificationEmail>>> = ({ signal }) =>
    getNotificationEmail(appId, signal);

  return { queryKey, queryFn, enabled: !!appId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getNotificationEmail>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetNotificationEmailQueryResult = NonNullable<Awaited<ReturnType<typeof getNotificationEmail>>>;
export type GetNotificationEmailQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Notification Emails
 */

export function useGetNotificationEmail<
  TData = Awaited<ReturnType<typeof getNotificationEmail>>,
  TError = ErrorType<HTTPValidationError>
>(
  appId: number,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getNotificationEmail>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetNotificationEmailQueryOptions(appId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Add email that will receive notification about finished props/topics calculations
 * @summary Add Notification Email To Application
 */
export const addNotificationEmail = (appId: number, createNotificationEmailSchema: CreateNotificationEmailSchema) => {
  return customInstance<DeepchecksLlmEvalApiV1SchemasRecordIdentifierSchema>({
    url: `/api/v1/applications/${appId}/notification_emails`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createNotificationEmailSchema
  });
};

export const getAddNotificationEmailMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addNotificationEmail>>,
    TError,
    { appId: number; data: CreateNotificationEmailSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof addNotificationEmail>>,
  TError,
  { appId: number; data: CreateNotificationEmailSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addNotificationEmail>>,
    { appId: number; data: CreateNotificationEmailSchema }
  > = props => {
    const { appId, data } = props ?? {};

    return addNotificationEmail(appId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddNotificationEmailMutationResult = NonNullable<Awaited<ReturnType<typeof addNotificationEmail>>>;
export type AddNotificationEmailMutationBody = CreateNotificationEmailSchema;
export type AddNotificationEmailMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Add Notification Email To Application
 */
export const useAddNotificationEmail = <TError = ErrorType<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addNotificationEmail>>,
    TError,
    { appId: number; data: CreateNotificationEmailSchema },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof addNotificationEmail>>,
  TError,
  { appId: number; data: CreateNotificationEmailSchema },
  TContext
> => {
  const mutationOptions = getAddNotificationEmailMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Add email that will receive notification about finished props/topics calculations
 * @summary Delete Notification Email From Application
 */
export const removeNotificationEmail = (appId: number, appNotificationEmailId: number) => {
  return customInstance<DeepchecksLlmEvalApiV1SchemasRecordIdentifierSchema>({
    url: `/api/v1/applications/${appId}/notification_emails/${appNotificationEmailId}`,
    method: 'DELETE'
  });
};

export const getRemoveNotificationEmailMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeNotificationEmail>>,
    TError,
    { appId: number; appNotificationEmailId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof removeNotificationEmail>>,
  TError,
  { appId: number; appNotificationEmailId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof removeNotificationEmail>>,
    { appId: number; appNotificationEmailId: number }
  > = props => {
    const { appId, appNotificationEmailId } = props ?? {};

    return removeNotificationEmail(appId, appNotificationEmailId);
  };

  return { mutationFn, ...mutationOptions };
};

export type RemoveNotificationEmailMutationResult = NonNullable<Awaited<ReturnType<typeof removeNotificationEmail>>>;

export type RemoveNotificationEmailMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Delete Notification Email From Application
 */
export const useRemoveNotificationEmail = <TError = ErrorType<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeNotificationEmail>>,
    TError,
    { appId: number; appNotificationEmailId: number },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof removeNotificationEmail>>,
  TError,
  { appId: number; appNotificationEmailId: number },
  TContext
> => {
  const mutationOptions = getRemoveNotificationEmailMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Retrain Deepchecks evaluator, this potentially can update the threshold used to set the annotations
 * @summary Retrain Dc Evaluator
 */
export const retrainDcEvaluator = (appId: number, params: RetrainDcEvaluatorParams) => {
  return customInstance<unknown>({ url: `/api/v1/applications/${appId}/retrain_dc_evaluator`, method: 'POST', params });
};

export const getRetrainDcEvaluatorMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof retrainDcEvaluator>>,
    TError,
    { appId: number; params: RetrainDcEvaluatorParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof retrainDcEvaluator>>,
  TError,
  { appId: number; params: RetrainDcEvaluatorParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof retrainDcEvaluator>>,
    { appId: number; params: RetrainDcEvaluatorParams }
  > = props => {
    const { appId, params } = props ?? {};

    return retrainDcEvaluator(appId, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type RetrainDcEvaluatorMutationResult = NonNullable<Awaited<ReturnType<typeof retrainDcEvaluator>>>;

export type RetrainDcEvaluatorMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Retrain Dc Evaluator
 */
export const useRetrainDcEvaluator = <TError = ErrorType<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof retrainDcEvaluator>>,
    TError,
    { appId: number; params: RetrainDcEvaluatorParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof retrainDcEvaluator>>,
  TError,
  { appId: number; params: RetrainDcEvaluatorParams },
  TContext
> => {
  const mutationOptions = getRetrainDcEvaluatorMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get Versions Metadata
 */
export const getVersionsMetadataApiV1ApplicationsAppIdVersionsMetadataGet = (appId: number, signal?: AbortSignal) => {
  return customInstance<VersionsMetadataSchema>({
    url: `/api/v1/applications/${appId}/versions-metadata`,
    method: 'GET',
    signal
  });
};

export const getGetVersionsMetadataApiV1ApplicationsAppIdVersionsMetadataGetQueryKey = (appId: number) => {
  return [`/api/v1/applications/${appId}/versions-metadata`] as const;
};

export const getGetVersionsMetadataApiV1ApplicationsAppIdVersionsMetadataGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getVersionsMetadataApiV1ApplicationsAppIdVersionsMetadataGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  appId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getVersionsMetadataApiV1ApplicationsAppIdVersionsMetadataGet>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetVersionsMetadataApiV1ApplicationsAppIdVersionsMetadataGetQueryKey(appId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getVersionsMetadataApiV1ApplicationsAppIdVersionsMetadataGet>>
  > = ({ signal }) => getVersionsMetadataApiV1ApplicationsAppIdVersionsMetadataGet(appId, signal);

  return { queryKey, queryFn, enabled: !!appId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getVersionsMetadataApiV1ApplicationsAppIdVersionsMetadataGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetVersionsMetadataApiV1ApplicationsAppIdVersionsMetadataGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getVersionsMetadataApiV1ApplicationsAppIdVersionsMetadataGet>>
>;
export type GetVersionsMetadataApiV1ApplicationsAppIdVersionsMetadataGetQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Versions Metadata
 */

export function useGetVersionsMetadataApiV1ApplicationsAppIdVersionsMetadataGet<
  TData = Awaited<ReturnType<typeof getVersionsMetadataApiV1ApplicationsAppIdVersionsMetadataGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  appId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getVersionsMetadataApiV1ApplicationsAppIdVersionsMetadataGet>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetVersionsMetadataApiV1ApplicationsAppIdVersionsMetadataGetQueryOptions(appId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Interaction Types Route
 */
export const getInteractionTypesRouteApiV1ApplicationsAppIdInteractionTypesGet = (
  appId: number,
  signal?: AbortSignal
) => {
  return customInstance<ReadInteractionTypeSchema[]>({
    url: `/api/v1/applications/${appId}/interaction-types`,
    method: 'GET',
    signal
  });
};

export const getGetInteractionTypesRouteApiV1ApplicationsAppIdInteractionTypesGetQueryKey = (appId: number) => {
  return [`/api/v1/applications/${appId}/interaction-types`] as const;
};

export const getGetInteractionTypesRouteApiV1ApplicationsAppIdInteractionTypesGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getInteractionTypesRouteApiV1ApplicationsAppIdInteractionTypesGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  appId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getInteractionTypesRouteApiV1ApplicationsAppIdInteractionTypesGet>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetInteractionTypesRouteApiV1ApplicationsAppIdInteractionTypesGetQueryKey(appId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getInteractionTypesRouteApiV1ApplicationsAppIdInteractionTypesGet>>
  > = ({ signal }) => getInteractionTypesRouteApiV1ApplicationsAppIdInteractionTypesGet(appId, signal);

  return { queryKey, queryFn, enabled: !!appId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getInteractionTypesRouteApiV1ApplicationsAppIdInteractionTypesGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetInteractionTypesRouteApiV1ApplicationsAppIdInteractionTypesGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getInteractionTypesRouteApiV1ApplicationsAppIdInteractionTypesGet>>
>;
export type GetInteractionTypesRouteApiV1ApplicationsAppIdInteractionTypesGetQueryError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Get Interaction Types Route
 */

export function useGetInteractionTypesRouteApiV1ApplicationsAppIdInteractionTypesGet<
  TData = Awaited<ReturnType<typeof getInteractionTypesRouteApiV1ApplicationsAppIdInteractionTypesGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  appId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getInteractionTypesRouteApiV1ApplicationsAppIdInteractionTypesGet>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetInteractionTypesRouteApiV1ApplicationsAppIdInteractionTypesGetQueryOptions(appId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Create Interaction Type Route
 */
export const createInteractionTypeRouteApiV1ApplicationsAppIdInteractionTypesPost = (
  appId: number,
  createInteractionTypeSchema: CreateInteractionTypeSchema
) => {
  return customInstance<DeepchecksLlmEvalApiV1SchemasRecordIdentifierSchema>({
    url: `/api/v1/applications/${appId}/interaction-types`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createInteractionTypeSchema
  });
};

export const getCreateInteractionTypeRouteApiV1ApplicationsAppIdInteractionTypesPostMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createInteractionTypeRouteApiV1ApplicationsAppIdInteractionTypesPost>>,
    TError,
    { appId: number; data: CreateInteractionTypeSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createInteractionTypeRouteApiV1ApplicationsAppIdInteractionTypesPost>>,
  TError,
  { appId: number; data: CreateInteractionTypeSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createInteractionTypeRouteApiV1ApplicationsAppIdInteractionTypesPost>>,
    { appId: number; data: CreateInteractionTypeSchema }
  > = props => {
    const { appId, data } = props ?? {};

    return createInteractionTypeRouteApiV1ApplicationsAppIdInteractionTypesPost(appId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateInteractionTypeRouteApiV1ApplicationsAppIdInteractionTypesPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof createInteractionTypeRouteApiV1ApplicationsAppIdInteractionTypesPost>>
>;
export type CreateInteractionTypeRouteApiV1ApplicationsAppIdInteractionTypesPostMutationBody =
  CreateInteractionTypeSchema;
export type CreateInteractionTypeRouteApiV1ApplicationsAppIdInteractionTypesPostMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Create Interaction Type Route
 */
export const useCreateInteractionTypeRouteApiV1ApplicationsAppIdInteractionTypesPost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createInteractionTypeRouteApiV1ApplicationsAppIdInteractionTypesPost>>,
    TError,
    { appId: number; data: CreateInteractionTypeSchema },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createInteractionTypeRouteApiV1ApplicationsAppIdInteractionTypesPost>>,
  TError,
  { appId: number; data: CreateInteractionTypeSchema },
  TContext
> => {
  const mutationOptions =
    getCreateInteractionTypeRouteApiV1ApplicationsAppIdInteractionTypesPostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Update Interaction Type Route
 */
export const updateInteractionTypeRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeIdPut = (
  appId: number,
  interactionTypeId: number,
  updateInteractionTypeSchema: UpdateInteractionTypeSchema
) => {
  return customInstance<DeepchecksLlmEvalApiV1SchemasRecordIdentifierSchema>({
    url: `/api/v1/applications/${appId}/interaction-types/${interactionTypeId}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: updateInteractionTypeSchema
  });
};

export const getUpdateInteractionTypeRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeIdPutMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateInteractionTypeRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeIdPut>>,
    TError,
    { appId: number; interactionTypeId: number; data: UpdateInteractionTypeSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateInteractionTypeRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeIdPut>>,
  TError,
  { appId: number; interactionTypeId: number; data: UpdateInteractionTypeSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateInteractionTypeRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeIdPut>>,
    { appId: number; interactionTypeId: number; data: UpdateInteractionTypeSchema }
  > = props => {
    const { appId, interactionTypeId, data } = props ?? {};

    return updateInteractionTypeRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeIdPut(
      appId,
      interactionTypeId,
      data
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateInteractionTypeRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeIdPutMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof updateInteractionTypeRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeIdPut>>
  >;
export type UpdateInteractionTypeRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeIdPutMutationBody =
  UpdateInteractionTypeSchema;
export type UpdateInteractionTypeRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeIdPutMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Update Interaction Type Route
 */
export const useUpdateInteractionTypeRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeIdPut = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateInteractionTypeRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeIdPut>>,
    TError,
    { appId: number; interactionTypeId: number; data: UpdateInteractionTypeSchema },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateInteractionTypeRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeIdPut>>,
  TError,
  { appId: number; interactionTypeId: number; data: UpdateInteractionTypeSchema },
  TContext
> => {
  const mutationOptions =
    getUpdateInteractionTypeRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeIdPutMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete Interaction Type Route
 */
export const deleteInteractionTypeRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeIdDelete = (
  appId: number,
  interactionTypeId: number
) => {
  return customInstance<DeepchecksLlmEvalApiV1SchemasRecordIdentifierSchema>({
    url: `/api/v1/applications/${appId}/interaction-types/${interactionTypeId}`,
    method: 'DELETE'
  });
};

export const getDeleteInteractionTypeRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeIdDeleteMutationOptions =
  <TError = ErrorType<HTTPValidationError>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof deleteInteractionTypeRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeIdDelete>
      >,
      TError,
      { appId: number; interactionTypeId: number },
      TContext
    >;
  }): UseMutationOptions<
    Awaited<ReturnType<typeof deleteInteractionTypeRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeIdDelete>>,
    TError,
    { appId: number; interactionTypeId: number },
    TContext
  > => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof deleteInteractionTypeRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeIdDelete>
      >,
      { appId: number; interactionTypeId: number }
    > = props => {
      const { appId, interactionTypeId } = props ?? {};

      return deleteInteractionTypeRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeIdDelete(
        appId,
        interactionTypeId
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type DeleteInteractionTypeRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeIdDeleteMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof deleteInteractionTypeRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeIdDelete>>
  >;

export type DeleteInteractionTypeRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeIdDeleteMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Delete Interaction Type Route
 */
export const useDeleteInteractionTypeRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeIdDelete = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteInteractionTypeRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeIdDelete>>,
    TError,
    { appId: number; interactionTypeId: number },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteInteractionTypeRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeIdDelete>>,
  TError,
  { appId: number; interactionTypeId: number },
  TContext
> => {
  const mutationOptions =
    getDeleteInteractionTypeRouteApiV1ApplicationsAppIdInteractionTypesInteractionTypeIdDeleteMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Create application version
 * @summary Create Application Version
 */
export const createAppVersion = (createApplicationVersionSchema: CreateApplicationVersionSchema) => {
  return customInstance<DeepchecksLlmEvalApiV1SchemasRecordIdentifierSchema>({
    url: `/api/v1/application-versions`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createApplicationVersionSchema
  });
};

export const getCreateAppVersionMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createAppVersion>>,
    TError,
    { data: CreateApplicationVersionSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createAppVersion>>,
  TError,
  { data: CreateApplicationVersionSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createAppVersion>>,
    { data: CreateApplicationVersionSchema }
  > = props => {
    const { data } = props ?? {};

    return createAppVersion(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateAppVersionMutationResult = NonNullable<Awaited<ReturnType<typeof createAppVersion>>>;
export type CreateAppVersionMutationBody = CreateApplicationVersionSchema;
export type CreateAppVersionMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Create Application Version
 */
export const useCreateAppVersion = <TError = ErrorType<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createAppVersion>>,
    TError,
    { data: CreateApplicationVersionSchema },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createAppVersion>>,
  TError,
  { data: CreateApplicationVersionSchema },
  TContext
> => {
  const mutationOptions = getCreateAppVersionMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Update application
 * @summary Update Application Version
 */
export const updateAppVersion = (updateApplicationVersionSchema: UpdateApplicationVersionSchema) => {
  return customInstance<unknown>({
    url: `/api/v1/application-versions`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: updateApplicationVersionSchema
  });
};

export const getUpdateAppVersionMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAppVersion>>,
    TError,
    { data: UpdateApplicationVersionSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateAppVersion>>,
  TError,
  { data: UpdateApplicationVersionSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateAppVersion>>,
    { data: UpdateApplicationVersionSchema }
  > = props => {
    const { data } = props ?? {};

    return updateAppVersion(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateAppVersionMutationResult = NonNullable<Awaited<ReturnType<typeof updateAppVersion>>>;
export type UpdateAppVersionMutationBody = UpdateApplicationVersionSchema;
export type UpdateAppVersionMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Update Application Version
 */
export const useUpdateAppVersion = <TError = ErrorType<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAppVersion>>,
    TError,
    { data: UpdateApplicationVersionSchema },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateAppVersion>>,
  TError,
  { data: UpdateApplicationVersionSchema },
  TContext
> => {
  const mutationOptions = getUpdateAppVersionMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * List application versions
 * @summary List Application Versions
 */
export const listAppVersions = (params?: ListAppVersionsParams, signal?: AbortSignal) => {
  return customInstance<ApplicationVersionSchema[]>({
    url: `/api/v1/application-versions`,
    method: 'GET',
    params,
    signal
  });
};

export const getListAppVersionsQueryKey = (params?: ListAppVersionsParams) => {
  return [`/api/v1/application-versions`, ...(params ? [params] : [])] as const;
};

export const getListAppVersionsQueryOptions = <
  TData = Awaited<ReturnType<typeof listAppVersions>>,
  TError = ErrorType<HTTPValidationError>
>(
  params?: ListAppVersionsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof listAppVersions>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListAppVersionsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listAppVersions>>> = ({ signal }) =>
    listAppVersions(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listAppVersions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListAppVersionsQueryResult = NonNullable<Awaited<ReturnType<typeof listAppVersions>>>;
export type ListAppVersionsQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary List Application Versions
 */

export function useListAppVersions<
  TData = Awaited<ReturnType<typeof listAppVersions>>,
  TError = ErrorType<HTTPValidationError>
>(
  params?: ListAppVersionsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof listAppVersions>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getListAppVersionsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Delete an application version
 * @summary Delete Application Version
 */
export const deleteAppVersion = (params?: DeleteAppVersionParams) => {
  return customInstance<RecordIdentifiersPairSchema>({ url: `/api/v1/application-versions`, method: 'DELETE', params });
};

export const getDeleteAppVersionMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAppVersion>>,
    TError,
    { params?: DeleteAppVersionParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteAppVersion>>,
  TError,
  { params?: DeleteAppVersionParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteAppVersion>>,
    { params?: DeleteAppVersionParams }
  > = props => {
    const { params } = props ?? {};

    return deleteAppVersion(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteAppVersionMutationResult = NonNullable<Awaited<ReturnType<typeof deleteAppVersion>>>;

export type DeleteAppVersionMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Delete Application Version
 */
export const useDeleteAppVersion = <TError = ErrorType<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAppVersion>>,
    TError,
    { params?: DeleteAppVersionParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteAppVersion>>,
  TError,
  { params?: DeleteAppVersionParams },
  TContext
> => {
  const mutationOptions = getDeleteAppVersionMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * List extended versions information, limited to the latest 200 versions
 * @summary List Application Versions Extended
 */
export const listAppVersionsExtended = (params: ListAppVersionsExtendedParams, signal?: AbortSignal) => {
  return customInstance<ApplicationVersionExtendedSchema[]>({
    url: `/api/v1/application-versions-extended`,
    method: 'GET',
    params,
    signal
  });
};

export const getListAppVersionsExtendedQueryKey = (params: ListAppVersionsExtendedParams) => {
  return [`/api/v1/application-versions-extended`, ...(params ? [params] : [])] as const;
};

export const getListAppVersionsExtendedQueryOptions = <
  TData = Awaited<ReturnType<typeof listAppVersionsExtended>>,
  TError = ErrorType<HTTPValidationError>
>(
  params: ListAppVersionsExtendedParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof listAppVersionsExtended>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListAppVersionsExtendedQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listAppVersionsExtended>>> = ({ signal }) =>
    listAppVersionsExtended(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listAppVersionsExtended>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListAppVersionsExtendedQueryResult = NonNullable<Awaited<ReturnType<typeof listAppVersionsExtended>>>;
export type ListAppVersionsExtendedQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary List Application Versions Extended
 */

export function useListAppVersionsExtended<
  TData = Awaited<ReturnType<typeof listAppVersionsExtended>>,
  TError = ErrorType<HTTPValidationError>
>(
  params: ListAppVersionsExtendedParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof listAppVersionsExtended>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getListAppVersionsExtendedQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Retrieve a summary about the production data inside a specific version
 * @summary Get Application Version Summary
 */
export const getAppVersionProdSummary = (
  appVersionId: number,
  params?: GetAppVersionProdSummaryParams,
  signal?: AbortSignal
) => {
  return customInstance<ProductionDataSummarySchema>({
    url: `/api/v1/application-versions/${appVersionId}/data-summary`,
    method: 'GET',
    params,
    signal
  });
};

export const getGetAppVersionProdSummaryQueryKey = (appVersionId: number, params?: GetAppVersionProdSummaryParams) => {
  return [`/api/v1/application-versions/${appVersionId}/data-summary`, ...(params ? [params] : [])] as const;
};

export const getGetAppVersionProdSummaryQueryOptions = <
  TData = Awaited<ReturnType<typeof getAppVersionProdSummary>>,
  TError = ErrorType<HTTPValidationError>
>(
  appVersionId: number,
  params?: GetAppVersionProdSummaryParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getAppVersionProdSummary>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAppVersionProdSummaryQueryKey(appVersionId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAppVersionProdSummary>>> = ({ signal }) =>
    getAppVersionProdSummary(appVersionId, params, signal);

  return { queryKey, queryFn, enabled: !!appVersionId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAppVersionProdSummary>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAppVersionProdSummaryQueryResult = NonNullable<Awaited<ReturnType<typeof getAppVersionProdSummary>>>;
export type GetAppVersionProdSummaryQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Application Version Summary
 */

export function useGetAppVersionProdSummary<
  TData = Awaited<ReturnType<typeof getAppVersionProdSummary>>,
  TError = ErrorType<HTTPValidationError>
>(
  appVersionId: number,
  params?: GetAppVersionProdSummaryParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getAppVersionProdSummary>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetAppVersionProdSummaryQueryOptions(appVersionId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * List properties definitions - system + custom
 * @summary List Properties Definitions
 */
export const listPropertiesDefinitions = (
  appId: number,
  params?: ListPropertiesDefinitionsParams,
  signal?: AbortSignal
) => {
  return customInstance<PropertyDefinitionSchema[]>({
    url: `/api/v1/applications/${appId}/properties-definitions`,
    method: 'GET',
    params,
    signal
  });
};

export const getListPropertiesDefinitionsQueryKey = (appId: number, params?: ListPropertiesDefinitionsParams) => {
  return [`/api/v1/applications/${appId}/properties-definitions`, ...(params ? [params] : [])] as const;
};

export const getListPropertiesDefinitionsQueryOptions = <
  TData = Awaited<ReturnType<typeof listPropertiesDefinitions>>,
  TError = ErrorType<HTTPValidationError>
>(
  appId: number,
  params?: ListPropertiesDefinitionsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof listPropertiesDefinitions>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListPropertiesDefinitionsQueryKey(appId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listPropertiesDefinitions>>> = ({ signal }) =>
    listPropertiesDefinitions(appId, params, signal);

  return { queryKey, queryFn, enabled: !!appId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listPropertiesDefinitions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListPropertiesDefinitionsQueryResult = NonNullable<Awaited<ReturnType<typeof listPropertiesDefinitions>>>;
export type ListPropertiesDefinitionsQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary List Properties Definitions
 */

export function useListPropertiesDefinitions<
  TData = Awaited<ReturnType<typeof listPropertiesDefinitions>>,
  TError = ErrorType<HTTPValidationError>
>(
  appId: number,
  params?: ListPropertiesDefinitionsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof listPropertiesDefinitions>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getListPropertiesDefinitionsQueryOptions(appId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * update property configuration
 * @summary Create Or Update Property
 */
export const createOrUpdatePropertyConfig = (createOrUpdatePropertySchema: CreateOrUpdatePropertySchema) => {
  return customInstance<unknown>({
    url: `/api/v1/properties-config`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: createOrUpdatePropertySchema
  });
};

export const getCreateOrUpdatePropertyConfigMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createOrUpdatePropertyConfig>>,
    TError,
    { data: CreateOrUpdatePropertySchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createOrUpdatePropertyConfig>>,
  TError,
  { data: CreateOrUpdatePropertySchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createOrUpdatePropertyConfig>>,
    { data: CreateOrUpdatePropertySchema }
  > = props => {
    const { data } = props ?? {};

    return createOrUpdatePropertyConfig(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateOrUpdatePropertyConfigMutationResult = NonNullable<
  Awaited<ReturnType<typeof createOrUpdatePropertyConfig>>
>;
export type CreateOrUpdatePropertyConfigMutationBody = CreateOrUpdatePropertySchema;
export type CreateOrUpdatePropertyConfigMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Create Or Update Property
 */
export const useCreateOrUpdatePropertyConfig = <TError = ErrorType<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createOrUpdatePropertyConfig>>,
    TError,
    { data: CreateOrUpdatePropertySchema },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createOrUpdatePropertyConfig>>,
  TError,
  { data: CreateOrUpdatePropertySchema },
  TContext
> => {
  const mutationOptions = getCreateOrUpdatePropertyConfigMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * List properties configurations
 * @summary List Properties Configs
 */
export const listPropertiesConfigs = (params: ListPropertiesConfigsParams, signal?: AbortSignal) => {
  return customInstance<ReadPropertyConfigSchema[]>({
    url: `/api/v1/properties-config`,
    method: 'GET',
    params,
    signal
  });
};

export const getListPropertiesConfigsQueryKey = (params: ListPropertiesConfigsParams) => {
  return [`/api/v1/properties-config`, ...(params ? [params] : [])] as const;
};

export const getListPropertiesConfigsQueryOptions = <
  TData = Awaited<ReturnType<typeof listPropertiesConfigs>>,
  TError = ErrorType<HTTPValidationError>
>(
  params: ListPropertiesConfigsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof listPropertiesConfigs>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListPropertiesConfigsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listPropertiesConfigs>>> = ({ signal }) =>
    listPropertiesConfigs(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listPropertiesConfigs>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListPropertiesConfigsQueryResult = NonNullable<Awaited<ReturnType<typeof listPropertiesConfigs>>>;
export type ListPropertiesConfigsQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary List Properties Configs
 */

export function useListPropertiesConfigs<
  TData = Awaited<ReturnType<typeof listPropertiesConfigs>>,
  TError = ErrorType<HTTPValidationError>
>(
  params: ListPropertiesConfigsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof listPropertiesConfigs>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getListPropertiesConfigsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Properties Configs Reorder
 */
export const propertiesConfigsReorderApiV1PropertiesConfigReorderPut = (
  propertiesConfigsReorderApiV1PropertiesConfigReorderPutBody: number[]
) => {
  return customInstance<unknown>({
    url: `/api/v1/properties-config-reorder`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: propertiesConfigsReorderApiV1PropertiesConfigReorderPutBody
  });
};

export const getPropertiesConfigsReorderApiV1PropertiesConfigReorderPutMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof propertiesConfigsReorderApiV1PropertiesConfigReorderPut>>,
    TError,
    { data: number[] },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof propertiesConfigsReorderApiV1PropertiesConfigReorderPut>>,
  TError,
  { data: number[] },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof propertiesConfigsReorderApiV1PropertiesConfigReorderPut>>,
    { data: number[] }
  > = props => {
    const { data } = props ?? {};

    return propertiesConfigsReorderApiV1PropertiesConfigReorderPut(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PropertiesConfigsReorderApiV1PropertiesConfigReorderPutMutationResult = NonNullable<
  Awaited<ReturnType<typeof propertiesConfigsReorderApiV1PropertiesConfigReorderPut>>
>;
export type PropertiesConfigsReorderApiV1PropertiesConfigReorderPutMutationBody = number[];
export type PropertiesConfigsReorderApiV1PropertiesConfigReorderPutMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Properties Configs Reorder
 */
export const usePropertiesConfigsReorderApiV1PropertiesConfigReorderPut = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof propertiesConfigsReorderApiV1PropertiesConfigReorderPut>>,
    TError,
    { data: number[] },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof propertiesConfigsReorderApiV1PropertiesConfigReorderPut>>,
  TError,
  { data: number[] },
  TContext
> => {
  const mutationOptions = getPropertiesConfigsReorderApiV1PropertiesConfigReorderPutMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Aggregate properties by filter
 * @summary Retrieve Aggregated Properties By Filter
 */
export const retrieveAggregatedPropertiesByFilter = (applicationVersionId: number, filterSchema: FilterSchema) => {
  return customInstance<unknown>({
    url: `/api/v1/application-versions/${applicationVersionId}/aggregated-properties-by-filter`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: filterSchema
  });
};

export const getRetrieveAggregatedPropertiesByFilterMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof retrieveAggregatedPropertiesByFilter>>,
    TError,
    { applicationVersionId: number; data: FilterSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof retrieveAggregatedPropertiesByFilter>>,
  TError,
  { applicationVersionId: number; data: FilterSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof retrieveAggregatedPropertiesByFilter>>,
    { applicationVersionId: number; data: FilterSchema }
  > = props => {
    const { applicationVersionId, data } = props ?? {};

    return retrieveAggregatedPropertiesByFilter(applicationVersionId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type RetrieveAggregatedPropertiesByFilterMutationResult = NonNullable<
  Awaited<ReturnType<typeof retrieveAggregatedPropertiesByFilter>>
>;
export type RetrieveAggregatedPropertiesByFilterMutationBody = FilterSchema;
export type RetrieveAggregatedPropertiesByFilterMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Retrieve Aggregated Properties By Filter
 */
export const useRetrieveAggregatedPropertiesByFilter = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof retrieveAggregatedPropertiesByFilter>>,
    TError,
    { applicationVersionId: number; data: FilterSchema },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof retrieveAggregatedPropertiesByFilter>>,
  TError,
  { applicationVersionId: number; data: FilterSchema },
  TContext
> => {
  const mutationOptions = getRetrieveAggregatedPropertiesByFilterMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * List property scores per versions by the property config
 * @summary List Properties Data
 */
export const listPropertiesDataApiV1ApplicationVersionsAppVersionIdPropertiesConfigDataGet = (
  appVersionId: number,
  params?: ListPropertiesDataApiV1ApplicationVersionsAppVersionIdPropertiesConfigDataGetParams,
  signal?: AbortSignal
) => {
  return customInstance<ListPropertiesDataApiV1ApplicationVersionsAppVersionIdPropertiesConfigDataGet200>({
    url: `/api/v1/application-versions/${appVersionId}/properties-config/data`,
    method: 'GET',
    params,
    signal
  });
};

export const getListPropertiesDataApiV1ApplicationVersionsAppVersionIdPropertiesConfigDataGetQueryKey = (
  appVersionId: number,
  params?: ListPropertiesDataApiV1ApplicationVersionsAppVersionIdPropertiesConfigDataGetParams
) => {
  return [`/api/v1/application-versions/${appVersionId}/properties-config/data`, ...(params ? [params] : [])] as const;
};

export const getListPropertiesDataApiV1ApplicationVersionsAppVersionIdPropertiesConfigDataGetQueryOptions = <
  TData = Awaited<ReturnType<typeof listPropertiesDataApiV1ApplicationVersionsAppVersionIdPropertiesConfigDataGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  appVersionId: number,
  params?: ListPropertiesDataApiV1ApplicationVersionsAppVersionIdPropertiesConfigDataGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listPropertiesDataApiV1ApplicationVersionsAppVersionIdPropertiesConfigDataGet>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getListPropertiesDataApiV1ApplicationVersionsAppVersionIdPropertiesConfigDataGetQueryKey(appVersionId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listPropertiesDataApiV1ApplicationVersionsAppVersionIdPropertiesConfigDataGet>>
  > = ({ signal }) =>
    listPropertiesDataApiV1ApplicationVersionsAppVersionIdPropertiesConfigDataGet(appVersionId, params, signal);

  return { queryKey, queryFn, enabled: !!appVersionId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listPropertiesDataApiV1ApplicationVersionsAppVersionIdPropertiesConfigDataGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListPropertiesDataApiV1ApplicationVersionsAppVersionIdPropertiesConfigDataGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof listPropertiesDataApiV1ApplicationVersionsAppVersionIdPropertiesConfigDataGet>>
>;
export type ListPropertiesDataApiV1ApplicationVersionsAppVersionIdPropertiesConfigDataGetQueryError =
  ErrorType<HTTPValidationError>;

/**
 * @summary List Properties Data
 */

export function useListPropertiesDataApiV1ApplicationVersionsAppVersionIdPropertiesConfigDataGet<
  TData = Awaited<ReturnType<typeof listPropertiesDataApiV1ApplicationVersionsAppVersionIdPropertiesConfigDataGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  appVersionId: number,
  params?: ListPropertiesDataApiV1ApplicationVersionsAppVersionIdPropertiesConfigDataGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listPropertiesDataApiV1ApplicationVersionsAppVersionIdPropertiesConfigDataGet>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getListPropertiesDataApiV1ApplicationVersionsAppVersionIdPropertiesConfigDataGetQueryOptions(
    appVersionId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * List property scores per versions by the property config (Deprecated, please use '/application-versions/{app_version_id}/properties-config/data' instead)
 * @deprecated
 * @summary List Properties Data
 */
export const listPropertiesDataDeprecated = (
  appVersionId: number,
  params?: ListPropertiesDataDeprecatedParams,
  signal?: AbortSignal
) => {
  return customInstance<ListPropertiesDataDeprecated200>({
    url: `/api/v1/application_versions/${appVersionId}/properties-config/data`,
    method: 'GET',
    params,
    signal
  });
};

export const getListPropertiesDataDeprecatedQueryKey = (
  appVersionId: number,
  params?: ListPropertiesDataDeprecatedParams
) => {
  return [`/api/v1/application_versions/${appVersionId}/properties-config/data`, ...(params ? [params] : [])] as const;
};

export const getListPropertiesDataDeprecatedQueryOptions = <
  TData = Awaited<ReturnType<typeof listPropertiesDataDeprecated>>,
  TError = ErrorType<HTTPValidationError>
>(
  appVersionId: number,
  params?: ListPropertiesDataDeprecatedParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof listPropertiesDataDeprecated>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListPropertiesDataDeprecatedQueryKey(appVersionId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listPropertiesDataDeprecated>>> = ({ signal }) =>
    listPropertiesDataDeprecated(appVersionId, params, signal);

  return { queryKey, queryFn, enabled: !!appVersionId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listPropertiesDataDeprecated>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListPropertiesDataDeprecatedQueryResult = NonNullable<
  Awaited<ReturnType<typeof listPropertiesDataDeprecated>>
>;
export type ListPropertiesDataDeprecatedQueryError = ErrorType<HTTPValidationError>;

/**
 * @deprecated
 * @summary List Properties Data
 */

export function useListPropertiesDataDeprecated<
  TData = Awaited<ReturnType<typeof listPropertiesDataDeprecated>>,
  TError = ErrorType<HTTPValidationError>
>(
  appVersionId: number,
  params?: ListPropertiesDataDeprecatedParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof listPropertiesDataDeprecated>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getListPropertiesDataDeprecatedQueryOptions(appVersionId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Get top property interaction
 * @summary Get Interactions Per Property
 */
export const getInteractionsPerPropertyApiV1InteractionsPerPropertyGet = (
  params: GetInteractionsPerPropertyApiV1InteractionsPerPropertyGetParams,
  signal?: AbortSignal
) => {
  return customInstance<GetInteractionsPerPropertyApiV1InteractionsPerPropertyGet200>({
    url: `/api/v1/interactions-per-property`,
    method: 'GET',
    params,
    signal
  });
};

export const getGetInteractionsPerPropertyApiV1InteractionsPerPropertyGetQueryKey = (
  params: GetInteractionsPerPropertyApiV1InteractionsPerPropertyGetParams
) => {
  return [`/api/v1/interactions-per-property`, ...(params ? [params] : [])] as const;
};

export const getGetInteractionsPerPropertyApiV1InteractionsPerPropertyGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getInteractionsPerPropertyApiV1InteractionsPerPropertyGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  params: GetInteractionsPerPropertyApiV1InteractionsPerPropertyGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getInteractionsPerPropertyApiV1InteractionsPerPropertyGet>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetInteractionsPerPropertyApiV1InteractionsPerPropertyGetQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getInteractionsPerPropertyApiV1InteractionsPerPropertyGet>>
  > = ({ signal }) => getInteractionsPerPropertyApiV1InteractionsPerPropertyGet(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getInteractionsPerPropertyApiV1InteractionsPerPropertyGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetInteractionsPerPropertyApiV1InteractionsPerPropertyGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getInteractionsPerPropertyApiV1InteractionsPerPropertyGet>>
>;
export type GetInteractionsPerPropertyApiV1InteractionsPerPropertyGetQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Interactions Per Property
 */

export function useGetInteractionsPerPropertyApiV1InteractionsPerPropertyGet<
  TData = Awaited<ReturnType<typeof getInteractionsPerPropertyApiV1InteractionsPerPropertyGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  params: GetInteractionsPerPropertyApiV1InteractionsPerPropertyGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getInteractionsPerPropertyApiV1InteractionsPerPropertyGet>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetInteractionsPerPropertyApiV1InteractionsPerPropertyGetQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Recalculate llm properties
 * @summary Recalc Llm Properties
 */
export const recalcLlmPropertiesApiV1ApplicationsRecalcLlmPropertiesPut = (
  bodyRecalcLlmPropertiesApiV1ApplicationsRecalcLlmPropertiesPut: BodyRecalcLlmPropertiesApiV1ApplicationsRecalcLlmPropertiesPut
) => {
  return customInstance<unknown>({
    url: `/api/v1/applications/recalc-llm-properties`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: bodyRecalcLlmPropertiesApiV1ApplicationsRecalcLlmPropertiesPut
  });
};

export const getRecalcLlmPropertiesApiV1ApplicationsRecalcLlmPropertiesPutMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof recalcLlmPropertiesApiV1ApplicationsRecalcLlmPropertiesPut>>,
    TError,
    { data: BodyRecalcLlmPropertiesApiV1ApplicationsRecalcLlmPropertiesPut },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof recalcLlmPropertiesApiV1ApplicationsRecalcLlmPropertiesPut>>,
  TError,
  { data: BodyRecalcLlmPropertiesApiV1ApplicationsRecalcLlmPropertiesPut },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof recalcLlmPropertiesApiV1ApplicationsRecalcLlmPropertiesPut>>,
    { data: BodyRecalcLlmPropertiesApiV1ApplicationsRecalcLlmPropertiesPut }
  > = props => {
    const { data } = props ?? {};

    return recalcLlmPropertiesApiV1ApplicationsRecalcLlmPropertiesPut(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type RecalcLlmPropertiesApiV1ApplicationsRecalcLlmPropertiesPutMutationResult = NonNullable<
  Awaited<ReturnType<typeof recalcLlmPropertiesApiV1ApplicationsRecalcLlmPropertiesPut>>
>;
export type RecalcLlmPropertiesApiV1ApplicationsRecalcLlmPropertiesPutMutationBody =
  BodyRecalcLlmPropertiesApiV1ApplicationsRecalcLlmPropertiesPut;
export type RecalcLlmPropertiesApiV1ApplicationsRecalcLlmPropertiesPutMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Recalc Llm Properties
 */
export const useRecalcLlmPropertiesApiV1ApplicationsRecalcLlmPropertiesPut = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof recalcLlmPropertiesApiV1ApplicationsRecalcLlmPropertiesPut>>,
    TError,
    { data: BodyRecalcLlmPropertiesApiV1ApplicationsRecalcLlmPropertiesPut },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof recalcLlmPropertiesApiV1ApplicationsRecalcLlmPropertiesPut>>,
  TError,
  { data: BodyRecalcLlmPropertiesApiV1ApplicationsRecalcLlmPropertiesPut },
  TContext
> => {
  const mutationOptions = getRecalcLlmPropertiesApiV1ApplicationsRecalcLlmPropertiesPutMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Get histogram for a property
 * @summary Get Property Histogram
 */
export const getPropertyHistogram = (
  applicationVersionId: number,
  environment: EnvType,
  params: GetPropertyHistogramParams,
  signal?: AbortSignal
) => {
  return customInstance<unknown>({
    url: `/api/v1/application-version/${applicationVersionId}/environment/${environment}/histogram`,
    method: 'GET',
    params,
    signal
  });
};

export const getGetPropertyHistogramQueryKey = (
  applicationVersionId: number,
  environment: EnvType,
  params: GetPropertyHistogramParams
) => {
  return [
    `/api/v1/application-version/${applicationVersionId}/environment/${environment}/histogram`,
    ...(params ? [params] : [])
  ] as const;
};

export const getGetPropertyHistogramQueryOptions = <
  TData = Awaited<ReturnType<typeof getPropertyHistogram>>,
  TError = ErrorType<HTTPValidationError>
>(
  applicationVersionId: number,
  environment: EnvType,
  params: GetPropertyHistogramParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getPropertyHistogram>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPropertyHistogramQueryKey(applicationVersionId, environment, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPropertyHistogram>>> = ({ signal }) =>
    getPropertyHistogram(applicationVersionId, environment, params, signal);

  return { queryKey, queryFn, enabled: !!(applicationVersionId && environment), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPropertyHistogram>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPropertyHistogramQueryResult = NonNullable<Awaited<ReturnType<typeof getPropertyHistogram>>>;
export type GetPropertyHistogramQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Property Histogram
 */

export function useGetPropertyHistogram<
  TData = Awaited<ReturnType<typeof getPropertyHistogram>>,
  TError = ErrorType<HTTPValidationError>
>(
  applicationVersionId: number,
  environment: EnvType,
  params: GetPropertyHistogramParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getPropertyHistogram>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetPropertyHistogramQueryOptions(applicationVersionId, environment, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Pentest Prompts
 */
export const getPentestPromptsApiV1PentestPromptsGet = (
  params: GetPentestPromptsApiV1PentestPromptsGetParams,
  signal?: AbortSignal
) => {
  return customInstance<unknown>({ url: `/api/v1/pentest-prompts`, method: 'GET', params, signal });
};

export const getGetPentestPromptsApiV1PentestPromptsGetQueryKey = (
  params: GetPentestPromptsApiV1PentestPromptsGetParams
) => {
  return [`/api/v1/pentest-prompts`, ...(params ? [params] : [])] as const;
};

export const getGetPentestPromptsApiV1PentestPromptsGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getPentestPromptsApiV1PentestPromptsGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  params: GetPentestPromptsApiV1PentestPromptsGetParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPentestPromptsApiV1PentestPromptsGet>>, TError, TData>;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPentestPromptsApiV1PentestPromptsGetQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPentestPromptsApiV1PentestPromptsGet>>> = ({ signal }) =>
    getPentestPromptsApiV1PentestPromptsGet(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPentestPromptsApiV1PentestPromptsGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPentestPromptsApiV1PentestPromptsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPentestPromptsApiV1PentestPromptsGet>>
>;
export type GetPentestPromptsApiV1PentestPromptsGetQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Pentest Prompts
 */

export function useGetPentestPromptsApiV1PentestPromptsGet<
  TData = Awaited<ReturnType<typeof getPentestPromptsApiV1PentestPromptsGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  params: GetPentestPromptsApiV1PentestPromptsGetParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPentestPromptsApiV1PentestPromptsGet>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetPentestPromptsApiV1PentestPromptsGetQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Pentest Prompts Per Category
 */
export const getPentestPromptsPerCategoryApiV1PentestInputsPerCategoryGet = (
  params: GetPentestPromptsPerCategoryApiV1PentestInputsPerCategoryGetParams,
  signal?: AbortSignal
) => {
  return customInstance<PentestPerCategorySchema>({
    url: `/api/v1/pentest-inputs-per-category`,
    method: 'GET',
    params,
    signal
  });
};

export const getGetPentestPromptsPerCategoryApiV1PentestInputsPerCategoryGetQueryKey = (
  params: GetPentestPromptsPerCategoryApiV1PentestInputsPerCategoryGetParams
) => {
  return [`/api/v1/pentest-inputs-per-category`, ...(params ? [params] : [])] as const;
};

export const getGetPentestPromptsPerCategoryApiV1PentestInputsPerCategoryGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getPentestPromptsPerCategoryApiV1PentestInputsPerCategoryGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  params: GetPentestPromptsPerCategoryApiV1PentestInputsPerCategoryGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPentestPromptsPerCategoryApiV1PentestInputsPerCategoryGet>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPentestPromptsPerCategoryApiV1PentestInputsPerCategoryGetQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPentestPromptsPerCategoryApiV1PentestInputsPerCategoryGet>>
  > = ({ signal }) => getPentestPromptsPerCategoryApiV1PentestInputsPerCategoryGet(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPentestPromptsPerCategoryApiV1PentestInputsPerCategoryGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPentestPromptsPerCategoryApiV1PentestInputsPerCategoryGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPentestPromptsPerCategoryApiV1PentestInputsPerCategoryGet>>
>;
export type GetPentestPromptsPerCategoryApiV1PentestInputsPerCategoryGetQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Pentest Prompts Per Category
 */

export function useGetPentestPromptsPerCategoryApiV1PentestInputsPerCategoryGet<
  TData = Awaited<ReturnType<typeof getPentestPromptsPerCategoryApiV1PentestInputsPerCategoryGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  params: GetPentestPromptsPerCategoryApiV1PentestInputsPerCategoryGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPentestPromptsPerCategoryApiV1PentestInputsPerCategoryGet>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetPentestPromptsPerCategoryApiV1PentestInputsPerCategoryGetQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Pentest Categories
 */
export const getPentestCategoriesApiV1PentestCategoriesGet = (signal?: AbortSignal) => {
  return customInstance<string[]>({ url: `/api/v1/pentest-categories`, method: 'GET', signal });
};

export const getGetPentestCategoriesApiV1PentestCategoriesGetQueryKey = () => {
  return [`/api/v1/pentest-categories`] as const;
};

export const getGetPentestCategoriesApiV1PentestCategoriesGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getPentestCategoriesApiV1PentestCategoriesGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getPentestCategoriesApiV1PentestCategoriesGet>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPentestCategoriesApiV1PentestCategoriesGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPentestCategoriesApiV1PentestCategoriesGet>>> = ({
    signal
  }) => getPentestCategoriesApiV1PentestCategoriesGet(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPentestCategoriesApiV1PentestCategoriesGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPentestCategoriesApiV1PentestCategoriesGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPentestCategoriesApiV1PentestCategoriesGet>>
>;
export type GetPentestCategoriesApiV1PentestCategoriesGetQueryError = ErrorType<unknown>;

/**
 * @summary Get Pentest Categories
 */

export function useGetPentestCategoriesApiV1PentestCategoriesGet<
  TData = Awaited<ReturnType<typeof getPentestCategoriesApiV1PentestCategoriesGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getPentestCategoriesApiV1PentestCategoriesGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetPentestCategoriesApiV1PentestCategoriesGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Annotate Step
 */
export const annotateStepApiV1InteractionsStepsStepIdAnnotatePut = (
  stepId: string,
  params: AnnotateStepApiV1InteractionsStepsStepIdAnnotatePutParams
) => {
  return customInstance<unknown>({ url: `/api/v1/interactions/steps/${stepId}/annotate`, method: 'PUT', params });
};

export const getAnnotateStepApiV1InteractionsStepsStepIdAnnotatePutMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof annotateStepApiV1InteractionsStepsStepIdAnnotatePut>>,
    TError,
    { stepId: string; params: AnnotateStepApiV1InteractionsStepsStepIdAnnotatePutParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof annotateStepApiV1InteractionsStepsStepIdAnnotatePut>>,
  TError,
  { stepId: string; params: AnnotateStepApiV1InteractionsStepsStepIdAnnotatePutParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof annotateStepApiV1InteractionsStepsStepIdAnnotatePut>>,
    { stepId: string; params: AnnotateStepApiV1InteractionsStepsStepIdAnnotatePutParams }
  > = props => {
    const { stepId, params } = props ?? {};

    return annotateStepApiV1InteractionsStepsStepIdAnnotatePut(stepId, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type AnnotateStepApiV1InteractionsStepsStepIdAnnotatePutMutationResult = NonNullable<
  Awaited<ReturnType<typeof annotateStepApiV1InteractionsStepsStepIdAnnotatePut>>
>;

export type AnnotateStepApiV1InteractionsStepsStepIdAnnotatePutMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Annotate Step
 */
export const useAnnotateStepApiV1InteractionsStepsStepIdAnnotatePut = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof annotateStepApiV1InteractionsStepsStepIdAnnotatePut>>,
    TError,
    { stepId: string; params: AnnotateStepApiV1InteractionsStepsStepIdAnnotatePutParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof annotateStepApiV1InteractionsStepsStepIdAnnotatePut>>,
  TError,
  { stepId: string; params: AnnotateStepApiV1InteractionsStepsStepIdAnnotatePutParams },
  TContext
> => {
  const mutationOptions = getAnnotateStepApiV1InteractionsStepsStepIdAnnotatePutMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Get interaction steps
 * @summary Get Steps For Interaction
 */
export const getStepsForInteractionApiV1ApplicationVersionsAppVersionIdInteractionsUserInteractionIdStepsGet = (
  appVersionId: number,
  userInteractionId: string,
  params?: GetStepsForInteractionApiV1ApplicationVersionsAppVersionIdInteractionsUserInteractionIdStepsGetParams,
  signal?: AbortSignal
) => {
  return customInstance<StepSchema[]>({
    url: `/api/v1/application-versions/${appVersionId}/interactions/${userInteractionId}/steps`,
    method: 'GET',
    params,
    signal
  });
};

export const getGetStepsForInteractionApiV1ApplicationVersionsAppVersionIdInteractionsUserInteractionIdStepsGetQueryKey =
  (
    appVersionId: number,
    userInteractionId: string,
    params?: GetStepsForInteractionApiV1ApplicationVersionsAppVersionIdInteractionsUserInteractionIdStepsGetParams
  ) => {
    return [
      `/api/v1/application-versions/${appVersionId}/interactions/${userInteractionId}/steps`,
      ...(params ? [params] : [])
    ] as const;
  };

export const getGetStepsForInteractionApiV1ApplicationVersionsAppVersionIdInteractionsUserInteractionIdStepsGetQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof getStepsForInteractionApiV1ApplicationVersionsAppVersionIdInteractionsUserInteractionIdStepsGet>
    >,
    TError = ErrorType<HTTPValidationError>
  >(
    appVersionId: number,
    userInteractionId: string,
    params?: GetStepsForInteractionApiV1ApplicationVersionsAppVersionIdInteractionsUserInteractionIdStepsGetParams,
    options?: {
      query?: UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getStepsForInteractionApiV1ApplicationVersionsAppVersionIdInteractionsUserInteractionIdStepsGet
          >
        >,
        TError,
        TData
      >;
    }
  ) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetStepsForInteractionApiV1ApplicationVersionsAppVersionIdInteractionsUserInteractionIdStepsGetQueryKey(
        appVersionId,
        userInteractionId,
        params
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof getStepsForInteractionApiV1ApplicationVersionsAppVersionIdInteractionsUserInteractionIdStepsGet
        >
      >
    > = ({ signal }) =>
      getStepsForInteractionApiV1ApplicationVersionsAppVersionIdInteractionsUserInteractionIdStepsGet(
        appVersionId,
        userInteractionId,
        params,
        signal
      );

    return { queryKey, queryFn, enabled: !!(appVersionId && userInteractionId), ...queryOptions } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof getStepsForInteractionApiV1ApplicationVersionsAppVersionIdInteractionsUserInteractionIdStepsGet
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetStepsForInteractionApiV1ApplicationVersionsAppVersionIdInteractionsUserInteractionIdStepsGetQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof getStepsForInteractionApiV1ApplicationVersionsAppVersionIdInteractionsUserInteractionIdStepsGet>
    >
  >;
export type GetStepsForInteractionApiV1ApplicationVersionsAppVersionIdInteractionsUserInteractionIdStepsGetQueryError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Get Steps For Interaction
 */

export function useGetStepsForInteractionApiV1ApplicationVersionsAppVersionIdInteractionsUserInteractionIdStepsGet<
  TData = Awaited<
    ReturnType<typeof getStepsForInteractionApiV1ApplicationVersionsAppVersionIdInteractionsUserInteractionIdStepsGet>
  >,
  TError = ErrorType<HTTPValidationError>
>(
  appVersionId: number,
  userInteractionId: string,
  params?: GetStepsForInteractionApiV1ApplicationVersionsAppVersionIdInteractionsUserInteractionIdStepsGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          typeof getStepsForInteractionApiV1ApplicationVersionsAppVersionIdInteractionsUserInteractionIdStepsGet
        >
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetStepsForInteractionApiV1ApplicationVersionsAppVersionIdInteractionsUserInteractionIdStepsGetQueryOptions(
      appVersionId,
      userInteractionId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Get interaction steps (Deprecated, please use '/application-versions/{app_version_id}/interactions/{user_interaction_id}/steps' instead)
 * @deprecated
 * @summary Get Steps For Interaction
 */
export const getStepsForInteractionDeprecated = (
  appVersionId: number,
  userInteractionId: string,
  params?: GetStepsForInteractionDeprecatedParams,
  signal?: AbortSignal
) => {
  return customInstance<StepSchema[]>({
    url: `/api/v1/application_versions/${appVersionId}/interactions/${userInteractionId}/steps`,
    method: 'GET',
    params,
    signal
  });
};

export const getGetStepsForInteractionDeprecatedQueryKey = (
  appVersionId: number,
  userInteractionId: string,
  params?: GetStepsForInteractionDeprecatedParams
) => {
  return [
    `/api/v1/application_versions/${appVersionId}/interactions/${userInteractionId}/steps`,
    ...(params ? [params] : [])
  ] as const;
};

export const getGetStepsForInteractionDeprecatedQueryOptions = <
  TData = Awaited<ReturnType<typeof getStepsForInteractionDeprecated>>,
  TError = ErrorType<HTTPValidationError>
>(
  appVersionId: number,
  userInteractionId: string,
  params?: GetStepsForInteractionDeprecatedParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getStepsForInteractionDeprecated>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetStepsForInteractionDeprecatedQueryKey(appVersionId, userInteractionId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getStepsForInteractionDeprecated>>> = ({ signal }) =>
    getStepsForInteractionDeprecated(appVersionId, userInteractionId, params, signal);

  return { queryKey, queryFn, enabled: !!(appVersionId && userInteractionId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getStepsForInteractionDeprecated>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetStepsForInteractionDeprecatedQueryResult = NonNullable<
  Awaited<ReturnType<typeof getStepsForInteractionDeprecated>>
>;
export type GetStepsForInteractionDeprecatedQueryError = ErrorType<HTTPValidationError>;

/**
 * @deprecated
 * @summary Get Steps For Interaction
 */

export function useGetStepsForInteractionDeprecated<
  TData = Awaited<ReturnType<typeof getStepsForInteractionDeprecated>>,
  TError = ErrorType<HTTPValidationError>
>(
  appVersionId: number,
  userInteractionId: string,
  params?: GetStepsForInteractionDeprecatedParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getStepsForInteractionDeprecated>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetStepsForInteractionDeprecatedQueryOptions(
    appVersionId,
    userInteractionId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Available Step Names
 */
export const getAvailableStepNamesApiV1ApplicationsAppIdAvailableStepNamesGet = (
  appId: number,
  signal?: AbortSignal
) => {
  return customInstance<string[]>({ url: `/api/v1/applications/${appId}/available-step-names`, method: 'GET', signal });
};

export const getGetAvailableStepNamesApiV1ApplicationsAppIdAvailableStepNamesGetQueryKey = (appId: number) => {
  return [`/api/v1/applications/${appId}/available-step-names`] as const;
};

export const getGetAvailableStepNamesApiV1ApplicationsAppIdAvailableStepNamesGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getAvailableStepNamesApiV1ApplicationsAppIdAvailableStepNamesGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  appId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAvailableStepNamesApiV1ApplicationsAppIdAvailableStepNamesGet>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetAvailableStepNamesApiV1ApplicationsAppIdAvailableStepNamesGetQueryKey(appId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAvailableStepNamesApiV1ApplicationsAppIdAvailableStepNamesGet>>
  > = ({ signal }) => getAvailableStepNamesApiV1ApplicationsAppIdAvailableStepNamesGet(appId, signal);

  return { queryKey, queryFn, enabled: !!appId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAvailableStepNamesApiV1ApplicationsAppIdAvailableStepNamesGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAvailableStepNamesApiV1ApplicationsAppIdAvailableStepNamesGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAvailableStepNamesApiV1ApplicationsAppIdAvailableStepNamesGet>>
>;
export type GetAvailableStepNamesApiV1ApplicationsAppIdAvailableStepNamesGetQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Available Step Names
 */

export function useGetAvailableStepNamesApiV1ApplicationsAppIdAvailableStepNamesGet<
  TData = Awaited<ReturnType<typeof getAvailableStepNamesApiV1ApplicationsAppIdAvailableStepNamesGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  appId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAvailableStepNamesApiV1ApplicationsAppIdAvailableStepNamesGet>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetAvailableStepNamesApiV1ApplicationsAppIdAvailableStepNamesGetQueryOptions(appId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Return number of interactions per annotation according to filters
 * @summary Retrieve Annotation Statistics By Filter
 */
export const retrieveAnnotationStatsByFilter = (applicationVersionId: number, filterSchema: FilterSchema) => {
  return customInstance<RetrieveAnnotationStatsByFilter200>({
    url: `/api/v1/application-versions/${applicationVersionId}/annotation-stats-by-filter`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: filterSchema
  });
};

export const getRetrieveAnnotationStatsByFilterMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof retrieveAnnotationStatsByFilter>>,
    TError,
    { applicationVersionId: number; data: FilterSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof retrieveAnnotationStatsByFilter>>,
  TError,
  { applicationVersionId: number; data: FilterSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof retrieveAnnotationStatsByFilter>>,
    { applicationVersionId: number; data: FilterSchema }
  > = props => {
    const { applicationVersionId, data } = props ?? {};

    return retrieveAnnotationStatsByFilter(applicationVersionId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type RetrieveAnnotationStatsByFilterMutationResult = NonNullable<
  Awaited<ReturnType<typeof retrieveAnnotationStatsByFilter>>
>;
export type RetrieveAnnotationStatsByFilterMutationBody = FilterSchema;
export type RetrieveAnnotationStatsByFilterMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Retrieve Annotation Statistics By Filter
 */
export const useRetrieveAnnotationStatsByFilter = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof retrieveAnnotationStatsByFilter>>,
    TError,
    { applicationVersionId: number; data: FilterSchema },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof retrieveAnnotationStatsByFilter>>,
  TError,
  { applicationVersionId: number; data: FilterSchema },
  TContext
> => {
  const mutationOptions = getRetrieveAnnotationStatsByFilterMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Download interactions
 * @summary Download Interactions
 */
export const downloadInteractions = (
  bodyDownloadInteractions: BodyDownloadInteractions,
  params?: DownloadInteractionsParams
) => {
  return customInstance<unknown>({
    url: `/api/v1/interactions-download`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: bodyDownloadInteractions,
    params
  });
};

export const getDownloadInteractionsMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof downloadInteractions>>,
    TError,
    { data: BodyDownloadInteractions; params?: DownloadInteractionsParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof downloadInteractions>>,
  TError,
  { data: BodyDownloadInteractions; params?: DownloadInteractionsParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof downloadInteractions>>,
    { data: BodyDownloadInteractions; params?: DownloadInteractionsParams }
  > = props => {
    const { data, params } = props ?? {};

    return downloadInteractions(data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type DownloadInteractionsMutationResult = NonNullable<Awaited<ReturnType<typeof downloadInteractions>>>;
export type DownloadInteractionsMutationBody = BodyDownloadInteractions;
export type DownloadInteractionsMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Download Interactions
 */
export const useDownloadInteractions = <TError = ErrorType<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof downloadInteractions>>,
    TError,
    { data: BodyDownloadInteractions; params?: DownloadInteractionsParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof downloadInteractions>>,
  TError,
  { data: BodyDownloadInteractions; params?: DownloadInteractionsParams },
  TContext
> => {
  const mutationOptions = getDownloadInteractionsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * update interactions
 * @summary Update Interaction
 */
export const updateInteractions = (
  appVersionId: number,
  userInteractionId: string,
  updateInteractionSchema: UpdateInteractionSchema
) => {
  return customInstance<string>({
    url: `/api/v1/application-versions/${appVersionId}/interactions/${userInteractionId}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: updateInteractionSchema
  });
};

export const getUpdateInteractionsMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateInteractions>>,
    TError,
    { appVersionId: number; userInteractionId: string; data: UpdateInteractionSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateInteractions>>,
  TError,
  { appVersionId: number; userInteractionId: string; data: UpdateInteractionSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateInteractions>>,
    { appVersionId: number; userInteractionId: string; data: UpdateInteractionSchema }
  > = props => {
    const { appVersionId, userInteractionId, data } = props ?? {};

    return updateInteractions(appVersionId, userInteractionId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateInteractionsMutationResult = NonNullable<Awaited<ReturnType<typeof updateInteractions>>>;
export type UpdateInteractionsMutationBody = UpdateInteractionSchema;
export type UpdateInteractionsMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Update Interaction
 */
export const useUpdateInteractions = <TError = ErrorType<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateInteractions>>,
    TError,
    { appVersionId: number; userInteractionId: string; data: UpdateInteractionSchema },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateInteractions>>,
  TError,
  { appVersionId: number; userInteractionId: string; data: UpdateInteractionSchema },
  TContext
> => {
  const mutationOptions = getUpdateInteractionsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Retrieve interaction
 * @summary Retrieve Interaction
 */
export const retrieveInteraction = (appVersionId: number, userInteractionId: string, signal?: AbortSignal) => {
  return customInstance<InteractionSchema>({
    url: `/api/v1/application-versions/${appVersionId}/interactions/${userInteractionId}`,
    method: 'GET',
    signal
  });
};

export const getRetrieveInteractionQueryKey = (appVersionId: number, userInteractionId: string) => {
  return [`/api/v1/application-versions/${appVersionId}/interactions/${userInteractionId}`] as const;
};

export const getRetrieveInteractionQueryOptions = <
  TData = Awaited<ReturnType<typeof retrieveInteraction>>,
  TError = ErrorType<HTTPValidationError>
>(
  appVersionId: number,
  userInteractionId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof retrieveInteraction>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRetrieveInteractionQueryKey(appVersionId, userInteractionId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof retrieveInteraction>>> = ({ signal }) =>
    retrieveInteraction(appVersionId, userInteractionId, signal);

  return { queryKey, queryFn, enabled: !!(appVersionId && userInteractionId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof retrieveInteraction>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RetrieveInteractionQueryResult = NonNullable<Awaited<ReturnType<typeof retrieveInteraction>>>;
export type RetrieveInteractionQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Retrieve Interaction
 */

export function useRetrieveInteraction<
  TData = Awaited<ReturnType<typeof retrieveInteraction>>,
  TError = ErrorType<HTTPValidationError>
>(
  appVersionId: number,
  userInteractionId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof retrieveInteraction>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getRetrieveInteractionQueryOptions(appVersionId, userInteractionId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * update interactions (Deprecated, please use '/application-versions/{app_version_id}/interactions/{user_interaction_id}' instead)
 * @deprecated
 * @summary Update Interaction
 */
export const updateInteractionsDeprecated = (
  appVersionId: number,
  userInteractionId: string,
  updateInteractionSchema: UpdateInteractionSchema
) => {
  return customInstance<string>({
    url: `/api/v1/application_versions/${appVersionId}/interactions/${userInteractionId}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: updateInteractionSchema
  });
};

export const getUpdateInteractionsDeprecatedMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateInteractionsDeprecated>>,
    TError,
    { appVersionId: number; userInteractionId: string; data: UpdateInteractionSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateInteractionsDeprecated>>,
  TError,
  { appVersionId: number; userInteractionId: string; data: UpdateInteractionSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateInteractionsDeprecated>>,
    { appVersionId: number; userInteractionId: string; data: UpdateInteractionSchema }
  > = props => {
    const { appVersionId, userInteractionId, data } = props ?? {};

    return updateInteractionsDeprecated(appVersionId, userInteractionId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateInteractionsDeprecatedMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateInteractionsDeprecated>>
>;
export type UpdateInteractionsDeprecatedMutationBody = UpdateInteractionSchema;
export type UpdateInteractionsDeprecatedMutationError = ErrorType<HTTPValidationError>;

/**
 * @deprecated
 * @summary Update Interaction
 */
export const useUpdateInteractionsDeprecated = <TError = ErrorType<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateInteractionsDeprecated>>,
    TError,
    { appVersionId: number; userInteractionId: string; data: UpdateInteractionSchema },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateInteractionsDeprecated>>,
  TError,
  { appVersionId: number; userInteractionId: string; data: UpdateInteractionSchema },
  TContext
> => {
  const mutationOptions = getUpdateInteractionsDeprecatedMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Retrieve interaction (Deprecated, please use '/application-versions/{app_version_id}/interactions/{user_interaction_id}' instead)
 * @deprecated
 * @summary Retrieve Interaction
 */
export const retrieveInteractionDeprecated = (
  appVersionId: number,
  userInteractionId: string,
  signal?: AbortSignal
) => {
  return customInstance<InteractionSchema>({
    url: `/api/v1/application_versions/${appVersionId}/interactions/${userInteractionId}`,
    method: 'GET',
    signal
  });
};

export const getRetrieveInteractionDeprecatedQueryKey = (appVersionId: number, userInteractionId: string) => {
  return [`/api/v1/application_versions/${appVersionId}/interactions/${userInteractionId}`] as const;
};

export const getRetrieveInteractionDeprecatedQueryOptions = <
  TData = Awaited<ReturnType<typeof retrieveInteractionDeprecated>>,
  TError = ErrorType<HTTPValidationError>
>(
  appVersionId: number,
  userInteractionId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof retrieveInteractionDeprecated>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRetrieveInteractionDeprecatedQueryKey(appVersionId, userInteractionId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof retrieveInteractionDeprecated>>> = ({ signal }) =>
    retrieveInteractionDeprecated(appVersionId, userInteractionId, signal);

  return { queryKey, queryFn, enabled: !!(appVersionId && userInteractionId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof retrieveInteractionDeprecated>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RetrieveInteractionDeprecatedQueryResult = NonNullable<
  Awaited<ReturnType<typeof retrieveInteractionDeprecated>>
>;
export type RetrieveInteractionDeprecatedQueryError = ErrorType<HTTPValidationError>;

/**
 * @deprecated
 * @summary Retrieve Interaction
 */

export function useRetrieveInteractionDeprecated<
  TData = Awaited<ReturnType<typeof retrieveInteractionDeprecated>>,
  TError = ErrorType<HTTPValidationError>
>(
  appVersionId: number,
  userInteractionId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof retrieveInteractionDeprecated>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getRetrieveInteractionDeprecatedQueryOptions(appVersionId, userInteractionId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Update interaction custom property
 * @summary Update Interaction Custom Property
 */
export const updateInteractionCustomProperty = (
  appVersionId: number,
  userInteractionId: string,
  updateInteractionCustomPropertySchema: UpdateInteractionCustomPropertySchema
) => {
  return customInstance<unknown>({
    url: `/api/v1/application-versions/${appVersionId}/interactions/${userInteractionId}/custom-properties`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: updateInteractionCustomPropertySchema
  });
};

export const getUpdateInteractionCustomPropertyMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateInteractionCustomProperty>>,
    TError,
    { appVersionId: number; userInteractionId: string; data: UpdateInteractionCustomPropertySchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateInteractionCustomProperty>>,
  TError,
  { appVersionId: number; userInteractionId: string; data: UpdateInteractionCustomPropertySchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateInteractionCustomProperty>>,
    { appVersionId: number; userInteractionId: string; data: UpdateInteractionCustomPropertySchema }
  > = props => {
    const { appVersionId, userInteractionId, data } = props ?? {};

    return updateInteractionCustomProperty(appVersionId, userInteractionId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateInteractionCustomPropertyMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateInteractionCustomProperty>>
>;
export type UpdateInteractionCustomPropertyMutationBody = UpdateInteractionCustomPropertySchema;
export type UpdateInteractionCustomPropertyMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Update Interaction Custom Property
 */
export const useUpdateInteractionCustomProperty = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateInteractionCustomProperty>>,
    TError,
    { appVersionId: number; userInteractionId: string; data: UpdateInteractionCustomPropertySchema },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateInteractionCustomProperty>>,
  TError,
  { appVersionId: number; userInteractionId: string; data: UpdateInteractionCustomPropertySchema },
  TContext
> => {
  const mutationOptions = getUpdateInteractionCustomPropertyMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Update interaction custom property (Deprecated, please use '/application-versions/{app_version_id}/interactions/{user_interaction_id}/custom-properties' instead)
 * @deprecated
 * @summary Update Interaction Custom Property
 */
export const updateInteractionCustomPropertyDeprecated = (
  appVersionId: number,
  userInteractionId: string,
  updateInteractionCustomPropertySchema: UpdateInteractionCustomPropertySchema
) => {
  return customInstance<unknown>({
    url: `/api/v1/application_versions/${appVersionId}/interactions/${userInteractionId}/custom-properties`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: updateInteractionCustomPropertySchema
  });
};

export const getUpdateInteractionCustomPropertyDeprecatedMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateInteractionCustomPropertyDeprecated>>,
    TError,
    { appVersionId: number; userInteractionId: string; data: UpdateInteractionCustomPropertySchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateInteractionCustomPropertyDeprecated>>,
  TError,
  { appVersionId: number; userInteractionId: string; data: UpdateInteractionCustomPropertySchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateInteractionCustomPropertyDeprecated>>,
    { appVersionId: number; userInteractionId: string; data: UpdateInteractionCustomPropertySchema }
  > = props => {
    const { appVersionId, userInteractionId, data } = props ?? {};

    return updateInteractionCustomPropertyDeprecated(appVersionId, userInteractionId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateInteractionCustomPropertyDeprecatedMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateInteractionCustomPropertyDeprecated>>
>;
export type UpdateInteractionCustomPropertyDeprecatedMutationBody = UpdateInteractionCustomPropertySchema;
export type UpdateInteractionCustomPropertyDeprecatedMutationError = ErrorType<HTTPValidationError>;

/**
 * @deprecated
 * @summary Update Interaction Custom Property
 */
export const useUpdateInteractionCustomPropertyDeprecated = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateInteractionCustomPropertyDeprecated>>,
    TError,
    { appVersionId: number; userInteractionId: string; data: UpdateInteractionCustomPropertySchema },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateInteractionCustomPropertyDeprecated>>,
  TError,
  { appVersionId: number; userInteractionId: string; data: UpdateInteractionCustomPropertySchema },
  TContext
> => {
  const mutationOptions = getUpdateInteractionCustomPropertyDeprecatedMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * List interactions by filter
 * @summary Get Interactions By Filter
 */
export const listInteractionsByFilter = (
  applicationVersionId: number,
  listInteractionsByFilterBody: ListInteractionsByFilterBody,
  params?: ListInteractionsByFilterParams
) => {
  return customInstance<InteractionSchema[]>({
    url: `/api/v1/application-versions/${applicationVersionId}/get-interactions-by-filter`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: listInteractionsByFilterBody,
    params
  });
};

export const getListInteractionsByFilterMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof listInteractionsByFilter>>,
    TError,
    { applicationVersionId: number; data: ListInteractionsByFilterBody; params?: ListInteractionsByFilterParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof listInteractionsByFilter>>,
  TError,
  { applicationVersionId: number; data: ListInteractionsByFilterBody; params?: ListInteractionsByFilterParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof listInteractionsByFilter>>,
    { applicationVersionId: number; data: ListInteractionsByFilterBody; params?: ListInteractionsByFilterParams }
  > = props => {
    const { applicationVersionId, data, params } = props ?? {};

    return listInteractionsByFilter(applicationVersionId, data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type ListInteractionsByFilterMutationResult = NonNullable<Awaited<ReturnType<typeof listInteractionsByFilter>>>;
export type ListInteractionsByFilterMutationBody = ListInteractionsByFilterBody;
export type ListInteractionsByFilterMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Interactions By Filter
 */
export const useListInteractionsByFilter = <TError = ErrorType<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof listInteractionsByFilter>>,
    TError,
    { applicationVersionId: number; data: ListInteractionsByFilterBody; params?: ListInteractionsByFilterParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof listInteractionsByFilter>>,
  TError,
  { applicationVersionId: number; data: ListInteractionsByFilterBody; params?: ListInteractionsByFilterParams },
  TContext
> => {
  const mutationOptions = getListInteractionsByFilterMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * List annotations origin count by filter
 * @summary Get Annotation Origin Count By Filter
 */
export const getAnnotationOriginCountByFilterApiV1ApplicationVersionsApplicationVersionIdGetAnnotationsOriginCountByFilterPost =
  (applicationVersionId: number, filterSchema: FilterSchema) => {
    return customInstance<AnnotationsOriginCount[]>({
      url: `/api/v1/application-versions/${applicationVersionId}/get-annotations-origin-count-by-filter`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: filterSchema
    });
  };

export const getGetAnnotationOriginCountByFilterApiV1ApplicationVersionsApplicationVersionIdGetAnnotationsOriginCountByFilterPostMutationOptions =
  <TError = ErrorType<HTTPValidationError>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof getAnnotationOriginCountByFilterApiV1ApplicationVersionsApplicationVersionIdGetAnnotationsOriginCountByFilterPost
        >
      >,
      TError,
      { applicationVersionId: number; data: FilterSchema },
      TContext
    >;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof getAnnotationOriginCountByFilterApiV1ApplicationVersionsApplicationVersionIdGetAnnotationsOriginCountByFilterPost
      >
    >,
    TError,
    { applicationVersionId: number; data: FilterSchema },
    TContext
  > => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof getAnnotationOriginCountByFilterApiV1ApplicationVersionsApplicationVersionIdGetAnnotationsOriginCountByFilterPost
        >
      >,
      { applicationVersionId: number; data: FilterSchema }
    > = props => {
      const { applicationVersionId, data } = props ?? {};

      return getAnnotationOriginCountByFilterApiV1ApplicationVersionsApplicationVersionIdGetAnnotationsOriginCountByFilterPost(
        applicationVersionId,
        data
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type GetAnnotationOriginCountByFilterApiV1ApplicationVersionsApplicationVersionIdGetAnnotationsOriginCountByFilterPostMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getAnnotationOriginCountByFilterApiV1ApplicationVersionsApplicationVersionIdGetAnnotationsOriginCountByFilterPost
      >
    >
  >;
export type GetAnnotationOriginCountByFilterApiV1ApplicationVersionsApplicationVersionIdGetAnnotationsOriginCountByFilterPostMutationBody =
  FilterSchema;
export type GetAnnotationOriginCountByFilterApiV1ApplicationVersionsApplicationVersionIdGetAnnotationsOriginCountByFilterPostMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Get Annotation Origin Count By Filter
 */
export const useGetAnnotationOriginCountByFilterApiV1ApplicationVersionsApplicationVersionIdGetAnnotationsOriginCountByFilterPost =
  <TError = ErrorType<HTTPValidationError>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof getAnnotationOriginCountByFilterApiV1ApplicationVersionsApplicationVersionIdGetAnnotationsOriginCountByFilterPost
        >
      >,
      TError,
      { applicationVersionId: number; data: FilterSchema },
      TContext
    >;
  }): UseMutationResult<
    Awaited<
      ReturnType<
        typeof getAnnotationOriginCountByFilterApiV1ApplicationVersionsApplicationVersionIdGetAnnotationsOriginCountByFilterPost
      >
    >,
    TError,
    { applicationVersionId: number; data: FilterSchema },
    TContext
  > => {
    const mutationOptions =
      getGetAnnotationOriginCountByFilterApiV1ApplicationVersionsApplicationVersionIdGetAnnotationsOriginCountByFilterPostMutationOptions(
        options
      );

    return useMutation(mutationOptions);
  };

/**
 * List interactions download by filter
 * @summary Download All Interactions By Filter
 */
export const listInteractionsDownloadByFilter = (
  applicationVersionId: number,
  listInteractionsDownloadByFilterBody: ListInteractionsDownloadByFilterBody,
  params?: ListInteractionsDownloadByFilterParams
) => {
  return customInstance<unknown>({
    url: `/api/v1/application-versions/${applicationVersionId}/interactions-download-all-by-filter`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: listInteractionsDownloadByFilterBody,
    params
  });
};

export const getListInteractionsDownloadByFilterMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof listInteractionsDownloadByFilter>>,
    TError,
    {
      applicationVersionId: number;
      data: ListInteractionsDownloadByFilterBody;
      params?: ListInteractionsDownloadByFilterParams;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof listInteractionsDownloadByFilter>>,
  TError,
  {
    applicationVersionId: number;
    data: ListInteractionsDownloadByFilterBody;
    params?: ListInteractionsDownloadByFilterParams;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof listInteractionsDownloadByFilter>>,
    {
      applicationVersionId: number;
      data: ListInteractionsDownloadByFilterBody;
      params?: ListInteractionsDownloadByFilterParams;
    }
  > = props => {
    const { applicationVersionId, data, params } = props ?? {};

    return listInteractionsDownloadByFilter(applicationVersionId, data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type ListInteractionsDownloadByFilterMutationResult = NonNullable<
  Awaited<ReturnType<typeof listInteractionsDownloadByFilter>>
>;
export type ListInteractionsDownloadByFilterMutationBody = ListInteractionsDownloadByFilterBody;
export type ListInteractionsDownloadByFilterMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Download All Interactions By Filter
 */
export const useListInteractionsDownloadByFilter = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof listInteractionsDownloadByFilter>>,
    TError,
    {
      applicationVersionId: number;
      data: ListInteractionsDownloadByFilterBody;
      params?: ListInteractionsDownloadByFilterParams;
    },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof listInteractionsDownloadByFilter>>,
  TError,
  {
    applicationVersionId: number;
    data: ListInteractionsDownloadByFilterBody;
    params?: ListInteractionsDownloadByFilterParams;
  },
  TContext
> => {
  const mutationOptions = getListInteractionsDownloadByFilterMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Copy Interaction To Eval Set
 */
export const copyInteractionToEvalSetApiV1CopyInteractionToEvalSetPost = (
  bodyCopyInteractionToEvalSetApiV1CopyInteractionToEvalSetPost: BodyCopyInteractionToEvalSetApiV1CopyInteractionToEvalSetPost
) => {
  return customInstance<string[]>({
    url: `/api/v1/copy-interaction-to-eval-set`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: bodyCopyInteractionToEvalSetApiV1CopyInteractionToEvalSetPost
  });
};

export const getCopyInteractionToEvalSetApiV1CopyInteractionToEvalSetPostMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof copyInteractionToEvalSetApiV1CopyInteractionToEvalSetPost>>,
    TError,
    { data: BodyCopyInteractionToEvalSetApiV1CopyInteractionToEvalSetPost },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof copyInteractionToEvalSetApiV1CopyInteractionToEvalSetPost>>,
  TError,
  { data: BodyCopyInteractionToEvalSetApiV1CopyInteractionToEvalSetPost },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof copyInteractionToEvalSetApiV1CopyInteractionToEvalSetPost>>,
    { data: BodyCopyInteractionToEvalSetApiV1CopyInteractionToEvalSetPost }
  > = props => {
    const { data } = props ?? {};

    return copyInteractionToEvalSetApiV1CopyInteractionToEvalSetPost(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CopyInteractionToEvalSetApiV1CopyInteractionToEvalSetPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof copyInteractionToEvalSetApiV1CopyInteractionToEvalSetPost>>
>;
export type CopyInteractionToEvalSetApiV1CopyInteractionToEvalSetPostMutationBody =
  BodyCopyInteractionToEvalSetApiV1CopyInteractionToEvalSetPost;
export type CopyInteractionToEvalSetApiV1CopyInteractionToEvalSetPostMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Copy Interaction To Eval Set
 */
export const useCopyInteractionToEvalSetApiV1CopyInteractionToEvalSetPost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof copyInteractionToEvalSetApiV1CopyInteractionToEvalSetPost>>,
    TError,
    { data: BodyCopyInteractionToEvalSetApiV1CopyInteractionToEvalSetPost },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof copyInteractionToEvalSetApiV1CopyInteractionToEvalSetPost>>,
  TError,
  { data: BodyCopyInteractionToEvalSetApiV1CopyInteractionToEvalSetPost },
  TContext
> => {
  const mutationOptions = getCopyInteractionToEvalSetApiV1CopyInteractionToEvalSetPostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete Interactions
 */
export const deleteInteractionsApiV1ApplicationVersionsApplicationVersionIdInteractionsDelete = (
  applicationVersionId: number,
  deleteInteractionsApiV1ApplicationVersionsApplicationVersionIdInteractionsDeleteBody: DeleteInteractionsApiV1ApplicationVersionsApplicationVersionIdInteractionsDeleteBody
) => {
  return customInstance<unknown>({
    url: `/api/v1/application-versions/${applicationVersionId}/interactions`,
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    data: deleteInteractionsApiV1ApplicationVersionsApplicationVersionIdInteractionsDeleteBody
  });
};

export const getDeleteInteractionsApiV1ApplicationVersionsApplicationVersionIdInteractionsDeleteMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteInteractionsApiV1ApplicationVersionsApplicationVersionIdInteractionsDelete>>,
    TError,
    {
      applicationVersionId: number;
      data: DeleteInteractionsApiV1ApplicationVersionsApplicationVersionIdInteractionsDeleteBody;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteInteractionsApiV1ApplicationVersionsApplicationVersionIdInteractionsDelete>>,
  TError,
  {
    applicationVersionId: number;
    data: DeleteInteractionsApiV1ApplicationVersionsApplicationVersionIdInteractionsDeleteBody;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteInteractionsApiV1ApplicationVersionsApplicationVersionIdInteractionsDelete>>,
    {
      applicationVersionId: number;
      data: DeleteInteractionsApiV1ApplicationVersionsApplicationVersionIdInteractionsDeleteBody;
    }
  > = props => {
    const { applicationVersionId, data } = props ?? {};

    return deleteInteractionsApiV1ApplicationVersionsApplicationVersionIdInteractionsDelete(applicationVersionId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteInteractionsApiV1ApplicationVersionsApplicationVersionIdInteractionsDeleteMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof deleteInteractionsApiV1ApplicationVersionsApplicationVersionIdInteractionsDelete>>
  >;
export type DeleteInteractionsApiV1ApplicationVersionsApplicationVersionIdInteractionsDeleteMutationBody =
  DeleteInteractionsApiV1ApplicationVersionsApplicationVersionIdInteractionsDeleteBody;
export type DeleteInteractionsApiV1ApplicationVersionsApplicationVersionIdInteractionsDeleteMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Delete Interactions
 */
export const useDeleteInteractionsApiV1ApplicationVersionsApplicationVersionIdInteractionsDelete = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteInteractionsApiV1ApplicationVersionsApplicationVersionIdInteractionsDelete>>,
    TError,
    {
      applicationVersionId: number;
      data: DeleteInteractionsApiV1ApplicationVersionsApplicationVersionIdInteractionsDeleteBody;
    },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteInteractionsApiV1ApplicationVersionsApplicationVersionIdInteractionsDelete>>,
  TError,
  {
    applicationVersionId: number;
    data: DeleteInteractionsApiV1ApplicationVersionsApplicationVersionIdInteractionsDeleteBody;
  },
  TContext
> => {
  const mutationOptions =
    getDeleteInteractionsApiV1ApplicationVersionsApplicationVersionIdInteractionsDeleteMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Get similarity score
 * @summary Get Similarity Score
 */
export const getSimilarityScoreApiV1ApplicationVersionsGetSimilarityScoreGet = (
  params: GetSimilarityScoreApiV1ApplicationVersionsGetSimilarityScoreGetParams,
  signal?: AbortSignal
) => {
  return customInstance<GetSimilarityScoreApiV1ApplicationVersionsGetSimilarityScoreGet200>({
    url: `/api/v1/application-versions/get-similarity-score`,
    method: 'GET',
    params,
    signal
  });
};

export const getGetSimilarityScoreApiV1ApplicationVersionsGetSimilarityScoreGetQueryKey = (
  params: GetSimilarityScoreApiV1ApplicationVersionsGetSimilarityScoreGetParams
) => {
  return [`/api/v1/application-versions/get-similarity-score`, ...(params ? [params] : [])] as const;
};

export const getGetSimilarityScoreApiV1ApplicationVersionsGetSimilarityScoreGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getSimilarityScoreApiV1ApplicationVersionsGetSimilarityScoreGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  params: GetSimilarityScoreApiV1ApplicationVersionsGetSimilarityScoreGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSimilarityScoreApiV1ApplicationVersionsGetSimilarityScoreGet>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetSimilarityScoreApiV1ApplicationVersionsGetSimilarityScoreGetQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSimilarityScoreApiV1ApplicationVersionsGetSimilarityScoreGet>>
  > = ({ signal }) => getSimilarityScoreApiV1ApplicationVersionsGetSimilarityScoreGet(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSimilarityScoreApiV1ApplicationVersionsGetSimilarityScoreGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetSimilarityScoreApiV1ApplicationVersionsGetSimilarityScoreGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSimilarityScoreApiV1ApplicationVersionsGetSimilarityScoreGet>>
>;
export type GetSimilarityScoreApiV1ApplicationVersionsGetSimilarityScoreGetQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Similarity Score
 */

export function useGetSimilarityScoreApiV1ApplicationVersionsGetSimilarityScoreGet<
  TData = Awaited<ReturnType<typeof getSimilarityScoreApiV1ApplicationVersionsGetSimilarityScoreGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  params: GetSimilarityScoreApiV1ApplicationVersionsGetSimilarityScoreGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSimilarityScoreApiV1ApplicationVersionsGetSimilarityScoreGet>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetSimilarityScoreApiV1ApplicationVersionsGetSimilarityScoreGetQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Get similarity score (Deprecated, please use '/application-versions/get-similarity-score' instead)
 * @deprecated
 * @summary Get Similarity Score
 */
export const getSimilarityScoreDeprecated = (params: GetSimilarityScoreDeprecatedParams, signal?: AbortSignal) => {
  return customInstance<GetSimilarityScoreDeprecated200>({
    url: `/api/v1/application_versions/get-similarity-score`,
    method: 'GET',
    params,
    signal
  });
};

export const getGetSimilarityScoreDeprecatedQueryKey = (params: GetSimilarityScoreDeprecatedParams) => {
  return [`/api/v1/application_versions/get-similarity-score`, ...(params ? [params] : [])] as const;
};

export const getGetSimilarityScoreDeprecatedQueryOptions = <
  TData = Awaited<ReturnType<typeof getSimilarityScoreDeprecated>>,
  TError = ErrorType<HTTPValidationError>
>(
  params: GetSimilarityScoreDeprecatedParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getSimilarityScoreDeprecated>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSimilarityScoreDeprecatedQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSimilarityScoreDeprecated>>> = ({ signal }) =>
    getSimilarityScoreDeprecated(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSimilarityScoreDeprecated>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetSimilarityScoreDeprecatedQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSimilarityScoreDeprecated>>
>;
export type GetSimilarityScoreDeprecatedQueryError = ErrorType<HTTPValidationError>;

/**
 * @deprecated
 * @summary Get Similarity Score
 */

export function useGetSimilarityScoreDeprecated<
  TData = Awaited<ReturnType<typeof getSimilarityScoreDeprecated>>,
  TError = ErrorType<HTTPValidationError>
>(
  params: GetSimilarityScoreDeprecatedParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getSimilarityScoreDeprecated>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetSimilarityScoreDeprecatedQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Interaction Completion Status
 */
export const getInteractionCompletionStatusApiV1ApplicationVersionsApplicationVersionIdInteractionsCompleteStatusPost =
  (
    applicationVersionId: number,
    bodyGetInteractionCompletionStatusApiV1ApplicationVersionsApplicationVersionIdInteractionsCompleteStatusPost: BodyGetInteractionCompletionStatusApiV1ApplicationVersionsApplicationVersionIdInteractionsCompleteStatusPost
  ) => {
    return customInstance<GetInteractionCompletionStatusApiV1ApplicationVersionsApplicationVersionIdInteractionsCompleteStatusPost200>(
      {
        url: `/api/v1/application-versions/${applicationVersionId}/interactions/complete-status`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: bodyGetInteractionCompletionStatusApiV1ApplicationVersionsApplicationVersionIdInteractionsCompleteStatusPost
      }
    );
  };

export const getGetInteractionCompletionStatusApiV1ApplicationVersionsApplicationVersionIdInteractionsCompleteStatusPostMutationOptions =
  <TError = ErrorType<HTTPValidationError>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof getInteractionCompletionStatusApiV1ApplicationVersionsApplicationVersionIdInteractionsCompleteStatusPost
        >
      >,
      TError,
      {
        applicationVersionId: number;
        data: BodyGetInteractionCompletionStatusApiV1ApplicationVersionsApplicationVersionIdInteractionsCompleteStatusPost;
      },
      TContext
    >;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof getInteractionCompletionStatusApiV1ApplicationVersionsApplicationVersionIdInteractionsCompleteStatusPost
      >
    >,
    TError,
    {
      applicationVersionId: number;
      data: BodyGetInteractionCompletionStatusApiV1ApplicationVersionsApplicationVersionIdInteractionsCompleteStatusPost;
    },
    TContext
  > => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof getInteractionCompletionStatusApiV1ApplicationVersionsApplicationVersionIdInteractionsCompleteStatusPost
        >
      >,
      {
        applicationVersionId: number;
        data: BodyGetInteractionCompletionStatusApiV1ApplicationVersionsApplicationVersionIdInteractionsCompleteStatusPost;
      }
    > = props => {
      const { applicationVersionId, data } = props ?? {};

      return getInteractionCompletionStatusApiV1ApplicationVersionsApplicationVersionIdInteractionsCompleteStatusPost(
        applicationVersionId,
        data
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type GetInteractionCompletionStatusApiV1ApplicationVersionsApplicationVersionIdInteractionsCompleteStatusPostMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getInteractionCompletionStatusApiV1ApplicationVersionsApplicationVersionIdInteractionsCompleteStatusPost
      >
    >
  >;
export type GetInteractionCompletionStatusApiV1ApplicationVersionsApplicationVersionIdInteractionsCompleteStatusPostMutationBody =
  BodyGetInteractionCompletionStatusApiV1ApplicationVersionsApplicationVersionIdInteractionsCompleteStatusPost;
export type GetInteractionCompletionStatusApiV1ApplicationVersionsApplicationVersionIdInteractionsCompleteStatusPostMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Get Interaction Completion Status
 */
export const useGetInteractionCompletionStatusApiV1ApplicationVersionsApplicationVersionIdInteractionsCompleteStatusPost =
  <TError = ErrorType<HTTPValidationError>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof getInteractionCompletionStatusApiV1ApplicationVersionsApplicationVersionIdInteractionsCompleteStatusPost
        >
      >,
      TError,
      {
        applicationVersionId: number;
        data: BodyGetInteractionCompletionStatusApiV1ApplicationVersionsApplicationVersionIdInteractionsCompleteStatusPost;
      },
      TContext
    >;
  }): UseMutationResult<
    Awaited<
      ReturnType<
        typeof getInteractionCompletionStatusApiV1ApplicationVersionsApplicationVersionIdInteractionsCompleteStatusPost
      >
    >,
    TError,
    {
      applicationVersionId: number;
      data: BodyGetInteractionCompletionStatusApiV1ApplicationVersionsApplicationVersionIdInteractionsCompleteStatusPost;
    },
    TContext
  > => {
    const mutationOptions =
      getGetInteractionCompletionStatusApiV1ApplicationVersionsApplicationVersionIdInteractionsCompleteStatusPostMutationOptions(
        options
      );

    return useMutation(mutationOptions);
  };

/**
 * Get interaction properties metadata
 * @summary Get Interaction Properties Metadata
 */
export const getInteractionPropertiesMetadataApiV1ApplicationVersionsApplicationVersionIdInteractionsUserInteractionIdPropertiesMetadataGet =
  (applicationVersionId: number, userInteractionId: string, signal?: AbortSignal) => {
    return customInstance<PerPropertySchema[]>({
      url: `/api/v1/application-versions/${applicationVersionId}/interactions/${userInteractionId}/properties-metadata`,
      method: 'GET',
      signal
    });
  };

export const getGetInteractionPropertiesMetadataApiV1ApplicationVersionsApplicationVersionIdInteractionsUserInteractionIdPropertiesMetadataGetQueryKey =
  (applicationVersionId: number, userInteractionId: string) => {
    return [
      `/api/v1/application-versions/${applicationVersionId}/interactions/${userInteractionId}/properties-metadata`
    ] as const;
  };

export const getGetInteractionPropertiesMetadataApiV1ApplicationVersionsApplicationVersionIdInteractionsUserInteractionIdPropertiesMetadataGetQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        typeof getInteractionPropertiesMetadataApiV1ApplicationVersionsApplicationVersionIdInteractionsUserInteractionIdPropertiesMetadataGet
      >
    >,
    TError = ErrorType<HTTPValidationError>
  >(
    applicationVersionId: number,
    userInteractionId: string,
    options?: {
      query?: UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getInteractionPropertiesMetadataApiV1ApplicationVersionsApplicationVersionIdInteractionsUserInteractionIdPropertiesMetadataGet
          >
        >,
        TError,
        TData
      >;
    }
  ) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetInteractionPropertiesMetadataApiV1ApplicationVersionsApplicationVersionIdInteractionsUserInteractionIdPropertiesMetadataGetQueryKey(
        applicationVersionId,
        userInteractionId
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof getInteractionPropertiesMetadataApiV1ApplicationVersionsApplicationVersionIdInteractionsUserInteractionIdPropertiesMetadataGet
        >
      >
    > = ({ signal }) =>
      getInteractionPropertiesMetadataApiV1ApplicationVersionsApplicationVersionIdInteractionsUserInteractionIdPropertiesMetadataGet(
        applicationVersionId,
        userInteractionId,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!(applicationVersionId && userInteractionId),
      ...queryOptions
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof getInteractionPropertiesMetadataApiV1ApplicationVersionsApplicationVersionIdInteractionsUserInteractionIdPropertiesMetadataGet
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetInteractionPropertiesMetadataApiV1ApplicationVersionsApplicationVersionIdInteractionsUserInteractionIdPropertiesMetadataGetQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getInteractionPropertiesMetadataApiV1ApplicationVersionsApplicationVersionIdInteractionsUserInteractionIdPropertiesMetadataGet
      >
    >
  >;
export type GetInteractionPropertiesMetadataApiV1ApplicationVersionsApplicationVersionIdInteractionsUserInteractionIdPropertiesMetadataGetQueryError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Get Interaction Properties Metadata
 */

export function useGetInteractionPropertiesMetadataApiV1ApplicationVersionsApplicationVersionIdInteractionsUserInteractionIdPropertiesMetadataGet<
  TData = Awaited<
    ReturnType<
      typeof getInteractionPropertiesMetadataApiV1ApplicationVersionsApplicationVersionIdInteractionsUserInteractionIdPropertiesMetadataGet
    >
  >,
  TError = ErrorType<HTTPValidationError>
>(
  applicationVersionId: number,
  userInteractionId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          typeof getInteractionPropertiesMetadataApiV1ApplicationVersionsApplicationVersionIdInteractionsUserInteractionIdPropertiesMetadataGet
        >
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetInteractionPropertiesMetadataApiV1ApplicationVersionsApplicationVersionIdInteractionsUserInteractionIdPropertiesMetadataGetQueryOptions(
      applicationVersionId,
      userInteractionId,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Get interaction properties metadata (Deprecated, please use '/application-versions/{application_version_id}/interactions/{user_interaction_id}/properties-metadata' instead)
 * @deprecated
 * @summary Get Interaction Properties Metadata
 */
export const getInteractionPropertiesMetadataDeprecated = (
  applicationVersionId: number,
  userInteractionId: string,
  signal?: AbortSignal
) => {
  return customInstance<PerPropertySchema[]>({
    url: `/api/v1/application_versions/${applicationVersionId}/interactions/${userInteractionId}/properties-metadata`,
    method: 'GET',
    signal
  });
};

export const getGetInteractionPropertiesMetadataDeprecatedQueryKey = (
  applicationVersionId: number,
  userInteractionId: string
) => {
  return [
    `/api/v1/application_versions/${applicationVersionId}/interactions/${userInteractionId}/properties-metadata`
  ] as const;
};

export const getGetInteractionPropertiesMetadataDeprecatedQueryOptions = <
  TData = Awaited<ReturnType<typeof getInteractionPropertiesMetadataDeprecated>>,
  TError = ErrorType<HTTPValidationError>
>(
  applicationVersionId: number,
  userInteractionId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getInteractionPropertiesMetadataDeprecated>>, TError, TData>;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetInteractionPropertiesMetadataDeprecatedQueryKey(applicationVersionId, userInteractionId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getInteractionPropertiesMetadataDeprecated>>> = ({ signal }) =>
    getInteractionPropertiesMetadataDeprecated(applicationVersionId, userInteractionId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(applicationVersionId && userInteractionId),
    ...queryOptions
  } as UseQueryOptions<Awaited<ReturnType<typeof getInteractionPropertiesMetadataDeprecated>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetInteractionPropertiesMetadataDeprecatedQueryResult = NonNullable<
  Awaited<ReturnType<typeof getInteractionPropertiesMetadataDeprecated>>
>;
export type GetInteractionPropertiesMetadataDeprecatedQueryError = ErrorType<HTTPValidationError>;

/**
 * @deprecated
 * @summary Get Interaction Properties Metadata
 */

export function useGetInteractionPropertiesMetadataDeprecated<
  TData = Awaited<ReturnType<typeof getInteractionPropertiesMetadataDeprecated>>,
  TError = ErrorType<HTTPValidationError>
>(
  applicationVersionId: number,
  userInteractionId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getInteractionPropertiesMetadataDeprecated>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetInteractionPropertiesMetadataDeprecatedQueryOptions(
    applicationVersionId,
    userInteractionId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Hello World
 */
export const helloWorldApiV1SayHelloGet = (signal?: AbortSignal) => {
  return customInstance<string>({ url: `/api/v1/say-hello`, method: 'GET', signal });
};

export const getHelloWorldApiV1SayHelloGetQueryKey = () => {
  return [`/api/v1/say-hello`] as const;
};

export const getHelloWorldApiV1SayHelloGetQueryOptions = <
  TData = Awaited<ReturnType<typeof helloWorldApiV1SayHelloGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof helloWorldApiV1SayHelloGet>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getHelloWorldApiV1SayHelloGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof helloWorldApiV1SayHelloGet>>> = ({ signal }) =>
    helloWorldApiV1SayHelloGet(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof helloWorldApiV1SayHelloGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type HelloWorldApiV1SayHelloGetQueryResult = NonNullable<Awaited<ReturnType<typeof helloWorldApiV1SayHelloGet>>>;
export type HelloWorldApiV1SayHelloGetQueryError = ErrorType<unknown>;

/**
 * @summary Hello World
 */

export function useHelloWorldApiV1SayHelloGet<
  TData = Awaited<ReturnType<typeof helloWorldApiV1SayHelloGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof helloWorldApiV1SayHelloGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getHelloWorldApiV1SayHelloGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Retrieve Backend Version
 */
export const retrieveBackendVersionApiV1BackendVersionGet = (signal?: AbortSignal) => {
  return customInstance<unknown>({ url: `/api/v1/backend-version`, method: 'GET', signal });
};

export const getRetrieveBackendVersionApiV1BackendVersionGetQueryKey = () => {
  return [`/api/v1/backend-version`] as const;
};

export const getRetrieveBackendVersionApiV1BackendVersionGetQueryOptions = <
  TData = Awaited<ReturnType<typeof retrieveBackendVersionApiV1BackendVersionGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof retrieveBackendVersionApiV1BackendVersionGet>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRetrieveBackendVersionApiV1BackendVersionGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof retrieveBackendVersionApiV1BackendVersionGet>>> = ({
    signal
  }) => retrieveBackendVersionApiV1BackendVersionGet(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof retrieveBackendVersionApiV1BackendVersionGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RetrieveBackendVersionApiV1BackendVersionGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof retrieveBackendVersionApiV1BackendVersionGet>>
>;
export type RetrieveBackendVersionApiV1BackendVersionGetQueryError = ErrorType<unknown>;

/**
 * @summary Retrieve Backend Version
 */

export function useRetrieveBackendVersionApiV1BackendVersionGet<
  TData = Awaited<ReturnType<typeof retrieveBackendVersionApiV1BackendVersionGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof retrieveBackendVersionApiV1BackendVersionGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getRetrieveBackendVersionApiV1BackendVersionGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Retrieve metadata of the system, such as last update time of the application
 * @summary Get Status Info
 */
export const getAppLastUpdateTime = (params: GetAppLastUpdateTimeParams, signal?: AbortSignal) => {
  return customInstance<DeepchecksLlmEvalApiV1GeneralRecordIdentifierSchema>({
    url: `/api/v1/sys-info`,
    method: 'GET',
    params,
    signal
  });
};

export const getGetAppLastUpdateTimeQueryKey = (params: GetAppLastUpdateTimeParams) => {
  return [`/api/v1/sys-info`, ...(params ? [params] : [])] as const;
};

export const getGetAppLastUpdateTimeQueryOptions = <
  TData = Awaited<ReturnType<typeof getAppLastUpdateTime>>,
  TError = ErrorType<HTTPValidationError>
>(
  params: GetAppLastUpdateTimeParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getAppLastUpdateTime>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAppLastUpdateTimeQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAppLastUpdateTime>>> = ({ signal }) =>
    getAppLastUpdateTime(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAppLastUpdateTime>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAppLastUpdateTimeQueryResult = NonNullable<Awaited<ReturnType<typeof getAppLastUpdateTime>>>;
export type GetAppLastUpdateTimeQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Status Info
 */

export function useGetAppLastUpdateTime<
  TData = Awaited<ReturnType<typeof getAppLastUpdateTime>>,
  TError = ErrorType<HTTPValidationError>
>(
  params: GetAppLastUpdateTimeParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getAppLastUpdateTime>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetAppLastUpdateTimeQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Update annotation for interaction
 * @summary Update Annotations
 */
export const updateAnnotationsApiV1AnnotationsPut = (annotationUpdateSchema: AnnotationUpdateSchema) => {
  return customInstance<unknown>({
    url: `/api/v1/annotations`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: annotationUpdateSchema
  });
};

export const getUpdateAnnotationsApiV1AnnotationsPutMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAnnotationsApiV1AnnotationsPut>>,
    TError,
    { data: AnnotationUpdateSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateAnnotationsApiV1AnnotationsPut>>,
  TError,
  { data: AnnotationUpdateSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateAnnotationsApiV1AnnotationsPut>>,
    { data: AnnotationUpdateSchema }
  > = props => {
    const { data } = props ?? {};

    return updateAnnotationsApiV1AnnotationsPut(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateAnnotationsApiV1AnnotationsPutMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateAnnotationsApiV1AnnotationsPut>>
>;
export type UpdateAnnotationsApiV1AnnotationsPutMutationBody = AnnotationUpdateSchema;
export type UpdateAnnotationsApiV1AnnotationsPutMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Update Annotations
 */
export const useUpdateAnnotationsApiV1AnnotationsPut = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAnnotationsApiV1AnnotationsPut>>,
    TError,
    { data: AnnotationUpdateSchema },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateAnnotationsApiV1AnnotationsPut>>,
  TError,
  { data: AnnotationUpdateSchema },
  TContext
> => {
  const mutationOptions = getUpdateAnnotationsApiV1AnnotationsPutMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Reset annotation for estimated interaction to pending
 * @summary Reset Annotation
 */
export const resetAnnotationApiV1AnnotationsResetEstimatedPost = (resetAnnotationPayload: ResetAnnotationPayload) => {
  return customInstance<unknown>({
    url: `/api/v1/annotations/reset-estimated`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: resetAnnotationPayload
  });
};

export const getResetAnnotationApiV1AnnotationsResetEstimatedPostMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resetAnnotationApiV1AnnotationsResetEstimatedPost>>,
    TError,
    { data: ResetAnnotationPayload },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof resetAnnotationApiV1AnnotationsResetEstimatedPost>>,
  TError,
  { data: ResetAnnotationPayload },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof resetAnnotationApiV1AnnotationsResetEstimatedPost>>,
    { data: ResetAnnotationPayload }
  > = props => {
    const { data } = props ?? {};

    return resetAnnotationApiV1AnnotationsResetEstimatedPost(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ResetAnnotationApiV1AnnotationsResetEstimatedPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof resetAnnotationApiV1AnnotationsResetEstimatedPost>>
>;
export type ResetAnnotationApiV1AnnotationsResetEstimatedPostMutationBody = ResetAnnotationPayload;
export type ResetAnnotationApiV1AnnotationsResetEstimatedPostMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Reset Annotation
 */
export const useResetAnnotationApiV1AnnotationsResetEstimatedPost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resetAnnotationApiV1AnnotationsResetEstimatedPost>>,
    TError,
    { data: ResetAnnotationPayload },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof resetAnnotationApiV1AnnotationsResetEstimatedPost>>,
  TError,
  { data: ResetAnnotationPayload },
  TContext
> => {
  const mutationOptions = getResetAnnotationApiV1AnnotationsResetEstimatedPostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get Insight
 */
export const getInsightApiV1InsightsGet = (params: GetInsightApiV1InsightsGetParams, signal?: AbortSignal) => {
  return customInstance<InsightSchema[]>({ url: `/api/v1/insights`, method: 'GET', params, signal });
};

export const getGetInsightApiV1InsightsGetQueryKey = (params: GetInsightApiV1InsightsGetParams) => {
  return [`/api/v1/insights`, ...(params ? [params] : [])] as const;
};

export const getGetInsightApiV1InsightsGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getInsightApiV1InsightsGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  params: GetInsightApiV1InsightsGetParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getInsightApiV1InsightsGet>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetInsightApiV1InsightsGetQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getInsightApiV1InsightsGet>>> = ({ signal }) =>
    getInsightApiV1InsightsGet(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getInsightApiV1InsightsGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetInsightApiV1InsightsGetQueryResult = NonNullable<Awaited<ReturnType<typeof getInsightApiV1InsightsGet>>>;
export type GetInsightApiV1InsightsGetQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Insight
 */

export function useGetInsightApiV1InsightsGet<
  TData = Awaited<ReturnType<typeof getInsightApiV1InsightsGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  params: GetInsightApiV1InsightsGetParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getInsightApiV1InsightsGet>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetInsightApiV1InsightsGetQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Top Insights Per App
 */
export const getTopInsightsPerAppApiV1TopInsightsPerAppGet = (
  params: GetTopInsightsPerAppApiV1TopInsightsPerAppGetParams,
  signal?: AbortSignal
) => {
  return customInstance<InsightSchema[]>({ url: `/api/v1/top-insights-per-app`, method: 'GET', params, signal });
};

export const getGetTopInsightsPerAppApiV1TopInsightsPerAppGetQueryKey = (
  params: GetTopInsightsPerAppApiV1TopInsightsPerAppGetParams
) => {
  return [`/api/v1/top-insights-per-app`, ...(params ? [params] : [])] as const;
};

export const getGetTopInsightsPerAppApiV1TopInsightsPerAppGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getTopInsightsPerAppApiV1TopInsightsPerAppGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  params: GetTopInsightsPerAppApiV1TopInsightsPerAppGetParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getTopInsightsPerAppApiV1TopInsightsPerAppGet>>, TError, TData>;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTopInsightsPerAppApiV1TopInsightsPerAppGetQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTopInsightsPerAppApiV1TopInsightsPerAppGet>>> = ({
    signal
  }) => getTopInsightsPerAppApiV1TopInsightsPerAppGet(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTopInsightsPerAppApiV1TopInsightsPerAppGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTopInsightsPerAppApiV1TopInsightsPerAppGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTopInsightsPerAppApiV1TopInsightsPerAppGet>>
>;
export type GetTopInsightsPerAppApiV1TopInsightsPerAppGetQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Top Insights Per App
 */

export function useGetTopInsightsPerAppApiV1TopInsightsPerAppGet<
  TData = Awaited<ReturnType<typeof getTopInsightsPerAppApiV1TopInsightsPerAppGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  params: GetTopInsightsPerAppApiV1TopInsightsPerAppGetParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getTopInsightsPerAppApiV1TopInsightsPerAppGet>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetTopInsightsPerAppApiV1TopInsightsPerAppGetQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Calculate insights for given application version.
 * @summary Recalculate Insights
 */
export const recalculateInsightsApiV1InsightsRecalculatePost = (
  params: RecalculateInsightsApiV1InsightsRecalculatePostParams
) => {
  return customInstance<unknown>({ url: `/api/v1/insights/recalculate`, method: 'POST', params });
};

export const getRecalculateInsightsApiV1InsightsRecalculatePostMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof recalculateInsightsApiV1InsightsRecalculatePost>>,
    TError,
    { params: RecalculateInsightsApiV1InsightsRecalculatePostParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof recalculateInsightsApiV1InsightsRecalculatePost>>,
  TError,
  { params: RecalculateInsightsApiV1InsightsRecalculatePostParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof recalculateInsightsApiV1InsightsRecalculatePost>>,
    { params: RecalculateInsightsApiV1InsightsRecalculatePostParams }
  > = props => {
    const { params } = props ?? {};

    return recalculateInsightsApiV1InsightsRecalculatePost(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type RecalculateInsightsApiV1InsightsRecalculatePostMutationResult = NonNullable<
  Awaited<ReturnType<typeof recalculateInsightsApiV1InsightsRecalculatePost>>
>;

export type RecalculateInsightsApiV1InsightsRecalculatePostMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Recalculate Insights
 */
export const useRecalculateInsightsApiV1InsightsRecalculatePost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof recalculateInsightsApiV1InsightsRecalculatePost>>,
    TError,
    { params: RecalculateInsightsApiV1InsightsRecalculatePostParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof recalculateInsightsApiV1InsightsRecalculatePost>>,
  TError,
  { params: RecalculateInsightsApiV1InsightsRecalculatePostParams },
  TContext
> => {
  const mutationOptions = getRecalculateInsightsApiV1InsightsRecalculatePostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Update insight
 * @summary Update Insight
 */
export const updateInsight = (insightId: number, updateInsightSchema: UpdateInsightSchema) => {
  return customInstance<DeepchecksLlmEvalApiV1SchemasRecordIdentifierSchema[]>({
    url: `/api/v1/insights/${insightId}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: updateInsightSchema
  });
};

export const getUpdateInsightMutationOptions = <TError = ErrorType<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateInsight>>,
    TError,
    { insightId: number; data: UpdateInsightSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateInsight>>,
  TError,
  { insightId: number; data: UpdateInsightSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateInsight>>,
    { insightId: number; data: UpdateInsightSchema }
  > = props => {
    const { insightId, data } = props ?? {};

    return updateInsight(insightId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateInsightMutationResult = NonNullable<Awaited<ReturnType<typeof updateInsight>>>;
export type UpdateInsightMutationBody = UpdateInsightSchema;
export type UpdateInsightMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Update Insight
 */
export const useUpdateInsight = <TError = ErrorType<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateInsight>>,
    TError,
    { insightId: number; data: UpdateInsightSchema },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateInsight>>,
  TError,
  { insightId: number; data: UpdateInsightSchema },
  TContext
> => {
  const mutationOptions = getUpdateInsightMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get Generated Inputs
 */
export const getGeneratedInputsApiV1GenerateUserInputsPost = (
  generateConfig: GenerateConfig,
  params: GetGeneratedInputsApiV1GenerateUserInputsPostParams
) => {
  return customInstance<unknown>({
    url: `/api/v1/generate-user-inputs`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: generateConfig,
    params
  });
};

export const getGetGeneratedInputsApiV1GenerateUserInputsPostMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getGeneratedInputsApiV1GenerateUserInputsPost>>,
    TError,
    { data: GenerateConfig; params: GetGeneratedInputsApiV1GenerateUserInputsPostParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof getGeneratedInputsApiV1GenerateUserInputsPost>>,
  TError,
  { data: GenerateConfig; params: GetGeneratedInputsApiV1GenerateUserInputsPostParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getGeneratedInputsApiV1GenerateUserInputsPost>>,
    { data: GenerateConfig; params: GetGeneratedInputsApiV1GenerateUserInputsPostParams }
  > = props => {
    const { data, params } = props ?? {};

    return getGeneratedInputsApiV1GenerateUserInputsPost(data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetGeneratedInputsApiV1GenerateUserInputsPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof getGeneratedInputsApiV1GenerateUserInputsPost>>
>;
export type GetGeneratedInputsApiV1GenerateUserInputsPostMutationBody = GenerateConfig;
export type GetGeneratedInputsApiV1GenerateUserInputsPostMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Generated Inputs
 */
export const useGetGeneratedInputsApiV1GenerateUserInputsPost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getGeneratedInputsApiV1GenerateUserInputsPost>>,
    TError,
    { data: GenerateConfig; params: GetGeneratedInputsApiV1GenerateUserInputsPostParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof getGeneratedInputsApiV1GenerateUserInputsPost>>,
  TError,
  { data: GenerateConfig; params: GetGeneratedInputsApiV1GenerateUserInputsPostParams },
  TContext
> => {
  const mutationOptions = getGetGeneratedInputsApiV1GenerateUserInputsPostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Create a new golden set generation task
 * @summary Create Question Gen Task For App
 */
export const createQuestionGenTaskForAppApiV1ApplicationsAppIdGoldensetGeneratorTaskPost = (appId: number) => {
  return customInstance<DeepchecksLlmEvalApiV1SchemasRecordIdentifierSchema>({
    url: `/api/v1/applications/${appId}/goldenset-generator-task`,
    method: 'POST'
  });
};

export const getCreateQuestionGenTaskForAppApiV1ApplicationsAppIdGoldensetGeneratorTaskPostMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createQuestionGenTaskForAppApiV1ApplicationsAppIdGoldensetGeneratorTaskPost>>,
    TError,
    { appId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createQuestionGenTaskForAppApiV1ApplicationsAppIdGoldensetGeneratorTaskPost>>,
  TError,
  { appId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createQuestionGenTaskForAppApiV1ApplicationsAppIdGoldensetGeneratorTaskPost>>,
    { appId: number }
  > = props => {
    const { appId } = props ?? {};

    return createQuestionGenTaskForAppApiV1ApplicationsAppIdGoldensetGeneratorTaskPost(appId);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateQuestionGenTaskForAppApiV1ApplicationsAppIdGoldensetGeneratorTaskPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof createQuestionGenTaskForAppApiV1ApplicationsAppIdGoldensetGeneratorTaskPost>>
>;

export type CreateQuestionGenTaskForAppApiV1ApplicationsAppIdGoldensetGeneratorTaskPostMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Create Question Gen Task For App
 */
export const useCreateQuestionGenTaskForAppApiV1ApplicationsAppIdGoldensetGeneratorTaskPost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createQuestionGenTaskForAppApiV1ApplicationsAppIdGoldensetGeneratorTaskPost>>,
    TError,
    { appId: number },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createQuestionGenTaskForAppApiV1ApplicationsAppIdGoldensetGeneratorTaskPost>>,
  TError,
  { appId: number },
  TContext
> => {
  const mutationOptions =
    getCreateQuestionGenTaskForAppApiV1ApplicationsAppIdGoldensetGeneratorTaskPostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Adds a new data source to the gen task
 * @summary Add Source
 */
export const addSourceApiV1GoldensetGeneratorTaskTaskIdDataSourcePost = (
  taskId: string,
  bodyAddSourceApiV1GoldensetGeneratorTaskTaskIdDataSourcePost: BodyAddSourceApiV1GoldensetGeneratorTaskTaskIdDataSourcePost
) => {
  const formData = new FormData();
  formData.append('data', bodyAddSourceApiV1GoldensetGeneratorTaskTaskIdDataSourcePost as any);

  return customInstance<AddSourceApiV1GoldensetGeneratorTaskTaskIdDataSourcePost200>({
    url: `/api/v1/goldenset-generator-task/${taskId}/data-source`,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData
  });
};

export const getAddSourceApiV1GoldensetGeneratorTaskTaskIdDataSourcePostMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addSourceApiV1GoldensetGeneratorTaskTaskIdDataSourcePost>>,
    TError,
    { taskId: string; data: BodyAddSourceApiV1GoldensetGeneratorTaskTaskIdDataSourcePost },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof addSourceApiV1GoldensetGeneratorTaskTaskIdDataSourcePost>>,
  TError,
  { taskId: string; data: BodyAddSourceApiV1GoldensetGeneratorTaskTaskIdDataSourcePost },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addSourceApiV1GoldensetGeneratorTaskTaskIdDataSourcePost>>,
    { taskId: string; data: BodyAddSourceApiV1GoldensetGeneratorTaskTaskIdDataSourcePost }
  > = props => {
    const { taskId, data } = props ?? {};

    return addSourceApiV1GoldensetGeneratorTaskTaskIdDataSourcePost(taskId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddSourceApiV1GoldensetGeneratorTaskTaskIdDataSourcePostMutationResult = NonNullable<
  Awaited<ReturnType<typeof addSourceApiV1GoldensetGeneratorTaskTaskIdDataSourcePost>>
>;
export type AddSourceApiV1GoldensetGeneratorTaskTaskIdDataSourcePostMutationBody =
  BodyAddSourceApiV1GoldensetGeneratorTaskTaskIdDataSourcePost;
export type AddSourceApiV1GoldensetGeneratorTaskTaskIdDataSourcePostMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Add Source
 */
export const useAddSourceApiV1GoldensetGeneratorTaskTaskIdDataSourcePost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addSourceApiV1GoldensetGeneratorTaskTaskIdDataSourcePost>>,
    TError,
    { taskId: string; data: BodyAddSourceApiV1GoldensetGeneratorTaskTaskIdDataSourcePost },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof addSourceApiV1GoldensetGeneratorTaskTaskIdDataSourcePost>>,
  TError,
  { taskId: string; data: BodyAddSourceApiV1GoldensetGeneratorTaskTaskIdDataSourcePost },
  TContext
> => {
  const mutationOptions = getAddSourceApiV1GoldensetGeneratorTaskTaskIdDataSourcePostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Deletes a new data source from the gen task
 * @summary Delete Source
 */
export const deleteSourceApiV1GoldensetGeneratorTaskTaskIdDataSourceSourceIdDelete = (
  taskId: string,
  sourceId: string
) => {
  return customInstance<DeleteSourceApiV1GoldensetGeneratorTaskTaskIdDataSourceSourceIdDelete200>({
    url: `/api/v1/goldenset-generator-task/${taskId}/data-source/${sourceId}`,
    method: 'DELETE'
  });
};

export const getDeleteSourceApiV1GoldensetGeneratorTaskTaskIdDataSourceSourceIdDeleteMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSourceApiV1GoldensetGeneratorTaskTaskIdDataSourceSourceIdDelete>>,
    TError,
    { taskId: string; sourceId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteSourceApiV1GoldensetGeneratorTaskTaskIdDataSourceSourceIdDelete>>,
  TError,
  { taskId: string; sourceId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteSourceApiV1GoldensetGeneratorTaskTaskIdDataSourceSourceIdDelete>>,
    { taskId: string; sourceId: string }
  > = props => {
    const { taskId, sourceId } = props ?? {};

    return deleteSourceApiV1GoldensetGeneratorTaskTaskIdDataSourceSourceIdDelete(taskId, sourceId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteSourceApiV1GoldensetGeneratorTaskTaskIdDataSourceSourceIdDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteSourceApiV1GoldensetGeneratorTaskTaskIdDataSourceSourceIdDelete>>
>;

export type DeleteSourceApiV1GoldensetGeneratorTaskTaskIdDataSourceSourceIdDeleteMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Delete Source
 */
export const useDeleteSourceApiV1GoldensetGeneratorTaskTaskIdDataSourceSourceIdDelete = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSourceApiV1GoldensetGeneratorTaskTaskIdDataSourceSourceIdDelete>>,
    TError,
    { taskId: string; sourceId: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteSourceApiV1GoldensetGeneratorTaskTaskIdDataSourceSourceIdDelete>>,
  TError,
  { taskId: string; sourceId: string },
  TContext
> => {
  const mutationOptions =
    getDeleteSourceApiV1GoldensetGeneratorTaskTaskIdDataSourceSourceIdDeleteMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Starting the samples generation process
 * @summary Generate Samples
 */
export const generateSamplesApiV1GoldensetGeneratorTaskTaskIdGeneratePost = (
  taskId: string,
  bodyGenerateSamplesApiV1GoldensetGeneratorTaskTaskIdGeneratePost: BodyGenerateSamplesApiV1GoldensetGeneratorTaskTaskIdGeneratePost,
  params: GenerateSamplesApiV1GoldensetGeneratorTaskTaskIdGeneratePostParams
) => {
  return customInstance<unknown[]>({
    url: `/api/v1/goldenset-generator-task/${taskId}/generate`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: bodyGenerateSamplesApiV1GoldensetGeneratorTaskTaskIdGeneratePost,
    params
  });
};

export const getGenerateSamplesApiV1GoldensetGeneratorTaskTaskIdGeneratePostMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof generateSamplesApiV1GoldensetGeneratorTaskTaskIdGeneratePost>>,
    TError,
    {
      taskId: string;
      data: BodyGenerateSamplesApiV1GoldensetGeneratorTaskTaskIdGeneratePost;
      params: GenerateSamplesApiV1GoldensetGeneratorTaskTaskIdGeneratePostParams;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof generateSamplesApiV1GoldensetGeneratorTaskTaskIdGeneratePost>>,
  TError,
  {
    taskId: string;
    data: BodyGenerateSamplesApiV1GoldensetGeneratorTaskTaskIdGeneratePost;
    params: GenerateSamplesApiV1GoldensetGeneratorTaskTaskIdGeneratePostParams;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof generateSamplesApiV1GoldensetGeneratorTaskTaskIdGeneratePost>>,
    {
      taskId: string;
      data: BodyGenerateSamplesApiV1GoldensetGeneratorTaskTaskIdGeneratePost;
      params: GenerateSamplesApiV1GoldensetGeneratorTaskTaskIdGeneratePostParams;
    }
  > = props => {
    const { taskId, data, params } = props ?? {};

    return generateSamplesApiV1GoldensetGeneratorTaskTaskIdGeneratePost(taskId, data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type GenerateSamplesApiV1GoldensetGeneratorTaskTaskIdGeneratePostMutationResult = NonNullable<
  Awaited<ReturnType<typeof generateSamplesApiV1GoldensetGeneratorTaskTaskIdGeneratePost>>
>;
export type GenerateSamplesApiV1GoldensetGeneratorTaskTaskIdGeneratePostMutationBody =
  BodyGenerateSamplesApiV1GoldensetGeneratorTaskTaskIdGeneratePost;
export type GenerateSamplesApiV1GoldensetGeneratorTaskTaskIdGeneratePostMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Generate Samples
 */
export const useGenerateSamplesApiV1GoldensetGeneratorTaskTaskIdGeneratePost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof generateSamplesApiV1GoldensetGeneratorTaskTaskIdGeneratePost>>,
    TError,
    {
      taskId: string;
      data: BodyGenerateSamplesApiV1GoldensetGeneratorTaskTaskIdGeneratePost;
      params: GenerateSamplesApiV1GoldensetGeneratorTaskTaskIdGeneratePostParams;
    },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof generateSamplesApiV1GoldensetGeneratorTaskTaskIdGeneratePost>>,
  TError,
  {
    taskId: string;
    data: BodyGenerateSamplesApiV1GoldensetGeneratorTaskTaskIdGeneratePost;
    params: GenerateSamplesApiV1GoldensetGeneratorTaskTaskIdGeneratePostParams;
  },
  TContext
> => {
  const mutationOptions = getGenerateSamplesApiV1GoldensetGeneratorTaskTaskIdGeneratePostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Downloads the selected samples from the samples generation process
 * @summary Download Selected Generated Samples
 */
export const downloadSelectedGeneratedSamplesApiV1GoldensetGeneratorTaskTaskIdDownloadPost = (
  taskId: string,
  downloadSelectedGeneratedSamplesApiV1GoldensetGeneratorTaskTaskIdDownloadPostBody: unknown[]
) => {
  return customInstance<string[]>({
    url: `/api/v1/goldenset-generator-task/${taskId}/download`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: downloadSelectedGeneratedSamplesApiV1GoldensetGeneratorTaskTaskIdDownloadPostBody
  });
};

export const getDownloadSelectedGeneratedSamplesApiV1GoldensetGeneratorTaskTaskIdDownloadPostMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof downloadSelectedGeneratedSamplesApiV1GoldensetGeneratorTaskTaskIdDownloadPost>>,
    TError,
    { taskId: string; data: unknown[] },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof downloadSelectedGeneratedSamplesApiV1GoldensetGeneratorTaskTaskIdDownloadPost>>,
  TError,
  { taskId: string; data: unknown[] },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof downloadSelectedGeneratedSamplesApiV1GoldensetGeneratorTaskTaskIdDownloadPost>>,
    { taskId: string; data: unknown[] }
  > = props => {
    const { taskId, data } = props ?? {};

    return downloadSelectedGeneratedSamplesApiV1GoldensetGeneratorTaskTaskIdDownloadPost(taskId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type DownloadSelectedGeneratedSamplesApiV1GoldensetGeneratorTaskTaskIdDownloadPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof downloadSelectedGeneratedSamplesApiV1GoldensetGeneratorTaskTaskIdDownloadPost>>
>;
export type DownloadSelectedGeneratedSamplesApiV1GoldensetGeneratorTaskTaskIdDownloadPostMutationBody = unknown[];
export type DownloadSelectedGeneratedSamplesApiV1GoldensetGeneratorTaskTaskIdDownloadPostMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Download Selected Generated Samples
 */
export const useDownloadSelectedGeneratedSamplesApiV1GoldensetGeneratorTaskTaskIdDownloadPost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof downloadSelectedGeneratedSamplesApiV1GoldensetGeneratorTaskTaskIdDownloadPost>>,
    TError,
    { taskId: string; data: unknown[] },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof downloadSelectedGeneratedSamplesApiV1GoldensetGeneratorTaskTaskIdDownloadPost>>,
  TError,
  { taskId: string; data: unknown[] },
  TContext
> => {
  const mutationOptions =
    getDownloadSelectedGeneratedSamplesApiV1GoldensetGeneratorTaskTaskIdDownloadPostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Clean up all resources regarding the samples generation
 * @summary Destroy All
 */
export const destroyAllApiV1GoldensetGeneratorTaskTaskIdDestroyPost = (taskId: string) => {
  return customInstance<unknown>({ url: `/api/v1/goldenset-generator-task/${taskId}/destroy`, method: 'POST' });
};

export const getDestroyAllApiV1GoldensetGeneratorTaskTaskIdDestroyPostMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof destroyAllApiV1GoldensetGeneratorTaskTaskIdDestroyPost>>,
    TError,
    { taskId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof destroyAllApiV1GoldensetGeneratorTaskTaskIdDestroyPost>>,
  TError,
  { taskId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof destroyAllApiV1GoldensetGeneratorTaskTaskIdDestroyPost>>,
    { taskId: string }
  > = props => {
    const { taskId } = props ?? {};

    return destroyAllApiV1GoldensetGeneratorTaskTaskIdDestroyPost(taskId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DestroyAllApiV1GoldensetGeneratorTaskTaskIdDestroyPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof destroyAllApiV1GoldensetGeneratorTaskTaskIdDestroyPost>>
>;

export type DestroyAllApiV1GoldensetGeneratorTaskTaskIdDestroyPostMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Destroy All
 */
export const useDestroyAllApiV1GoldensetGeneratorTaskTaskIdDestroyPost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof destroyAllApiV1GoldensetGeneratorTaskTaskIdDestroyPost>>,
    TError,
    { taskId: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof destroyAllApiV1GoldensetGeneratorTaskTaskIdDestroyPost>>,
  TError,
  { taskId: string },
  TContext
> => {
  const mutationOptions = getDestroyAllApiV1GoldensetGeneratorTaskTaskIdDestroyPostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Retrieve a mapping of topics list per environment for the given  app version
 * @summary Get topics
 */
export const getTopicsApiV1ApplicationVersionsApplicationVersionIdTopicsPost = (
  applicationVersionId: number,
  filterSchema: FilterSchema
) => {
  return customInstance<AppVersionTopicsResponseSchema>({
    url: `/api/v1/application-versions/${applicationVersionId}/topics`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: filterSchema
  });
};

export const getGetTopicsApiV1ApplicationVersionsApplicationVersionIdTopicsPostMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getTopicsApiV1ApplicationVersionsApplicationVersionIdTopicsPost>>,
    TError,
    { applicationVersionId: number; data: FilterSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof getTopicsApiV1ApplicationVersionsApplicationVersionIdTopicsPost>>,
  TError,
  { applicationVersionId: number; data: FilterSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getTopicsApiV1ApplicationVersionsApplicationVersionIdTopicsPost>>,
    { applicationVersionId: number; data: FilterSchema }
  > = props => {
    const { applicationVersionId, data } = props ?? {};

    return getTopicsApiV1ApplicationVersionsApplicationVersionIdTopicsPost(applicationVersionId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetTopicsApiV1ApplicationVersionsApplicationVersionIdTopicsPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof getTopicsApiV1ApplicationVersionsApplicationVersionIdTopicsPost>>
>;
export type GetTopicsApiV1ApplicationVersionsApplicationVersionIdTopicsPostMutationBody = FilterSchema;
export type GetTopicsApiV1ApplicationVersionsApplicationVersionIdTopicsPostMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Get topics
 */
export const useGetTopicsApiV1ApplicationVersionsApplicationVersionIdTopicsPost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getTopicsApiV1ApplicationVersionsApplicationVersionIdTopicsPost>>,
    TError,
    { applicationVersionId: number; data: FilterSchema },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof getTopicsApiV1ApplicationVersionsApplicationVersionIdTopicsPost>>,
  TError,
  { applicationVersionId: number; data: FilterSchema },
  TContext
> => {
  const mutationOptions = getGetTopicsApiV1ApplicationVersionsApplicationVersionIdTopicsPostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Async operation to start topics calculation
 * @summary Generate Topics
 */
export const generateTopicsApiV1GenerateTopicsPost = (params: GenerateTopicsApiV1GenerateTopicsPostParams) => {
  return customInstance<unknown>({ url: `/api/v1/generate-topics`, method: 'POST', params });
};

export const getGenerateTopicsApiV1GenerateTopicsPostMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof generateTopicsApiV1GenerateTopicsPost>>,
    TError,
    { params: GenerateTopicsApiV1GenerateTopicsPostParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof generateTopicsApiV1GenerateTopicsPost>>,
  TError,
  { params: GenerateTopicsApiV1GenerateTopicsPostParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof generateTopicsApiV1GenerateTopicsPost>>,
    { params: GenerateTopicsApiV1GenerateTopicsPostParams }
  > = props => {
    const { params } = props ?? {};

    return generateTopicsApiV1GenerateTopicsPost(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type GenerateTopicsApiV1GenerateTopicsPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof generateTopicsApiV1GenerateTopicsPost>>
>;

export type GenerateTopicsApiV1GenerateTopicsPostMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Generate Topics
 */
export const useGenerateTopicsApiV1GenerateTopicsPost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof generateTopicsApiV1GenerateTopicsPost>>,
    TError,
    { params: GenerateTopicsApiV1GenerateTopicsPostParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof generateTopicsApiV1GenerateTopicsPost>>,
  TError,
  { params: GenerateTopicsApiV1GenerateTopicsPostParams },
  TContext
> => {
  const mutationOptions = getGenerateTopicsApiV1GenerateTopicsPostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * List custom properties definitions
 * @summary List Custom Props Definitions
 */
export const listCustomPropDefinition = (
  appId: number,
  params?: ListCustomPropDefinitionParams,
  signal?: AbortSignal
) => {
  return customInstance<CustomPropDefinitionSchema[]>({
    url: `/api/v1/applications/${appId}/custom-prop-definitions`,
    method: 'GET',
    params,
    signal
  });
};

export const getListCustomPropDefinitionQueryKey = (appId: number, params?: ListCustomPropDefinitionParams) => {
  return [`/api/v1/applications/${appId}/custom-prop-definitions`, ...(params ? [params] : [])] as const;
};

export const getListCustomPropDefinitionQueryOptions = <
  TData = Awaited<ReturnType<typeof listCustomPropDefinition>>,
  TError = ErrorType<HTTPValidationError>
>(
  appId: number,
  params?: ListCustomPropDefinitionParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof listCustomPropDefinition>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListCustomPropDefinitionQueryKey(appId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listCustomPropDefinition>>> = ({ signal }) =>
    listCustomPropDefinition(appId, params, signal);

  return { queryKey, queryFn, enabled: !!appId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listCustomPropDefinition>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListCustomPropDefinitionQueryResult = NonNullable<Awaited<ReturnType<typeof listCustomPropDefinition>>>;
export type ListCustomPropDefinitionQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary List Custom Props Definitions
 */

export function useListCustomPropDefinition<
  TData = Awaited<ReturnType<typeof listCustomPropDefinition>>,
  TError = ErrorType<HTTPValidationError>
>(
  appId: number,
  params?: ListCustomPropDefinitionParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof listCustomPropDefinition>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getListCustomPropDefinitionQueryOptions(appId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Create custom properties definitions
 * @summary Create Custom Props Definitions
 */
export const createCustomPropDefinition = (appId: number, customPropDefinitionSchema: CustomPropDefinitionSchema[]) => {
  return customInstance<unknown>({
    url: `/api/v1/applications/${appId}/custom-prop-definitions`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: customPropDefinitionSchema
  });
};

export const getCreateCustomPropDefinitionMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCustomPropDefinition>>,
    TError,
    { appId: number; data: CustomPropDefinitionSchema[] },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createCustomPropDefinition>>,
  TError,
  { appId: number; data: CustomPropDefinitionSchema[] },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createCustomPropDefinition>>,
    { appId: number; data: CustomPropDefinitionSchema[] }
  > = props => {
    const { appId, data } = props ?? {};

    return createCustomPropDefinition(appId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateCustomPropDefinitionMutationResult = NonNullable<
  Awaited<ReturnType<typeof createCustomPropDefinition>>
>;
export type CreateCustomPropDefinitionMutationBody = CustomPropDefinitionSchema[];
export type CreateCustomPropDefinitionMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Create Custom Props Definitions
 */
export const useCreateCustomPropDefinition = <TError = ErrorType<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCustomPropDefinition>>,
    TError,
    { appId: number; data: CustomPropDefinitionSchema[] },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createCustomPropDefinition>>,
  TError,
  { appId: number; data: CustomPropDefinitionSchema[] },
  TContext
> => {
  const mutationOptions = getCreateCustomPropDefinitionMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Update custom properties display names
 * @summary Update Custom Props Definitions
 */
export const updateCustomPropDefinition = (
  appId: number,
  updateCustomPropDefinitionSchema: UpdateCustomPropDefinitionSchema[]
) => {
  return customInstance<unknown>({
    url: `/api/v1/applications/${appId}/custom-prop-definitions`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: updateCustomPropDefinitionSchema
  });
};

export const getUpdateCustomPropDefinitionMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCustomPropDefinition>>,
    TError,
    { appId: number; data: UpdateCustomPropDefinitionSchema[] },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateCustomPropDefinition>>,
  TError,
  { appId: number; data: UpdateCustomPropDefinitionSchema[] },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateCustomPropDefinition>>,
    { appId: number; data: UpdateCustomPropDefinitionSchema[] }
  > = props => {
    const { appId, data } = props ?? {};

    return updateCustomPropDefinition(appId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateCustomPropDefinitionMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateCustomPropDefinition>>
>;
export type UpdateCustomPropDefinitionMutationBody = UpdateCustomPropDefinitionSchema[];
export type UpdateCustomPropDefinitionMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Update Custom Props Definitions
 */
export const useUpdateCustomPropDefinition = <TError = ErrorType<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCustomPropDefinition>>,
    TError,
    { appId: number; data: UpdateCustomPropDefinitionSchema[] },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateCustomPropDefinition>>,
  TError,
  { appId: number; data: UpdateCustomPropDefinitionSchema[] },
  TContext
> => {
  const mutationOptions = getUpdateCustomPropDefinitionMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Delete custom properties definitions
 * @summary Delete Custom Props Definitions
 */
export const deleteCustomPropDefinition = (appId: number, params: DeleteCustomPropDefinitionParams) => {
  return customInstance<unknown>({
    url: `/api/v1/applications/${appId}/custom-prop-definitions`,
    method: 'DELETE',
    params
  });
};

export const getDeleteCustomPropDefinitionMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCustomPropDefinition>>,
    TError,
    { appId: number; params: DeleteCustomPropDefinitionParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteCustomPropDefinition>>,
  TError,
  { appId: number; params: DeleteCustomPropDefinitionParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteCustomPropDefinition>>,
    { appId: number; params: DeleteCustomPropDefinitionParams }
  > = props => {
    const { appId, params } = props ?? {};

    return deleteCustomPropDefinition(appId, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteCustomPropDefinitionMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteCustomPropDefinition>>
>;

export type DeleteCustomPropDefinitionMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Delete Custom Props Definitions
 */
export const useDeleteCustomPropDefinition = <TError = ErrorType<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCustomPropDefinition>>,
    TError,
    { appId: number; params: DeleteCustomPropDefinitionParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteCustomPropDefinition>>,
  TError,
  { appId: number; params: DeleteCustomPropDefinitionParams },
  TContext
> => {
  const mutationOptions = getDeleteCustomPropDefinitionMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * List llm properties definitions
 * @summary List Llm Props Definitions
 */
export const listLlmPropsDefinitions = (
  appId: number,
  params?: ListLlmPropsDefinitionsParams,
  signal?: AbortSignal
) => {
  return customInstance<OrderedLlmPropertySchema[]>({
    url: `/api/v1/applications/${appId}/llm-prop-definitions`,
    method: 'GET',
    params,
    signal
  });
};

export const getListLlmPropsDefinitionsQueryKey = (appId: number, params?: ListLlmPropsDefinitionsParams) => {
  return [`/api/v1/applications/${appId}/llm-prop-definitions`, ...(params ? [params] : [])] as const;
};

export const getListLlmPropsDefinitionsQueryOptions = <
  TData = Awaited<ReturnType<typeof listLlmPropsDefinitions>>,
  TError = ErrorType<HTTPValidationError>
>(
  appId: number,
  params?: ListLlmPropsDefinitionsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof listLlmPropsDefinitions>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListLlmPropsDefinitionsQueryKey(appId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listLlmPropsDefinitions>>> = ({ signal }) =>
    listLlmPropsDefinitions(appId, params, signal);

  return { queryKey, queryFn, enabled: !!appId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listLlmPropsDefinitions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListLlmPropsDefinitionsQueryResult = NonNullable<Awaited<ReturnType<typeof listLlmPropsDefinitions>>>;
export type ListLlmPropsDefinitionsQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary List Llm Props Definitions
 */

export function useListLlmPropsDefinitions<
  TData = Awaited<ReturnType<typeof listLlmPropsDefinitions>>,
  TError = ErrorType<HTTPValidationError>
>(
  appId: number,
  params?: ListLlmPropsDefinitionsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof listLlmPropsDefinitions>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getListLlmPropsDefinitionsQueryOptions(appId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Create llm properties definitions
 * @summary Create Llm Prop Definition
 */
export const createLlmPropsDefinitions = (
  appId: number,
  bodyCreateLlmPropsDefinitions: BodyCreateLlmPropsDefinitions
) => {
  const formData = new FormData();
  if (bodyCreateLlmPropsDefinitions.description !== undefined && bodyCreateLlmPropsDefinitions.description !== null) {
    formData.append('description', bodyCreateLlmPropsDefinitions.description);
  }
  formData.append('name', bodyCreateLlmPropsDefinitions.name);
  bodyCreateLlmPropsDefinitions.pipeline_elements_to_use.forEach(value =>
    formData.append('pipeline_elements_to_use', value)
  );
  formData.append('score_steps', bodyCreateLlmPropsDefinitions.score_steps);
  if (
    bodyCreateLlmPropsDefinitions.interaction_type !== undefined &&
    bodyCreateLlmPropsDefinitions.interaction_type !== null
  ) {
    formData.append('interaction_type', bodyCreateLlmPropsDefinitions.interaction_type);
  }
  if (
    bodyCreateLlmPropsDefinitions.few_shot_file !== undefined &&
    bodyCreateLlmPropsDefinitions.few_shot_file !== null
  ) {
    formData.append('few_shot_file', bodyCreateLlmPropsDefinitions.few_shot_file);
  }

  return customInstance<unknown>({
    url: `/api/v1/applications/${appId}/llm-prop-definitions`,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData
  });
};

export const getCreateLlmPropsDefinitionsMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createLlmPropsDefinitions>>,
    TError,
    { appId: number; data: BodyCreateLlmPropsDefinitions },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createLlmPropsDefinitions>>,
  TError,
  { appId: number; data: BodyCreateLlmPropsDefinitions },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createLlmPropsDefinitions>>,
    { appId: number; data: BodyCreateLlmPropsDefinitions }
  > = props => {
    const { appId, data } = props ?? {};

    return createLlmPropsDefinitions(appId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateLlmPropsDefinitionsMutationResult = NonNullable<
  Awaited<ReturnType<typeof createLlmPropsDefinitions>>
>;
export type CreateLlmPropsDefinitionsMutationBody = BodyCreateLlmPropsDefinitions;
export type CreateLlmPropsDefinitionsMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Create Llm Prop Definition
 */
export const useCreateLlmPropsDefinitions = <TError = ErrorType<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createLlmPropsDefinitions>>,
    TError,
    { appId: number; data: BodyCreateLlmPropsDefinitions },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createLlmPropsDefinitions>>,
  TError,
  { appId: number; data: BodyCreateLlmPropsDefinitions },
  TContext
> => {
  const mutationOptions = getCreateLlmPropsDefinitionsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Update llm properties display names
 * @summary Update Llm Props Definitions
 */
export const updateLlmPropsDefinitions = (
  appId: number,
  updateLLMPropDefinitionSchema: UpdateLLMPropDefinitionSchema[]
) => {
  return customInstance<unknown>({
    url: `/api/v1/applications/${appId}/llm-prop-definitions`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: updateLLMPropDefinitionSchema
  });
};

export const getUpdateLlmPropsDefinitionsMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateLlmPropsDefinitions>>,
    TError,
    { appId: number; data: UpdateLLMPropDefinitionSchema[] },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateLlmPropsDefinitions>>,
  TError,
  { appId: number; data: UpdateLLMPropDefinitionSchema[] },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateLlmPropsDefinitions>>,
    { appId: number; data: UpdateLLMPropDefinitionSchema[] }
  > = props => {
    const { appId, data } = props ?? {};

    return updateLlmPropsDefinitions(appId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateLlmPropsDefinitionsMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateLlmPropsDefinitions>>
>;
export type UpdateLlmPropsDefinitionsMutationBody = UpdateLLMPropDefinitionSchema[];
export type UpdateLlmPropsDefinitionsMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Update Llm Props Definitions
 */
export const useUpdateLlmPropsDefinitions = <TError = ErrorType<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateLlmPropsDefinitions>>,
    TError,
    { appId: number; data: UpdateLLMPropDefinitionSchema[] },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateLlmPropsDefinitions>>,
  TError,
  { appId: number; data: UpdateLLMPropDefinitionSchema[] },
  TContext
> => {
  const mutationOptions = getUpdateLlmPropsDefinitionsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Delete llm properties definitions
 * @summary Delete Llm Props Definitions
 */
export const deleteLlmPropsDefinitions = (appId: number, params: DeleteLlmPropsDefinitionsParams) => {
  return customInstance<unknown>({
    url: `/api/v1/applications/${appId}/llm-prop-definitions`,
    method: 'DELETE',
    params
  });
};

export const getDeleteLlmPropsDefinitionsMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteLlmPropsDefinitions>>,
    TError,
    { appId: number; params: DeleteLlmPropsDefinitionsParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteLlmPropsDefinitions>>,
  TError,
  { appId: number; params: DeleteLlmPropsDefinitionsParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteLlmPropsDefinitions>>,
    { appId: number; params: DeleteLlmPropsDefinitionsParams }
  > = props => {
    const { appId, params } = props ?? {};

    return deleteLlmPropsDefinitions(appId, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteLlmPropsDefinitionsMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteLlmPropsDefinitions>>
>;

export type DeleteLlmPropsDefinitionsMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Delete Llm Props Definitions
 */
export const useDeleteLlmPropsDefinitions = <TError = ErrorType<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteLlmPropsDefinitions>>,
    TError,
    { appId: number; params: DeleteLlmPropsDefinitionsParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteLlmPropsDefinitions>>,
  TError,
  { appId: number; params: DeleteLlmPropsDefinitionsParams },
  TContext
> => {
  const mutationOptions = getDeleteLlmPropsDefinitionsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get Llm Prop Few Shot
 */
export const getLlmPropFewShotApiV1LlmPropDefinitionsLlmPropIdFewShotGet = (
  llmPropId: number,
  signal?: AbortSignal
) => {
  return customInstance<unknown>({ url: `/api/v1/llm-prop-definitions/${llmPropId}/few-shot`, method: 'GET', signal });
};

export const getGetLlmPropFewShotApiV1LlmPropDefinitionsLlmPropIdFewShotGetQueryKey = (llmPropId: number) => {
  return [`/api/v1/llm-prop-definitions/${llmPropId}/few-shot`] as const;
};

export const getGetLlmPropFewShotApiV1LlmPropDefinitionsLlmPropIdFewShotGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getLlmPropFewShotApiV1LlmPropDefinitionsLlmPropIdFewShotGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  llmPropId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLlmPropFewShotApiV1LlmPropDefinitionsLlmPropIdFewShotGet>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetLlmPropFewShotApiV1LlmPropDefinitionsLlmPropIdFewShotGetQueryKey(llmPropId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getLlmPropFewShotApiV1LlmPropDefinitionsLlmPropIdFewShotGet>>
  > = ({ signal }) => getLlmPropFewShotApiV1LlmPropDefinitionsLlmPropIdFewShotGet(llmPropId, signal);

  return { queryKey, queryFn, enabled: !!llmPropId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getLlmPropFewShotApiV1LlmPropDefinitionsLlmPropIdFewShotGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetLlmPropFewShotApiV1LlmPropDefinitionsLlmPropIdFewShotGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLlmPropFewShotApiV1LlmPropDefinitionsLlmPropIdFewShotGet>>
>;
export type GetLlmPropFewShotApiV1LlmPropDefinitionsLlmPropIdFewShotGetQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Llm Prop Few Shot
 */

export function useGetLlmPropFewShotApiV1LlmPropDefinitionsLlmPropIdFewShotGet<
  TData = Awaited<ReturnType<typeof getLlmPropFewShotApiV1LlmPropDefinitionsLlmPropIdFewShotGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  llmPropId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLlmPropFewShotApiV1LlmPropDefinitionsLlmPropIdFewShotGet>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetLlmPropFewShotApiV1LlmPropDefinitionsLlmPropIdFewShotGetQueryOptions(llmPropId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Get template for llm test
 * @summary Get Template For Llm Test
 */
export const getTemplateForLlmTestApiV1TemplateForLlmTestGet = (signal?: AbortSignal) => {
  return customInstance<string>({ url: `/api/v1/template-for-llm-test`, method: 'GET', signal });
};

export const getGetTemplateForLlmTestApiV1TemplateForLlmTestGetQueryKey = () => {
  return [`/api/v1/template-for-llm-test`] as const;
};

export const getGetTemplateForLlmTestApiV1TemplateForLlmTestGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getTemplateForLlmTestApiV1TemplateForLlmTestGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getTemplateForLlmTestApiV1TemplateForLlmTestGet>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTemplateForLlmTestApiV1TemplateForLlmTestGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTemplateForLlmTestApiV1TemplateForLlmTestGet>>> = ({
    signal
  }) => getTemplateForLlmTestApiV1TemplateForLlmTestGet(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTemplateForLlmTestApiV1TemplateForLlmTestGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTemplateForLlmTestApiV1TemplateForLlmTestGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTemplateForLlmTestApiV1TemplateForLlmTestGet>>
>;
export type GetTemplateForLlmTestApiV1TemplateForLlmTestGetQueryError = ErrorType<unknown>;

/**
 * @summary Get Template For Llm Test
 */

export function useGetTemplateForLlmTestApiV1TemplateForLlmTestGet<
  TData = Awaited<ReturnType<typeof getTemplateForLlmTestApiV1TemplateForLlmTestGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getTemplateForLlmTestApiV1TemplateForLlmTestGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetTemplateForLlmTestApiV1TemplateForLlmTestGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Get user interaction id and version for running llm property
 * @summary Get Test Run Interaction Id
 */
export const getTestRunInteractionIdApiV1ApplicationsAppIdInteractionForLlmTestGet = (
  appId: number,
  params: GetTestRunInteractionIdApiV1ApplicationsAppIdInteractionForLlmTestGetParams,
  signal?: AbortSignal
) => {
  return customInstance<LlmRunInteraction[]>({
    url: `/api/v1/applications/${appId}/interaction-for-llm-test`,
    method: 'GET',
    params,
    signal
  });
};

export const getGetTestRunInteractionIdApiV1ApplicationsAppIdInteractionForLlmTestGetQueryKey = (
  appId: number,
  params: GetTestRunInteractionIdApiV1ApplicationsAppIdInteractionForLlmTestGetParams
) => {
  return [`/api/v1/applications/${appId}/interaction-for-llm-test`, ...(params ? [params] : [])] as const;
};

export const getGetTestRunInteractionIdApiV1ApplicationsAppIdInteractionForLlmTestGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getTestRunInteractionIdApiV1ApplicationsAppIdInteractionForLlmTestGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  appId: number,
  params: GetTestRunInteractionIdApiV1ApplicationsAppIdInteractionForLlmTestGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getTestRunInteractionIdApiV1ApplicationsAppIdInteractionForLlmTestGet>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetTestRunInteractionIdApiV1ApplicationsAppIdInteractionForLlmTestGetQueryKey(appId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getTestRunInteractionIdApiV1ApplicationsAppIdInteractionForLlmTestGet>>
  > = ({ signal }) => getTestRunInteractionIdApiV1ApplicationsAppIdInteractionForLlmTestGet(appId, params, signal);

  return { queryKey, queryFn, enabled: !!appId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTestRunInteractionIdApiV1ApplicationsAppIdInteractionForLlmTestGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTestRunInteractionIdApiV1ApplicationsAppIdInteractionForLlmTestGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTestRunInteractionIdApiV1ApplicationsAppIdInteractionForLlmTestGet>>
>;
export type GetTestRunInteractionIdApiV1ApplicationsAppIdInteractionForLlmTestGetQueryError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Get Test Run Interaction Id
 */

export function useGetTestRunInteractionIdApiV1ApplicationsAppIdInteractionForLlmTestGet<
  TData = Awaited<ReturnType<typeof getTestRunInteractionIdApiV1ApplicationsAppIdInteractionForLlmTestGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  appId: number,
  params: GetTestRunInteractionIdApiV1ApplicationsAppIdInteractionForLlmTestGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getTestRunInteractionIdApiV1ApplicationsAppIdInteractionForLlmTestGet>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetTestRunInteractionIdApiV1ApplicationsAppIdInteractionForLlmTestGetQueryOptions(
    appId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Test run llm property
 * @summary Test Run Llm Prop
 */
export const testRunLlmPropApiV1ApplicationsApplicationVersionIdLlmPropTestRunUserInteractionIdPost = (
  applicationVersionId: number,
  userInteractionId: string,
  bodyTestRunLlmPropApiV1ApplicationsApplicationVersionIdLlmPropTestRunUserInteractionIdPost: BodyTestRunLlmPropApiV1ApplicationsApplicationVersionIdLlmPropTestRunUserInteractionIdPost
) => {
  const formData = new FormData();
  if (
    bodyTestRunLlmPropApiV1ApplicationsApplicationVersionIdLlmPropTestRunUserInteractionIdPost.description !==
      undefined &&
    bodyTestRunLlmPropApiV1ApplicationsApplicationVersionIdLlmPropTestRunUserInteractionIdPost.description !== null
  ) {
    formData.append(
      'description',
      bodyTestRunLlmPropApiV1ApplicationsApplicationVersionIdLlmPropTestRunUserInteractionIdPost.description
    );
  }
  formData.append(
    'name',
    bodyTestRunLlmPropApiV1ApplicationsApplicationVersionIdLlmPropTestRunUserInteractionIdPost.name
  );
  bodyTestRunLlmPropApiV1ApplicationsApplicationVersionIdLlmPropTestRunUserInteractionIdPost.pipeline_elements_to_use.forEach(
    value => formData.append('pipeline_elements_to_use', value)
  );
  formData.append(
    'score_steps',
    bodyTestRunLlmPropApiV1ApplicationsApplicationVersionIdLlmPropTestRunUserInteractionIdPost.score_steps
  );
  if (
    bodyTestRunLlmPropApiV1ApplicationsApplicationVersionIdLlmPropTestRunUserInteractionIdPost.few_shot_file !==
      undefined &&
    bodyTestRunLlmPropApiV1ApplicationsApplicationVersionIdLlmPropTestRunUserInteractionIdPost.few_shot_file !== null
  ) {
    formData.append(
      'few_shot_file',
      bodyTestRunLlmPropApiV1ApplicationsApplicationVersionIdLlmPropTestRunUserInteractionIdPost.few_shot_file
    );
  }

  return customInstance<LlmRunResult>({
    url: `/api/v1/applications/${applicationVersionId}/llm-prop-test-run/${userInteractionId}`,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData
  });
};

export const getTestRunLlmPropApiV1ApplicationsApplicationVersionIdLlmPropTestRunUserInteractionIdPostMutationOptions =
  <TError = ErrorType<HTTPValidationError>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof testRunLlmPropApiV1ApplicationsApplicationVersionIdLlmPropTestRunUserInteractionIdPost>
      >,
      TError,
      {
        applicationVersionId: number;
        userInteractionId: string;
        data: BodyTestRunLlmPropApiV1ApplicationsApplicationVersionIdLlmPropTestRunUserInteractionIdPost;
      },
      TContext
    >;
  }): UseMutationOptions<
    Awaited<ReturnType<typeof testRunLlmPropApiV1ApplicationsApplicationVersionIdLlmPropTestRunUserInteractionIdPost>>,
    TError,
    {
      applicationVersionId: number;
      userInteractionId: string;
      data: BodyTestRunLlmPropApiV1ApplicationsApplicationVersionIdLlmPropTestRunUserInteractionIdPost;
    },
    TContext
  > => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof testRunLlmPropApiV1ApplicationsApplicationVersionIdLlmPropTestRunUserInteractionIdPost>
      >,
      {
        applicationVersionId: number;
        userInteractionId: string;
        data: BodyTestRunLlmPropApiV1ApplicationsApplicationVersionIdLlmPropTestRunUserInteractionIdPost;
      }
    > = props => {
      const { applicationVersionId, userInteractionId, data } = props ?? {};

      return testRunLlmPropApiV1ApplicationsApplicationVersionIdLlmPropTestRunUserInteractionIdPost(
        applicationVersionId,
        userInteractionId,
        data
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type TestRunLlmPropApiV1ApplicationsApplicationVersionIdLlmPropTestRunUserInteractionIdPostMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof testRunLlmPropApiV1ApplicationsApplicationVersionIdLlmPropTestRunUserInteractionIdPost>>
  >;
export type TestRunLlmPropApiV1ApplicationsApplicationVersionIdLlmPropTestRunUserInteractionIdPostMutationBody =
  BodyTestRunLlmPropApiV1ApplicationsApplicationVersionIdLlmPropTestRunUserInteractionIdPost;
export type TestRunLlmPropApiV1ApplicationsApplicationVersionIdLlmPropTestRunUserInteractionIdPostMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Test Run Llm Prop
 */
export const useTestRunLlmPropApiV1ApplicationsApplicationVersionIdLlmPropTestRunUserInteractionIdPost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof testRunLlmPropApiV1ApplicationsApplicationVersionIdLlmPropTestRunUserInteractionIdPost>>,
    TError,
    {
      applicationVersionId: number;
      userInteractionId: string;
      data: BodyTestRunLlmPropApiV1ApplicationsApplicationVersionIdLlmPropTestRunUserInteractionIdPost;
    },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof testRunLlmPropApiV1ApplicationsApplicationVersionIdLlmPropTestRunUserInteractionIdPost>>,
  TError,
  {
    applicationVersionId: number;
    userInteractionId: string;
    data: BodyTestRunLlmPropApiV1ApplicationsApplicationVersionIdLlmPropTestRunUserInteractionIdPost;
  },
  TContext
> => {
  const mutationOptions =
    getTestRunLlmPropApiV1ApplicationsApplicationVersionIdLlmPropTestRunUserInteractionIdPostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * List llm bank properties
 * @summary List Llm Bank Props
 */
export const listLlmBankProps = (params: ListLlmBankPropsParams, signal?: AbortSignal) => {
  return customInstance<LlmPropertySchema[]>({ url: `/api/v1/llm-bank-properties`, method: 'GET', params, signal });
};

export const getListLlmBankPropsQueryKey = (params: ListLlmBankPropsParams) => {
  return [`/api/v1/llm-bank-properties`, ...(params ? [params] : [])] as const;
};

export const getListLlmBankPropsQueryOptions = <
  TData = Awaited<ReturnType<typeof listLlmBankProps>>,
  TError = ErrorType<HTTPValidationError>
>(
  params: ListLlmBankPropsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof listLlmBankProps>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListLlmBankPropsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listLlmBankProps>>> = ({ signal }) =>
    listLlmBankProps(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listLlmBankProps>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListLlmBankPropsQueryResult = NonNullable<Awaited<ReturnType<typeof listLlmBankProps>>>;
export type ListLlmBankPropsQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary List Llm Bank Props
 */

export function useListLlmBankProps<
  TData = Awaited<ReturnType<typeof listLlmBankProps>>,
  TError = ErrorType<HTTPValidationError>
>(
  params: ListLlmBankPropsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof listLlmBankProps>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getListLlmBankPropsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * List application logs
 * @summary List Application Logs
 */
export const listAppLogs = (appId: number, params?: ListAppLogsParams, signal?: AbortSignal) => {
  return customInstance<ApplicationLogSchema[]>({
    url: `/api/v1/applications/${appId}/logs`,
    method: 'GET',
    params,
    signal
  });
};

export const getListAppLogsQueryKey = (appId: number, params?: ListAppLogsParams) => {
  return [`/api/v1/applications/${appId}/logs`, ...(params ? [params] : [])] as const;
};

export const getListAppLogsQueryOptions = <
  TData = Awaited<ReturnType<typeof listAppLogs>>,
  TError = ErrorType<HTTPValidationError>
>(
  appId: number,
  params?: ListAppLogsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof listAppLogs>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListAppLogsQueryKey(appId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listAppLogs>>> = ({ signal }) =>
    listAppLogs(appId, params, signal);

  return { queryKey, queryFn, enabled: !!appId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listAppLogs>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListAppLogsQueryResult = NonNullable<Awaited<ReturnType<typeof listAppLogs>>>;
export type ListAppLogsQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary List Application Logs
 */

export function useListAppLogs<
  TData = Awaited<ReturnType<typeof listAppLogs>>,
  TError = ErrorType<HTTPValidationError>
>(
  appId: number,
  params?: ListAppLogsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof listAppLogs>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getListAppLogsQueryOptions(appId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Get similar versions for the given application id and user interaction id.
 * @summary Get Similar
 */
export const getSimilarApiV1ApplicationVersionsComparisonPut = (
  params: GetSimilarApiV1ApplicationVersionsComparisonPutParams
) => {
  return customInstance<ApplicationVersionSchema[]>({
    url: `/api/v1/application-versions/comparison`,
    method: 'PUT',
    params
  });
};

export const getGetSimilarApiV1ApplicationVersionsComparisonPutMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getSimilarApiV1ApplicationVersionsComparisonPut>>,
    TError,
    { params: GetSimilarApiV1ApplicationVersionsComparisonPutParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof getSimilarApiV1ApplicationVersionsComparisonPut>>,
  TError,
  { params: GetSimilarApiV1ApplicationVersionsComparisonPutParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getSimilarApiV1ApplicationVersionsComparisonPut>>,
    { params: GetSimilarApiV1ApplicationVersionsComparisonPutParams }
  > = props => {
    const { params } = props ?? {};

    return getSimilarApiV1ApplicationVersionsComparisonPut(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetSimilarApiV1ApplicationVersionsComparisonPutMutationResult = NonNullable<
  Awaited<ReturnType<typeof getSimilarApiV1ApplicationVersionsComparisonPut>>
>;

export type GetSimilarApiV1ApplicationVersionsComparisonPutMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Similar
 */
export const useGetSimilarApiV1ApplicationVersionsComparisonPut = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getSimilarApiV1ApplicationVersionsComparisonPut>>,
    TError,
    { params: GetSimilarApiV1ApplicationVersionsComparisonPutParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof getSimilarApiV1ApplicationVersionsComparisonPut>>,
  TError,
  { params: GetSimilarApiV1ApplicationVersionsComparisonPutParams },
  TContext
> => {
  const mutationOptions = getGetSimilarApiV1ApplicationVersionsComparisonPutMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Endpoint that will return interactions that are dissimilar between 2 versions in respect to a property/annotation/similarity score
 * @summary Interactions Comparison
 */
export const interactionsComparisonApiV1ApplicationVersionsInteractionComparisonPost = (
  bodyInteractionsComparisonApiV1ApplicationVersionsInteractionComparisonPost: BodyInteractionsComparisonApiV1ApplicationVersionsInteractionComparisonPost
) => {
  return customInstance<InteractionsComparisonResponse>({
    url: `/api/v1/application-versions/interaction-comparison`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: bodyInteractionsComparisonApiV1ApplicationVersionsInteractionComparisonPost
  });
};

export const getInteractionsComparisonApiV1ApplicationVersionsInteractionComparisonPostMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof interactionsComparisonApiV1ApplicationVersionsInteractionComparisonPost>>,
    TError,
    { data: BodyInteractionsComparisonApiV1ApplicationVersionsInteractionComparisonPost },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof interactionsComparisonApiV1ApplicationVersionsInteractionComparisonPost>>,
  TError,
  { data: BodyInteractionsComparisonApiV1ApplicationVersionsInteractionComparisonPost },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof interactionsComparisonApiV1ApplicationVersionsInteractionComparisonPost>>,
    { data: BodyInteractionsComparisonApiV1ApplicationVersionsInteractionComparisonPost }
  > = props => {
    const { data } = props ?? {};

    return interactionsComparisonApiV1ApplicationVersionsInteractionComparisonPost(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type InteractionsComparisonApiV1ApplicationVersionsInteractionComparisonPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof interactionsComparisonApiV1ApplicationVersionsInteractionComparisonPost>>
>;
export type InteractionsComparisonApiV1ApplicationVersionsInteractionComparisonPostMutationBody =
  BodyInteractionsComparisonApiV1ApplicationVersionsInteractionComparisonPost;
export type InteractionsComparisonApiV1ApplicationVersionsInteractionComparisonPostMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Interactions Comparison
 */
export const useInteractionsComparisonApiV1ApplicationVersionsInteractionComparisonPost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof interactionsComparisonApiV1ApplicationVersionsInteractionComparisonPost>>,
    TError,
    { data: BodyInteractionsComparisonApiV1ApplicationVersionsInteractionComparisonPost },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof interactionsComparisonApiV1ApplicationVersionsInteractionComparisonPost>>,
  TError,
  { data: BodyInteractionsComparisonApiV1ApplicationVersionsInteractionComparisonPost },
  TContext
> => {
  const mutationOptions =
    getInteractionsComparisonApiV1ApplicationVersionsInteractionComparisonPostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get Ui Preferences
 */
export const getUiPreferencesApiV1UiPreferencesGet = (signal?: AbortSignal) => {
  return customInstance<GetUiPreferencesApiV1UiPreferencesGet200>({
    url: `/api/v1/ui-preferences`,
    method: 'GET',
    signal
  });
};

export const getGetUiPreferencesApiV1UiPreferencesGetQueryKey = () => {
  return [`/api/v1/ui-preferences`] as const;
};

export const getGetUiPreferencesApiV1UiPreferencesGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getUiPreferencesApiV1UiPreferencesGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUiPreferencesApiV1UiPreferencesGet>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUiPreferencesApiV1UiPreferencesGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUiPreferencesApiV1UiPreferencesGet>>> = ({ signal }) =>
    getUiPreferencesApiV1UiPreferencesGet(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUiPreferencesApiV1UiPreferencesGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUiPreferencesApiV1UiPreferencesGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUiPreferencesApiV1UiPreferencesGet>>
>;
export type GetUiPreferencesApiV1UiPreferencesGetQueryError = ErrorType<unknown>;

/**
 * @summary Get Ui Preferences
 */

export function useGetUiPreferencesApiV1UiPreferencesGet<
  TData = Awaited<ReturnType<typeof getUiPreferencesApiV1UiPreferencesGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUiPreferencesApiV1UiPreferencesGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetUiPreferencesApiV1UiPreferencesGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update Ui Preferences
 */
export const updateUiPreferencesApiV1UiPreferencesPut = (
  updateUiPreferencesApiV1UiPreferencesPutBody: UpdateUiPreferencesApiV1UiPreferencesPutBody
) => {
  return customInstance<UpdateUiPreferencesApiV1UiPreferencesPut200>({
    url: `/api/v1/ui-preferences`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: updateUiPreferencesApiV1UiPreferencesPutBody
  });
};

export const getUpdateUiPreferencesApiV1UiPreferencesPutMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUiPreferencesApiV1UiPreferencesPut>>,
    TError,
    { data: UpdateUiPreferencesApiV1UiPreferencesPutBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateUiPreferencesApiV1UiPreferencesPut>>,
  TError,
  { data: UpdateUiPreferencesApiV1UiPreferencesPutBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateUiPreferencesApiV1UiPreferencesPut>>,
    { data: UpdateUiPreferencesApiV1UiPreferencesPutBody }
  > = props => {
    const { data } = props ?? {};

    return updateUiPreferencesApiV1UiPreferencesPut(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateUiPreferencesApiV1UiPreferencesPutMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateUiPreferencesApiV1UiPreferencesPut>>
>;
export type UpdateUiPreferencesApiV1UiPreferencesPutMutationBody = UpdateUiPreferencesApiV1UiPreferencesPutBody;
export type UpdateUiPreferencesApiV1UiPreferencesPutMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Update Ui Preferences
 */
export const useUpdateUiPreferencesApiV1UiPreferencesPut = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUiPreferencesApiV1UiPreferencesPut>>,
    TError,
    { data: UpdateUiPreferencesApiV1UiPreferencesPutBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateUiPreferencesApiV1UiPreferencesPut>>,
  TError,
  { data: UpdateUiPreferencesApiV1UiPreferencesPutBody },
  TContext
> => {
  const mutationOptions = getUpdateUiPreferencesApiV1UiPreferencesPutMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Flags used by the UI
 * @summary Get Usage Feature Flags
 */
export const getUsageFeatureFlags = (signal?: AbortSignal) => {
  return customInstance<UsageModel>({ url: `/api/v1/metering/usage-feature-flags`, method: 'GET', signal });
};

export const getGetUsageFeatureFlagsQueryKey = () => {
  return [`/api/v1/metering/usage-feature-flags`] as const;
};

export const getGetUsageFeatureFlagsQueryOptions = <
  TData = Awaited<ReturnType<typeof getUsageFeatureFlags>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUsageFeatureFlags>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUsageFeatureFlagsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsageFeatureFlags>>> = ({ signal }) =>
    getUsageFeatureFlags(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUsageFeatureFlags>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUsageFeatureFlagsQueryResult = NonNullable<Awaited<ReturnType<typeof getUsageFeatureFlags>>>;
export type GetUsageFeatureFlagsQueryError = ErrorType<unknown>;

/**
 * @summary Get Usage Feature Flags
 */

export function useGetUsageFeatureFlags<
  TData = Awaited<ReturnType<typeof getUsageFeatureFlags>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUsageFeatureFlags>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetUsageFeatureFlagsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Retrieves the active usage plan for the current user
 * @summary Get Active Usage Plan
 */
export const getActiveUsagePlan = (signal?: AbortSignal) => {
  return customInstance<GetActiveUsagePlan200>({ url: `/api/v1/metering/usage-plan/active`, method: 'GET', signal });
};

export const getGetActiveUsagePlanQueryKey = () => {
  return [`/api/v1/metering/usage-plan/active`] as const;
};

export const getGetActiveUsagePlanQueryOptions = <
  TData = Awaited<ReturnType<typeof getActiveUsagePlan>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getActiveUsagePlan>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetActiveUsagePlanQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getActiveUsagePlan>>> = ({ signal }) =>
    getActiveUsagePlan(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getActiveUsagePlan>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetActiveUsagePlanQueryResult = NonNullable<Awaited<ReturnType<typeof getActiveUsagePlan>>>;
export type GetActiveUsagePlanQueryError = ErrorType<unknown>;

/**
 * @summary Get Active Usage Plan
 */

export function useGetActiveUsagePlan<
  TData = Awaited<ReturnType<typeof getActiveUsagePlan>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getActiveUsagePlan>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetActiveUsagePlanQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Retrieves all usage plans for the current user in the given period
 * @summary Get Usage Plan
 */
export const getUsagePlan = (params?: GetUsagePlanParams, signal?: AbortSignal) => {
  return customInstance<UserUsagePlanSchema[]>({ url: `/api/v1/metering/usage-plan`, method: 'GET', params, signal });
};

export const getGetUsagePlanQueryKey = (params?: GetUsagePlanParams) => {
  return [`/api/v1/metering/usage-plan`, ...(params ? [params] : [])] as const;
};

export const getGetUsagePlanQueryOptions = <
  TData = Awaited<ReturnType<typeof getUsagePlan>>,
  TError = ErrorType<HTTPValidationError>
>(
  params?: GetUsagePlanParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getUsagePlan>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUsagePlanQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsagePlan>>> = ({ signal }) => getUsagePlan(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUsagePlan>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUsagePlanQueryResult = NonNullable<Awaited<ReturnType<typeof getUsagePlan>>>;
export type GetUsagePlanQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Usage Plan
 */

export function useGetUsagePlan<
  TData = Awaited<ReturnType<typeof getUsagePlan>>,
  TError = ErrorType<HTTPValidationError>
>(
  params?: GetUsagePlanParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getUsagePlan>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetUsagePlanQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Retrieve the bill for a given period
 * @summary Get Usage Bill
 */
export const getUsageBill = (params?: GetUsageBillParams, signal?: AbortSignal) => {
  return customInstance<DeepchecksLlmEvalApiV1MeteringUsageUsageReport>({
    url: `/api/v1/metering/usage/bill`,
    method: 'GET',
    params,
    signal
  });
};

export const getGetUsageBillQueryKey = (params?: GetUsageBillParams) => {
  return [`/api/v1/metering/usage/bill`, ...(params ? [params] : [])] as const;
};

export const getGetUsageBillQueryOptions = <
  TData = Awaited<ReturnType<typeof getUsageBill>>,
  TError = ErrorType<HTTPValidationError>
>(
  params?: GetUsageBillParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getUsageBill>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUsageBillQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsageBill>>> = ({ signal }) => getUsageBill(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUsageBill>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUsageBillQueryResult = NonNullable<Awaited<ReturnType<typeof getUsageBill>>>;
export type GetUsageBillQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Usage Bill
 */

export function useGetUsageBill<
  TData = Awaited<ReturnType<typeof getUsageBill>>,
  TError = ErrorType<HTTPValidationError>
>(
  params?: GetUsageBillParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getUsageBill>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetUsageBillQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Retrieve the tokens usage for a given period by app by env
 * @summary Get Used Tokens
 */
export const getUsedTokens = (params?: GetUsedTokensParams, signal?: AbortSignal) => {
  return customInstance<AppTokensUsageReport[]>({
    url: `/api/v1/metering/usage/tokens`,
    method: 'GET',
    params,
    signal
  });
};

export const getGetUsedTokensQueryKey = (params?: GetUsedTokensParams) => {
  return [`/api/v1/metering/usage/tokens`, ...(params ? [params] : [])] as const;
};

export const getGetUsedTokensQueryOptions = <
  TData = Awaited<ReturnType<typeof getUsedTokens>>,
  TError = ErrorType<HTTPValidationError>
>(
  params?: GetUsedTokensParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getUsedTokens>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUsedTokensQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsedTokens>>> = ({ signal }) =>
    getUsedTokens(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUsedTokens>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUsedTokensQueryResult = NonNullable<Awaited<ReturnType<typeof getUsedTokens>>>;
export type GetUsedTokensQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Used Tokens
 */

export function useGetUsedTokens<
  TData = Awaited<ReturnType<typeof getUsedTokens>>,
  TError = ErrorType<HTTPValidationError>
>(
  params?: GetUsedTokensParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getUsedTokens>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetUsedTokensQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Set datadog user integration configuration
 * @summary Set Datadog User Config
 */
export const setDatadogUserConfigApiV1DatadogUserConfigPut = (
  datadogUserConfigData: DatadogUserConfigData,
  params?: SetDatadogUserConfigApiV1DatadogUserConfigPutParams
) => {
  return customInstance<unknown>({
    url: `/api/v1/datadog-user-config`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: datadogUserConfigData,
    params
  });
};

export const getSetDatadogUserConfigApiV1DatadogUserConfigPutMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setDatadogUserConfigApiV1DatadogUserConfigPut>>,
    TError,
    { data: DatadogUserConfigData; params?: SetDatadogUserConfigApiV1DatadogUserConfigPutParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof setDatadogUserConfigApiV1DatadogUserConfigPut>>,
  TError,
  { data: DatadogUserConfigData; params?: SetDatadogUserConfigApiV1DatadogUserConfigPutParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setDatadogUserConfigApiV1DatadogUserConfigPut>>,
    { data: DatadogUserConfigData; params?: SetDatadogUserConfigApiV1DatadogUserConfigPutParams }
  > = props => {
    const { data, params } = props ?? {};

    return setDatadogUserConfigApiV1DatadogUserConfigPut(data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetDatadogUserConfigApiV1DatadogUserConfigPutMutationResult = NonNullable<
  Awaited<ReturnType<typeof setDatadogUserConfigApiV1DatadogUserConfigPut>>
>;
export type SetDatadogUserConfigApiV1DatadogUserConfigPutMutationBody = DatadogUserConfigData;
export type SetDatadogUserConfigApiV1DatadogUserConfigPutMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Set Datadog User Config
 */
export const useSetDatadogUserConfigApiV1DatadogUserConfigPut = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setDatadogUserConfigApiV1DatadogUserConfigPut>>,
    TError,
    { data: DatadogUserConfigData; params?: SetDatadogUserConfigApiV1DatadogUserConfigPutParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof setDatadogUserConfigApiV1DatadogUserConfigPut>>,
  TError,
  { data: DatadogUserConfigData; params?: SetDatadogUserConfigApiV1DatadogUserConfigPutParams },
  TContext
> => {
  const mutationOptions = getSetDatadogUserConfigApiV1DatadogUserConfigPutMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Get datadog integration configuration
 * @summary Get Config
 */
export const getConfigApiV1DatadogConfigGet = (params?: GetConfigApiV1DatadogConfigGetParams, signal?: AbortSignal) => {
  return customInstance<DatadogConfigData>({ url: `/api/v1/datadog-config`, method: 'GET', params, signal });
};

export const getGetConfigApiV1DatadogConfigGetQueryKey = (params?: GetConfigApiV1DatadogConfigGetParams) => {
  return [`/api/v1/datadog-config`, ...(params ? [params] : [])] as const;
};

export const getGetConfigApiV1DatadogConfigGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getConfigApiV1DatadogConfigGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  params?: GetConfigApiV1DatadogConfigGetParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getConfigApiV1DatadogConfigGet>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetConfigApiV1DatadogConfigGetQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getConfigApiV1DatadogConfigGet>>> = ({ signal }) =>
    getConfigApiV1DatadogConfigGet(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getConfigApiV1DatadogConfigGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetConfigApiV1DatadogConfigGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getConfigApiV1DatadogConfigGet>>
>;
export type GetConfigApiV1DatadogConfigGetQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Config
 */

export function useGetConfigApiV1DatadogConfigGet<
  TData = Awaited<ReturnType<typeof getConfigApiV1DatadogConfigGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  params?: GetConfigApiV1DatadogConfigGetParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getConfigApiV1DatadogConfigGet>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetConfigApiV1DatadogConfigGetQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Pentest Config
 */
export const getPentestConfigApiV1PentestConfigGet = (signal?: AbortSignal) => {
  return customInstance<PentestModel>({ url: `/api/v1/pentest-config`, method: 'GET', signal });
};

export const getGetPentestConfigApiV1PentestConfigGetQueryKey = () => {
  return [`/api/v1/pentest-config`] as const;
};

export const getGetPentestConfigApiV1PentestConfigGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getPentestConfigApiV1PentestConfigGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getPentestConfigApiV1PentestConfigGet>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPentestConfigApiV1PentestConfigGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPentestConfigApiV1PentestConfigGet>>> = ({ signal }) =>
    getPentestConfigApiV1PentestConfigGet(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPentestConfigApiV1PentestConfigGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPentestConfigApiV1PentestConfigGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPentestConfigApiV1PentestConfigGet>>
>;
export type GetPentestConfigApiV1PentestConfigGetQueryError = ErrorType<unknown>;

/**
 * @summary Get Pentest Config
 */

export function useGetPentestConfigApiV1PentestConfigGet<
  TData = Awaited<ReturnType<typeof getPentestConfigApiV1PentestConfigGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getPentestConfigApiV1PentestConfigGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetPentestConfigApiV1PentestConfigGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Get default time range for the application version and env type, time range is set to the time of the last interaction in the system minus3 weeks
 * @summary Get Default Time Range
 */
export const getDefaultTimeRangeApiV1ApplicationVersionsApplicationVersionIdDefaultTimeRangeGet = (
  applicationVersionId: number,
  params: GetDefaultTimeRangeApiV1ApplicationVersionsApplicationVersionIdDefaultTimeRangeGetParams,
  signal?: AbortSignal
) => {
  return customInstance<DefaultTimeRangeSchema>({
    url: `/api/v1/application-versions/${applicationVersionId}/default-time-range`,
    method: 'GET',
    params,
    signal
  });
};

export const getGetDefaultTimeRangeApiV1ApplicationVersionsApplicationVersionIdDefaultTimeRangeGetQueryKey = (
  applicationVersionId: number,
  params: GetDefaultTimeRangeApiV1ApplicationVersionsApplicationVersionIdDefaultTimeRangeGetParams
) => {
  return [
    `/api/v1/application-versions/${applicationVersionId}/default-time-range`,
    ...(params ? [params] : [])
  ] as const;
};

export const getGetDefaultTimeRangeApiV1ApplicationVersionsApplicationVersionIdDefaultTimeRangeGetQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getDefaultTimeRangeApiV1ApplicationVersionsApplicationVersionIdDefaultTimeRangeGet>
  >,
  TError = ErrorType<HTTPValidationError>
>(
  applicationVersionId: number,
  params: GetDefaultTimeRangeApiV1ApplicationVersionsApplicationVersionIdDefaultTimeRangeGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDefaultTimeRangeApiV1ApplicationVersionsApplicationVersionIdDefaultTimeRangeGet>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDefaultTimeRangeApiV1ApplicationVersionsApplicationVersionIdDefaultTimeRangeGetQueryKey(
      applicationVersionId,
      params
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getDefaultTimeRangeApiV1ApplicationVersionsApplicationVersionIdDefaultTimeRangeGet>>
  > = ({ signal }) =>
    getDefaultTimeRangeApiV1ApplicationVersionsApplicationVersionIdDefaultTimeRangeGet(
      applicationVersionId,
      params,
      signal
    );

  return { queryKey, queryFn, enabled: !!applicationVersionId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getDefaultTimeRangeApiV1ApplicationVersionsApplicationVersionIdDefaultTimeRangeGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetDefaultTimeRangeApiV1ApplicationVersionsApplicationVersionIdDefaultTimeRangeGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDefaultTimeRangeApiV1ApplicationVersionsApplicationVersionIdDefaultTimeRangeGet>>
>;
export type GetDefaultTimeRangeApiV1ApplicationVersionsApplicationVersionIdDefaultTimeRangeGetQueryError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Get Default Time Range
 */

export function useGetDefaultTimeRangeApiV1ApplicationVersionsApplicationVersionIdDefaultTimeRangeGet<
  TData = Awaited<
    ReturnType<typeof getDefaultTimeRangeApiV1ApplicationVersionsApplicationVersionIdDefaultTimeRangeGet>
  >,
  TError = ErrorType<HTTPValidationError>
>(
  applicationVersionId: number,
  params: GetDefaultTimeRangeApiV1ApplicationVersionsApplicationVersionIdDefaultTimeRangeGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDefaultTimeRangeApiV1ApplicationVersionsApplicationVersionIdDefaultTimeRangeGet>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetDefaultTimeRangeApiV1ApplicationVersionsApplicationVersionIdDefaultTimeRangeGetQueryOptions(
      applicationVersionId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Property Monitor
 */
export const getPropertyMonitorApiV1ApplicationVersionsApplicationVersionIdGetPropertyMonitorPost = (
  applicationVersionId: number,
  monitorFilterSchema: MonitorFilterSchema,
  params: GetPropertyMonitorApiV1ApplicationVersionsApplicationVersionIdGetPropertyMonitorPostParams
) => {
  return customInstance<PropertiesMonitorSchema>({
    url: `/api/v1/application-versions/${applicationVersionId}/get-property-monitor`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: monitorFilterSchema,
    params
  });
};

export const getGetPropertyMonitorApiV1ApplicationVersionsApplicationVersionIdGetPropertyMonitorPostMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getPropertyMonitorApiV1ApplicationVersionsApplicationVersionIdGetPropertyMonitorPost>>,
    TError,
    {
      applicationVersionId: number;
      data: MonitorFilterSchema;
      params: GetPropertyMonitorApiV1ApplicationVersionsApplicationVersionIdGetPropertyMonitorPostParams;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof getPropertyMonitorApiV1ApplicationVersionsApplicationVersionIdGetPropertyMonitorPost>>,
  TError,
  {
    applicationVersionId: number;
    data: MonitorFilterSchema;
    params: GetPropertyMonitorApiV1ApplicationVersionsApplicationVersionIdGetPropertyMonitorPostParams;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getPropertyMonitorApiV1ApplicationVersionsApplicationVersionIdGetPropertyMonitorPost>>,
    {
      applicationVersionId: number;
      data: MonitorFilterSchema;
      params: GetPropertyMonitorApiV1ApplicationVersionsApplicationVersionIdGetPropertyMonitorPostParams;
    }
  > = props => {
    const { applicationVersionId, data, params } = props ?? {};

    return getPropertyMonitorApiV1ApplicationVersionsApplicationVersionIdGetPropertyMonitorPost(
      applicationVersionId,
      data,
      params
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type GetPropertyMonitorApiV1ApplicationVersionsApplicationVersionIdGetPropertyMonitorPostMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof getPropertyMonitorApiV1ApplicationVersionsApplicationVersionIdGetPropertyMonitorPost>>
  >;
export type GetPropertyMonitorApiV1ApplicationVersionsApplicationVersionIdGetPropertyMonitorPostMutationBody =
  MonitorFilterSchema;
export type GetPropertyMonitorApiV1ApplicationVersionsApplicationVersionIdGetPropertyMonitorPostMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Get Property Monitor
 */
export const useGetPropertyMonitorApiV1ApplicationVersionsApplicationVersionIdGetPropertyMonitorPost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getPropertyMonitorApiV1ApplicationVersionsApplicationVersionIdGetPropertyMonitorPost>>,
    TError,
    {
      applicationVersionId: number;
      data: MonitorFilterSchema;
      params: GetPropertyMonitorApiV1ApplicationVersionsApplicationVersionIdGetPropertyMonitorPostParams;
    },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof getPropertyMonitorApiV1ApplicationVersionsApplicationVersionIdGetPropertyMonitorPost>>,
  TError,
  {
    applicationVersionId: number;
    data: MonitorFilterSchema;
    params: GetPropertyMonitorApiV1ApplicationVersionsApplicationVersionIdGetPropertyMonitorPostParams;
  },
  TContext
> => {
  const mutationOptions =
    getGetPropertyMonitorApiV1ApplicationVersionsApplicationVersionIdGetPropertyMonitorPostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get Annotations Monitor
 */
export const getAnnotationsMonitorApiV1ApplicationVersionsApplicationVersionIdGetAnnotationsMonitorPost = (
  applicationVersionId: number,
  monitorFilterSchema: MonitorFilterSchema
) => {
  return customInstance<AnnotationsMonitorSchema>({
    url: `/api/v1/application-versions/${applicationVersionId}/get-annotations-monitor`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: monitorFilterSchema
  });
};

export const getGetAnnotationsMonitorApiV1ApplicationVersionsApplicationVersionIdGetAnnotationsMonitorPostMutationOptions =
  <TError = ErrorType<HTTPValidationError>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof getAnnotationsMonitorApiV1ApplicationVersionsApplicationVersionIdGetAnnotationsMonitorPost>
      >,
      TError,
      { applicationVersionId: number; data: MonitorFilterSchema },
      TContext
    >;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof getAnnotationsMonitorApiV1ApplicationVersionsApplicationVersionIdGetAnnotationsMonitorPost>
    >,
    TError,
    { applicationVersionId: number; data: MonitorFilterSchema },
    TContext
  > => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof getAnnotationsMonitorApiV1ApplicationVersionsApplicationVersionIdGetAnnotationsMonitorPost>
      >,
      { applicationVersionId: number; data: MonitorFilterSchema }
    > = props => {
      const { applicationVersionId, data } = props ?? {};

      return getAnnotationsMonitorApiV1ApplicationVersionsApplicationVersionIdGetAnnotationsMonitorPost(
        applicationVersionId,
        data
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type GetAnnotationsMonitorApiV1ApplicationVersionsApplicationVersionIdGetAnnotationsMonitorPostMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof getAnnotationsMonitorApiV1ApplicationVersionsApplicationVersionIdGetAnnotationsMonitorPost>
    >
  >;
export type GetAnnotationsMonitorApiV1ApplicationVersionsApplicationVersionIdGetAnnotationsMonitorPostMutationBody =
  MonitorFilterSchema;
export type GetAnnotationsMonitorApiV1ApplicationVersionsApplicationVersionIdGetAnnotationsMonitorPostMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Get Annotations Monitor
 */
export const useGetAnnotationsMonitorApiV1ApplicationVersionsApplicationVersionIdGetAnnotationsMonitorPost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof getAnnotationsMonitorApiV1ApplicationVersionsApplicationVersionIdGetAnnotationsMonitorPost>
    >,
    TError,
    { applicationVersionId: number; data: MonitorFilterSchema },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof getAnnotationsMonitorApiV1ApplicationVersionsApplicationVersionIdGetAnnotationsMonitorPost>
  >,
  TError,
  { applicationVersionId: number; data: MonitorFilterSchema },
  TContext
> => {
  const mutationOptions =
    getGetAnnotationsMonitorApiV1ApplicationVersionsApplicationVersionIdGetAnnotationsMonitorPostMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};

/**
 * Create a new interaction session
 * @summary Create Interaction Session Fastapi
 */
export const createInteractionSession = (
  appVersionId: number,
  interactionSessionCreateSchema: InteractionSessionCreateSchema
) => {
  return customInstance<unknown>({
    url: `/api/v1/application-versions/${appVersionId}/interaction_sessions`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: interactionSessionCreateSchema
  });
};

export const getCreateInteractionSessionMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createInteractionSession>>,
    TError,
    { appVersionId: number; data: InteractionSessionCreateSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createInteractionSession>>,
  TError,
  { appVersionId: number; data: InteractionSessionCreateSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createInteractionSession>>,
    { appVersionId: number; data: InteractionSessionCreateSchema }
  > = props => {
    const { appVersionId, data } = props ?? {};

    return createInteractionSession(appVersionId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateInteractionSessionMutationResult = NonNullable<Awaited<ReturnType<typeof createInteractionSession>>>;
export type CreateInteractionSessionMutationBody = InteractionSessionCreateSchema;
export type CreateInteractionSessionMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Create Interaction Session Fastapi
 */
export const useCreateInteractionSession = <TError = ErrorType<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createInteractionSession>>,
    TError,
    { appVersionId: number; data: InteractionSessionCreateSchema },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createInteractionSession>>,
  TError,
  { appVersionId: number; data: InteractionSessionCreateSchema },
  TContext
> => {
  const mutationOptions = getCreateInteractionSessionMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Get all interaction sessions for a version
 * @summary List Interaction Sessions
 */
export const listInteractionSessions = (
  appVersionId: number,
  params?: ListInteractionSessionsParams,
  signal?: AbortSignal
) => {
  return customInstance<InteractionSessionSchema[]>({
    url: `/api/v1/application-versions/${appVersionId}/interaction_sessions`,
    method: 'GET',
    params,
    signal
  });
};

export const getListInteractionSessionsQueryKey = (appVersionId: number, params?: ListInteractionSessionsParams) => {
  return [`/api/v1/application-versions/${appVersionId}/interaction_sessions`, ...(params ? [params] : [])] as const;
};

export const getListInteractionSessionsQueryOptions = <
  TData = Awaited<ReturnType<typeof listInteractionSessions>>,
  TError = ErrorType<HTTPValidationError>
>(
  appVersionId: number,
  params?: ListInteractionSessionsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof listInteractionSessions>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListInteractionSessionsQueryKey(appVersionId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listInteractionSessions>>> = ({ signal }) =>
    listInteractionSessions(appVersionId, params, signal);

  return { queryKey, queryFn, enabled: !!appVersionId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listInteractionSessions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListInteractionSessionsQueryResult = NonNullable<Awaited<ReturnType<typeof listInteractionSessions>>>;
export type ListInteractionSessionsQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary List Interaction Sessions
 */

export function useListInteractionSessions<
  TData = Awaited<ReturnType<typeof listInteractionSessions>>,
  TError = ErrorType<HTTPValidationError>
>(
  appVersionId: number,
  params?: ListInteractionSessionsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof listInteractionSessions>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getListInteractionSessionsQueryOptions(appVersionId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Delete multiple interaction sessions
 * @summary Delete Interaction Sessions
 */
export const deleteInteractionSessions = (appVersionId: number, params?: DeleteInteractionSessionsParams) => {
  return customInstance<void>({
    url: `/api/v1/application-versions/${appVersionId}/interaction_sessions`,
    method: 'DELETE',
    params
  });
};

export const getDeleteInteractionSessionsMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteInteractionSessions>>,
    TError,
    { appVersionId: number; params?: DeleteInteractionSessionsParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteInteractionSessions>>,
  TError,
  { appVersionId: number; params?: DeleteInteractionSessionsParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteInteractionSessions>>,
    { appVersionId: number; params?: DeleteInteractionSessionsParams }
  > = props => {
    const { appVersionId, params } = props ?? {};

    return deleteInteractionSessions(appVersionId, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteInteractionSessionsMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteInteractionSessions>>
>;

export type DeleteInteractionSessionsMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Delete Interaction Sessions
 */
export const useDeleteInteractionSessions = <TError = ErrorType<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteInteractionSessions>>,
    TError,
    { appVersionId: number; params?: DeleteInteractionSessionsParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteInteractionSessions>>,
  TError,
  { appVersionId: number; params?: DeleteInteractionSessionsParams },
  TContext
> => {
  const mutationOptions = getDeleteInteractionSessionsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Create a new interaction session (Deprecated, please use '/application-versions/{app_version_id}/interaction_sessions' instead)
 * @deprecated
 * @summary Create Interaction Session Fastapi
 */
export const createInteractionSessionDeprecated = (
  appVersionId: number,
  interactionSessionCreateSchema: InteractionSessionCreateSchema
) => {
  return customInstance<unknown>({
    url: `/api/v1/application_versions/${appVersionId}/interaction_sessions`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: interactionSessionCreateSchema
  });
};

export const getCreateInteractionSessionDeprecatedMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createInteractionSessionDeprecated>>,
    TError,
    { appVersionId: number; data: InteractionSessionCreateSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createInteractionSessionDeprecated>>,
  TError,
  { appVersionId: number; data: InteractionSessionCreateSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createInteractionSessionDeprecated>>,
    { appVersionId: number; data: InteractionSessionCreateSchema }
  > = props => {
    const { appVersionId, data } = props ?? {};

    return createInteractionSessionDeprecated(appVersionId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateInteractionSessionDeprecatedMutationResult = NonNullable<
  Awaited<ReturnType<typeof createInteractionSessionDeprecated>>
>;
export type CreateInteractionSessionDeprecatedMutationBody = InteractionSessionCreateSchema;
export type CreateInteractionSessionDeprecatedMutationError = ErrorType<HTTPValidationError>;

/**
 * @deprecated
 * @summary Create Interaction Session Fastapi
 */
export const useCreateInteractionSessionDeprecated = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createInteractionSessionDeprecated>>,
    TError,
    { appVersionId: number; data: InteractionSessionCreateSchema },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createInteractionSessionDeprecated>>,
  TError,
  { appVersionId: number; data: InteractionSessionCreateSchema },
  TContext
> => {
  const mutationOptions = getCreateInteractionSessionDeprecatedMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Get all interaction sessions for a version (Deprecated, please use '/application-versions/{app_version_id}/interaction_sessions' instead)
 * @deprecated
 * @summary List Interaction Sessions
 */
export const listInteractionSessionsDeprecated = (
  appVersionId: number,
  params?: ListInteractionSessionsDeprecatedParams,
  signal?: AbortSignal
) => {
  return customInstance<InteractionSessionSchema[]>({
    url: `/api/v1/application_versions/${appVersionId}/interaction_sessions`,
    method: 'GET',
    params,
    signal
  });
};

export const getListInteractionSessionsDeprecatedQueryKey = (
  appVersionId: number,
  params?: ListInteractionSessionsDeprecatedParams
) => {
  return [`/api/v1/application_versions/${appVersionId}/interaction_sessions`, ...(params ? [params] : [])] as const;
};

export const getListInteractionSessionsDeprecatedQueryOptions = <
  TData = Awaited<ReturnType<typeof listInteractionSessionsDeprecated>>,
  TError = ErrorType<HTTPValidationError>
>(
  appVersionId: number,
  params?: ListInteractionSessionsDeprecatedParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof listInteractionSessionsDeprecated>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListInteractionSessionsDeprecatedQueryKey(appVersionId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listInteractionSessionsDeprecated>>> = ({ signal }) =>
    listInteractionSessionsDeprecated(appVersionId, params, signal);

  return { queryKey, queryFn, enabled: !!appVersionId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listInteractionSessionsDeprecated>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListInteractionSessionsDeprecatedQueryResult = NonNullable<
  Awaited<ReturnType<typeof listInteractionSessionsDeprecated>>
>;
export type ListInteractionSessionsDeprecatedQueryError = ErrorType<HTTPValidationError>;

/**
 * @deprecated
 * @summary List Interaction Sessions
 */

export function useListInteractionSessionsDeprecated<
  TData = Awaited<ReturnType<typeof listInteractionSessionsDeprecated>>,
  TError = ErrorType<HTTPValidationError>
>(
  appVersionId: number,
  params?: ListInteractionSessionsDeprecatedParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof listInteractionSessionsDeprecated>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getListInteractionSessionsDeprecatedQueryOptions(appVersionId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Delete multiple interaction sessions (Deprecated, please use '/application-versions/{app_version_id}/interaction_sessions' instead)
 * @deprecated
 * @summary Delete Interaction Sessions
 */
export const deleteInteractionSessionsDeprecated = (
  appVersionId: number,
  params?: DeleteInteractionSessionsDeprecatedParams
) => {
  return customInstance<void>({
    url: `/api/v1/application_versions/${appVersionId}/interaction_sessions`,
    method: 'DELETE',
    params
  });
};

export const getDeleteInteractionSessionsDeprecatedMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteInteractionSessionsDeprecated>>,
    TError,
    { appVersionId: number; params?: DeleteInteractionSessionsDeprecatedParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteInteractionSessionsDeprecated>>,
  TError,
  { appVersionId: number; params?: DeleteInteractionSessionsDeprecatedParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteInteractionSessionsDeprecated>>,
    { appVersionId: number; params?: DeleteInteractionSessionsDeprecatedParams }
  > = props => {
    const { appVersionId, params } = props ?? {};

    return deleteInteractionSessionsDeprecated(appVersionId, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteInteractionSessionsDeprecatedMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteInteractionSessionsDeprecated>>
>;

export type DeleteInteractionSessionsDeprecatedMutationError = ErrorType<HTTPValidationError>;

/**
 * @deprecated
 * @summary Delete Interaction Sessions
 */
export const useDeleteInteractionSessionsDeprecated = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteInteractionSessionsDeprecated>>,
    TError,
    { appVersionId: number; params?: DeleteInteractionSessionsDeprecatedParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteInteractionSessionsDeprecated>>,
  TError,
  { appVersionId: number; params?: DeleteInteractionSessionsDeprecatedParams },
  TContext
> => {
  const mutationOptions = getDeleteInteractionSessionsDeprecatedMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Delete a specific interaction session
 * @summary Delete Interaction Session
 */
export const deleteInteractionSession = (appVersionId: number, interactionSessionId: string) => {
  return customInstance<void>({
    url: `/api/v1/application-versions/${appVersionId}/interaction_sessions/${interactionSessionId}`,
    method: 'DELETE'
  });
};

export const getDeleteInteractionSessionMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteInteractionSession>>,
    TError,
    { appVersionId: number; interactionSessionId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteInteractionSession>>,
  TError,
  { appVersionId: number; interactionSessionId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteInteractionSession>>,
    { appVersionId: number; interactionSessionId: string }
  > = props => {
    const { appVersionId, interactionSessionId } = props ?? {};

    return deleteInteractionSession(appVersionId, interactionSessionId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteInteractionSessionMutationResult = NonNullable<Awaited<ReturnType<typeof deleteInteractionSession>>>;

export type DeleteInteractionSessionMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Delete Interaction Session
 */
export const useDeleteInteractionSession = <TError = ErrorType<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteInteractionSession>>,
    TError,
    { appVersionId: number; interactionSessionId: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteInteractionSession>>,
  TError,
  { appVersionId: number; interactionSessionId: string },
  TContext
> => {
  const mutationOptions = getDeleteInteractionSessionMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Delete a specific interaction session (Deprecated, please use '/application-versions/{app_version_id}/interaction_sessions/{interaction_session_id}' instead)
 * @deprecated
 * @summary Delete Interaction Session
 */
export const deleteInteractionSessionDeprecated = (appVersionId: number, interactionSessionId: string) => {
  return customInstance<void>({
    url: `/api/v1/application_versions/${appVersionId}/interaction_sessions/${interactionSessionId}`,
    method: 'DELETE'
  });
};

export const getDeleteInteractionSessionDeprecatedMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteInteractionSessionDeprecated>>,
    TError,
    { appVersionId: number; interactionSessionId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteInteractionSessionDeprecated>>,
  TError,
  { appVersionId: number; interactionSessionId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteInteractionSessionDeprecated>>,
    { appVersionId: number; interactionSessionId: string }
  > = props => {
    const { appVersionId, interactionSessionId } = props ?? {};

    return deleteInteractionSessionDeprecated(appVersionId, interactionSessionId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteInteractionSessionDeprecatedMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteInteractionSessionDeprecated>>
>;

export type DeleteInteractionSessionDeprecatedMutationError = ErrorType<HTTPValidationError>;

/**
 * @deprecated
 * @summary Delete Interaction Session
 */
export const useDeleteInteractionSessionDeprecated = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteInteractionSessionDeprecated>>,
    TError,
    { appVersionId: number; interactionSessionId: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteInteractionSessionDeprecated>>,
  TError,
  { appVersionId: number; interactionSessionId: string },
  TContext
> => {
  const mutationOptions = getDeleteInteractionSessionDeprecatedMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Get interaction session by ID
 * @summary Get Interaction Session
 */
export const getInteractionSession = (appVersionId: number, sessionId: string, signal?: AbortSignal) => {
  return customInstance<ReadInteractionSessionSchema>({
    url: `/api/v1/application-versions/${appVersionId}/interaction_sessions/${sessionId}`,
    method: 'GET',
    signal
  });
};

export const getGetInteractionSessionQueryKey = (appVersionId: number, sessionId: string) => {
  return [`/api/v1/application-versions/${appVersionId}/interaction_sessions/${sessionId}`] as const;
};

export const getGetInteractionSessionQueryOptions = <
  TData = Awaited<ReturnType<typeof getInteractionSession>>,
  TError = ErrorType<HTTPValidationError>
>(
  appVersionId: number,
  sessionId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getInteractionSession>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetInteractionSessionQueryKey(appVersionId, sessionId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getInteractionSession>>> = ({ signal }) =>
    getInteractionSession(appVersionId, sessionId, signal);

  return { queryKey, queryFn, enabled: !!(appVersionId && sessionId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getInteractionSession>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetInteractionSessionQueryResult = NonNullable<Awaited<ReturnType<typeof getInteractionSession>>>;
export type GetInteractionSessionQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Interaction Session
 */

export function useGetInteractionSession<
  TData = Awaited<ReturnType<typeof getInteractionSession>>,
  TError = ErrorType<HTTPValidationError>
>(
  appVersionId: number,
  sessionId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getInteractionSession>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetInteractionSessionQueryOptions(appVersionId, sessionId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Update interaction session by ID
 * @summary Update Interaction Session Router
 */
export const updateInteractionSession = (
  appVersionId: number,
  sessionId: string,
  updateInteractionSessionSchema: UpdateInteractionSessionSchema
) => {
  return customInstance<unknown>({
    url: `/api/v1/application-versions/${appVersionId}/interaction_sessions/${sessionId}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: updateInteractionSessionSchema
  });
};

export const getUpdateInteractionSessionMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateInteractionSession>>,
    TError,
    { appVersionId: number; sessionId: string; data: UpdateInteractionSessionSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateInteractionSession>>,
  TError,
  { appVersionId: number; sessionId: string; data: UpdateInteractionSessionSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateInteractionSession>>,
    { appVersionId: number; sessionId: string; data: UpdateInteractionSessionSchema }
  > = props => {
    const { appVersionId, sessionId, data } = props ?? {};

    return updateInteractionSession(appVersionId, sessionId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateInteractionSessionMutationResult = NonNullable<Awaited<ReturnType<typeof updateInteractionSession>>>;
export type UpdateInteractionSessionMutationBody = UpdateInteractionSessionSchema;
export type UpdateInteractionSessionMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Update Interaction Session Router
 */
export const useUpdateInteractionSession = <TError = ErrorType<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateInteractionSession>>,
    TError,
    { appVersionId: number; sessionId: string; data: UpdateInteractionSessionSchema },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateInteractionSession>>,
  TError,
  { appVersionId: number; sessionId: string; data: UpdateInteractionSessionSchema },
  TContext
> => {
  const mutationOptions = getUpdateInteractionSessionMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Get interaction session by ID (Deprecated, please use '/application-versions/{app_version_id}/interaction_sessions/{session_id}' instead)
 * @deprecated
 * @summary Get Interaction Session
 */
export const getInteractionSessionDeprecated = (appVersionId: number, sessionId: string, signal?: AbortSignal) => {
  return customInstance<ReadInteractionSessionSchema>({
    url: `/api/v1/application_versions/${appVersionId}/interaction_sessions/${sessionId}`,
    method: 'GET',
    signal
  });
};

export const getGetInteractionSessionDeprecatedQueryKey = (appVersionId: number, sessionId: string) => {
  return [`/api/v1/application_versions/${appVersionId}/interaction_sessions/${sessionId}`] as const;
};

export const getGetInteractionSessionDeprecatedQueryOptions = <
  TData = Awaited<ReturnType<typeof getInteractionSessionDeprecated>>,
  TError = ErrorType<HTTPValidationError>
>(
  appVersionId: number,
  sessionId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getInteractionSessionDeprecated>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetInteractionSessionDeprecatedQueryKey(appVersionId, sessionId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getInteractionSessionDeprecated>>> = ({ signal }) =>
    getInteractionSessionDeprecated(appVersionId, sessionId, signal);

  return { queryKey, queryFn, enabled: !!(appVersionId && sessionId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getInteractionSessionDeprecated>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetInteractionSessionDeprecatedQueryResult = NonNullable<
  Awaited<ReturnType<typeof getInteractionSessionDeprecated>>
>;
export type GetInteractionSessionDeprecatedQueryError = ErrorType<HTTPValidationError>;

/**
 * @deprecated
 * @summary Get Interaction Session
 */

export function useGetInteractionSessionDeprecated<
  TData = Awaited<ReturnType<typeof getInteractionSessionDeprecated>>,
  TError = ErrorType<HTTPValidationError>
>(
  appVersionId: number,
  sessionId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getInteractionSessionDeprecated>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetInteractionSessionDeprecatedQueryOptions(appVersionId, sessionId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Update interaction session by ID (Deprecated, please use '/application-versions/{app_version_id}/interaction_sessions/{session_id}' instead)
 * @deprecated
 * @summary Update Interaction Session Router
 */
export const updateInteractionSessionDeprecated = (
  appVersionId: number,
  sessionId: string,
  updateInteractionSessionSchema: UpdateInteractionSessionSchema
) => {
  return customInstance<unknown>({
    url: `/api/v1/application_versions/${appVersionId}/interaction_sessions/${sessionId}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: updateInteractionSessionSchema
  });
};

export const getUpdateInteractionSessionDeprecatedMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateInteractionSessionDeprecated>>,
    TError,
    { appVersionId: number; sessionId: string; data: UpdateInteractionSessionSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateInteractionSessionDeprecated>>,
  TError,
  { appVersionId: number; sessionId: string; data: UpdateInteractionSessionSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateInteractionSessionDeprecated>>,
    { appVersionId: number; sessionId: string; data: UpdateInteractionSessionSchema }
  > = props => {
    const { appVersionId, sessionId, data } = props ?? {};

    return updateInteractionSessionDeprecated(appVersionId, sessionId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateInteractionSessionDeprecatedMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateInteractionSessionDeprecated>>
>;
export type UpdateInteractionSessionDeprecatedMutationBody = UpdateInteractionSessionSchema;
export type UpdateInteractionSessionDeprecatedMutationError = ErrorType<HTTPValidationError>;

/**
 * @deprecated
 * @summary Update Interaction Session Router
 */
export const useUpdateInteractionSessionDeprecated = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateInteractionSessionDeprecated>>,
    TError,
    { appVersionId: number; sessionId: string; data: UpdateInteractionSessionSchema },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateInteractionSessionDeprecated>>,
  TError,
  { appVersionId: number; sessionId: string; data: UpdateInteractionSessionSchema },
  TContext
> => {
  const mutationOptions = getUpdateInteractionSessionDeprecatedMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Upload file with interactions (csv or excel)
 * @summary Upload
 */
export const uploadApiV1UploadCsvPost = (bodyUploadApiV1UploadCsvPost: BodyUploadApiV1UploadCsvPost) => {
  const formData = new FormData();
  formData.append('application_name', bodyUploadApiV1UploadCsvPost.application_name);
  formData.append('application_version_name', bodyUploadApiV1UploadCsvPost.application_version_name);
  formData.append('env_type', bodyUploadApiV1UploadCsvPost.env_type);
  formData.append('file', bodyUploadApiV1UploadCsvPost.file);

  return customInstance<unknown>({
    url: `/api/v1/upload-csv`,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData
  });
};

export const getUploadApiV1UploadCsvPostMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadApiV1UploadCsvPost>>,
    TError,
    { data: BodyUploadApiV1UploadCsvPost },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof uploadApiV1UploadCsvPost>>,
  TError,
  { data: BodyUploadApiV1UploadCsvPost },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof uploadApiV1UploadCsvPost>>,
    { data: BodyUploadApiV1UploadCsvPost }
  > = props => {
    const { data } = props ?? {};

    return uploadApiV1UploadCsvPost(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UploadApiV1UploadCsvPostMutationResult = NonNullable<Awaited<ReturnType<typeof uploadApiV1UploadCsvPost>>>;
export type UploadApiV1UploadCsvPostMutationBody = BodyUploadApiV1UploadCsvPost;
export type UploadApiV1UploadCsvPostMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Upload
 */
export const useUploadApiV1UploadCsvPost = <TError = ErrorType<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadApiV1UploadCsvPost>>,
    TError,
    { data: BodyUploadApiV1UploadCsvPost },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof uploadApiV1UploadCsvPost>>,
  TError,
  { data: BodyUploadApiV1UploadCsvPost },
  TContext
> => {
  const mutationOptions = getUploadApiV1UploadCsvPostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Create interactions
 * @summary Create Interactions
 */
export const createInteractions = (bodyCreateInteractions: BodyCreateInteractions) => {
  return customInstance<CreateInteractions201Item[]>({
    url: `/api/v1/interactions`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: bodyCreateInteractions
  });
};

export const getCreateInteractionsMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createInteractions>>,
    TError,
    { data: BodyCreateInteractions },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createInteractions>>,
  TError,
  { data: BodyCreateInteractions },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createInteractions>>,
    { data: BodyCreateInteractions }
  > = props => {
    const { data } = props ?? {};

    return createInteractions(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateInteractionsMutationResult = NonNullable<Awaited<ReturnType<typeof createInteractions>>>;
export type CreateInteractionsMutationBody = BodyCreateInteractions;
export type CreateInteractionsMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Create Interactions
 */
export const useCreateInteractions = <TError = ErrorType<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createInteractions>>,
    TError,
    { data: BodyCreateInteractions },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createInteractions>>,
  TError,
  { data: BodyCreateInteractions },
  TContext
> => {
  const mutationOptions = getCreateInteractionsMutationOptions(options);

  return useMutation(mutationOptions);
};
